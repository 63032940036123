<template>
  <v-container class="fill-height px-5" style="min-width: 1190px; max-width: 1400px; max-height: 100%">
    <v-row class="fill-height">
      <v-col
        class="rounded-xl"
        style="z-index:99; background-color: white;height: 100%; min-width: 550px;"
        cols="6"
      >
        <v-row class="mt-10">
          <v-col
            offset="2"
            cols="8"
            class="d-flex flex-column align-center"
          >
            <img src="/img/Komacut-logo.svg" width="180px">

            <span class="text-h5 font-weight-bold mt-8">
              {{ $t('views.forgottenPassword.forgotYourPassword') }}
            </span>
            <span class="mt-5 mb-16">
              {{ $t('views.forgottenPassword.dontWorry') }}</span>

            <v-form
              ref="loginForm"
              v-model="isLoginFormValid"
              style="width: 100%;"
              class="mt-16"
              @submit.prevent="emailSubmit"
            >
              <v-text-field
                v-model="email"
                :label="$t('common.email')"
                :rules="[isRequired]"
                data-cy="forgotten-password-email-input"
                outlined
                dense
                autofocus
              />

              <v-btn
                color="success"
                type="submits"
                :disabled="isSendResetPwdPending"
                :loading="isSendResetPwdPending"
                data-cy="reset-password-button"
                large
                block
              >
                {{ $t('views.forgottenPassword.resetPassword') }}
              </v-btn>
            </v-form>
            <div style="width: 100%;" class="mt-16 d-flex justify-start">
              <v-btn
                class="pl-0"
                color="primary"
                text
                @click="$router.go(-1)"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{ $t('common.back') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <p class="text--secondary caption text-center mt-16 mb-0" style="margin-top: 250px !important">
          {{ $t('common.footerRight') }}  <a href="https://komacut.com/about-us/contact-us/" class="text-decoration-none"> {{ $t('common.contactUs') }}</a>
        </p>
      </v-col>
      <v-col cols="6" class="pl-0 d-flex justify-center align-center">
        <core-promotional />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapActions } from 'vuex';
import FormRules from '@/mixins/form-rules';

export default {
  name: 'login',

  mixins: [
    FormRules,
  ],

  data() {
    return {
      email: '',
      isLoginFormValid: false,
      isSendResetPwdPending: false,
    };
  },

  methods: {
    ...mapActions('app', ['pushSnack']),
    async emailSubmit() {
      try {
        this.isSendResetPwdPending = true;
        await this.$feathers.service('authManagement').create({
          action: 'sendResetPwd',
          value: {
            _id: this.email,
          }
        });
        this.isSendResetPwdPending = false;
        this.$router.push('/resetpassword_send_email');
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.login-card {
  z-index: 5;
}

</style>
