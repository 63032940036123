import Vue from 'vue';

export const reset = defaults => state => {
  Object.assign(state, defaults || {});
};

export const clear = property => state => {
  const currentState = state;
  Vue.delete(currentState, property);
};

export const clearAt = property => (state, payload) => {
  const currentState = state;
  Vue.delete(_.get(currentState, property), payload._id);
};

export const set = property => (state, payload) => {
  const currentState = state;
  Vue.set(currentState, property, payload);
};

export const setAt = property => (state, payload) => {
  const currentState = state;
  Vue.set(_.get(currentState, property), payload._id, payload);
};

export const update = property => (state, payload) => {
  const currentState = state;
  const old = _.get(currentState, property);
  Vue.set(currentState, property, { ...old, ...payload });
};

export const updateAt = property => (state, payload) => {
  const currentState = state;
  const old = _.get(currentState, [property, payload._id]);
  Vue.set(_.get(currentState, property), payload._id, { ...old, ...payload });
};

export const toggle = property => state => {
  const currentState = state;
  Vue.set(currentState, property, !state[property]);
};

export const setStorage = property => (state, payload) => {
  const currentState = state;
  Vue.set(currentState, property, payload);
  let v = payload;
  if (_.isObject(payload)) {
    v = JSON.stringify(payload);
  }
  localStorage.setItem(property, v);
};

export const toggleStorage = property => state => {
  const currentState = state;
  Vue.set(currentState, property, !state[property]);
  localStorage.setItem(property, currentState[property]);
};

export const clearStorage = property => state => {
  const currentState = state;
  Vue.set(currentState, property, undefined);
  localStorage.removeItem(property);
};
