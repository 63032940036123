import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.VUE_APP_DATADOG_RUM_APP_ID,
  clientToken: process.env.VUE_APP_DATADOG_RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: `komacut-${process.env.NODE_ENV}`,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackInteractions: true
});

datadogRum.startSessionReplayRecording();
