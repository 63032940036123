export default {
  props: {
    // Service name. This will automate fetching, pagination, etc...
    modelName: String,
  },

  computed: {
    /**
     * Returns the model from the modelName property.
     *
     * @returns {BaseModel}
     */
    model() {
      // ***************** TODO : Uncomment when backend needed
      return this.$FeathersVuex.api[this.modelName];
      // return true;
    },

    /**
     * Returns the model service namespace.
     *
     * @returns {string}
     */
    namespace() {
      return _.get(this.model, 'namespace');
    },

    /**
     * Returns the store.
     *
     * @returns {object}
     */
    store() {
      return _.get(this.model, 'store');
    },

    /**
     * Returns the service's store state.
     *
     * @returns {object}
     */
    storeState() {
      return _.get(this.store, ['state', this.namespace]);
    },

    /**
     * Returns all store's getters.
     *
     * @returns {[string]}
     */
    storeGetters() {
      return _.get(this.store, 'getters');
    },

    /**
     * Returns list of all documents in the store.
     *
     * @returns {array}
     */
    list() {
      return this.storeGetter('list');
    },
  },

  methods: {
    /**
     * Generate a service action or getter store path.
     *
     * @param {string} name Name of getter or action.
     *
     * @returns {string}
     */
    ns(name) {
      return `${this.namespace}/${name}`;
    },

    /**
     * Retrieve a specific getter from the service store.
     *
     * @param {string} name Name of the getter.
     *
     * @returns {any}
     */
    storeGetter(name) {
      return _.get(this.storeGetters, this.ns(name));
    },

    /**
     * Dispatch the service store action.
     *
     * @param {string} name Name of the action to dispatch.
     * @param  {...any} args
     *
     * @returns {Promise}
     */
    storeDispatch(name, ...args) {
      return this.store.dispatch(this.ns(name), ...args);
    },

    /**
     * Find a store entity by it's id
     */
    getById(_id) {
      return this.store && this.storeDispatch('get', _id);
    },

    /**
     * Get documents from the service store.
     *
     * @param {object} params Find action params to pass.
     *
     * @returns {array}
     */
    find(params) {
      return this.store
        ? this.storeGetter('find')(params).data
        : [];
    },

    /**
     * Fetch documents from the server.
     *
     * @param {object} params Find action params to pass.
     *
     * @returns {Promise}
     */
    fetch(params) {
      return this.store
        ? this.storeDispatch('find', params)
        : {
          data: [],
          total: 0,
        };
    },

    /**
     * Generate a mongo query object.
     *
     * @param {object} options To build the mongo query.
     *    {boolean} local If the query if generated for local store or server use.
     *    {string|[string]} sortKey Key or keys to sort by.
     *    {boolean|[boolean]} sortDesc Sort descending order or not.
     *    {number} limit Maximum number of documents to retrieve.
     *    {number} page Current page of documents to retrieve.
     *    {string} search Full text (almost) search value.
     *    {[string]} searchFields Fields to use for the text search.
     *
     * @returns {string}
     */
    generateQuery(options) {
      const keys = options.sortKey;
      const orders = options.sortDesc;
      const sort = {};

      if (Array.isArray(keys)) {
        for (let x = 0; x < keys.length; x++) {
          const k = keys[x];
          const o = orders === false || _.get(orders, x) === false;
          if (o !== undefined) {
            sort[k] = o === false ? 1 : -1;
          }
        }
      } else if (keys && orders !== undefined) {
        sort[keys] = orders === false ? 1 : -1;
      }

      let query = {
        $skip: options.limit * Math.max(0, options.page - 1),
        $limit: options.limit,
        $sort: sort,
      };

      if (!_.isEmpty(options.search)) {
        // Documents should already be aggregated from the server here
        // Just do a query on searchFields and newly aggregated fields
        if (options.local) {
          const aggregateFields = [];

          const re = new RegExp(options.search, 'i');

          const instancePaths = _.get(this.model, 'instanceDefaults', () => undefined)();

          // Get only fields of type string
          const stringFields = options.searchFields.filter(f => _.isString(_.get(instancePaths, f)));
          const fields = [
            ...stringFields.map(field => ({ [field]: re })),
            { 'createdBy.displayName': re },
            { 'createdBy.email': re },
            { 'updatedBy.displayName': re },
            { 'updatedBy.email': re },
          ];

          if (options.searchAggregate) {
            for (const a of options.searchAggregate) {
              const { searchFields } = a;

              const searchFieldsPaths = {};
              searchFields.map(f => _.set(searchFieldsPaths, [f], ''));

              const servicePaths = {
                ..._.get(
                  this.$FeathersVuex.api.byServicePath,
                  [a.from || '', 'instanceDefaults'],
                  () => {}
                )(),
                ...searchFieldsPaths,
              };

              // Get only fields of type string
              const serviceStringFields = searchFields.filter(f => _.isString(_.get(servicePaths, [f])));

              serviceStringFields.forEach(f => {
                aggregateFields.push({ [f]: re });
              });
            }
          }

          query.$or = [
            ...fields,
            ...aggregateFields,
          ];
        } else {
          query.search = options.search;
          query.searchFields = [...options.searchFields, 'createdBy', 'updatedBy'];
          query.searchAggregate = _.compact([
            ...(options.searchAggregate || []),
            {
              from: 'users',
              localField: 'updatedById',
              foreignField: '_id',
              as: 'updatedByDetail',
              project: { displayName: 1, email: 1 },
              searchFields: [
                'updatedByDetail.displayName',
                'updatedByDetail.email',
              ],
            },
            {
              from: 'users',
              localField: 'createdById',
              foreignField: '_id',
              as: 'createdByDetail',
              project: { displayName: 1, email: 1 },
              searchFields: [
                'createdByDetail.displayName',
                'createdByDetail.email',
              ],
            },
          ]);
        }
      }

      const filterField = _.get(options.filter, 'field');
      const filterValue = _.get(options.filter, 'value');
      if (!_.isEmpty(filterField) && !_.isEmpty(filterValue) && filterValue !== '*') {
        if (filterValue.startsWith('*')) {
          query[filterField] = new RegExp(`^${filterValue.substr(1)}`);
        } else if (filterValue.endsWith('*')) {
          query[filterField] = new RegExp(`${filterValue.substr(-1)}$`);
        } else {
          query[filterField] = filterValue;
        }
      }

      query = { ...query, ...(options.query || {}) };

      return query;
    },

    /**
     * Remove a document from store and server.
     *
     * @param {string} _id Id of document to remove.
     *
     * @returns {Promise}
     */
    removeDoc(_id) {
      return this.store
        ? this.storeDispatch('remove', _id).then(() => this.$refs.tabs.removeTab(-1, _id))
        : Promise.resolve(() => {});
    },

    /**
     * Remove multiple documents from store and server.
     *
     * @param {[string]} docIds Array of document ids.
     *
     * @returns {Promise}
     */
    remove(docIds) {
      const promises = [];
      for (const _id of docIds) {
        promises.push(this.removeDoc(_id));
      }
      return Promise.all(promises);
    },
  },
};
