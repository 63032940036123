const PAYMENT_STATUS = Object.freeze({
  INITIATED: 'initiated',
  IN_PROGRESS: 'inProgress',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
});

module.exports = {
  PAYMENT_STATUS
};
