import ModelableMixin from './modelable';

export default {
  mixins: [
    ModelableMixin,
  ],

  props: {
    value: {},
  },

  mounted() {
    this.originalFormData = _.cloneDeep(this.value);
  },

  data() {
    return {
      originalFormData: {},
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },

    originalFormDataChanged() {
      return !_.isEqual(this.localValue, this.originalFormData);
    },
  },
};
