<template>
  <v-card
    v-if="showBanner"
    rounded
    width="95%"
    min-width="1118"
    height="101px"
    color="#F9F9F9"
    outlined
    style="background-color: #616161"
  >
    <v-card-text style="color: #FFFFFF">
      <v-row clas="d-flex">
        <img
          class="mt-n8 ml-1"
          src="/img/welcomeScreen/icon-referral-speaker.svg"
        >
        <div class="ml-4 mt-3 text-h4 font-weight-bold text-uppercase">
          {{ $t('views.dashboard.promotionCountdownBanner.tenPercentOff') }}
        </div>
        <v-col style="max-width: 230px;">
          <v-row>
            <div class="mt-n3 ml-4 text-h6 font-weight-bold">
              {{ $t('views.dashboard.promotionCountdownBanner.forNewCustomer') }}
            </div>
          </v-row>
          <v-row>
            <div class="ml-4 text-h6 font-weight-bold">
              {{ $t('views.dashboard.promotionCountdownBanner.onInitialPurchase') }}
            </div>
          </v-row>
        </v-col>
        <v-col style="max-width: 100px;">
          <v-row class="mt-n2">
            <div class="ml-4 text-body-2 font-weight-bold" style="color: #C5FDCB">
              {{ $t('views.dashboard.promotionCountdownBanner.promotion') }}
            </div>
          </v-row>
          <v-row>
            <div class="ml-4 text-body-2 font-weight-bold" style="color: #C5FDCB">
              {{ $t('views.dashboard.promotionCountdownBanner.endingIn') }}
            </div>
          </v-row>
        </v-col>
        <v-col class="pa-0" style="max-width: 260px;">
          <v-row class="ml-2" no-gutters>
            <v-col style="max-width: 60px;">
              <v-row no-gutters>
                <v-sheet
                  elevation="1"
                  height="50"
                  rounded
                  shaped
                  width="47"
                  color="#323232"
                >
                  <div
                    class="text-h6 font-weight-bold pt-2 pl-3"
                    style="color: #FFFFFF;"
                  >
                    {{ remainingTime.days }}
                  </div>
                </v-sheet>
              </v-row>
              <v-row no-gutters>
                <span
                  class="text-caption ml-2 font-weight-bold"
                  style="color: #C5FDCB"
                >
                  {{ $t('views.dashboard.promotionCountdownBanner.days') }}
                </span>
              </v-row>
            </v-col>
            <v-col style="max-width: 60px;">
              <v-row no-gutters>
                <v-sheet
                  elevation="1"
                  height="50"
                  rounded
                  shaped
                  width="47"
                  color="#323232"
                >
                  <div
                    class="text-h6 font-weight-bold pt-2 pl-3"
                    style="color: #FFFFFF;"
                  >
                    {{ remainingTime.hours }}
                  </div>
                </v-sheet>
              </v-row>
              <v-row no-gutters>
                <span
                  class="text-caption ml-2 font-weight-bold"
                  style="color: #C5FDCB"
                >
                  {{ $t('views.dashboard.promotionCountdownBanner.hours') }}
                </span>
              </v-row>
            </v-col>
            <v-col style="max-width: 60px;">
              <v-row no-gutters>
                <v-sheet
                  elevation="1"
                  height="50"
                  rounded
                  shaped
                  width="47"
                  color="#323232"
                >
                  <div
                    class="text-h6 font-weight-bold pt-2 pl-3"
                    style="color: #FFFFFF;"
                  >
                    {{ remainingTime.minutes }}
                  </div>
                </v-sheet>
              </v-row>
              <v-row no-gutters>
                <span
                  class="text-caption font-weight-bold"
                  style="color: #C5FDCB"
                >
                  {{ $t('views.dashboard.promotionCountdownBanner.minutes') }}
                </span>
              </v-row>
            </v-col>
            <v-col style="max-width: 60px;">
              <v-row no-gutters>
                <v-sheet
                  elevation="1"
                  height="50"
                  rounded
                  shaped
                  width="47"
                  color="#323232"
                >
                  <div
                    class="text-h6 font-weight-bold pt-2 pl-3"
                    style="color: #FFFFFF;"
                  >
                    {{ remainingTime.seconds }}
                  </div>
                </v-sheet>
              </v-row>
              <v-row no-gutters>
                <span
                  class="text-caption font-weight-bold"
                  style="color: #C5FDCB"
                >
                  {{ $t('views.dashboard.promotionCountdownBanner.seconds') }}
                </span>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <div class="d-flex flex-column ml-6">
          <v-btn
            class="mt-2"
            color="primary"
            @click="onActionButtonClicked"
          >
            {{ $t('views.dashboard.promotionCountdownBanner.getYourDiscount') }}
          </v-btn>
          <span class="mt-1 text-caption text-decoration-underline font-weight-bold d-flex justify-center">
            <a href="https://www.komacut.com/legal/discount-on-first-order/" target="_blank" style="color: #C5FDCB">
              {{ $t('views.dashboard.promotionCountdownBanner.viewTerms') }}
            </a>
          </span>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WelcomeScreenPromotionCountdownBanner',
  props: {
    expiryTime: { type: String },
  },
  data() {
    return {
      showBanner: false,
      targetTime: new Date(),
      remainingTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  computed: {
    ...mapGetters('temp-rfqs', { getTempRfqIdInProgress: 'getTempRfqIdInProgress' }),
  },
  mounted() {
    this.targetTime = moment(this.expiryTime, 'YYYYMMDD HH:mm:ss.SSS').toDate();

    this.updateCountdown();
    // Update the countdown every second
    this.countdownInterval = setInterval(this.updateCountdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const timeDiff = this.targetTime - now;

      if (timeDiff > 0) {
        this.remainingTime.days = Math.floor(timeDiff / (24 * 3600000));
        this.remainingTime.hours = Math.floor((timeDiff % (24 * 3600000)) / 3600000);
        this.remainingTime.minutes = Math.floor((timeDiff % 3600000) / 60000);
        this.remainingTime.seconds = Math.floor((timeDiff % 60000) / 1000);
        if (!this.showBanner) {
          this.showBanner = true;
        }
      } else {
        this.showBanner = false;
        // Countdown has ended, you can add logic here
        clearInterval(this.countdownInterval);
      }
    },
    onActionButtonClicked() {
      this.$router.push(`/rfqs/${this.getTempRfqIdInProgress}`);
    },
  },
};
</script>

<style scoped>
</style>
