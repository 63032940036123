<template>
  <div
    style="background-color: #E9F4ED; font-size: 15px"
    class="rounded d-flex flex-column align-center py-0 my-0 text-truncate"
  >
    <div>
      <span class="body-2 text-right">{{ $t('views.instantQuote.quantity') }}: {{ '\xa0' }} </span>
      <span class="body-2">{{ $t('common.customizedBagPrice') }}</span>
    </div>

    <v-container class="py-0">
      <v-row class="flex-nowrap">
        <template v-for="(bracket, i) in customerPriceRange">
          <v-spacer v-if="i>0" :key="`space-${i}`" />
          <v-col :key="i" class="text-center pa-0">
            <span
              :key="`qty-${i}`"
              style="color: #8D8D8D;text-overflow: ellipsis; style:15px"
              :data-cy="`unit-price-qty-${i}`"
            >
              {{ i === 0 ? quantity : bracket.Qty }}:
            </span>
            <span
              :key="`price-${i}`"
              class="text-truncate"
              :data-cy="`unit-price-amount-${i}`"
            >
              {{

                currency(bracket.Price, {
                  symbol: company.currencySymbol,
                  precision: 4
                }).format()

              }} {{ '\xa0' }}
            </span>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import currency from 'currency.js';
import { models } from 'feathers-vuex';

const { Company } = models.api;

export default {
  name: 'CustomBagPriceMoqTable',
  props: {
    part: Object,
  },
  data: () => ({
    currency,
  }),
  computed: {
    company: () => Company.getFromStore(0),

    priceRange: vm => vm.part.priceMoq,

    quantity: vm => {
      if (!vm.part.ReqQty || vm.part.ReqQty <= 0) {
        return 1;
      }
      if (!vm.part.ReqQty || vm.part.ReqQty <= 100) {
        return 100;
      }
      if (vm.part.ReqQty >= 1000000) {
        return 1000000;
      }
      return vm.part.ReqQty;
    },
    customerPriceRange() {
      if (!this.priceRange || this.quantity <= 0) {
        return [];
      }

      const reqQtyPriceIndex = this.priceRange
        .findIndex(range => range.Qty > this.quantity) - 1;

      if (reqQtyPriceIndex < 0) {
        return this.priceRange.slice(-1);
      }
      return this.priceRange
        .slice(reqQtyPriceIndex, Math.min(reqQtyPriceIndex + 3, this.priceRange.length));
    },
  },
};
</script>
