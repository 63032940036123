<template>
  <v-card
    rounded
    width="95%"
    color="#F9F9F9"
    elevation="0"
    class="transparent"
  >
    <v-card-text>
      <v-row>
        <v-col cols="3" style="">
          <v-row>
            <img
              style="vertical-align:middle"
              src="/img/welcomeScreen/icon-order-step1.svg"
            >
            <v-divider class="dashed-line mt-8 mx-1" />
          </v-row>
          <v-row class="">
            <v-col cols="2" class="ml-n4">
              <img
                style="vertical-align:middle"
                src="/img/welcomeScreen/icon-digit-1.svg"
              >
            </v-col>
            <v-col cols="10">
              <v-row>
                <div class="text-body-1 font-weight-medium text-uppercase">
                  {{ $t('views.dashboard.welcomeScreenOrderStepsBanner.step1Label') }}
                </div>
              </v-row>
              <v-row>
                <div class="" style="color: #747474">
                  {{ $t('views.dashboard.welcomeScreenOrderStepsBanner.step1Desc') }}
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" style="">
          <v-row>
            <img
              style="vertical-align:middle"
              src="/img/welcomeScreen/icon-order-step2.svg"
            >
            <v-divider class="dashed-line mt-8 mx-1" />
          </v-row>
          <v-row>
            <v-col cols="2" class="ml-n4">
              <img
                style="vertical-align:middle"
                src="/img/welcomeScreen/icon-digit-2.svg"
              >
            </v-col>
            <v-col cols="10">
              <v-row>
                <div class="text-body-1 font-weight-medium text-uppercase">
                  {{ $t('views.dashboard.welcomeScreenOrderStepsBanner.step2Label') }}
                </div>
              </v-row>
              <v-row>
                <div class="" style="color: #747474">
                  {{ $t('views.dashboard.welcomeScreenOrderStepsBanner.step2Desc') }}
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" style="">
          <v-row>
            <img
              style="vertical-align:middle"
              src="/img/welcomeScreen/icon-order-step3.svg"
            >
            <v-divider class="dashed-line mt-8 mx-1" />
          </v-row>
          <v-row>
            <v-col cols="2" class="ml-n4">
              <img
                style="vertical-align:middle"
                src="/img/welcomeScreen/icon-digit-3.svg"
              >
            </v-col>
            <v-col cols="10">
              <v-row>
                <div class="text-body-1 font-weight-medium text-uppercase">
                  {{ $t('views.dashboard.welcomeScreenOrderStepsBanner.step3Label') }}
                </div>
              </v-row>
              <v-row>
                <div class="" style="color: #747474">
                  {{ $t('views.dashboard.welcomeScreenOrderStepsBanner.step3Desc') }}
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" style="">
          <v-row>
            <img
              style="vertical-align:middle"
              src="/img/welcomeScreen/icon-order-step4.svg"
            >
          </v-row>
          <v-row class="mt-3">
            <v-col cols="2" class="ml-n4">
              <img
                style="vertical-align:middle"
                src="/img/welcomeScreen/icon-digit-4.svg"
              >
            </v-col>
            <v-col cols="10">
              <v-row>
                <div class="text-body-1 font-weight-medium text-uppercase">
                  {{ $t('views.dashboard.welcomeScreenOrderStepsBanner.step4Label') }}
                </div>
              </v-row>
              <v-row>
                <div class="" style="color: #747474">
                  {{ $t('views.dashboard.welcomeScreenOrderStepsBanner.step4Desc') }}
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'WelcomeScreenOrderStepsBanner',
};
</script>

<style scoped>
.dashed-line {
  margin: 0 auto; /* Center the line horizontally */
  border-top: 1px dashed #5FCE79; /* You can adjust the color and thickness as needed */
}
</style>
