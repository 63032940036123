<template>
  <v-card>
    <v-card-title
      style="background-color: #4fb052; color: white;"
    >
      {{ _.get(analysisData[0], 'value') }}
    </v-card-title>

    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr
            v-for="(item, i) in analysisData.slice(1)"
            :key="item.field"
            :class="{'dark': (i % 2 !== 0)}"
          >
            <td style="display: flex; align-items: center;">
              <img
                :src="`/img/${item.icon}.svg`"
                class="mr-1"
                width="21px"
                height="21px"
              >
              {{ item.field }}
            </td>
            <td>
              {{ item.value }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { models } from 'feathers-vuex';

const { CsiAnalysis } = models.api;
export default {
  name: 'PartAnalysisTable',
  props: {
    part: Object,
  },
  computed: {
    analysisData() {
      let tableData = [];

      if (this.part) {
        tableData = _.get(CsiAnalysis.getFromStore(this.part.Item), 'data', []);
      }

      if (_.isEmpty(tableData)) {
        tableData = [
          { field: null, value: 'Manufacturability & Design' },
          { field: 'Unfolding', value: '-' },
          { field: 'Bend to Hole', value: '-' },
          { field: 'Hole to Edge', value: '-' },
          { field: 'Bend Radius', value: '-' },
          { field: 'Bend to Edge', value: '-' },
          { field: 'Hole Size vs Thickness', value: '-' },
        ];
      } else {
        tableData = tableData.filter(datum => datum.Category === 'T1')
          .sort((datum1, datum2) => +datum1.Rank - +datum2.Rank)
          .map(datum => ({ field: datum.Description, value: datum.Value }));
      }

      const message = _.get(this.part, 'EstimateLineStatus.isManufacturableMsg', '-');
      const isManufacturable = _.get(this.part, 'EstimateLineStatus.isManufacturable');
      tableData.push({
        field: 'Warnings', value: (isManufacturable ? message : '-'), color: 'error',
      });
      tableData.push({
        field: 'Errors', value: (isManufacturable ? '-' : message), icon: 'clock', color: 'grey',
      });

      const icons = { Pass: 'check-mark', Fail: 'error' };
      return tableData.map(row => ({ ...{ icon: _.get(icons, row.value, 'error') }, ...row }));
    },
  }
};
</script>

<style scoped>

</style>
