<template>
  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-header class="py-1">
        <div class="text-subtitle-2">
          {{ $t( _.get(partDesignData[0], 'value')) }}
        </div>
        <v-select
          v-model="units"
          style="z-index:999"
          solo
          flat
          dense
          outlined
          disabled
          :label="$t('common.metric')"
          :items="[$t('common.metric'), $t('common.imperial')]"
          hide-details
          @click.stop
        >
          <template #prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mt-2"
                  x-small
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Coming soon</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(item) in partDesignData.slice(1)"
                  :key="item.field"
                >
                  <td class="d-flex align-center text-truncate" style="color: #747474;">
                    <v-icon small class="mr-2" color="green">
                      {{ item.icon }}
                    </v-icon>
                    {{ $t(item.field) }}
                  </td>
                  <td class="text-truncate font-weight-medium" style="text-align: right">
                    <span
                      class="ml-3"
                    >
                      {{ (locale === 'en-US') || (item.valueCHS === '-') ? $t(item.value) : item.valueCHS }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapState } from 'vuex';

const { CsiAnalysis } = models.api;
export default {
  name: 'PartDesignTable',
  props: {
    part: Object,
  },
  data() {
    return {
      units: 'Metric',
      tableData: []
    };
  },
  computed: {
    ...mapState('app', ['locale']),
    partDesignData() {
      let tableData = [];
      if (this.part) {
        tableData = _.get(CsiAnalysis.getFromStore(this.part.Item), 'data', []);
      }
      if (_.isEmpty(tableData)) {
        tableData = [
          { field: null, value: '3dSideViewer.partsDesignData.title' },
          { field: '3dSideViewer.partsDesignData.fabricationProcesses', value: '-' },
          { field: '3dSideViewer.partsDesignData.netWeight', value: '-' },
          { field: '3dSideViewer.partsDesignData.grossWeight', value: '-' },
          { field: '3dSideViewer.partsDesignData.materialUsage', value: '-' },
          { field: '3dSideViewer.partsDesignData.quantitySheet', value: '-' },
          { field: '3dSideViewer.partsDesignData.material', value: '-' },
        ];
      } else {
        tableData = tableData.filter(datum => datum.Category === 'S2')
          .filter(datum => (datum.Description ? datum.Description.startsWith('T1') : ''))
          .sort((datum1, datum2) => +datum1.Rank - +datum2.Rank)
          .map(datum => ({ field: datum.Description.replace('T1.', ''), value: datum.Value || '-', valueCHS: datum.ValueCHS || '-' }));
      }

      const icons = [
        'mdi-ticket-confirmation',
        'mdi-dots-horizontal',
        'mdi-lock-plus',
        'mdi-text-box-check-outline',
        'mdi-vibrate',
        'mdi-chart-donut',
      ];
      return tableData.map((row, index) => ({ ...{ icon: icons[index - 1] }, ...row }));
    }
  },

};

</script>

<style scoped>
::v-deep .v-input__slot{
  margin-top: 2px !important;
  width: 120px;
}
</style>
