import currency from 'currency.js';
import { models } from 'feathers-vuex';
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';
import { BagItem } from '@/utils/types';

const servicePath = 'custom-bags';

const Model = class CustomBag extends BaseModel {
  static modelName = 'CustomBag';

  static instanceDefaults() {
    return {
      items: [],
      bagName: '',
      barCode: '',
      description: '',
      qty: 100
    };
  }

  static setupInstance() {
  }

  // eslint-disable-next-line class-methods-use-this
  get currencySymbol() {
    return _.get(models.api.Company.getFromStore(0), 'currencySymbol', '$');
  }

  get bagTotal() {
    return currency(this.items.reduce((total, item) => total.add(item.SubTotal), currency(0, { symbol: this.currencySymbol })), { symbol: this.currencySymbol });
  }

  get SubTotal() {
    return this.bagTotal.multiply(this.qty);
  }

  addItem(id, qty, currentId) {
    const items = this.items.filter(item => item._id === id);
    if (items.length) {
      items[0].ReqQty = qty;
      return;
    }

    this.items.push(new BagItem(id, qty, currentId));
  }

  removeItem(id) {
    const index = this.items.findIndex(item => item._id === id);
    this.items.splice(index, 1);
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
