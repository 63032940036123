// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

import i18n from '../plugins/i18n';

const servicePath = 'my-parts';
const Model = class MyPart extends BaseModel {
  static modelName = 'MyPart';

  static instanceDefaults() {
    return {
      qtyOfPart: 1,
      defaultQuantity: 1
    };
  }

  static setupInstance(data) {
    if (data.PriceMOQ) {
      const priceMoq = JSON.parse(data.PriceMOQ);
      // eslint-disable-next-line no-underscore-dangle
      this._defaultQuantity = Math.min(...priceMoq.map(o => o.Qty));
      // eslint-disable-next-line no-underscore-dangle
      this._qtyOfPart = this._defaultQuantity;
      // eslint-disable-next-line no-underscore-dangle
    } else {
      data.PriceMOQ = [];
      // eslint-disable-next-line no-underscore-dangle
      this._defaultQuantity = 1;
    }

    if (data.ExpiryDate) {
      data.ExpiryDate = new Date(data.ExpiryDate);
    }
    if (data.MaterialStr && data.MaterialSeriesStr) {
      this.materialSeriesShortened = data.MaterialSeriesStr.replace(data.MaterialStr, '').trim();
    }
    return data;
  }

  get qtyOfPart() {
    // eslint-disable-next-line no-underscore-dangle
    return this._qtyOfPart;
  }

  set qtyOfPart(value) {
    // eslint-disable-next-line no-underscore-dangle
    if (value <= this.defaultQuantity) {
      // eslint-disable-next-line no-underscore-dangle
      this._qtyOfPart = this.defaultQuantity;
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    this._qtyOfPart = value;
  }

  get defaultQuantity() {
    // eslint-disable-next-line no-underscore-dangle
    return this._defaultQuantity;
  }

  set defaultQuantity(value) {
    // eslint-disable-next-line no-underscore-dangle
    this._defaultQuantity = value;
  }

  get materialTranslation() {
    return i18n.locale === 'en-US' ? this.MaterialStr : this.MaterialStrChs;
  }

  get materialThicknessTranslation() {
    return i18n.locale === 'en-US' ? this.MaterialThicknessStr : this.MaterialThicknessStrChs;
  }

  get materialSeriesTranslation() {
    return i18n.locale === 'en-US' ? this.materialSeriesShortened : this.MaterialSeriesStrChs;
  }

  get materialFinishTranslation() {
    return i18n.locale === 'en-US' ? this.MaterialFinishStr.replace(/-/g, '') : this.MaterialFinishStrChs.replace(/-/g, '');
  }

  get materialFinishOptionTranslation() {
    return i18n.locale === 'en-US' ? this.MaterialFinishOptionStr.replace(/-/g, '') : this.MaterialFinishOptionStrChs.replace(/-/g, '');
  }

  get toleranceTranslation() {
    if (!this.Tolerance) {
      return '-';
    }

    return i18n.t(`views.instantQuote.tolerance${this.Tolerance.replace('Precision', '').trim()}`);
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
