<template>
  <v-container fluid class="py-0 mb-3 px-1">
    <v-row style="background-color: white; border-radius: 10px;">
      <v-col
        cols="3"
        class="py-0 px-5"
        style="min-width: 175px; max-width: 175px;"
        data-cy="vertical-block-1-grid"
        @click="onClickButton"
      >
        <core-thumbnail-viewer
          :part="part"
          :part-lookup-key="'Item'"
          :width="90"
          :height="90"
          class="mr-2 mt-2"
          data-cy="image-grid"
        />
        <v-btn
          text
          x-small
          color="primary"
          class="pl-0"
          data-cy="view-details-grid"
        >
          {{ $t('common.viewDetails') }}
          <v-icon x-small right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-col>
      <v-col class="px-1 pb-0 ma-0" data-cy="vertical-block-2-grid">
        <v-row class="text-h5 pa-1 ma-0" data-cy="horizontal-1-vertical-block-2-grid">
          <v-col cols="4" class="pa-0" data-cy="quote-title-grid">
            <span v-html="highlight(part.CustItemName)" />
            {{ $t('common.revision', {revision: part.Revision}) }}
          </v-col>
          <v-col cols="4" class="d-flex justify-center pa-0 mt-1">
            <v-btn
              v-if="_.get(user, 'isAdmin') || _.get(user, ['rights', 'myParts'])"
              text
              small
              :disabled="isAlreadyInMyParts"
              :loading="myPart.isSavePending"
              color="primary"
              data-cy="save-parts-grid"
              @click="saveToMyParts"
            >
              <span v-if="isAlreadyInMyParts">
                {{ $t('views.rfqs.existsInMyParts') }}
              </span>
              <template v-else>
                <v-icon left>
                  mdi-download
                </v-icon>
                <span>
                  {{ $t('views.rfqs.saveToMyParts') }}
                </span>
              </template>
            </v-btn>
            <core-upload-svg-drawing
              v-if="needSvg"
              class="mt-n1"
              :part="part"
              :button-attrs="{ 'small': true }"
              :button-icon-attrs="{ style: 'font-size: 18px' }"
            />
          </v-col>
          <!--          <v-col cols="4" class="d-flex justify-center pa-0">-->
          <!--            <core-my-part-pdf :part="part" />-->
          <!--          </v-col>-->
        </v-row>
        <v-row class="px-1 ma-0" data-cy="horizontal-2-vertical-block-2-grid">
          {{ part.CustItemDesc }}
        </v-row>
        <v-row class="pa-0 ma-0" data-cy="horizontal-3-vertical-block-2-grid">
          <v-col class="px-1 ma-0">
            <span
              class="caption"
              style="display:block; color: #8D8D8D"
              data-cy="material-label-grid"
            >{{ $t('views.rfqs.material') }}</span>
            <span data-cy="material-grid" v-html="highlight(part.materialTranslation)" />
          </v-col>
          <v-col v-if="part.Machine === 'sheet-metal'" class="px-3 ma-0">
            <span
              class="caption"
              style="display:block;  color: #8D8D8D"
              data-cy="thickness-label-grid"
            >
              {{ $t('views.rfqs.thickness') }}
            </span>
            <span data-cy="thickness-grid" v-html="highlight(part.materialThicknessTranslation)" />
          </v-col>
          <v-col v-else class="px-3 ma-0">
            <span
              class="caption"
              style="display:block;  color: #8D8D8D"
              data-cy="thickness-label-grid"
            >
              {{ $t('views.rfqs.roughness') }}
            </span>
            <span data-cy="thickness-grid" v-html="highlight(roughnessName)" />
          </v-col>
          <v-col class="px-3 ma-0">
            <span
              class="caption"
              style="display:block; color: #8D8D8D"
              data-cy="series-label-grid"
            >
              {{ $t('views.rfqs.series') }}
            </span>
            <span data-cy="series-grid" v-html="highlight(part.materialSeriesTranslation)" />
          </v-col>
          <v-col class="px-3 ma-0">
            <span
              class="caption"
              style="display:block; color: #8D8D8D"
              data-cy="finish-label-grid"
            >
              {{ $t('views.rfqs.finish') }}
            </span>
            <span
              data-cy="finish-grid"
              v-html="highlight( part.materialFinishTranslation )"
            />
          </v-col>
          <v-col class="px-3 ma-0">
            <span
              class="caption"
              style="display:block; color: #8D8D8D"
              data-cy="tolerances-label-grid"
            >
              {{ $t('views.instantQuote.tolerances') }}
            </span>
            <span data-cy="tolerances-grid">
              {{
                part.toleranceTranslation
              }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="2"
        class="pt-2 pb-0 px-1"
        style="min-width: 190px; display:flex; align-items:center;"
        data-cy="vertical-block-3-grid"
      >
        <core-price-moq-table :part="part" class="mr-5" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapGetters, mapState } from 'vuex';

const { MyPart } = models.api;

export default {
  name: 'RfqPartCard',
  props: {
    part: Object,
    customerPriceRange: Array,
    searchValue: String,
    filterPart: { type: Array, default: () => [] }
  },
  data() {
    return {
      myPart: {}
    };
  },
  computed: {
    ...mapState('app', ['locale']),
    ...mapGetters('auth', ['user']),
    isAlreadyInMyParts: vm => !!MyPart.getFromStore(vm.part.Item),
    needSvg: vm => vm.part.Machine === 'sheet-metal' && vm.part.LaserEngraving === '1',
    roughnessName: vm => {
      switch (vm.part.Roughness) {
        case '6.0':
          return vm.$t('views.rfqs.roughnessMilled');
        case '3.2':
          return vm.$t('views.rfqs.roughnessStandard');
        case '1.6':
          return vm.$t('views.rfqs.roughnessFine');
        case '0.8':
          return vm.$t('views.rfqs.roughnessGrade');
        default:
          return 'N/A';
      }
    },
  },
  mounted() {
    // TODO use get by id service when is available
    MyPart.find({ query: {} });
  },
  methods: {
    onClickButton() {
      this.$emit('click:sideView', this.part);
    },
    saveToMyParts() {
      this.myPart = new MyPart({ ..._.omit(this.part, '_id') });
      this.myPart.create({ notificationMessage: this.$t('views.rfqs.savedToMyParts') });
    },
    highlight(value) {
      const escapedStringRegEx = _.escapeRegExp(this.searchValue);
      const re = new RegExp(`(${escapedStringRegEx})`, 'gi');
      if (!_.isEmpty(this.filterPart)) {
        return value.replace(
          re,
          '<span style="background-color: yellow">$1</span>'
        );
      }
      return value;
    },
  }
};
</script>
