const LEVEL = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

module.exports = {
  LEVEL,
};
