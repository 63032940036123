var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[(_vm._.isEmpty(_vm.orderLines) && _vm.loadingParts)?_c('v-row',[_c('v-skeleton-loader',{staticClass:"mx-3",attrs:{"width":"100%","loading":"","type":("table-thead,table-row-divider@" + _vm.nbrOfOrder)}})],1):_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.orderLinesTableHeaders,"items":_vm.orderLines,"loading":_vm._.isEmpty(_vm.orderLines),"disable-pagination":"","hide-default-footer":"","no-data-text":_vm.$t('common.noDataAvailable'),"loading-text":_vm.$t('common.loadingItems')},on:{"click:row":function($event){return _vm.open3DViewer($event)}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"pa-5 d-flex justify-space-between",attrs:{"width":"100%","loading":"","type":"komacutLine@10","types":{komacutLine:("table-cell@" + _vm.nbrOfOrder)}}})]},proxy:true},{key:"item.CustItemName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('core-thumbnail-viewer',{staticClass:"mr-5 ml-3",attrs:{"part":item,"part-lookup-key":'Item',"width":60,"height":60,"data-cy":"quote-image"}}),_c('span',{attrs:{"data-cy":"quote-part-value"},domProps:{"innerHTML":_vm._s(_vm.highlight(item.CustItemName))}})],1)]}},{key:"item.Tolerance",fn:function(ref){
var orderline = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-tolerance-value"}},[_vm._v(" "+_vm._s(orderline.toleranceTranslation)+" ")])]}},{key:"item.MaterialStr",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-material-value"},domProps:{"innerHTML":_vm._s(_vm.highlight(orderLine.materialTranslation))}})]}},{key:"item.materialSeriesShortened",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-series-value"},domProps:{"innerHTML":_vm._s(_vm.highlight(orderLine.materialSeriesTranslation))}})]}},{key:"item.MaterialThicknessStr",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-thickness-value"},domProps:{"innerHTML":_vm._s(_vm.highlight(orderLine.materialThicknessTranslation))}})]}},{key:"item.MaterialFinishStr",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-finish-value"},domProps:{"innerHTML":_vm._s(_vm.highlight(orderLine.materialFinishTranslation ))}})]}},{key:"item.Revision",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-revision-value"}},[_vm._v(_vm._s(orderLine.Revision))])]}},{key:"item.CustItemDesc",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-description-value"}},[_vm._v(_vm._s(orderLine.CustItemDesc))])]}},{key:"item.Quantity",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-qty-value"}},[_vm._v(_vm._s(orderLine.Quantity))])]}},{key:"item.Price",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-unit-value"}},[_vm._v(_vm._s(_vm.currency(orderLine.Price, {symbol: _vm.company.currencySymbol}).format()))])]}},{key:"item._id",fn:function(ref){
var orderLine = ref.item;
return [_c('span',{attrs:{"data-cy":"quote-net-value"}},[_vm._v(_vm._s(_vm.currency(orderLine.Price, {symbol: _vm.company.currencySymbol}).multiply(orderLine.Quantity).format()))])]}}])}),((_vm.loadingParts || (_vm.isLoadingPartsOrderId===_vm.orderId)) && !_vm._.isEmpty(_vm.orderLines))?_vm._l(((_vm.nbrOfOrder - _vm.orderIdInStore.length)),function(index){return _c('v-skeleton-loader',{key:("table-" + index),staticClass:"pa-5 d-flex justify-space-between",attrs:{"width":"100%","loading":"","type":"komacutLine@10","types":{komacutLine:("table-cell@" + (_vm.nbrOfOrder - _vm.orderIdInStore.length))}}})}):_vm._e(),(!_vm._.isEmpty(_vm.filteredPartItems) && (_vm.nbrOfOrder >1) && (_vm.filteredPartItems.length !== _vm.nbrOfOrder))?_c('v-card',{staticClass:"grey lighten-2  text-center pa-0 text-uppercase",attrs:{"flat":""},on:{"click":_vm.getAllMissingPart}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(("" + (_vm.displayAll ? _vm.$t('views.rfqs.hidePart') : _vm.$t('views.rfqs.showAllParts'))))+" ")]),_c('br'),_c('div',{staticClass:"mdi mdi-chevron-down mt-n2",class:{'mdi-rotate-180':_vm.displayAll},staticStyle:{"font-size":"20px","height":"25px"}})]):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }