<template>
  <div>
    <template v-if="!isLoaded">
      <v-skeleton-loader
        v-if="!hasError"
        :width="width"
        :height="height"
        type="image"
        class="my-2"
      />
      <v-tooltip v-else right nudge-bottom="50px">
        <template v-slot:activator="{ on, attrs }">
          <v-sheet
            color="#F4F4F4"
            :width="width"
            :height="height"
            v-bind="attrs"
            class="d-flex justify-center align-center my-2"
            v-on="on"
          >
            <span style="color:#8D8D8D">?</span>
          </v-sheet>
        </template>
        <span>{{ $t('common.failedToDownloadThumbnail') }}</span>
      </v-tooltip>
    </template>
    <v-fade-transition>
      <img
        v-show="isLoaded"
        class="my-2 "
        :src="thumbnailUri"
        :height="height"
        :width="width"
        alt="thumbnail"
        @load="isLoaded = true"
        @error="hasError = true"
      >
    </v-fade-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  /* TODO It would be nice to use Vuetify's image component (v-img), but unfortunately
  *   there seems to be no simple way to display a skeleton loader until
  *   a v-img loads using v-show. So we're stuck with a regular image tag */
  name: 'ThumbnailViewer',
  props: {
    part: Object,
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    partLookupKey: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    isLoaded: false,
    hasError: false,
  }),
  computed: {
    ...mapState('auth', ['accessToken']),

    thumbnailUri: vm => {
      if (!vm.part) {
        return '';
      }
      const host = process.env.VUE_APP_API_URL;
      const extension = vm.part.is2dModel ? 'SVG' : 'PNG';
      const fileName = `${_.get(vm.part, 'DrawingRowPointer', '').toLowerCase()}.${extension}`;
      if (vm.partLookupKey) {
        return `${host}uploads/${fileName}/${vm.accessToken}/${vm.part[vm.partLookupKey]}`;
      }
      return `${host}uploads/${fileName}/${vm.accessToken}/${vm.part._id}`;
    }
  },
};
</script>

<style scoped>
/* Without this, there is a random extra ~6 pixels below the image.
 After scrutinizing over pixels for a while, I found this: https://stackoverflow.com/a/5804278/9214972 */
img {
  vertical-align: middle;
}
</style>
