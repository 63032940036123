// eslint-disable-next-line max-classes-per-file
import { models } from 'feathers-vuex';
import currency from 'currency.js';
import i18n from '@/plugins/i18n';

class OrderItem {
  constructor(type, id, ReqQty, drawingConsultancyPdf = null) {
    this.type = type;
    this._id = id;
    this.ReqQty = ReqQty;
    this.drawingConsultancyPdf = drawingConsultancyPdf;

    if (this.type === 'customBag') {
      this.myItem = models.api.CustomBag.getFromStore(this._id);
    }
    if (this.type === 'myproduct') {
      this.myItem = models.api.MyProduct.getFromStore(this._id);

      if (this.myItem.ProductCode === 'FG') {
        this.myItem = { ...this.myItem, ...this.myItem.PropertiesFG[0] };
      }

      if (this.myItem.ProductCode === 'HW-Bag') {
        this.myItem = { ...this.myItem, ...this.myItem.PropertiesHWBag[0] };
      }
    }
    if (this.type === 'part') {
      this.myItem = models.api.MyPart.getFromStore(this._id);
    }

    if (this.type === 'estimate') {
      this.myItem = models.api.Part.getFromStore(this._id);
    }

    if (this.type === 'orderLine') {
      this.myItem = models.api.OrderLine.getFromStore(this._id);
    }

    if (this.type === 'hardware') {
      this.myItem = models.api.HardwareProduct.getFromStore(this._id);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get currencySymbol() {
    return _.get(models.api.Company.getFromStore(0), 'currencySymbol', '$');
  }

  get CustItemName() {
    return this.myItem.CustItemName;
  }

  get Revision() {
    if (this.type === 'part' || this.type === 'estimate') {
      return this.myItem.Revision;
    }
    return '';
  }

  get CustItemDesc() {
    switch (this.type) {
      case 'hardware':
        return this.myItem.description;
      case 'part':
        return this.myItem.CustItemDesc;
      case 'estimate':
        return this.myItem.CustItemDesc;
      default:
        return '';
    }
  }

  get SubTotal() {
    return currency(this.UnitPrice.multiply(this.ReqQty).value, { symbol: this.currencySymbol, precision: 2 });
  }

  get materialTranslation() {
    if (this.type === 'part' || this.type === 'estimate' || this.type === 'myproduct') {
      return i18n.locale === 'en-US' ? this.myItem.MaterialStr : this.myItem.MaterialStrChs;
    }
    return '';
  }

  get materialSeriesTranslation() {
    if (this.type === 'part' || this.type === 'estimate' || this.type === 'myproduct') {
      return i18n.locale === 'en-US' ? this.myItem.materialSeriesShortened : this.myItem.MaterialSeriesStrChs;
    }
    return '';
  }

  get materialThicknessTranslation() {
    if (this.type === 'part' || this.type === 'estimate' || this.type === 'myproduct') {
      return i18n.locale === 'en-US' ? this.myItem.MaterialThicknessStr : this.myItem.MaterialThicknessStrChs;
    }
    return '';
  }

  get materialFinishTranslation() {
    if (this.type === 'part' || this.type === 'estimate' || this.type === 'myproduct') {
      if (this.myItem.MaterialFinishStr) {
        return i18n.locale === 'en-US' ? this.myItem.MaterialFinishStr.replace(/-/g, '') : this.myItem.MaterialFinishStrChs.replace(/-/g, '');
      }
    }
    return '';
  }

  get materialFinishOptionTranslation() {
    if (this.type === 'part' || this.type === 'estimate' || this.type === 'myproduct') {
      if (this.myItem.MaterialFinishOptionStr) {
        return i18n.locale === 'en-US' ? this.myItem.MaterialFinishOptionStr.replace(/-/g, '') : this.myItem.MaterialFinishOptionStrChs.replace(/-/g, '');
      }
    }
    return '';
  }

  get toleranceTranslation() {
    if (this.type === 'part' || this.type === 'estimate' || this.type === 'myproduct') {
      if (!this.myItem.Tolerance) {
        return '';
      }
      return i18n.t(`views.instantQuote.tolerance${this.myItem.Tolerance.replace('Precision', '').trim()}`);
    }
    return '';
  }

  get MaterialStr() {
    if (this.type === 'part' || this.type === 'estimate') {
      return this.myItem.MaterialStr;
    }
    return '';
  }

  get materialSeriesShortened() {
    if (this.type === 'part' || this.type === 'estimate') {
      return this.myItem.materialSeriesShortened;
    }
    return '';
  }

  get MaterialThicknessStr() {
    if (this.type === 'part' || this.type === 'estimate') {
      return this.myItem.MaterialThicknessStr;
    }
    return '';
  }

  get MaterialFinishStr() {
    if (this.type === 'part' || this.type === 'estimate') {
      return this.myItem.MaterialFinishStr;
    }
    return '';
  }

  get Tolerance() {
    if (this.type === 'part' || this.type === 'estimate') {
      return this.myItem.Tolerance;
    }
    return '';
  }

  get DrawingRowPointer() {
    return this.myItem.DrawingRowPointer;
  }

  get UnitPrice() {
    const getPriceMoq = JSON.parse(JSON.stringify(this.myItem).toLowerCase()).pricemoq;

    let priceMoq;
    if (typeof getPriceMoq === 'string') {
      priceMoq = JSON.parse(getPriceMoq);
    } else {
      priceMoq = getPriceMoq;
    }

    const priceMOQ = [...priceMoq].sort(({ qty: a }, { qty: b }) => b - a);

    const findPrice = priceMOQ.find(({ qty }) => qty <= this.ReqQty) || Math.min(...priceMOQ.map(o => o.qty));

    if (!findPrice) {
      return currency(0, { symbol: this.currencySymbol });
    }

    return currency(findPrice.price, { symbol: this.currencySymbol, precision: 4 });
  }

  get is2dModel() {
    if (this.myItem.CustFileName) {
      return this.myItem.CustFileName.split('.').pop().replaceAll(' ', '').toUpperCase() === 'DXF';
    }
    return false;
  }

  // get UnitPrice() {
  //   let priceMoq;
  //   if (typeof this.myItem.PriceMOQ === 'string') {
  //     priceMoq = JSON.parse(this.myItem.PriceMOQ);
  //   } else {
  //     priceMoq = this.myItem.priceMOQ;
  //   }
  //
  //   /* Technically not needed as the next loop will catch this case anyway */
  //   const maxDiscountRange = _.last(priceMoq);
  //   if (this.ReqQty >= maxDiscountRange.Qty) {
  //     return currency(maxDiscountRange.Price, { symbol: this.currencySymbol });
  //   }
  //
  //   /* If we are not eligible for the NEXT discounted quantity, or one does not exist, return the 'current' one */
  //   let nextPriceRange;
  //   for (let i = 0; i < priceMoq.length; i++) {
  //     nextPriceRange = priceMoq[i + 1];
  //     if (!nextPriceRange || nextPriceRange.Qty > this.ReqQty) {
  //       return currency(priceMoq[i].Price, { symbol: this.currencySymbol });
  //     }
  //   }
  //
  //   return currency(0, { symbol: this.currencySymbol });
  // }
}

class BagItem {
  constructor(id, ReqQty, categoryId) {
    this._id = id;
    this.categoryId = categoryId;
    this.ReqQty = ReqQty;
    this.myItem = models.api.HardwareProduct.getFromStore(this._id);
  }

  // eslint-disable-next-line class-methods-use-this
  get currencySymbol() {
    return _.get(models.api.Company.getFromStore(0), 'currencySymbol', '$');
  }

  get SubTotal() {
    return this.UnitPrice.multiply(this.ReqQty);
  }

  get UnitPrice() {
    let priceMoq;

    if (typeof this.myItem.PriceMOQ === 'string') {
      priceMoq = JSON.parse(this.myItem.PriceMOQ);
    } else {
      priceMoq = this.myItem.priceMOQ;
    }

    /* Technically not needed as the next loop will catch this case anyway */
    const maxDiscountRange = _.last(priceMoq);
    if (this.ReqQty >= maxDiscountRange.Qty) {
      return currency(maxDiscountRange.Price, { symbol: this.currencySymbol });
    }

    /* If we are not eligible for the NEXT discounted quantity, or one does not exist, return the 'current' one */
    let nextPriceRange;
    for (let i = 0; i < priceMoq.length; i++) {
      nextPriceRange = priceMoq[i + 1];
      if (!nextPriceRange || nextPriceRange.Qty > this.ReqQty) {
        return currency(priceMoq[i].Price, { symbol: this.currencySymbol });
      }
    }

    return currency(0, { symbol: this.currencySymbol });
  }
}

export { OrderItem, BagItem };
