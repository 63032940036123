<template>
  <!-- TODO ensure we always have a new instance with v-if so
   things like form validation don't show errors in a new dialog
   (similar to lazy validation)-->
  <v-dialog
    v-if="$attrs.value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card :min-width="$attrs['min-width']" :loading="isLoading">
      <v-card-title
        v-if="$slots.title"
        class="py-4 justify-center text-h5 font-weight-bold"
      >
        <slot name="title" />
        <v-btn
          icon
          absolute
          style="right: 16px"
          data-cy="close-dialog-button"
          @click="$emit('click:cancel')"
        >
          <v-icon large>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="$slots.content" class="pa-4">
        <slot name="content" />
      </v-card-text>

      <v-card-actions
        v-if="$slots.actions || type !== types.custom"
        class="pt-2 pb-8 justify-center"
      >
        <slot name="actions">
          <template v-if="type !== types.custom">
            <v-btn
              v-if="[types.acknowledgement, types.confirmation].includes(type)"
              color="primary"
              class="px-8"
              :loading="isLoading"
              data-cy="ok-button-dialog"
              :disabled="isLoading || isOkDisabled"
              @click="$emit('click:ok')"
            >
              {{ okButtonText || $t('common.confirm') }}
            </v-btn>
            <v-btn
              v-if="type === types.confirmation"
              class="px-8"
              color="primary"
              text
              data-cy="cancel-button-dialog"
              :disabled="isLoading || isCancelDisabled"
              depressed
              @click="$emit('click:cancel')"
            >
              {{ cancelButtonText || $t('common.cancel') }}
            </v-btn>
          </template>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* Must be defined externally, since prop validation doesn't have access to 'this' */
const types = {
  confirmation: 'confirmation',
  acknowledgement: 'acknowledgement',
  custom: 'custom',
};

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: () => types.custom,
      validator: type => Object.values(types).includes(type),
    },
    isLoading: Boolean,
    isOkDisabled: Boolean,
    okButtonText: String,
    cancelButtonText: String,
    isCancelDisabled: Boolean,
  },
  data: () => ({
    types,
  })
};
</script>

<style scoped>

</style>
