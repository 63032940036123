export default {
  methods: {
    convertCustomerNumberToInt(customerNumber) {
      let intValue = 0;
      let multiplier = 1; // To keep track of the position value

      // Iterate over the characters of the customer number from right to left
      for (let i = customerNumber.length - 1; i >= 0; i--) {
        const char = customerNumber[i];

        // Check if the character is a digit
        if (/[0-9]/.test(char)) {
          // Convert the character to its numeric value and update the integer value
          intValue += parseInt(char, 10) * multiplier;
          // Update the multiplier for the next position
          multiplier *= 10;
        } else {
          // Stop iteration if a non-numeric character is encountered
          break;
        }
      }

      return intValue;
    }
  },
};
