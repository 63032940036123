<template>
  <v-container class="fill-height px-5" style="min-width: 1400px; max-width: 1400px; max-height: 100%">
    <v-row class="fill-height">
      <v-col
        class="rounded-xl pa-0"
        style="z-index:99; background-color: white;height: 100%; min-width: 550px;"
        cols="6"
      >
        <v-row class="d-flex justify-space-between align-center">
          <v-select
            style="max-width: 130px;"
            class="ml-3 mt-2"
            :value="locale"
            :items="langList"
            item-value="value"
            item-text="label"
            background-color="transparent"
            solo
            flat
            hide-details
            @change="(currentLang) => {setLocale({ $i18n: i18n, locale: currentLang });} "
          >
            <template slot="selection" slot-scope="data">
              <v-icon class="mr-2" color="primary">
                mdi-web
              </v-icon>
              <span style="color: #2c984e;">{{ data.item.label }}</span>
            </template>
          </v-select>
        </v-row>

        <v-row class="d-flex justify-space-between align-center">
          <v-col offset="2" cols="8" class="d-flex justify-center align-center">
            <img src="/img/Komacut-logo.svg" width="180px">
          </v-col>
        </v-row>
        <v-row>
          <v-col offset="2" cols="8" class="d-flex justify-center align-center">
            <p class="text-h5 font-weight-bold">
              {{ $t('views.signUp.signUpToKomacut') }}
            </p>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="isFormValid">
          <v-row>
            <v-col class="pb-0" offset="2" cols="8">
              <v-text-field
                v-model.trim="user._id"
                style="width: 800px"
                outlined
                dense
                :label="$t('common.email')"
                :rules=" [isRequired, isEmail]"
                data-cy="email-input"
                :messages="$t('views.signUp.emailFieldInstruction')"
                required
                @keydown.space.prevent
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="2" cols="4">
              <v-text-field
                v-model.trim="user.firstName"
                maxlength="15"
                outlined
                dense
                hide-details
                data-cy="first-name-input"
                :label="$t('common.firstName')"
                :rules=" [isRequired]"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.trim="user.lastName"
                maxlength="30"
                outlined
                dense
                hide-details
                data-cy="last-name-input"
                :label="$t('common.lastName')"
                :rules=" [isRequired]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" offset="2" cols="8">
              <vue-tel-input-vuetify
                v-model="phone"
                maxlength="60"
                :label="$t('views.signUp.phone')"
                :placeholder="$t('views.signUp.phonePlaceholder')"
                outlined
                dense
                data-cy="phone-input"
                :prefix="countryCode"
                :only-countries="getCountryCodes"
                :rules=" [v => !!v && isValid]"
                @country-changed="(v) => {countryCode = v.dialCode}"
                @onInput="onInput"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="2" cols="8">
              <v-text-field
                v-model="user.CompanyName"
                maxlength="60"
                :label="$t('views.signUp.companyName')"
                outlined
                dense
                hide-details
                data-cy="company-name-input"
                :rules=" [isRequired]"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="2" cols="8">
              <v-select
                v-model="user.Department"
                maxlength="50"
                :items="userDepartments"
                :label="$t('views.preferences.tabs.userManagement.department')"
                :rules="[isRequired]"
                outlined
                data-cy="department-input"
                dense
                hide-details
              >
                <template slot="item" slot-scope="data">
                  <span data-cy="department-option">{{ data.item }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col offset="2" cols="8">
              <v-select
                v-model="user.Country"
                :items="countries"
                :label="$t('views.signUp.country')"
                item-text="name"
                item-value="code"
                outlined
                dense
                hide-details
                data-cy="country-input"
                :rules=" [isRequired]"
                required
              >
                <template slot="item" slot-scope="data">
                  <span data-cy="country-option">{{ data.item.name }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              offset="2"
              cols="8"
              class="pb-0"
            >
              <v-text-field
                v-model="user.password"
                :label="$t('views.signUp.password')"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isPasswordVisible ? 'text' : 'password'"
                outlined
                dense
                required
                hide-details
                data-cy="password-input"
                :rules="[v => (v && v.length >= 8) ,
                         v => /(?=.*[A-Z])/.test(v) ,
                         v => /(?=.*\d)/.test(v) ,
                         v => /([@%+\\/'!#$^?:,(){}\[\]~`\-_.])/.test(v)]"
                @click:append="isPasswordVisible = !isPasswordVisible"
              />
            </v-col>
          </v-row>
          <v-col
            offset="2"
            cols="8"
            class="py-0"
          >
            <p class="ma-0">
              {{ $t('views.resetPassword.mustContain') }}
            </p>

            <p class="red--text ma-0" :class="{ 'green--text': user.password.length >= 8 }">
              <v-icon v-if="user.password.length >= 8" small color="green">
                mdi-check
              </v-icon>

              <v-icon v-else small color="red">
                mdi-close
              </v-icon>
              {{ $t('views.resetPassword.minCharacters') }}
            </p>
            <p class="red--text ma-0" :class="{ 'green--text': /(?=.*[a-z])/.test(user.password)}">
              <v-icon v-if="/(?=.*[a-z])/.test(user.password)" small color="green">
                mdi-check
              </v-icon>

              <v-icon v-else small color="red">
                mdi-close
              </v-icon>
              {{ $t('views.resetPassword.oneLower') }}
            </p>
            <p class="red--text ma-0" :class="{ 'green--text': /(?=.*[A-Z])/.test(user.password) }">
              <v-icon v-if="/(?=.*[A-Z])/.test(user.password)" small color="green">
                mdi-check
              </v-icon>

              <v-icon v-else small color="red">
                mdi-close
              </v-icon>
              {{ $t('views.resetPassword.oneUpper') }}
            </p>
            <p class="red--text ma-0" :class="{ 'green--text': /(?=.*\d)/.test(user.password) }">
              <v-icon v-if="/(?=.*\d)/.test(user.password)" small color="green">
                mdi-check
              </v-icon>

              <v-icon v-else small color="red">
                mdi-close
              </v-icon>
              {{ $t('views.resetPassword.oneDigit') }}
            </p>
            <p
              class="red--text ma-0"
              :class="{ 'green--text': /([@%+\\/'!#$^?:,(){}\[\]~`\-_.])/.test(user.password) }"
            >
              <v-icon v-if="/([@%+\\/'!#$^?:,(){}\[\]~`\-_.])/.test(user.password)" small color="green">
                mdi-check
              </v-icon>

              <v-icon v-else small color="red">
                mdi-close
              </v-icon>
              {{ $t('views.resetPassword.oneSpecial') }}
            </p>
          </v-col>
          <v-row>
            <v-col
              offset="2"
              cols="8"
            >
              <v-text-field
                v-model="confirmPassword"
                :label="$t('views.signUp.confirmPassword')"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isPasswordVisible ? 'text' : 'password'"
                :rules="[isRequired,(user.password === confirmPassword) || $t('views.signUp.passwordMustMatch')]"
                outlined
                dense
                required
                hide-details
                data-cy="confirm-password-input"
                @click:append="isPasswordVisible = !isPasswordVisible"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="8" offset="2">
            <v-btn
              :loading="user.isSavePending"
              color="success"
              type="submits"
              large
              block
              data-cy="sign-up-button"
              @click="createUser"
            >
              {{ $t('views.signUp.signUp') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col offset="2" cols="8" class="text--secondary caption text-decoration-none mb-8">
            <p class="mb-0">
              {{ $t('views.signUp.bySigning') }}
              <a
                class="text-decoration-none"
                href="https://komacut.com/legal/general-terms-and-conditions/"
              >{{
                $t('views.signUp.termsAndConditions')
              }}</a>
            </p>

            <p>
              {{ $t('views.signUp.alreadyHaveAnAccount') }} <a
                class="text-decoration-none"
                href="/login"
              >{{ $t('views.login.login') }}</a>
            </p>
          </v-col>
        </v-row>
        <p class="text--secondary caption text-center  mb-0">
          {{ $t('common.footerRight') }} <a
            href="https://komacut.com/about-us/contact-us/"
            class="text-decoration-none"
          > {{ $t('common.contactUs') }}</a>
        </p>
      </v-col>
      <v-col cols="6" class="pl-0 d-flex justify-center align-center">
        <core-promotional />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapActions, mapState } from 'vuex';
import AddressFormatter from '@shopify/address';
import FormRules from '@/mixins/form-rules';
import i18n from '@/plugins/i18n';

const { User, Referral } = models.api;
export default {
  name: 'signUp',

  mixins: [
    FormRules,
  ],

  data() {
    return {
      langList: [
        { label: 'English', value: 'en-US' },
        { label: '中文', value: 'cn' }
      ],
      i18n,
      chineseForm: null,
      englishForm: null,
      user: new User(),
      confirmPassword: '',
      isPasswordVisible: false,
      isFormValid: false,
      chineseJson: [],
      englishJson: [],
      phone: '',
      countryCode: '',
      isValid: false,
    };
  },

  computed: {
    ...mapState('app', ['locale']),
    Country: () => models.api.Country,
    fetchedCountries: vm => vm.Country.findInStore().data,
    unfilteredCountries: vm => (vm.locale === 'en-US' ? vm.englishJson : vm.chineseJson),
    countries: vm => vm.unfilteredCountries.filter(c => vm.fetchedCountries.map(fc => fc.Code).includes(c.code)),

    userDepartments: vm => ([
      vm.$t('views.preferences.tabs.userManagement.departments.designAndEngineering'),
      vm.$t('views.preferences.tabs.userManagement.departments.management'),
      vm.$t('views.preferences.tabs.userManagement.departments.purchasing'),
      vm.$t('views.preferences.tabs.userManagement.departments.strategicProcurement'),
      vm.$t('views.preferences.tabs.userManagement.departments.logistics'),
      vm.$t('views.preferences.tabs.userManagement.departments.production'),
      vm.$t('views.preferences.tabs.userManagement.departments.finance'),
    ]),
    getCountryCodes: vm => vm.fetchedCountries.map(c => c.Code),

  },
  async created() {
    const navLang = navigator.language;
    let currentLang = navLang.split('-')[0];
    // The standard for Chinese localization is not cn but zh cf :
    // http:// www.lingoes.net/en/translator/langcode.htm
    currentLang = (currentLang === 'zh') ? 'cn' : 'en-US';
    i18n.locale = currentLang;
    this.chineseForm = new AddressFormatter('ZH_CN');
    this.englishForm = new AddressFormatter('EN');
    this.chineseJson = await this.chineseForm.getCountries();
    this.englishJson = await this.englishForm.getCountries();
    const { refCode } = this.$route.query;
    if (refCode) {
      this.user.refCode = refCode;
      const referral = await Referral.get(refCode);
      this.user.firstName = referral.firstName;
      this.user.lastName = referral.lastName;
      this.user._id = referral.email;
      this.user.CompanyName = referral.company;
    }
  },

  methods: {
    ...mapActions('app', ['pushSnack']),
    ...mapActions('app', ['pushSnack', 'setLocale']),

    onInput(e) {
      this.isValid = e.valid;
    },

    async createUser() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        // eslint-disable-next-line no-underscore-dangle
        this.user.__id = this.user._id;
        this.user.ContactEmail = this.user._id;
        this.user.signUp = true;
        this.user.locale = this.locale;
        this.user.officePhone = `${this.countryCode} ${this.phone}`;
        await this.user.create();
        this.$router.push({
          name: 'SignUpSendEmail',
          params: { id: this.user._id }
        });
      }
    },
  },
};
</script>

<style scoped>
>>> .v-text-field__details {
  padding-left: 2px !important;
  padding-right: 4px !important;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #D4D8DC;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>
