// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'payment-results';

const Model = class PaymentResult extends BaseModel {
    static modelName = 'PaymentResult';

    static instanceDefaults() {
      return {
        merchant_transaction_id: ''
      };
    }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
