<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-card flat style="border-radius:0; background-color: #fafffb">
          <template v-if="isCardView ">
            <v-data-table
              :headers="partsTableHeaders"
              :items="parts"
              :loading="_.isEmpty(parts)"
              hide-default-footer
              :no-data-text="$t('common.noDataAvailable')"
              :loading-text="$t('common.loadingItems')"
              disable-pagination
              data-cy="table-list"
              :footer-props="{ 'items-per-page-text': $t('common.rowPerPageText'),
                               'page-text': `{0}-{1} ${$t('common.of')} {2}` }"
            >
              <template #loading>
                <v-skeleton-loader
                  class="pa-5 d-flex justify-space-between"
                  width="100%"
                  loading
                  :type="`komacutLine@10`"
                  :types="{komacutLine:`table-cell@${nbrOfPart}`}"
                />
              </template>
              <template #item.CustItemName="{ item }">
                <div class="ml-3" style="display: flex; align-items: center;">
                  <core-thumbnail-viewer
                    :part="item"
                    :part-lookup-key="'Item'"
                    :width="60"
                    :height="60"
                    class="mr-5"
                    data-cy="image-list"
                  />
                  <span>
                    <span data-cy="part-list" v-html="highlight(item.CustItemName)" />
                  </span>
                </div>
              </template>
              <template #item.Revision="{ item }">
                <span data-cy="revision-list" v-html="highlight(item.Revision)" />
              </template>
              <template #item.CustItemDesc="{ item }">
                <span data-cy="description-list" v-html="highlight(item.CustItemDesc)" />
              </template>
              <template #item.ReqQty="{ item }">
                <span data-cy="qty-list" v-html="highlight(item.ReqQty)" />
              </template>
              <template #item.Tolerance="{item}">
                <span data-cy="tolerance-list">{{
                  item.toleranceTranslation
                }}
                </span>
              </template>
              <template #item.PriceMOQ="{ item }">
                <span data-cy="unit-list">{{
                  currency(customerPriceRange(item)[0].Price, {symbol: company.currencySymbol}).format()
                }}</span>
              </template>
              <template #item.MaterialStr="{ item }">
                <span
                  data-cy="material-list"
                  v-html="highlight(item.materialTranslation)"
                />
              </template>
              <!--              <template #item.PDF="{item}">-->
              <!--                <core-my-part-pdf-viewer :part="item" />-->
              <!--              </template>-->
              <template #item.materialSeriesShortened="{ item }">
                <span
                  data-cy="series-list"
                  v-html="highlight(item.materialSeriesTranslation)"
                />
              </template>

              <template #item.MaterialThicknessStr="{ item }">
                <span
                  data-cy="thickness-list"
                  v-html="highlight(item.materialThicknessTranslation)"
                />
              </template>

              <template #item.Roughness="{ item }">
                <span
                  data-cy="roughness-list"
                  v-html="highlight(getRoughnessName(item.Roughness))"
                />
              </template>

              <template #item.MaterialFinishStr="{ item }">
                <span
                  data-cy="finish-list"
                  v-html="highlight(item.materialFinishTranslation)"
                />
              </template>

              <template #item.Net="{ item }">
                <span data-cy="net-list">{{
                  currency(parseFloat(customerPriceRange(item)[0].Price, 10) * item.ReqQty, {symbol: company.currencySymbol}).format()
                }}</span>
              </template>
            </v-data-table>

            <template v-if="(loadingParts || (isLoadingPartsRfqId===rfqId) && !_.isEmpty(parts))">
              <v-skeleton-loader
                v-for="index in (nbrOfPart - partsIdInStore.length)"
                :key="`table-${index}`"
                class="mx-3"
                width="100%"
                loading
                :type="`table-row-divider`"
              />
            </template>
            <v-card
              v-if="!_.isEmpty(filteredPartItems) && (nbrOfPart >1) && (filteredPartItems.length !== nbrOfPart)"
              flat
              class="grey lighten-2  text-center pa-0 text-uppercase"
              @click="getAllMissingPart"
            >
              <span style=" font-size: 12px">
                {{ `${displayAll ? $t('views.rfqs.hidePart') : $t('views.rfqs.showAllParts')}` }}
              </span>
              <br>
              <div
                class="mdi mdi-chevron-down mt-n2"
                style="font-size: 20px; height:25px"
                :class="{'mdi-rotate-180':displayAll}"
              />
            </v-card>
          </template>

          <v-sheet v-else color="#fafffb" class="pt-0 px-2">
            <core-rfq-part-card
              v-for="(part) in parts"
              :key="`card-${part._id}`"
              :filter-part="filteredPartItems"
              :search-value="searchValue"
              :customer-price-range="customerPriceRange(part)"
              :part="part"
              :is-mesh-visible="true"
              @click:sideView="onClickChild(part)"
            />
            <template v-if="loadingParts || (isLoadingPartsRfqId===rfqId)">
              <core-rfq-card-skeleton v-for="index in (nbrOfPart - partsIdInStore.length)" :key="`skeleton-${index}`" />
            </template>
            <v-card
              v-if="!_.isEmpty(filteredPartItems) && (nbrOfPart >1) && (filteredPartItems.length !== nbrOfPart)"
              flat
              class="grey lighten-2  text-center pa-0 text-uppercase"
              @click="getAllMissingPart"
            >
              <span style=" font-size: 12px">
                {{ `${displayAll ? $t('views.rfqs.hidePart') : $t('views.rfqs.showAllParts')}` }}
              </span>
              <br>
              <div
                class="mdi mdi-chevron-down mt-n2"
                style="font-size: 20px; height:25px"
                :class="{'mdi-rotate-180':displayAll}"
              />
            </v-card>
          </v-sheet>

          <v-card-actions style="background-color: #fafffb" class="pb-3 px-0 d-flex justify-end">
            <v-tooltip bottom :disabled="!isSvgPending">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on">
                  <v-btn
                    v-if="_.get(user, 'isAdmin') || _.get(user, ['rights', 'placeOrder'])"
                    v-bind="attrs"
                    :disabled="isExpired || isSvgPending"
                    depressed
                    width="242"
                    color="primary"
                    data-cy="place-order-button"
                    @click="addEstimateToOrder()"
                  >
                    {{ $t('views.productOption.addToOrder') }}
                  </v-btn>
                </div>
              </template>
              {{ $t('views.productOption.addToOrderSvgToolTip') }}
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex';
import currency from 'currency.js';
import { mapState, mapGetters, mapActions } from 'vuex';
import CurrencyFormatterMixin from '@/mixins/currencyFormatter';

const { Part, Company } = models.api;

export default {
  name: 'RfqPartsTable',
  mixins: [CurrencyFormatterMixin],
  props: {
    rfqId: { type: String },
    isLoadingPartsRfqId: { type: String },
    nbrOfPart: { type: Number },
    isCardView: { type: Boolean },
    searchValue: { type: String },
    isExpired: { type: Boolean },
    filteredPartItems: { type: Array, default: () => [] }
  },
  data() {
    return {
      currency,
      displayAll: false,
      orderId: '',
      loadingParts: false
    };
  },
  computed: {
    ...mapState('app', ['locale']),
    ...mapGetters('auth', ['user']),
    partsIdInStore() {
      return Part.findInStore({
        query: {
          EstimateID: this.rfqId,
        },
      }).data.map(part => part._id);
    },

    parts() {
      const queryFilter = {
        query: {
          EstimateID: this.rfqId,
        }
      };

      if (!this.displayAll && !_.isEmpty(this.filteredPartItems)) {
        queryFilter.query._id = { $in: this.filteredPartItems };
      }

      return Part.findInStore(queryFilter).data;
    },

    isSvgPending: vm => vm.parts.some(part => part.Machine === 'sheet-metal' && part.LaserEngraving === '1' && !part.SvgFile),

    company: () => Company.getFromStore(0),

    partsTableHeaders: vm => [
      {
        text: vm.$t('views.rfqs.partNumber'),
        value: 'CustItemName',
      },
      {
        text: vm.$t('views.rfqs.revision'),
        value: 'Revision',
      },
      {
        text: vm.$t('common.description'),
        value: 'CustItemDesc',
      },
      {
        text: vm.$t('views.rfqs.material'),
        value: 'MaterialStr',
      },
      {
        text: vm.$t('views.rfqs.series'),
        value: 'materialSeriesShortened',
      },
      {
        text: vm.$t('views.rfqs.thickness'),
        value: 'MaterialThicknessStr',
      },
      {
        text: vm.$t('views.rfqs.roughness'),
        value: 'Roughness',
      },
      {
        text: vm.$t('views.rfqs.finish'),
        value: 'MaterialFinishStr',
      },
      {
        text: vm.$t('views.instantQuote.tolerances'),
        value: 'Tolerance',
      },

      {
        text: vm.$t('views.rfqs.unitPrice', { currencySymbol: vm.company.currencySymbol }),
        value: 'PriceMOQ',
        sortable: false,
      },
      {
        text: vm.$t('views.rfqs.quantity'),
        value: 'ReqQty',
      },
      {
        text: vm.$t('views.rfqs.netPrice', { currencySymbol: vm.company.currencySymbol }),
        value: 'Net',
        sortable: false,
      },
    ].map(h => ({
      align: 'center',
      class: ['text-uppercase'],
      ...h,
    })),
  },

  methods: {
    ...mapActions('app', ['initCurrentOrder']),

    onClickChild(value) {
      this.$emit('click:openSideView', value);
    },
    priceRange(part) {
      try {
        return JSON.parse(part.PriceMOQ);
      } catch {
        return false;
      }
    },
    async getAllMissingPart() {
      this.displayAll = !this.displayAll;
      if (!this.displayAll) {
        return;
      }

      if (this.partsIdInStore.length === this.nbrOfPart) {
        return;
      }
      this.loadingParts = true;
      await Part.find({
        query: {
          EstimateID: this.rfqId,
          _id: { $nin: this.partsIdInStore }
        }
      });
      this.loadingParts = false;
    },
    customerPriceRange(part) {
      if (!this.priceRange(part)) {
        return [];
      }
      const reqQtyPriceIndex = this.priceRange(part)
        .findIndex(range => range.Qty > part.ReqQty) - 1;
      if (reqQtyPriceIndex < 0) {
        return this.priceRange(part).slice(-1);
      }
      return this.priceRange(part)
        .slice(reqQtyPriceIndex, Math.min(reqQtyPriceIndex + 3, this.priceRange(part).length));
    },
    highlight(value) {
      const escapedStringRegEx = _.escapeRegExp(this.searchValue);
      const re = new RegExp(`(${escapedStringRegEx})`, 'gi');
      if (!_.isEmpty(this.filteredPartItems)) {
        return value.replace(
          re,
          '<span style="background-color: yellow">$1</span>'
        );
      }
      return value;
    },
    getPrice(estimateLine) {
      if (!estimateLine.PriceMOQ) {
        return currency(0, { symbol: this.company.currencySymbol });
      }
      const pricePerMoq = JSON.parse(estimateLine.PriceMOQ)[0];
      if (!pricePerMoq) {
        return currency(0, { symbol: this.company.currencySymbol });
      }
      return currency(pricePerMoq.Price, { symbol: this.company.currencySymbol });
    },

    async addEstimateToOrder() {
      const order = await this.initCurrentOrder();

      this.parts.forEach(part => order.addItem('estimate', part._id, part.ReqQty));
    },

    getRoughnessName(roughnessValue) {
      switch (roughnessValue) {
        case '6.0':
          return this.$t('views.rfqs.roughnessMilled');
        case '3.2':
          return this.$t('views.rfqs.roughnessStandard');
        case '1.6':
          return this.$t('views.rfqs.roughnessFine');
        case '0.8':
          return this.$t('views.rfqs.roughnessGrade');
        default:
          return '-';
      }
    },
  }
};
</script>

<style scoped>

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 25px !important;
  margin-top: 10px;
}

>>> .v-data-table__wrapper > table > thead > tr > th {
  color: #9f9f9f !important;;
  background-color: #f4f4f4 !important;
  height: 15px !important;
}

::v-deep thead th:first-child {
  border-radius: 0 !important;
}

::v-deep thead th:last-child {
  border-radius: 0 !important;
}
</style>
