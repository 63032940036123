import { mapActions } from 'vuex';
import { LEVEL } from '../utils/log';

export default {
  methods: {
    ...mapActions('app', ['pushSnack']),

    /**
     * Update an entity (data) and update tab content.
     *
     * @param {Object} tab Tab to update.
     * @param {BaseModel} data FeathersVuex model to update.
     */
    async updateEntity(tab, data) {
      const field = _.isEmpty(data._id) ? 'create' : 'update';
      try {
        const newEntity = await data.save();
        if (tab) {
          tab.entity = newEntity;
        }
        this.pushSnack({
          snack: { message: this.$t(`common.success.${field}`) },
          level: LEVEL.SUCCESS,
        });
      } catch (e) {
        console.error(e);
        this.pushSnack({
          snack: { message: this.$t(`${this.$t(`common.error.${field}`)}\n<i>${e.message}</i>`) },
          level: LEVEL.ERROR,
        });
      }
    },
  }
};
