import { datadogLogs } from '@datadog/browser-logs';
import { version } from '../../package.json';

datadogLogs.init({
  clientToken: process.env.VUE_APP_DATADOG_LOGGER_CLIENT_TOKEN,
  service: 'komacut',
  version,
  env: process.env.NODE_ENV,
  forwardErrorsToLogs: true,
  sampleRate: 100
});

export default datadogLogs;
