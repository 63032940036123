<template>
  <core-main>
    <template v-slot:title>
      {{ $t('form.dashboard.name') }}
    </template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" class="pa-2 pb-0 pl-10 transparent">
            <v-card-text>
              <v-row>
                <v-col>
                  <p
                    :class="['text-h3', {loading :!user.firstName}]"
                    data-cy="greeting-value"
                    style="color: #afa9a9"
                  >
                    {{ $t('views.dashboard.greeting', {name: user.firstName}) }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <div class="ml-10 pa-2 pt-0">
            <core-welcome-screen-upload-file-banner />
          </div>
        </v-col>
        <v-col v-if="showReferralBanner" cols="12">
          <div class="ml-10 pa-2 pt-0">
            <core-welcome-screen-referral />
          </div>
        </v-col>
        <v-col v-if="showPromotionCountdownBanner" cols="12">
          <div class="ml-10 pa-2 pt-0">
            <core-welcome-screen-promotion-countdown-banner :expiry-time="newSignUpPromoCode[0].ExpiryDate" />
          </div>
        </v-col>
        <v-col cols="12">
          <v-row>
            <div class="ml-12 pl-3 pa-2 text-h5 font-weight-bold text-capitalize" style="color: #2E343B;">
              {{ $t('views.dashboard.simpleProcessLabel') }}
            </div>
            <img
              class="pl-4 pr-2"
              style="vertical-align:middle"
              src="/img/welcomeScreen/icon-bulb.svg"
            >
            <div class="pt-4 text-body-2 font-weight-bold">
              {{ $t('views.dashboard.assistanceLabel1') }}
              <a href="#videoTutorials" @click="expandVideoTutorials = 0;">
                {{ $t('views.dashboard.assistanceLabel2') }}
              </a>
              {{ $t('views.dashboard.assistanceLabel3') }}
              {{ $t('views.dashboard.assistanceLabel4') }}
              {{ $t('views.dashboard.assistanceLabel5') }}
              <a href="mailto:feedback@komacut.com">
                {{ $t('views.dashboard.assistanceLabel6') }}
              </a>
            </div>
          </v-row>
        </v-col>
        <v-col cols="12">
          <div class="ml-10 pa-2 pt-0">
            <core-welcome-screen-order-steps-banner />
          </div>
        </v-col>
        <v-col cols="11">
          <v-expansion-panels class="ml-10 pa-2 pt-0">
            <v-expansion-panel>
              <v-expansion-panel-header class="elevation-0" style="background-color: #F9F9F9;">
                <div class="text-h6">
                  {{ $t('views.dashboard.whatWeCanDoForYou') }}
                </div>
                <template v-slot:actions>
                  <v-icon color="#46A55C">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card elevation="0" class="mb-5 pa-2 pl-10 transparent">
                  <v-card-text>
                    <v-row class="mt-2">
                      <v-col class="px-0" cols="2">
                        <div class="d-flex flex-column align-center" style="width: 100px">
                          <img
                            src="/img/laserCuttingIcon.svg"
                            width="70"
                            height="70"
                          >
                          <span class="font-weight-bold">
                            {{ $t('views.dashboard.manufacturingProcesses.laserCutting') }}
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="3">
                        <div class="d-flex flex-column align-center" style="width: 180px">
                          <img
                            src="/img/threadingIcon.svg"
                            width="70"
                            height="70"
                          >
                          <span class="font-weight-bold">
                            {{ $t('views.dashboard.manufacturingProcesses.threadingChamfering') }}
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="3">
                        <div class="d-flex flex-column align-center" style="width: 150px">
                          <img
                            src="img/bendingIcon.svg"
                            width="70"
                            height="70"
                          >
                          <span class="font-weight-bold">
                            {{ $t('views.dashboard.manufacturingProcesses.sheetMetalBending') }}
                          </span>
                        </div>
                      </v-col>

                      <v-col cols="3">
                        <div class="d-flex flex-column align-center" style="width: 130px">
                          <img
                            src="/img/surfaceFinishingIcon.svg"
                            width="70"
                            height="70"
                          >
                          <span class="font-weight-bold">
                            {{ $t('views.dashboard.manufacturingProcesses.surfaceFinishing') }}
                          </span>
                        </div>
                      </v-col>
                      <v-col class="px-0" cols="2">
                        <div class="d-flex flex-column align-center" style="width: 100px">
                          <img
                            src="/img/icon-cnc-urning2@1x.svg"
                            width="70"
                            height="70"
                          >
                          <span class="font-weight-bold">
                            {{ $t('views.dashboard.manufacturingProcesses.cncTurning') }}
                          </span>
                        </div>
                      </v-col>
                      <v-col class="px-0" cols="3">
                        <div class="d-flex flex-column align-center" style="width: 200px">
                          <img
                            src="/img/icon-bulk@1x.svg"
                            width="70"
                            height="70"
                          >
                          <span class="font-weight-bold">
                            {{ $t('views.dashboard.manufacturingProcesses.hardwareBulk') }}
                          </span>
                        </div>
                      </v-col>
                      <v-col class="px-0" cols="3">
                        <div class="d-flex flex-column align-center" style="width: 170px">
                          <img
                            src="/img/icon-custom@1x.svg"
                            width="70"
                            height="70"
                          >
                          <span class="font-weight-bold">
                            {{ $t('views.dashboard.manufacturingProcesses.hardwareCustomBags') }}
                          </span>
                        </div>
                      </v-col>
                      <v-col class="px-0 ml-n4" cols="3">
                        <div class="d-flex flex-column align-center" style="width: 180px">
                          <img
                            src="/img/laserEngravingIcon.svg"
                            width="70"
                            height="70"
                          >
                          <span class="font-weight-bold">
                            {{ $t('views.dashboard.manufacturingProcesses.laserEngraving') }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="11">
          <v-expansion-panels v-model="expandVideoTutorials" class="ml-10 pa-2 pt-0">
            <v-expansion-panel v-model="expandVideoTutorials">
              <v-expansion-panel-header class="elevation-0" style="background-color: #F9F9F9;">
                <div id="videoTutorials" class="text-h6">
                  {{ $t('views.dashboard.videoTutorials') }}
                </div>
                <template v-slot:actions>
                  <v-icon color="#46A55C">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card elevation="0" class="pa-2 transparent">
                  <v-card-text>
                    <v-row style="max-width: 1100px">
                      <v-col
                        v-for="tutorial in tutorials"
                        :key="tutorial.text"
                        cols="6"
                      >
                        <div
                          v-if="tutorial.display"
                          class="tutorials-container py-0 ma-0"
                          style="cursor: pointer;"
                          @click="tutorialDialog.tutorial = tutorial; tutorialDialog.isActive = true;"
                        >
                          <v-icon
                            left
                            medium
                            grey
                            active-class="active-filter"
                          >
                            mdi-arrow-right-thin
                          </v-icon>
                          <span
                            class="font-weight-bold"
                            :data-cy="`video-link-${tutorial.text.split('.').slice(-1)}`"
                          >
                            {{ $t(tutorial.text) }}
                          </span>
                          <div
                            style="padding-left: 30px;"
                          >
                            <v-icon
                              small
                              class="video-detail"
                              color="success"
                            >
                              mdi-video
                            </v-icon>
                            <span
                              class="video-detail"
                              style="margin-left: 10px;color:#46A55C;"
                            >
                              {{ $t(tutorial.duration) }} {{ $t('common.minute') }}
                            </span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <core-dialog
      v-model="tutorialDialog.isActive"
      width="1200px"
      persistent
      @click:cancel="tutorialDialog.isActive = false;isVideoLoaderShow = true"
    >
      <template #title>
        <span data-cy="video-title">{{ $t(tutorialDialog.tutorial.text) }}</span>
      </template>
      <template #content>
        <div class="d-flex justify-center">
          <video
            v-if="tutorialDialog.isActive"
            style="min-height: 500px"
            data-cy="video-viewer"
            class="d-flex"
            controls
            preload="auto"
            autoplay
            width="90%"
            height="60%"
          >
            <source :src="$t(tutorialDialog.tutorial.link)" type="video/mp4">
          </video>
        </div>
      </template>
    </core-dialog>
  </core-main>
</template>

<script>
import { mapState } from 'vuex';
import { models } from 'feathers-vuex';

import companyNumberToInt from '@/mixins/companyNumberToInt';

export default {
  mixins: [companyNumberToInt],
  data: () => ({
    isVideoLoaderShow: true,
    tutorialDialog: {
      isActive: false,
      tutorial: null,
    },
    expandVideoTutorials: undefined,
  }),
  computed: {
    ...mapState('auth', [
      'user',
    ]),
    company: () => models.api.Company.getFromStore(0),
    User: () => models.api.User,
    currentUser: vm => vm.User.getFromStore(vm.user._id),
    showReferralBanner: vm => (
      vm.company
      && vm.company.CurrCode === 'USD'
      && (vm.convertCustomerNumberToInt(vm.company.CustNum) >= 8 && vm.convertCustomerNumberToInt(vm.company.CustNum) <= 2250)
    ),
    newSignUpPromoCode: () => models.api.PromoCode.findInStore({ query: { PromoId: 'NewSignup10' } }).data,
    showPromotionCountdownBanner: vm => (
      vm.company
      && vm.company.CurrCode === 'USD'
      && vm.newSignUpPromoCode.length
    ),
    tutorials: () => [
      {
        icon: 'mdi-pencil',
        text: 'views.dashboard.getInstantQuote',
        link: 'views.dashboard.getInstantQuoteVideoUrl',
        duration: '1:45',
        display: true,
      },

      {
        icon: 'mdi-currency-usd',
        text: 'views.dashboard.reviewCosts',
        link: 'views.dashboard.reviewCostsVideoUrl',
        duration: '1:44',
        display: true,
      },
      {
        icon: 'mdi-format-list-bulleted',
        text: 'views.dashboard.requestForQuote',
        link: 'views.dashboard.requestForQuoteVideoUrl',
        duration: '1:13',
        display: true,
      },
      {
        icon: 'mdi-bookmark-outline',
        text: 'views.dashboard.createPartsCatalog',
        link: 'views.dashboard.createPartsCatalogVideoUrl',
        duration: '1:20',
        display: true,
      },
      {
        icon: 'mdi-ballot',
        text: 'views.dashboard.placeOrder',
        link: 'views.dashboard.placeOrderVideoUrl',
        duration: '1:29',
        display: true,
      },
      {
        icon: 'mdi-tune',
        text: 'views.dashboard.updateAccountPreferences',
        link: 'views.dashboard.updateAccountPreferencesVideoUrl',
        duration: '1:31',
        display: true,
      },

      {
        icon: 'mdi-tune',
        text: 'Hardware bulk',
        link: 'views.dashboard.updateAccountPreferencesVideoUrl',
        duration: '1:31',
        display: false,
      },
      {
        icon: 'mdi-tune',
        text: 'Hardware Custom Bag',
        link: 'views.dashboard.updateAccountPreferencesVideoUrl',
        duration: '1:31',
        display: false,
      },
    ],
  },
  mounted() {
    models.api.PromoCode.find();
  }
};
</script>
<style>
.tutorials-container:hover {
  background-color: #fff;
  color: #46A55C;
}

.tutorials-container:hover .video-detail {
  color: rgba(0, 0, 0, 0.54) !important
}
</style>
