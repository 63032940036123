export default {
  methods: {
    formatAddressText(address) {
      const addressFields = [
        'address1',
        'address2',
        'city',
        'region',
        'zip',
        'country',
      ];

      return addressFields
        .map(field => address[field])
        .filter(field => !!field)
        .join(', ');
    },
  },
};
