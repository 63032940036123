import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'roles';

const Model = class Role extends BaseModel {
  static modelName = 'Role';

  static instanceDefaults() {
    return {
      name: '',
      rights: [],
      inactive: false,
    };
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
