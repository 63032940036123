<template>
  <v-col cols="auto">
    <span class="subtitle-2"> {{ title }} </span>
    <v-radio-group
      v-model="model"
      row
    >
      <v-radio
        v-for="(option, i) in data"
        :key="i"
        :label="option"
        :value="option"
      />
    </v-radio-group>
  </v-col>
</template>

<script>
export default {
  props: [
    'title', 'data'
  ],
  data: () => ({
    model: ''
  })
};
</script>
