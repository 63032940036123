import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
// TODO : See if we can eliminate this, it's only used twice, is there an alternative in those cases?
import AsyncComputed from 'vue-async-computed';
import mask from 'vue-the-mask';
import '@babel/polyfill';
import vueFeathers from 'vue-feathers';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

// import '@/sass/variables.scss';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';
import './directives';
import './components';
import './registerServiceWorker';
import './plugins/datadog-rum';
// import ddlogger from './utils/logger';
import vuetify from './plugins/vuetify';
import _ from './plugins/simulateLodash';
import feathersClient from './plugins/feathers-client';

dayjs.extend(relativeTime);
window._ = _;
window.moment = dayjs;

Vue.config.productionTip = false;

// Make sure lodash works in templates too
Vue.prototype._ = _;
Vue.prototype.moment = dayjs;

Vue.use(AsyncComputed);
Vue.use(VueCompositionAPI);
Vue.use(mask);
Vue.use(vueFeathers, feathersClient);

dayjs.locale(i18n.locale);

// Vue.config.errorHandler = (err, _vm, info) => {
//   console.log('Uncaught FE Error', err);
//   ddlogger.logger.error('uncaught fe error', {
//     message: err.message,
//     stack: err.stack,
//     type: info,
//   });
// };
Vue.use(VueTelInputVuetify, {
  vuetify,
});

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
