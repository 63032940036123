<template>
  <v-card-actions class="pt-0">
    <div>
      <core-ownerships
        :value="{
          createdBy: rfq.createdBy,
          createdByEmail: rfq.createdByEmail,
          createdAt: rfq.createdAt,
        }"
      />
    </div>
  </v-card-actions>
</template>

<script>
export default {
  props: [
    'expanded', 'toggleaction', 'rfq',
  ],

  computed: {
    computedExpanded: {
      get() {
        return this.toggleExpanded;
      },
      set() {
        this.toggleaction();
      },
    },
  },
};
</script>
