<template>
  <v-container class="fill-height d-flex align-center justify-center flex-column flex-fill">
    <img
      src="/img/illustration@1x.svg"
      class="my-16"
      @click="$router.push('/')"
    >
    <p class="text-h2 font-weight-bold mb-16">
      {{ $t('common.pageNotFound') }}
    </p>
    <p class="text-h5 mb-16 font-weight-medium">
      {{ $t('common.sorryPageNotFound') }}
    </p>

    <v-btn
      color="primary mb-16"
      x-large
      depressed
      @click="$router.push('/')"
    >
      {{ $t('common.homePage') }}
    </v-btn>

    <img
      src="/img/Komacut-logo.svg"
      class="mt-16"
      width="189px"
      height="59px"
    >
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound'
};
</script>

<style scoped>

</style>
