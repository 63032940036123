export default {
  methods: {
    formatCurrency(amount, allowNone) {
      if (isNaN(amount)) {
        if (allowNone) {
          return 'TBD';
        }
        amount = 0;
      }
      const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
      return `$${(amount).toLocaleString('en', options)}`;
    },
  },
};
