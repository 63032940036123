<template>
  <div class="filter-switch-group">
    <v-subheader>
      {{ section }}
    </v-subheader>

    <v-list-item
      v-for="(option, i) in $attrs.options"
      :key="i"
      dense
    >
      <v-list-item-action>
        <v-switch
          v-model="localValue"
          :value="option"
        />
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ option }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import FilterMixin from '../../../mixins/filter';

export default {
  name: 'SwitchGroup',

  mixins: [
    FilterMixin,
  ],
};
</script>

<style scoped>

</style>
