<template>
  <v-card
    rounded
    height="250"
    width="95%"
    color="#F9F9F9"
    outlined
    style="outline: 1px dashed #5FCE79; stroke-dasharray: 5 10;"
  >
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <div class="text-h5 font-weight-bold mt-2 ml-4" style="color: #2E343B">
            {{ $t('views.dashboard.welcomeScreenUploadFileBanner.title') }}
          </div>
          <v-row class="ml-4 mt-8" style="width: 350px;">
            <img
              style="vertical-align:middle"
              src="/img/welcomeScreen/icon-upload.svg"
            >
            <v-divider class="dashed-line pt-4" />
            <img
              style="vertical-align:middle"
              src="/img/welcomeScreen/icon-ruler.svg"
            >
          </v-row>
          <v-row class="ml-4 mt-8" style="">
            <v-btn
              color="primary"
              x-large
              :disabled="!userCanQuote"
              @click="onActionButtonClicked"
            >
              {{ $t('views.dashboard.welcomeScreenUploadFileBanner.uploadButton') }}
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="4" style="">
          <v-row class="mt-10 pt-2 ml-4">
            <div class="mr-1">
              {{ $t('views.dashboard.welcomeScreenUploadFileBanner.3dFiles') }}
            </div>
            <img
              style="vertical-align:middle"
              src="/img/welcomeScreen/icon-cube.svg"
            >
          </v-row>
          <v-row class="ml-4">
            <div class="text-body-1 font-weight-bold mt-2" style="color: #4B4B4B">
              {{ $t('views.dashboard.welcomeScreenUploadFileBanner.stp') }}
            </div>
          </v-row>
          <v-row class="ml-4">
            <div class="text-body-1 font-weight-bold" style="color: #4B4B4B">
              {{ $t('views.dashboard.welcomeScreenUploadFileBanner.step') }}
            </div>
          </v-row>
          <v-row class="ml-4">
            <div class="text-body-1 font-weight-bold" style="color: #4B4B4B">
              {{ $t('views.dashboard.welcomeScreenUploadFileBanner.sldprt') }}
            </div>
          </v-row>
          <v-row class="ml-4">
            <p class="mt-4" style="font-size: 12px; font-weight: 300;">
              {{ $t('views.dashboard.welcomeScreenUploadFileBanner.label1') }}
            </p>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WelcomeScreenUploadFileBanner',
  computed: {
    ...mapGetters('auth', { loggedInUser: 'user' }),
    ...mapGetters('temp-rfqs', { getTempRfqIdInProgress: 'getTempRfqIdInProgress' }),
    userCanQuote: ({ loggedInUser }) => loggedInUser && (loggedInUser.isAdmin || _.get(loggedInUser, 'rights.instantQuote', false)),
  },
  methods: {
    onActionButtonClicked() {
      this.$router.push({ name: 'EditRFQ', params: { id: this.getTempRfqIdInProgress, openDialog: true } });
    },
  },
};
</script>

<style scoped>
.dashed-line {
  margin: 0 auto; /* Center the line horizontally */
  border-top: 1px dashed #5FCE79; /* You can adjust the color and thickness as needed */
  margin-top: 12px;
  width: 10px
}
</style>
