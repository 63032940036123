// eslint-disable-next-line max-len
/* eslint-disable no-underscore-dangle */
import currency from 'currency.js';
import { models } from 'feathers-vuex';
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';
import { OrderItem } from '@/utils/types';

const servicePath = 'orders';

const Model = class Order extends BaseModel {
  static modelName = 'Order';

  static instanceDefaults() {
    return {
      items: [],
      expediteOption: null,
      shippingAddress: null,
      forwarder: null,
      promoCodeObject: null,
      PaymentTransaction: []
    };
  }

  static setupInstance(data) {
    if (data.OrderDate) {
      data.formattedOrderDate = new Date(data.OrderDate);
      this._formattedOrderDate = moment(data.OrderDate).format('MMM D, YYYY');
    }
    if (data.ShipmentDate) {
      data.formattedShipmentDate = new Date(data.ShipmentDate);
      this._formattedShipmentDate = moment(data.ShipmentDate).format('MMM D, YYYY');
    }
    if (data.RequestDate) {
      data.formattedRequestDate = new Date(data.RequestDate);
      this._formattedRequestDate = moment(data.RequestDate).format('MMM D, YYYY');
    }

    return data;
  }

  get formattedOrderDate() {
    return moment(this._formattedOrderDate).format('MMM D, YYYY');
  }

  set formattedOrderDate(value) {
    this._formattedOrderDate = this.OrderDate;
  }

  get formattedShipmentDate() {
    return moment(this._formattedShipmentDate).format('MMM D, YYYY');
  }

  set formattedShipmentDate(value) {
    this._formattedShipmentDate = this.ShipmentDate;
  }

  get formattedRequestDate() {
    return moment(this._formattedRequestDate).format('MMM D, YYYY');
  }

  set formattedRequestDate(value) {
    this._formattedRequestDate = this.RequestDate;
  }

  get formattedTotal() {
    return currency(this.Total ? this.Total : this.grandTotal, { symbol: this.currencySymbol }).format();
  }

  get orderItemsTotal() {
    return currency(this.items.reduce((total, item) => total.add(item.SubTotal), currency(0, { symbol: this.currencySymbol })), { symbol: this.currencySymbol });
  }

  get discountAmount() {
    let discount = currency(0, { symbol: this.currencySymbol });
    if (this.promoCodeObject) {
      if (this.promoCodeObject.PromoType === 'Amount') {
        if (this.orderItemsTotal.value >= parseFloat(this.promoCodeObject.PromoMinOrderAmount)) {
          discount = currency(this.promoCodeObject.PromoDiscountAmount, { symbol: this.currencySymbol });
        }
      } else if (this.promoCodeObject.PromoType === 'Percentage') {
        if (this.orderItemsTotal.value >= parseFloat(this.promoCodeObject.PromoMinOrderAmount)) {
          discount = currency(this.orderItemsTotal.multiply(parseFloat(this.promoCodeObject.PromoRate)), { symbol: this.currencySymbol });
        }
        if (this.orderItemsTotal.value > parseFloat(this.promoCodeObject.PromoMaxOrderAmount)) {
          const discountOrderAmount = currency(parseFloat(this.promoCodeObject.PromoMaxOrderAmount), { symbol: this.currencySymbol });
          return currency(discountOrderAmount.multiply(parseFloat(this.promoCodeObject.PromoRate)), { symbol: this.currencySymbol });
        }
      }
    }
    return discount;
  }

  get expediteFees() {
    const multiplier = parseFloat(_.get(this.expediteOption, 'Rank', 0));
    return this.orderItemsTotal.multiply(multiplier);
  }

  get orderSubtotal() {
    return this.orderItemsTotal.add(this.expediteFees).subtract(this.discountAmount);
  }

  // eslint-disable-next-line class-methods-use-this
  get taxPercentage() {
    const company = models.api.Company.getFromStore(0);
    return company && company.TaxRate1 ? company.TaxRate1 / 100 : 0;
  }

  get estimatedTax() {
    const company = models.api.Company.getFromStore(0);
    if (company.IncludeTaxInPrice === '0') {
      return this.orderSubtotal.multiply(this.taxPercentage);
    }
    return currency(0, { symbol: this.currencySymbol });
  }

  get grandTotal() {
    return this.orderSubtotal.add(this.estimatedTax);
  }

  get taxIncludedInPrice() {
    const company = models.api.Company.getFromStore(0);
    if (company.IncludeTaxInPrice === '1') {
      const includeTaxOnExpedite = this.expediteFees - this.expediteFees / (1 + this.taxPercentage);
      return currency(
        this.items.reduce(
          (total, item) => total.add(item.SubTotal - item.SubTotal / (1 + this.taxPercentage)), currency(includeTaxOnExpedite, { symbol: this.currencySymbol })
        ), { symbol: this.currencySymbol }
      );
    }
    return currency(0, { symbol: this.currencySymbol });
  }

  // eslint-disable-next-line class-methods-use-this
  get currencySymbol() {
    return _.get(models.api.Company.getFromStore(0), 'currencySymbol', '$');
  }

  addItem(type, id, qty, drawingConsultancyPdf = null) {
    const items = this.items.filter(item => item._id === id);
    if (items.length) {
      items[0].ReqQty = qty;
      return;
    }

    this.items.push(new OrderItem(type, id, qty, drawingConsultancyPdf));
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
