<template>
  <div style="display: inline;">
    <div v-if="isPdfAlreadyUpload" style="display: inline">
      <v-btn
        text
        small
        class="px-0"
        :loading="isPdfRemoved"
        color="primary"

        @click.stop="isPDFDownload=true"
      >
        <v-icon>
          mdi-file-pdf-box
        </v-icon>
      </v-btn>
    </div>

    <v-icon v-else color="primary" size="20">
      mdi-note-off
    </v-icon>
    <v-overlay :value="isPDFDownload" z-index="90" @click.stop />
    <v-navigation-drawer
      v-model="isPDFDownload"
      width="70%"
      fixed
      stateless
      hide-overlay
      right
      temporary
      style="background-color: #ada6a6; z-index: 99 !important;"
      @click.stop
    >
      <div
        ref="rfqViewerContainer"
        style="height: calc(100% - 52px);z-index: 99 !important;"
        @click.stop
      >
        <div class="d-flex justify-end">
          <v-btn
            icon
            x-large
            @click="isPDFDownload=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <core-pdf-viewer
          v-if="isPDFDownload"
          :file-name="part.Item"
          :file-path="pdfUri"
          :item-height="800"
          @click.stop
        />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'MyPartPdfViewer',
  props: {
    part: {
      type: Object,
    },
  },
  data() {
    return {
      myDropZone: null,
      pdfPageHeight: 0,
      pdfPageWidth: 0,
      display: false,
      isPdfAlreadyUpload: false,
      isPDFDownload: false,
      isPdfUploadPending: false,
      isFileAdded: true,
      isPdfRemoved: false
    };
  },
  computed: {
    ...mapState('auth', ['accessToken']),

    pdfUri: vm => {
      if (!vm.part) {
        return '';
      }
      const host = process.env.VUE_APP_API_URL;
      const fileName = `${_.get(vm.part, 'DrawingRowPointer', '').toLowerCase()}.pdf`;
      return `${host}uploads/${fileName}/${vm.accessToken}/${vm.part.Item}`;
    }
  },

  async created() {
    await this.checkIfPdfExist();
  },
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      /* 70 breathing room */
      this.pdfPageWidth = this.$refs.rfqViewerContainer.getBoundingClientRect().width - 70;
      const ratio = 8.5 / 11;
      this.pdfPageHeight = this.pdfPageWidth / ratio;
    });
    resizeObserver.observe(this.$refs.rfqViewerContainer);
  },
  methods: {
    async checkIfPdfExist() {
      const response = await fetch(
        this.pdfUri,
      );
      this.isPdfAlreadyUpload = response.status === 200;
    }
  },

};
</script>

<style scoped>

</style>
