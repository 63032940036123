<template>
  <v-navigation-drawer
    v-model="isSidePanelOpen"
    style=";
        overflow-y: hidden !important;
        width: 100%;
        background-color: rgba(25, 25, 25, .9);"
    fixed
    stateless
    hide-overlay
    right
    temporary
  >
    <div
      class="d-flex justify-end"
      style="background-color: transparent;width:100%; z-index: 99; height: 112px"
    >
      <v-btn
        icon
        dark
        @click="$emit('closeSidePanel', false); isSidePanelOpen=false; activeTab = 0;"
      >
        <v-icon x-large>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <div
      v-if="isMeshUploaded"
      style="position:absolute; top: 136px; left: 50%; transform: translateX(-50%); z-index: 999"
    >
      <v-icon large class="mr-12" color="#5FCE79">
        mdi-rotate-3d
      </v-icon>
      <v-icon large color="#5FCE79">
        mdi-magnify-minus-outline
      </v-icon>
    </div>
    <div>
      <v-sheet
        style="position: fixed; top: 50px;  right: 0;"
        rounded
        class="pa-3"
      >
        <div v-if="part && part.Item" style="height: 100%;">
          <v-tabs
            v-model="activeTab"
            class="flex-grow-1"
            centered
            grow
            style="width:400px;"
          >
            <v-tab :disabled="analysisDataRankOne && analysisDataRankOne.Description === '3dSideViewer.error'">
              <v-icon class="mr-2">
                mdi-file-document
              </v-icon>
              <span>
                {{ $t('common.specifications') }}
              </span>
            </v-tab>

            <v-tab :disabled="analysisDataRankOne && analysisDataRankOne.Description === '3dSideViewer.error'">
              <v-icon class="mr-2">
                mdi-text-box-check-outline
              </v-icon>
              <span>
                {{ $t('common.analysis') }}
              </span>
            </v-tab>
          </v-tabs>
          <div
            v-if="isAnalysisPending"
            class="d-flex align-center justify-center"
            style="width: 400px; z-index: 99; height: calc(100vh - 250px); overflow-y: auto;"
          >
            <v-progress-circular
              :size="90"
              :width="5"
              indeterminate
              color="primary"
            />
          </div>
          <!-- TODO no need for components, bring them up to top level -->
          <v-tabs-items
            v-else
            v-model="activeTab"
            style="max-width: 450px; z-index: 99; height: calc(100vh - 250px); overflow-y: auto;"
          >
            <template
              v-if="analysisDataRankOne && analysisDataRankOne.Description === '3dSideViewer.error'"
            >
              <v-tab-item class="pt-5">
                <v-alert
                  color="#F3D9DE"
                >
                  <div class="body-2 font-weight-bold">
                    <v-tooltip top z-index="9999">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          class="mr-1"
                          color="#B00020"
                          style="vertical-align: sub"
                          v-on="on"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span v-html="$t('sidePanelErrorAndWarning.errorTooltip')" />
                    </v-tooltip>
                    <div class="d-inline">
                      <span class="text-uppercase">
                        <span>
                          <!--                          {{ $t('common.Error') }}&lt;!&ndash; - {{ $t('common.serverResponseError') }}&ndash;&gt;-->
                          {{ $t('common.Sorry') }}<!-- - {{ $t('common.serverResponseError') }}-->
                          <p class="text--secondary caption mb-0 mt-2">
                            {{ $t(analysisDataRankOne.Value) }}
                          </p>
                        </span>
                      </span>
                    </div>
                  </div>
                </v-alert>
              </v-tab-item>
            </template>
            <template v-else>
              <v-tab-item class="pt-5">
                <core-parts-design-data-table :part="part" />
                <core-manufacturing-process-table :part="part" class="my-2" />
              </v-tab-item>
              <v-tab-item class="pt-5">
                <core-dfm-table :part="part" class="my-2" />
                <core-process-limitations-table :part="part" class="my-2" />
                <core-warning-error-table :part="part" class="my-2" />
              </v-tab-item>
            </template>
          </v-tabs-items>
        </div>
        <div v-else class="d-flex font-weight-bold justify-end align-center" style="height:500px;">
          <span class="mx-12">
            <span v-if="analysisDataRankOne && analysisDataRankOne.Description === '3dSideViewer.error'">
              {{ $t(analysisDataRankOne.Value) }}
            </span>
            <span v-else>
              {{ $t('common.calculateCostToViewDetails') }}
            </span>
          </span>
        </div>
      </v-sheet>
      <div ref="largeInspector" />
      <core-model-viewer
        v-if="isOpen && !isAnalysisPending && !isMountedPending"
        :part="part"
        :height="modelViewerHeight"
        :width="modelViewerWidth"
        :viewport="$refs.largeInspector"
        :is-svg-uploaded="isSvgUploaded"
        :is-mesh-uploaded="isMeshUploaded"
        is-interactive
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapState } from 'vuex';

const { CsiAnalysis } = models.api;
export default {
  name: 'PartViewerDrawer',
  props: {
    part: Object,
    isOpen: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoadingSidePanelValues: false,
      modelViewerWidth: 0,
      modelViewerHeight: 0,
      activeTab: 0,
      isSidePanelOpen: this.isOpen,
      isMountedPending: true,
    };
  },
  computed: {
    ...mapState('csi-analysis', { isAnalysisPending: 'isGetPending' }),
    isMeshUploaded() {
      const estimateLineStatus = _.get(this.part, 'EstimateLineStatus', null);
      if (estimateLineStatus) {
        return estimateLineStatus.isMeshUploaded;
      }
      return !this.part.is2dModel;
    },
    isSvgUploaded() {
      const estimateLineStatus = _.get(this.part, 'EstimateLineStatus', null);
      if (estimateLineStatus) {
        return estimateLineStatus.isSvgUploaded;
      }
      return this.part.is2dModel;
    },
    analysisDataRankOne() {
      if (this.part) {
        const data = _.get(CsiAnalysis.getFromStore(this.part.Item), 'data', []);
        return data.find(d => d.Rank === '1');
      }
      return null;
    }
  },
  watch: {
    isOpen(val) {
      this.isSidePanelOpen = val;
      if (val) {
        document.documentElement.style.overflowY = 'hidden';
      } else {
        document.documentElement.style.overflowY = 'visible';
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.isMountedPending = false;
  },
  created() {
    if (this.part && this.part.Item) { CsiAnalysis.get(this.part.Item); }
  },
  methods: {

    handleResize() {
      if (this.isOpen) {
        if (this.isSvgUploaded) {
          this.modelViewerWidth = window.innerWidth - 425;
        } else {
          this.modelViewerWidth = window.innerWidth - 200;
        }
        this.modelViewerHeight = window.innerHeight - 112;
      } else {
        this.modelViewerWidth = 0;
        this.modelViewerHeight = 0;
      }
    },
  }
};
</script>
<style scoped>
>>> .v-navigation-drawer__content {
    overflow-y: hidden;
    min-width: 900px;
}

.part-viewer {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}
</style>
