<template>
  <v-container>
    <div class="d-flex justify-end mr-2 mt-2">
      <v-btn icon @click="$emit('closePanel')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-row justify="center">
      <v-col cols="6">
        <div class="d-flex justify-center">
          <span class="display-1 pb-6">Quote Summary</span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="mr-3 subtitle-1 font-weight-bold">Reference Number:</span>
          <span class="align-end subtitle-1">123</span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="mr-2 subtitle-1 font-weight-bold">Quote Status:</span>
          <span class="align-end subtitle-1">status</span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="mr-2 subtitle-1 font-weight-bold">Number of Parts:</span>
          <span class="align-end subtitle-1">
            Parts</span>
        </div>

        <div class="d-flex justify-space-between">
          <span class="mr-2 subtitle-1 font-weight-bold">Submitted by:</span>
          <span class="align-end subtitle-1">createdBy</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="px-8">
      <v-col>
        <core-rfq-parts-table :rfq-id="rfqId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RFQPartsPanel',
  props: {
    rfqId: {
      type: String
    },
  },

  computed: {
    ...mapGetters('parts', { findParts: 'find' }),

    parts() {
      return this.findParts({ query: {
        EstimateID: this.rfqId
      } }).data;
    },
  },
};
</script>
