<template>
  <v-col class="py-0">
    <v-row>
      <v-col class="py-0">
        <v-text-field
          v-model="data.PhoneOffice"
          v-mask="'(###) ###-####'"
          :label="$t('common.number')"
          :placeholder="$t(`form.phone.placeholders.number`)"
          :rules="[notEmptyRules]"
          @change="$emit('change')"
        />
      </v-col>

      <!-- <v-col class="py-0" cols="3">
        <v-text-field
          v-model="data.phoneExt"
          :label="$t('form.phone.fields.ext')"
          :placeholder="$t(`form.phone.placeholders.extension`)"
        />
      </v-col> -->
    </v-row>
  </v-col>
</template>

<script>
import EditFormMixin from '../../mixins/editForm';

export default {
  mixins: [
    EditFormMixin,
  ],

  data() {
    return {
      notEmptyRules: value => (!!value || 'Cannot be empty'),
    };
  },
};
</script>
