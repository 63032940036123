import Vue from 'vue';
import _ from '@/plugins/simulateLodash';

const requireComponent = require.context('./', true, /\.vue$/);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = _.upperFirst(
    _.camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
  );

  Vue.component(componentName, componentConfig.default || componentConfig);
});
