<template>
  <v-container class="fill-height px-5" style="min-width: 1190px; max-width: 1400px; max-height: 100%">
    <v-form
      ref="form"
      v-model="validation"
      style="width:100%"
      class="fill-height"
    >
      <v-row class="fill-height">
        <v-col
          class="rounded-xl"
          style="z-index:99; background-color: white;height: 100%; min-width: 550px;"
          cols="6"
        >
          <v-row class="mb-5 mt-10">
            <v-col
              offset="2"
              cols="8"
              class="d-flex flex-column align-center"
            >
              <img src="/img/Komacut-logo.svg" width="180px">

              <span class="text-h5 font-weight-bold mt-8">
                {{ $t('views.resetPassword.changePassword') }}
              </span>
              <span class="mt-5">{{ $t('views.resetPassword.pleaseEnterPW') }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              offset="2"
              cols="8"
              class="mt-16"
            >
              <v-text-field
                v-model="password"
                :label="$t('views.signUp.password')"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isPasswordVisible ? 'text' : 'password'"
                :rules="[v => (v && v.length >= 8) ,
                         v => /(?=.*[A-Z])/.test(v) ,
                         v => /(?=.*\d)/.test(v) ,
                         v => /([!@$%])/.test(v)]"
                data-cy="change-password-input"
                outlined
                dense
                required
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              offset="2"
              cols="8"
              class="py-0"
            >
              <p class="ma-0">
                {{ $t('views.resetPassword.mustContain') }}
              </p>

              <p class="red--text ma-0" :class="{ 'green--text': password.length >= 8 }">
                <v-icon v-if="password.length >= 8" small color="green">
                  mdi-check
                </v-icon>

                <v-icon v-else small color="red">
                  mdi-close
                </v-icon>
                {{ $t('views.resetPassword.minCharacters') }}
              </p>
              <p class="red--text ma-0" :class="{ 'green--text': /(?=.*[a-z])/.test(password)}">
                <v-icon v-if="/(?=.*[a-z])/.test(password)" small color="green">
                  mdi-check
                </v-icon>

                <v-icon v-else small color="red">
                  mdi-close
                </v-icon>
                {{ $t('views.resetPassword.oneLower') }}
              </p>
              <p class="red--text ma-0" :class="{ 'green--text': /(?=.*[A-Z])/.test(password) }">
                <v-icon v-if="/(?=.*[A-Z])/.test(password)" small color="green">
                  mdi-check
                </v-icon>

                <v-icon v-else small color="red">
                  mdi-close
                </v-icon>
                {{ $t('views.resetPassword.oneUpper') }}
              </p>
              <p class="red--text ma-0" :class="{ 'green--text': /(?=.*\d)/.test(password) }">
                <v-icon v-if="/(?=.*\d)/.test(password)" small color="green">
                  mdi-check
                </v-icon>

                <v-icon v-else small color="red">
                  mdi-close
                </v-icon>
                {{ $t('views.resetPassword.oneDigit') }}
              </p>
              <p
                class="red--text ma-0"
                :class="{ 'green--text': /([@%+\\/'!#$^?:,(){}\[\]~`\-_.])/.test(password) }"
              >
                <v-icon v-if="/([!@$%])/.test(password)" small color="green">
                  mdi-check
                </v-icon>

                <v-icon v-else small color="red">
                  mdi-close
                </v-icon>
                {{ $t('views.resetPassword.oneSpecial') }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              offset="2"
              cols="8"
            >
              <v-text-field
                v-model="confirmPassword"
                :label="$t('views.signUp.confirmPassword')"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isPasswordVisible ? 'text' : 'password'"
                :rules="[(password === confirmPassword) || $t('views.signUp.passwordMustMatch')]"
                outlined
                dense
                required
                data-cy="change-confirm-password-input"
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              offset="2"
              cols="8"
            >
              <v-btn
                :disabled="!isFormValid || isResetPwdLongPending"
                color="success"
                large
                block
                data-cy="change-password-button"
                :loading="isResetPwdLongPending"
                @click="passwordSubmit"
              >
                <span>
                  {{ $t('views.resetPassword.changePassword') }}
                </span>
              </v-btn>
            </v-col>
            <v-col offset="2" cols="8">
              <p class="text--secondary caption" style="position: relative; top:190px; left:40px">
                {{ $t('common.footerRight') }} <a
                  href="https://komacut.com/about-us/contact-us/"
                  class="text-decoration-none"
                > {{ $t('common.contactUs') }}</a>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pl-0 d-flex justify-center align-center">
          <core-promotional />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import FormRules from '@/mixins/form-rules';

export default {
  name: 'restPassword',

  mixins: [
    FormRules,
  ],

  data() {
    return {
      validation: false,
      password: '',
      token: '',
      confirmPassword: '',
      isPasswordVisible: false,
      isResetPwdLongPending: false,
    };
  },
  computed: {
    ...mapState('app', ['locale']),
    isFormValid() {
      return this.validation;
    },
  },
  created() {
    const { token } = this.$route.query;
    this.token = token;
  },
  methods: {
    async passwordSubmit() {
      console.log('test');
      if (this.validation) {
        try {
          this.isResetPwdLongPending = true;
          await this.$feathers.service('authManagement').create({
            action: 'resetPwdLong',
            value: { token: this.token, password: this.password }
          });
          this.isResetPwdLongPending = false;
          this.$router.push('/resetpassword_success');
        } catch (e) {
          console.error(e);
        }
      } else {
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style scoped>
.login-card {
  z-index: 5;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #D4D8DC;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

</style>
