import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    // Tabs associated with the form
    tabs: Object,

    // Tab associated with the form
    tab: Object,

    // Parent tab associated with the form
    parentTab: Object,

    // Force embedded mode
    embedded: Boolean,

    // paths to watch in tab.entity
    paths: Array,

    // Disable updates
    disabled: Boolean,

    // Force disable the update button
    updateDisabled: Boolean,

    // Sync data to a parent model
    value: Object,

    // When the data of new document is empty apply these values
    defaultValue: { type: Object, default: () => {} },

    // Custom modified data check function
    modifiedFunction: Function,
  },

  data() {
    return {
      data: {},
      recomputeOriginalData: false,
    };
  },

  computed: {
    ...mapGetters('tabs', { isTabModified: 'isModified' }),

    /**
     * Returns if there is a parentTab associated with this form.
     */
    isEmbedded() {
      return !_.isUndefined(this.parentTab) || this.embedded;
    },

    /**
     * In creation mode when tab's entity has no _id value.
     */
    creationMode() {
      return _.get(this.tab, 'entity._id') === undefined;
    },

    /**
     * Returns tab entity value.
     */
    originalData() {
      const p = this.paths ? ['entity', ...this.paths] : 'entity';
      const v = !_.isEmpty(this.value) ? this.value : this.defaultValue || {};
      // to ensure originalData will get recomputed
      if (this.recomputeOriginalData) {
        this.recomputeOriginalData = false;
      }
      return _.get(this.tab, p, v);
    },

    /**
     * Deep compares data to originalData.
     * Update tab modified value when data is modified.
     */
    isDataModified() {
      const m = this.modifiedFunction
        ? !this.modifiedFunction(this.data, this.originalData)
        : !_.isEqual(this.data, this.originalData);
      if (this.tab && this.isTabModified(this.tab._id) !== m) {
        this.setTabModified({ _id: this.tab._id, modified: m });
      }
      return m;
    },

    isUpdateDisabled() {
      return this.updateDisabled || !this.isDataModified;
    },
  },

  watch: {
    'tab.entity': {
      deep: true,

      handler() {
        this.recomputeOriginalData = true;
        this.$nextTick(() => {
          this.data = this.cloneOriginalData();
          this.$emit('input', this.data);
          this.updateTab({
            _id: this.tab._id,
            entityId: this.tab.entity._id,
          });
        });
      }
    },

    value: {
      deep: true,

      handler(newValue) {
        this.data = !this.tab && this.isDataModified
          ? this.cloneOriginalData()
          : newValue;
      }
    },

    data: {
      deep: true,

      handler(newValue) {
        if (this.isDataModified) {
          this.$emit('input', newValue);
        }
      }
    },
  },

  mounted() {
    this.data = this.cloneOriginalData();
    this.$emit('input', this.data);
  },

  methods: {
    ...mapActions('tabs', {
      setTabModified: 'setModified',
      updateTab: 'updateTab',
    }),

    // clones originalData model instance.
    cloneOriginalData() {
      return _.cloneDeep(this.originalData);
    },

    /**
     * Emits the update-entity event to parent component.
     */
    async updateData() {
      this.$emit('update-entity', this.tab, this.data);
    },
  }
};
