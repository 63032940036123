<template>
  <v-form
    ref="forwarderForm"
    v-model="isAddressValid"
    @input="$emit('validityChange', $event)"
  >
    <v-row v-if="value">
      <v-col class="py-0">
        <v-row>
          <v-col class="py-0" cols="12">
            <v-text-field
              v-model="value.CompanyName"
              maxlength="60"
              data-cy="new-forwarder-name-input"
              :placeholder="$t('common.name')"
              :label="$t('common.name')"
              :rules="[isRequired]"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row
          v-for="(r, i) in addressFormatted"
          :key="`row-${i}`"
        >
          <v-col
            v-for="(c, j) in r"
            :key="`col-${j}`"
            class="py-0"
          >
            <v-text-field
              v-if="['address1', 'address2', 'address3', 'address4'].includes(c)"
              v-model="value[c]"
              maxlength="50"
              :data-cy="`new-forwarder-${c}-input`"
              :label="countryLabels[c]"
              :placeholder="getPlaceholder(_.get(country, 'code'), c)"
              :rules="[v => c === 'address2' || isRequired(v)]"
              outlined
              dense
            />
            <v-text-field
              v-else-if="c === 'city'"
              v-model="value[c]"
              maxlength="30"
              data-cy="new-forwarder-city-input"
              :label="countryLabels[c]"
              :placeholder="getPlaceholder(_.get(country, 'code'), c)"
              :rules="[isRequired]"
              outlined
              dense
            />
            <v-text-field
              v-else-if="c === 'zip'"
              v-model="value[c]"
              maxlength="10"
              data-cy="new-forwarder-postal-code-input"
              :label="countryLabels.postalCode"
              :placeholder="getPlaceholder(_.get(country, 'code'), c)"
              :rules="[isRequired]"
              outlined
              dense
            />
            <v-select
              v-else-if="c === 'country'"
              v-model="value.country"
              maxlength="30"
              data-cy="new-forwarder-country-input"
              :label="countryLabels.country"
              :placeholder="getPlaceholder(_.get(country, 'code'), 'country')"
              :items="countries"
              :rules="[isRequired]"
              item-text="name"
              item-value="code"
              outlined
              dense
              @change="
                value.region = ''
                value.city = '';
                value.zip = '';
                $refs.forwarderForm.resetValidation();"
            >
              <template slot="item" slot-scope="data">
                <span data-cy="new-forwarder-country-option">{{ data.item.name }}</span>
              </template>
            </v-select>

            <v-select
              v-else-if="c === 'province'"
              v-model="value.region"
              maxlength="5"
              data-cy="new-forwarder-region-input"
              :label="countryLabels.zone"
              :disabled="_.isEmpty(regions)"
              :items="regions"
              :placeholder="getPlaceholder(_.get(country, 'code'), 'region')"
              :rules="[isRequired]"
              item-text="name"
              item-value="code"
              outlined
              dense
              @change="
                value.city = '';
                value.zip = '';
                $refs.forwarderForm.resetValidation();"
            >
              <template slot="item" slot-scope="data">
                <span data-cy="new-forwarder-region-option">{{ data.item.name }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <v-text-field
              v-model="value.phone"
              maxlength="25"
              data-cy="new-forwarder-phone-input"
              :label="countryLabels.phone"
              :placeholder="getPlaceholder(_.get(country, 'code'), 'phone')"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <v-text-field
              v-model="value.name"
              data-cy="new-forwarder-contact-input"
              maxlength="100"
              :label="$t('views.settings.tabs.forwarders.contact')"
              :placeholder="$t('views.settings.tabs.forwarders.contact')"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <v-text-field
              v-model="value.email"
              data-cy="new-forwarder-email-input"
              maxlength="128"
              :label="$t('form.address.fields.email')"
              :placeholder="getPlaceholder(_.get(country, 'code'), 'email')"
              :rules="[isEmail]"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <slot :isAddressValid="isAddressValid" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { mapState } from 'vuex';
import { models } from 'feathers-vuex';
import AddressFormatter from '@shopify/address';
import FormRules from '@/mixins/form-rules';

const { Company, Country, Regions } = models.api;

export default {
  mixins: [
    FormRules,
  ],

  props: {
    value: {},
  },

  data() {
    return {
      chineseForm: null,
      englishForm: null,
      fields: 'form.address.fields',
      placeholder: 'form.address.placeholders',
      isAddressValid: false,
      chineseJson: [],
      englishJson: [],
    };
  },

  computed: {
    ...mapState('app', ['locale']),

    fetchedCountries: () => Country.findInStore({ query: {} }).data,

    fetchedRegions: () => Regions.findInStore({ query: {} }).data,

    company: () => Company.getFromStore(0),

    regions: vm => _.get(vm, 'country.zones', [])
      .filter(r => vm.fetchedRegions.map(fr => fr.Code).includes(r.code)),

    unfilteredCountries: vm => (vm.locale === 'en-US' ? vm.englishJson : vm.chineseJson),

    country: vm => vm.countries.find(c => c.code === vm.value.country),

    countryLabels: vm => {
      if (vm.country) {
        return vm.country.labels;
      }
      if (vm.countries.length === 0 || !vm.company) {
        return {};
      }
      return vm.countries.find(c => c.code === vm.company.country).labels;
    },

    countries: vm => vm.unfilteredCountries.filter(c => vm.fetchedCountries.map(fc => fc.Code).includes(c.code)),

    addressFormatter() {
      return new AddressFormatter('CA');
    },

    addressFormatted() {
      // TODO: Refactor this, probably using a reduce() and simpler JS
      const DEFAULT_FORM_LAYOUT = '{firstName}{lastName}_{company}_{address1}'
        + '_{address2}_{city}_{country}{province}{zip}_{phone}';

      // https://regex101.com/r/SH6ovw/1
      // TODO: It seem this regex and replace was not working
      let formatString = _.get(this, 'country.formatting.edit', DEFAULT_FORM_LAYOUT)
        .replace('({firstName}(_?)|{lastName}(_?)|{company}(_?))', '');

      // quick and dirty
      const province = formatString.includes('{province}') ? '{province}' : '';
      formatString = formatString.replace('{country}', '');
      formatString = formatString.replace('{province}', '');
      formatString = formatString.replace('__', '_');

      const formatCountryProvince = formatString.replace('{company}', `{company}_{country}${province}`);

      return formatCountryProvince.split('_')
        .map(r => r.split('}{'))
        .map(r => {
          r[0] = r[0].replace('{', '');
          r[r.length - 1] = r[r.length - 1].replace('}', '');
          return r;
        });
    },
  },

  async created() {
    this.chineseForm = new AddressFormatter('ZH_CN');
    this.englishForm = new AddressFormatter('EN');
    this.chineseJson = await this.chineseForm.getCountries();
    this.englishJson = await this.englishForm.getCountries();
  },

  methods: {
    getPlaceholder(countryCode, fieldName) {
      const key = `${this.placeholder}.business.${countryCode}.${fieldName}`;

      if (this.$te(key)) {
        return this.$t(key);
      }

      return this.$t(`${this.placeholder}.business.default.${fieldName}`);
    },
  },
};
</script>
