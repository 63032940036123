<template>
  <v-card
    elevation="0"
    class="ma-0 pa-0"
    :width="width"
    :height="height"
    style="display: inline-grid;background-color: transparent"
  >
    <template v-if="!isLoaded">
      <v-skeleton-loader
        v-if="!hasError"
        :width="width"
        :height="height"
        type="image"
        class="d-flex justify-center align-center"
      />
      <v-tooltip v-else right nudge-bottom="50px">
        <template v-slot:activator="{ on, attrs }">
          <v-sheet
            color="#F4F4F4"
            :max-width="width"
            :height="height"
            v-bind="attrs"
            class="d-flex justify-center align-center "
            v-on="on"
          >
            <span style="color:#8D8D8D">?</span>
          </v-sheet>
        </template>
        <span>{{ $t('common.failedToDownloadThumbnail') }}</span>
      </v-tooltip>
    </template>
    <v-fade-transition>
      <v-img
        aspect-ratio="1"
        contain
        :src="thumbnailUri"
        :max-height="height"
        :max-width="width"
        alt="thumbnail"
        @load="isLoaded = true"
        @error="hasError = true"
      />
    </v-fade-transition>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  /* TODO It would be nice to use Vuetify's image component (v-img), but unfortunately
    *   there seems to be no simple way to display a skeleton loader until
    *   a v-img loads using v-show. So we're stuck with a regular image tag */
  name: 'ThumbnailViewer',
  props: {
    imageId: { type: String },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },

  },
  data: () => ({
    isLoaded: false,
    hasError: false,
  }),
  computed: {
    ...mapState('auth', ['accessToken']),

    thumbnailUri: vm => {
      if (!vm.imageId) {
        return '/-';
      }
      const host = process.env.VUE_APP_API_URL;
      const fileName = `${vm.imageId}.PNG`;
      return `${host}hardware/${fileName}/${vm.imageId}`;
    }
  },
};
</script>

<style scoped>
/* Without this, there is a random extra ~6 pixels below the image.
After scrutinizing over pixels for a while, I found this: https://stackoverflow.com/a/5804278/9214972 */
img {
  vertical-align: middle;
}
</style>
