<template>
  <div class="model-viewer" @click="$emit('model:clicked')">
    <div v-if="isMeshUploaded">
      <v-btn-toggle
        v-if="isInteractive"
        v-model="isUnfolded"
        style="position:absolute; top: 63px; left: 50%; transform: translateX(-50%);"
        mandatory
        dark
        borderless
        active-class="active"
      >
        <v-btn
          depressed
          style="background-color: #191919"
          :value="false"
          :width="['md','sm'].includes($vuetify.breakpoint.name) ? 80 : 180"
          :ripple="false"
          active-class="active"
        >
          <span class="mdi mdi-rotate-3d-variant pr-2" style="font-size: 25px" />
          <span class="hidden-md-and-down pr-2">
            {{ $t('common.3dView') }}
          </span>
        </v-btn>

        <v-btn
          v-if="showFlat"
          depressed
          style="background-color: #191919"
          :value="true"
          :width="['md','sm'].includes($vuetify.breakpoint.name) ? 80 : 180"
          :ripple="false"
          active-class="active"
        >
          <span class="mdi mdi-gamepad pr-2" style="font-size: 25px" />
          <span class="hidden-md-and-down pr-2">
            {{ $t('common.flat') }}
          </span>
        </v-btn>
      </v-btn-toggle>

      <v-progress-circular
        v-if="!isMeshLoaded && part.uploadProgress <= 100"
        :rotate="360"
        :size="width"
        :width="8"
        :value="part.uploadProgress"
        :indeterminate="!!(!isMeshLoaded && part.uploadProgress && part.uploadProgress === 100)"
        color="primary"
      >
        <span v-if="!isMeshUploaded && part.uploadProgress && part.uploadProgress === 100">
          {{ $t('common.analyzing') }}
        </span>
        <span v-else-if="isMeshUploaded && !isMeshLoaded">
          {{ $t('common.loading') }}
        </span>
        <span v-else>
          {{ Math.round(part.uploadProgress) }}
        </span>
      </v-progress-circular>

      <!-- Setting the height/width of this component does NOT change the height/width
       of the avatar, it changes the container -->
      <v-skeleton-loader
        v-else-if="!isMeshLoaded && !part.uploadProgress"
        :id="`model-viewer-placeholder-${viewerId}`"
        :class="{ 'skeleton-container' :!isMeshLoaded }"
        type="avatar"
        :width="width"
        :height="height"
      />
      <!-- v-show because we need the DOM element to hook onto
       https://vuejs.org/v2/guide/conditional.html#v-if-vs-v-show -->
      <v-slide-x-transition>
        <div
          v-show="isMeshLoaded"
          :id="`model-viewer-${viewerId}`"
          ref="modelViewer"
          v-bind="$attrs"
          v-on="$listeners"
        />
      </v-slide-x-transition>
    </div>

    <div v-else-if="isSvgUploaded">
      <template v-if="!isSvgLoaded">
        <v-skeleton-loader
          :width="width"
          :height="height"
          type="image"
          class="my-2"
        />
      </template>
      <v-fade-transition>
        <img
          ref="modelViewer"
          class="my-2"
          v-bind="$attrs"
          :src="meshUrl"
          alt="thumbnail"
          :style="{'max-width': width + 'px', 'max-height': height + 'px', 'min-width': width + 'px'}"
          @load="isSvgLoaded = true;"
          v-on="$listeners"
        >
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';

export default {
  name: 'ModelViewer',
  props: {
    part: {
      type: Object,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    viewport: null,
    isInteractive: Boolean,
    // isUnfolded: Boolean,
    isMeshUploaded: { type: Boolean, default: true },
    isSvgUploaded: { type: Boolean, default: false }
  },
  data() {
    return {
      cameraTarget: null,
      scene: null,
      mesh: null,
      controls: null,
      isMeshLoaded: false,
      viewerId: uuidv4(),
      isUnfolded: false,
      isSvgLoaded: false,
    };
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    viewport3d: vm => vm.viewport || vm.$refs.modelViewer,
    meshUrl: vm => {
      const host = process.env.VUE_APP_API_URL;
      let fileName = `${vm.part.DrawingRowPointer.toLowerCase()}-${vm.isUnfolded ? 'unfolded' : 'folded'}.STL`;
      if (vm.isSvgUploaded) {
        fileName = `${vm.part.DrawingRowPointer.toLowerCase()}.SVG`;
      }
      return `${host}uploads/${fileName}/${vm.accessToken}/${vm.part.Item}`;
    },
    showFlat: vm => {
      const machine = _.get(vm.part, 'Machine', undefined) || _.get(vm.part, 'AnalysisData.machine', undefined);
      return machine !== 'turning';
    },
  },
  watch: {
    async isMeshUploaded(val) {
      if (val) {
        await this.init();
        this.onResize();
      }
    },
    async isUnfolded() {
      this.cleanUpThreeJs();
      await this.init();
      this.onResize();
    },
    width() {
      this.onResize();
    },
    height() {
      this.onResize();
    },
  },

  async mounted() {
    if (this.isMeshUploaded) {
      await this.init();
      this.onResize();
    }
  },
  beforeDestroy() {
    if (this.isSvgUploaded) {
      return;
    }
    this.cleanUpThreeJs();
  },
  methods: {
    async init() {
      if (this.isSvgUploaded) {
        return;
      }
      this.scene = new THREE.Scene();

      const VIEW_ANGLE = 35;
      const ASPECT = this.width / this.height;
      const NEAR = 0.1;
      const FAR = 20000;

      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
      this.renderer.setClearColor(0x000000, 0); // the default
      this.renderer.setPixelRatio(window.devicePixelRatio * 1.5);
      this.renderer.setSize(this.width, this.height);

      this.viewport3d.appendChild(this.renderer.domElement);

      this.camera = new THREE.PerspectiveCamera(
        VIEW_ANGLE, // Field of view
        ASPECT, // Aspect ratio
        NEAR, // Near plane
        FAR, // Far plane
      );

      this.camera.position.set(
        -37.565773010253906,
        -25.910253524780273,
        -97.5260238647461,
      );

      this.scene.add(this.camera);

      this.createLights();

      if (this.isInteractive) {
        this.initControls();
      }

      this.loader = new STLLoader();

      const baseMaterial = new THREE.MeshPhongMaterial({ color: 0x777777 });

      // eslint-disable-next-line no-unused-vars
      const host = process.env.VUE_APP_API_URL;

      await this.loader.load(
        // `${host}uploads/${this.drawingRowPointer.toLowerCase()}.STL`,
        this.meshUrl,
        geometry => {
          this.mesh = new THREE.Mesh(geometry, baseMaterial);
          this.mesh.scale.set(0.5, 0.5, 0.5);
          this.mesh.position.set(0, 0, 0);
          this.mesh.rotation.set(0, 0, 0);

          const box = new THREE.Box3().setFromObject(this.mesh);
          const center = box.getCenter(new THREE.Vector3());

          this.mesh.position.x = -center.x;
          this.mesh.position.y = -center.y;
          this.mesh.position.z = -center.z;

          this.mesh.traverse(child => {
            if (child instanceof THREE.Mesh) {
              child.material = baseMaterial;
              child.material.side = THREE.DoubleSide;

              if (child.geometry !== undefined) {
                const edges = new THREE.EdgesGeometry(child.geometry);
                const line = new THREE.LineSegments(
                  edges,
                  new THREE.LineBasicMaterial({ color: 0x000000 }),
                );

                line.position.x = -center.x;
                line.position.y = -center.y;
                line.position.z = -center.z;

                line.scale.set(0.5, 0.5, 0.5);
                this.scene.add(line);
              }
            }
          });

          this.scene.add(this.mesh);
          this.$nextTick(() => {
            this.isMeshLoaded = true;
          });

          this.$emit('load:mesh');

          this.fitCameraToObject(this.camera, this.mesh, 2.8, null);
          this.animate();
        },
        null,
        error => {
          console.error(error);
        },
      );
    },
    initControls() {
      this.controls = new TrackballControls(this.camera, this.renderer.domElement);
      this.controls.rotateSpeed = 10;
      this.controls.zoomSpeed = 10;
      this.controls.noZoom = false;
      this.controls.noPan = true;
      this.controls.staticMoving = true;
      this.controls.dynamicDampingFactor = 0.3;
      /* Default control keys (I think they're arrow keys) */
      this.controls.keys = [65, 83, 68];
    },
    onResize() {
      if (this.isSvgUploaded) {
        return;
      }
      if (!this.renderer) {
        return;
      }
      this.camera.aspect = this.width / this.height;

      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.width, this.height);
      if (this.isInteractive) {
        this.controls.handleResize();
      }
    },
    fitCameraToObject(camera, object, offset, speed) {
      offset = offset || 1.25;

      const boundingBox = new THREE.Box3();

      // get bounding box of object - this will be used to setup controls and camera
      boundingBox.setFromObject(object);

      const center = boundingBox.getCenter(new THREE.Vector3());

      const size = boundingBox.getSize(new THREE.Vector3());

      // get the max side of the bounding box (fits to width OR height as needed )
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = camera.fov * (Math.PI / 180);
      // eslint-disable-next-line no-mixed-operators
      let distanceToMesh = Math.abs(maxDim / 4 * Math.tan(fov * 2));

      distanceToMesh *= offset; // zoom out a little so that objects don't fill the screen

      if (speed) {
        camera.position.x = Math.cos(speed) * distanceToMesh;
        camera.position.z = Math.sin(speed) * distanceToMesh;
      } else {
        camera.position.z = distanceToMesh;
      }

      const minZ = boundingBox.min.z;
      const cameraToFarEdge = (minZ < 0) ? -minZ + distanceToMesh : distanceToMesh - minZ;

      camera.far = cameraToFarEdge * 3;
      camera.updateProjectionMatrix();

      camera.lookAt(center);
    },
    cleanUpThreeJs() {
      // Removing all children...
      if (this.scene) {
        for (let i = this.scene.children.length - 1; i >= 0; i--) {
          this.scene.remove(this.scene.children[i]);
        }
      }

      const baseNode = this.viewport3d;
      if (baseNode) {
        while (baseNode.firstChild) {
          baseNode.removeChild(baseNode.firstChild);
        }
      }

      if (this.renderer) {
        this.renderer.dispose();
        // Prevents deleting the oldest context after 16 contexts
        this.renderer.forceContextLoss();
      }
      this.scene = null;
      this.camera = null;
      this.renderer = null;
      this.controls = null;
    },
    addShadowedLight(x, y, z, color, intensity) {
      const directionalLight = new THREE.DirectionalLight(color, intensity);
      directionalLight.position.set(x, y, z);
      directionalLight.lookAt(0, 0, 0);
      this.camera.add(directionalLight);

      directionalLight.castShadow = true;

      const d = 1;
      directionalLight.shadow.camera.left = -d;
      directionalLight.shadow.camera.right = d;
      directionalLight.shadow.camera.top = d;
      directionalLight.shadow.camera.bottom = -d;

      directionalLight.shadow.camera.near = 1;
      directionalLight.shadow.camera.far = 4;

      directionalLight.shadow.mapSize.width = 64;
      directionalLight.shadow.mapSize.height = 64;

      directionalLight.shadow.bias = 1;
    },
    createLights() {
      if (this.camera) {
        // Lights
        this.camera.add(new THREE.HemisphereLight(0x443333, 0x111122));
        this.addShadowedLight(1, 1, 1, 0xffffff, 1.35);
      }
    },
    animate() {
      // Seems to be a timing issue? requestAnimationFrame is called before animate is 'ready'??
      if (this.scene && this.animate) {
        requestAnimationFrame(this.animate);

        if (this.isInteractive) {
          this.controls.update();
        } else {
          // using timer as animation
          const speed = Date.now() * 0.00025;
          this.camera.position.x = Math.cos(speed) * 80;
          this.camera.position.z = Math.sin(speed) * 80;

          this.fitCameraToObject(this.camera, this.mesh, 2.8, speed);
        }
        this.renderer.render(this.scene, this.camera);
      }
    },
  }
};
</script>

<style scoped>
  .skeleton-container {
    display: flex; align-items: center; justify-content: center;
  }

  .active {
    opacity: 1 !important;
    color:#5FCE79 !important;
    background-color:#191919 !important;
  }

  .active::before{
    border-bottom: 2px solid #5FCE79 !important;
    opacity: 1;
  }

  .v-btn:before {
    border-radius: 0;
    background-color: transparent;
  }

  .btn--active{
    opacity: 1 !important;
  }

  .v-btn-toggle > .v-btn.v-btn {
    opacity: 1;
  }

  .v-btn--active::before {
    color: #5FCE79;
    opacity: 1 !important;
  }

  .model-viewer:hover {
    cursor: pointer;
  }
</style>
