import Vue from 'vue';
import Router from 'vue-router';
import { isJwtExpired } from 'jwt-check-expiration';

/** Add/replace client's router here */
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import OnBoarding from '@/views/OnBoarding.vue';
import Confirmation from '@/views/Confirmation.vue';
import ForgottenPassword from '@/views/ForgottenPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import SignUp from '@/views/SignUp.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import SignUpSendEmail from '@/views/SignUpSendEmail.vue';
import ResetPasswordSendEmail from '@/views/ResetPasswordSendEmail.vue';
import ResetPasswordSuccess from '@/views/ResetPasswordSuccess.vue';
import { LEVEL } from '@/utils/log';
import Store from './store/index';

import ddlogger from './utils/logger';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Dashboard,
      name: 'Dashboard',
    },
    {
      path: '/login',
      component: Login,
      name: 'Login',
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp,
    },
    {
      path: '/logout',
      component: Logout,
      name: 'Logout',
    },
    {
      path: '/404',
      component: PageNotFound,
      name: 'PageNotFound',
    },
    {
      path: '/signup_send_email',
      component: SignUpSendEmail,
      name: 'SignUpSendEmail',
    },
    {
      path: '/resetpassword_send_email',
      component: ResetPasswordSendEmail,
      name: 'ResetPasswordSendEmail',
    },
    {
      path: '/resetpassword_success',
      component: ResetPasswordSuccess,
      name: 'ResetPasswordSuccess',
    },
    {
      path: '/on_boarding',
      component: OnBoarding,
      name: 'OnBoarding'
    },
    {
      path: '/confirmation',
      component: Confirmation,
      name: 'Confirmation',
    },
    {
      path: '/forgotten_password',
      component: ForgottenPassword,
      name: 'ForgottenPassword',
    },
    {
      path: '/reset_password',
      component: ResetPassword,
      name: 'ResetPassword',
    },
    {
      path: '/rfqs',
      name: 'RFQS',
      component: () => import(/* webpackChunkName: "RFQS" */ './views/RFQS.vue'),
      meta: { requiredPermission: 'rfqs' },
    },
    {
      path: '/rfqs/:id',
      name: 'EditRFQ',
      component: () => import(/* webpackChunkName: "EditRFQ" */ './views/EditRFQ.vue'),
      meta: { requiredPermission: 'instantQuote' },
    },
    {
      path: '/hardware_categories',
      name: 'HardwareCategories',
      component: () => import(/* webpackChunkName: "Hardware" */ './views/HardwareCategories.vue'),
    },
    {
      path: '/hardware_categories/:id',
      name: 'HardwareCategory',
      props: true,
      component: () => import(/* webpackChunkName: "HardwareCategory" */ './views/HardwareCategory.vue'),
    },
    {
      path: '/orders',
      name: 'Orders',
      component: () => import(/* webpackChunkName: "Orders" */ './views/Orders.vue'),
      meta: { requiredPermission: 'orders' },
    },
    {
      path: '/order/new',
      name: 'NewOrder',
      props: true,
      component: () => import(/* webpackChunkName: "NewOrder" */ './views/EditOrder.vue'),
      meta: { requiredPermission: 'placeOrder' },
    },
    // {
    //   path: '/order/new/:type/:id',
    //   name: 'NewOrderWithData',
    //   props: true,
    //   component: () => import(/* webpackChunkName: "NewOrderWithData" */ './views/EditOrder.vue'),
    //   meta: { requiredPermission: 'placeOrder' },
    // },
    {
      path: '/order/confirmed',
      name: 'confirmedOrder',
      props: true,
      component: () => import(/* webpackChunkName: "confirmedOrder" */ './views/ConfirmedOrder.vue'),
      meta: { requiredPermission: 'placeOrder' },
    },
    {
      path: '/my_parts',
      name: 'MyParts',
      component: () => import(/* webpackChunkName: "MyParts" */ './views/MyParts.vue'),
      meta: { requiredPermission: 'myParts' },
    },
    {
      path: '/support',
      name: 'Support',
      props: true,
      component: () => import(/* webpackChunkName: "Support" */ './views/Support.vue'),
      meta: { requiredPermission: 'supportUser' },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import(/* webpackChunkName: "CompanySettings" */ './views/Settings/Index.vue'),
      children: [
        {
          path: 'profile',
          name: 'Profile',
          component: () => import(/* webpackChunkName: "UsersPreferences" */ './views/Preferences/UserPreferences.vue'),
          meta: { requiredPermission: 'userManagement' },
        },
        {
          path: 'company',
          name: 'CompanySettings',
          component: () => import(/* webpackChunkName: "CompanySettings" */ './views/Settings/CompanySettings.vue'),
          meta: { requiredPermission: 'companyManagement' },
        },
        {
          path: 'forwarders',
          name: 'ForwardersSettings',
          component: () => import(/* webpackChunkName: "ForwardersSettings" */ './views/Settings/Forwarders.vue'),
          meta: { requiredPermission: 'forwarderManagement' },
        },
        {
          path: 'user-management',
          name: 'UsersManagement',
          component: () => import(/* webpackChunkName: "UsersPreferences" */ './views/Preferences/UserManager.vue'),
          meta: { requiredPermission: 'userManagement' },
        },
        {
          path: 'account',
          name: 'AccountPreferences',
          component: () => import(/* webpackChunkName: "AccountPreferences" */ './views/Preferences/AccountStatementTab.vue'),
        },
        {
          path: 'billing',
          name: 'BillingPreferences',
          component: () => import(/* webpackChunkName: "BillingPreferences" */ './views/Preferences/BillingTab.vue'),
          meta: { requiredPermission: 'companyManagement' },
        },
        {
          path: 'statements',
          name: 'StatementPreferences',
          component: () => import(/* webpackChunkName: "StatementPreferences" */ './views/Preferences/AccountStatementTab.vue'),
          meta: { requiredPermission: 'accountStatement' },
        },
      ],
    },
    {
      path: '/preferences',
      name: 'Preferences',
      component: () => import(/* webpackChunkName: "UsersPreferences" */ './views/Preferences/Index.vue'),
      children: [
        {
          path: 'users',
          name: 'UsersPreferences',
          component: () => import(/* webpackChunkName: "UsersPreferences" */ './views/Preferences/UserManager.vue'),
          meta: { requiredPermission: 'userManagement' },
        },
        {
          path: 'account',
          name: 'AccountPreferences',
          component: () => import(/* webpackChunkName: "AccountPreferences" */ './views/Preferences/UserPreferences.vue'),
        },
        {
          path: 'billing',
          name: 'BillingPreferences',
          component: () => import(/* webpackChunkName: "BillingPreferences" */ './views/Preferences/BillingTab.vue'),
          meta: { requiredPermission: 'companyManagement' },
        },
        {
          path: 'statements',
          name: 'StatementPreferences',
          component: () => import(/* webpackChunkName: "StatementPreferences" */ './views/Preferences/AccountStatementTab.vue'),
          meta: { requiredPermission: 'accountStatement' },
        },
      ],
    },
    {
      path: '/tools',
      name: 'EngineeringTools',
      component: () => import(/* webpackChunkName: "EngineeringTools" */ './views/Tools/Index.vue'),
      children: [
        {
          path: '/tools/materials',
          name: 'MaterialsTools',
          component: () => import(/* webpackChunkName: "MaterialsTool" */ './views/Tools/MaterialProperties.vue'),
        },
        {
          path: '/tools/surface',
          name: 'SurfaceTools',
          component: () => import(/* webpackChunkName: "FinishingTool" */ './views/Tools/SurfaceFinish.vue'),
        },
        {
          path: '/tools/process',
          name: 'ToleranceTools',
          component: () => import(/* webpackChunkName: "ToleranceTool" */ './views/Tools/ProcessTolerances.vue'),
        },
        {
          path: '/tools/finish',
          name: 'FinishTools',
          component: () => import(/* webpackChunkName: "ToleranceTool" */ './views/Tools/FinishColor.vue'),
        },
        {
          path: '/tools/design',
          name: 'DesignTools',
          component: () => import(/* webpackChunkName: "ToleranceTool" */ './views/Tools/DesignForManufacturing.vue'),
        },
        {
          path: '/tools/guideline',
          name: 'GuideLine',
          component: () => import(/* webpackChunkName: "ToleranceTool" */ './views/Tools/GuideLine.vue'),
        },
      ],
    },
    {
      path: '/internal/3d-packaging-tool',
      component: () => import(/* webpackChunkName: "ThreeDPackagingTool" */ './views/ThreeDPackagingTool.vue'),
      name: 'ThreeDPackagingTool',
    },
  ],
});

// Must check string value
if (process.env.VUE_APP_DO_SHOW_SANDBOX === 'true') {
  router.addRoute({
    path: '/sandbox',
    name: 'Sandbox',
    component: () => import(/* webpackChunkName: "Sandbox" */ './views/Sandbox.vue'),
  });
}

// Global session route dispatching
router.beforeEach(async (to, from, next) => {
  const jwtToken = localStorage.getItem('feathers-jwt');
  if (jwtToken) {
    if (isJwtExpired(jwtToken)) {
      localStorage.removeItem('feathers-jwt');
      router.push('/login');
    }
  }

  // validate URL
  const link = router.resolve(to.path);
  if (link.resolved.matched.length === 0) {
    next('/404');
    return;
  }

  // Authenticate with the local email/password strategy
  const isOnLoginPage = ['SignUp', 'Login', 'Confirmation', 'OnBoarding', 'ForgottenPassword', 'ResetPassword', 'SignUpSendEmail', 'ResetPasswordSendEmail', 'ResetPasswordSuccess'].includes(to.name);
  try {
    /* TODO Implement JWT expiry validation */
    const auth = await router.app.$store.dispatch('auth/authenticate');
    let user;
    if (_.get(auth, 'user._id', false)) {
      user = router.app.$store.getters['users/get'](auth.user._id);
      if (!user.hasCsiData) {
        user = await router.app.$store.dispatch('users/get', [auth.user._id]);
        // No need to fetch company as for support user session token not set to CSI.
        if (to.name !== 'Support') {
          await router.app.$store.dispatch('company/find');
        }
      }
    }

    if (to.name === 'ThreeDPackagingTool') {
      const companies = router.app.$store.getters['company/find']();
      if (!process.env.VUE_APP_API_URL.includes('b2b-api') && companies.total && companies.data[0].AllowBetaTesting === '1') {
        next();
      } else {
        router.push('/404');
      }
    }
    ddlogger.logger.debug('navigation', {
      route: to.name,
      author: _.get(auth, 'user._id', 'anonymous')
    });

    if (to.meta.requiredPermission && !_.get(user, 'isAdmin', false)) {
      const required = Array.isArray(to.meta.requiredPermission) ? to.meta.requiredPermission : [to.meta.requiredPermission];
      const rights = _.get(user, 'rights', {});
      if (required.length > 0) {
        if (!required.some(req => rights[req]) && to.name !== 'Dashboard') {
          Store.dispatch('app/pushSnack', {
            snack: { message: 'rightError' },
            level: LEVEL.ERROR,
          });
          ddlogger.logger.error('unauthorized', {
            route: to.name,
            author: _.get(auth, 'user._id', 'anonymous')
          });
          router.push('/');
        }
      }
    }
    next();
  } catch (e) {
    if (!isOnLoginPage) {
      router.push('/login');
    } else {
      next();
    }
  }
  const nbrCountries = Store.getters['countries/list'].length;
  if (!nbrCountries) {
    await Store.dispatch('countries/find');
  }
  next();
});

export default router;
