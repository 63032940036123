export default [
  '*',

  'user.create',
  'user.read',
  'user.update',
  'user.delete',

  'role.create',
  'role.read',
  'role.update',
  'role.delete',

  'address.create',
  'address.read',
  'address.update',
  'address.delete',

  'phone.create',
  'phone.read',
  'phone.update',
  'phone.delete',

  'email.create',
  'email.read',
  'email.update',
  'email.delete',

  'contact.create',
  'contact.read',
  'contact.update',
  'contact.delete',

  'rfq.create',
  'rfq.read',
  'rfq.update',
  'rfq.delete',
  'order.create',
  'order.read',
  'order.update',
  'order.delete',
  'newRfq.create',
  'newRfq.read',
  'newRfq.update',
  'newRfq.delete',
];
