// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'rfqs';

const Model = class Rfq extends BaseModel {
  static modelName = 'Rfq';

  static instanceDefaults() {
    return {
    };
  }

  static setupInstance(data) {
    if (data.ExpiryDate) {
      data.ExpiryDate = new Date(data.ExpiryDate);
    }
    if (data.CreateDate) {
      data.CreateDate = new Date(data.CreateDate);
    }
    return data;
  }

  get formattedCreateDate() {
    return moment(this.CreateDate).format('MMM D, YYYY');
  }

  get formattedExpiryDate() {
    return moment(this.ExpiryDate).format('MMM D, YYYY');
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
