<template>
  <v-expand-transition v-show="computedExpanded">
    <v-card-text
      id="scroll-target"
      style="max-height: 200px"
      class="overflow-y-auto"
    >
      <core-rfq-parts-table
        v-if="_.get(data[0], 'pricing')"
        :parts="data"
        :dense="true"
      />
      <core-order-items-table
        v-else
        :items="data"
        :dense="true"
      />
      <!--      <v-list-->
      <!--        v-scroll:#scroll-target="onScroll"-->
      <!--        dense-->
      <!--        align="center"-->
      <!--        justify="center"-->
      <!--      >-->
      <!--        <v-list-item-->
      <!--          v-for="(part, i) in data"-->
      <!--          :key="i"-->
      <!--          @click="()=>{}"-->
      <!--        >-->
      <!--          <v-list-item-content>-->
      <!--            {{ part.clientPartNo || part.part.clientPartNo }}-->
      <!--          </v-list-item-content>-->
      <!--          <v-list-item-content>-->
      <!--            {{ part.material || part.part.material }}-->
      <!--          </v-list-item-content>-->
      <!--        </v-list-item>-->
      <!--      </v-list>-->
    </v-card-text>
  </v-expand-transition>
</template>

<script>
export default {
  props: ['expanded', 'data', 'toggleaction'],
  computed: {
    computedExpanded: {
      get() {
        return this.toggleExpanded;
      },
      set() {
        this.toggleaction();
      }
    }
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
  },
};
</script>
