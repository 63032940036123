<template>
  <div class="d-flex flex-column align-center">
    <v-sheet
      v-intersect="{
        handler: onIntersect,
        options: {
          threshold: [1.0]
        }
      }"
      tag="div"
      elevation="10"
      :width="pageWidth"
      :height="pageHeight"
      class="pdfCanvas"
    >
      <canvas
        v-show="isLoaded"
        id="pdfCanvas"
        ref="pdfCanvas"
        style="direction: ltr;"
        data-cy="pdf-canvas"
        :width="pageWidth"
        :height="pageHeight"
      />

      <v-skeleton-loader
        v-if="!isLoaded"
        :height="pageHeight"
        type="article,
                list-item-three-line,
                list-item-avatar-two-line,
                list-item-three-line,
                list-item-avatar-three-line"
      />
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true
    },
    itemHeight: {
      type: Number,
      required: true
    },
    containerRef: {
      type: Object
    },
  },

  data: () => ({
    height: 0,
    width: 0,
    isLoaded: false,
  }),

  computed: {
    pageHeight: vm => (vm.height ? vm.height : vm.itemHeight - vm.margin),
    pageWidth: vm => (vm.width ? vm.width : (vm.itemHeight - vm.margin) * (vm.baseRatio)),
    margin: () => 40,
    baseRatio: () => (8 / 11),
  },

  watch: {
    pageHeight() {
      this.getPage();
    },
  },

  created() {
    this.getPage();
  },

  methods: {

    onIntersect() {
      this.$emit('enterViewport', this.page.pageNumber);
    },

    async getPage() {
      try {
        if (!this.page.pdf) {
          return;
        }

        const page = await this.page.pdf.getPage(this.page.pageNumber);
        let viewport = page.getViewport({ scale: 1 });
        const scale = (this.itemHeight - this.margin) / viewport.height;
        viewport = page.getViewport({ scale }); // Prepare canvas using PDF page dimensions
        const canvas = this.$refs.pdfCanvas;
        const context = canvas.getContext('2d');
        this.height = viewport.height;
        this.width = viewport.width;
        // Render PDF page into canvas context
        const renderContext = { canvasContext: context, viewport };
        const renderTask = page.render(renderContext);
        renderTask.promise.then(() => {
          this.isLoaded = true;
        });
      } catch (e) {
        console.error(e);
      }
    }
  },
};
</script>

<style>
.pdfCanvas {
  transition: all 0.5s ease-in-out;
}
</style>
