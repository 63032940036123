// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'temp-parts';

const Model = class TempPart extends BaseModel {
  static modelName = 'TempPart';

  static instanceDefaults() {
    return {
      tempRfqId: null,
      csiRfqId: '',
      csiLineNumber: '',
      isCsi: false,
      uniqueFileName: '',
      CustItemName: '',
      CustItemDesc: '...',
      CustFileName: '',
      RevisionNumber: '0',
      Material: '',
      MaterialSeries: '',
      MaterialThickness: '',
      MaterialFinish: '',
      MaterialFinishOption: '',
      MaterialStr: '',
      MaterialSeriesStr: '',
      MaterialFinishStr: '',
      MaterialFinishOptionStr: '',
      MaterialThicknessStr: '',
      RawMaterial: '',
      DrawingRowPointer: '',
      DrawingNumber: '',
      Item: '',
      CsiAnalysisData: [],
      AnalysisData: {},
      SecondaryAnalysisData: {},
      ReqQty: 1,
      Status: 'Planned',
      shouldStartPreliminaryAnalysis: false,
      shouldStartFinalAnalysis: false,
      shouldCalculateCostWithoutFinalAnalysis: false,
      shouldUploadFilesToCsi: false,
      PriceMOQ: null,
      Tolerance: '',
      Roughness: '',
      EstimateLineStatus: {
        isDrawingUploaded: false,
        isDrawingUploadedCsi: false,
        isDrawingUploadedMsg: '',
        isManufacturable: false,
        isManufacturableMsg: '',
        isPreliminaryAnalysisInProgress: false,
        isPreliminaryAnalysisCompleted: false,
        isPreliminaryAnalysisCompletedMsg: false,
        isMainAnalysisInProgress: false,
        isMainAnalysisCompleted: false,
        isMainAnalysisCompletedMsg: false,
        isThumbnailUploaded: false,
        isThumbnailUploadedMsg: '',
        isObjUploaded: false,
        isObjUploadedCsi: false,
        isObjUploadedMsg: '',
        isDxfUploaded: false,
        isDxfUploadedCsi: false,
        isDxfUploadedMsg: '',
        isMeshUploaded: false,
        isMeshUploadedCsi: false,
        isMeshUploadedMsg: '',
        isThicknessCalculated: false,
        isThicknessCalculatedMsg: '',
        CsiErrorParam: ''
      },
      machine: 'sheet-metal',
      executionTime: 90,
      Threads: '',
      Countersinks: '',
      Counterbores: '',
    };
  }

  get materialSeriesShortened() {
    return this.MaterialSeriesStr.replace(this.MaterialStr, '').trim();
  }

  get isSheetMetalPart() {
    const machine = _.get(this, 'AnalysisData.machine', '');
    return machine === 'sheet-metal';
  }

  get isTurningPart() {
    const machine = _.get(this, 'AnalysisData.machine', '');
    return machine === 'turning';
  }

  get is2dModel() {
    if (this.CustFileName) {
      return this.CustFileName.split('.').pop().replaceAll(' ', '').toUpperCase() === 'DXF';
    }
    return false;
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath).hooks({
      error: {
        all: [
          context => {
            context.params.shouldShowNotification = !context.error.message.startsWith('CSIError.');
            return context;
          }
        ],
      }
    }),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
