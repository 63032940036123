<template>
  <v-container class="pa-2" fluid>
    <v-row>
      <v-col cols="3" class="text-center px-2 py-2" style="min-width: 175px; max-width: 175px;">
        <core-model-viewer
          :part="part"
          :width="part.EstimateLineStatus.isMeshUploaded ? 100: 160"
          :height="part.EstimateLineStatus.isMeshUploaded ? 100: 160"
          :is-mesh-uploaded="part.EstimateLineStatus.isMeshUploaded"
          :is-svg-uploaded="part.EstimateLineStatus.isSvgUploaded"
          :class="{ 'blip-3d': isMeshVisible }"
          @click.stop="onThumbClick"
          @load:mesh="uploadThumbnail"
        />
        <v-btn
          text
          x-small
          color="primary"
          @click.stop="onThumbClick"
        >
          {{ $t('common.viewDetails') }}
          <v-icon x-small right>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </v-col>
      <v-col class="px-1 ma-0">
        <v-row class="text-h5 pa-1 ma-0">
          {{ part.CustItemName }} {{ $t('views.rfqs.revision') }}. {{ part.RevisionNumber }}
        </v-row>
        <v-row class="px-1 ma-0">
          {{ part.CustItemDesc }}
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col class="px-1 ma-0">
            <span class="caption" style="display:block;">
              {{ $t('views.rfqs.material') }}
            </span>
            {{ locale === 'en-US' ? partMaterial.Description : partMaterial.DescriptionCHS }}
          </v-col>
          <v-col v-if="part.AnalysisData.machine === 'sheet-metal'" class="px-3 ma-0">
            <span class="caption" style="display:block;">
              {{ $t('views.rfqs.thickness') }}
            </span>
            {{ locale === 'en-US' ? getThickness.Description : getThickness.DescriptionCHS }}
          </v-col>
          <v-col v-else class="px-3 ma-0">
            <span class="caption" style="display:block;">
              {{ $t('views.rfqs.outerDiameter') }}
            </span>
            {{ getOuterDiameter }}
          </v-col>
          <v-col class="px-3 ma-0">
            <span class="caption" style="display:block;">
              {{ $t('views.rfqs.series') }}
            </span>
            {{
              locale === 'en-US' ? getSeries.Description.replace(partMaterial.Description, '').trim() : getSeries.DescriptionCHS
            }}
          </v-col>
          <v-col v-if="part.AnalysisData.machine === 'turning'" class="px-3 ma-0">
            <span class="caption" style="display:block;">
              {{ $t('views.instantQuote.roughness') }}
            </span>
            {{ getRoughness }}
          </v-col>
          <v-col class="px-3 ma-0">
            <span class="caption" style="display:block;">
              {{ $t('views.rfqs.finish') }}
            </span>
            {{ locale === 'en-US' ? getFinish.Description : getFinish.DescriptionCHS }}
          </v-col>
          <v-col class="px-3 ma-0">
            <span class="caption" style="display:block;">
              {{ $t('views.instantQuote.tolerances') }}
            </span>
            {{
              toleranceTranslation(part)
            }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="py-2 px-1" style="min-width: 190px; display:flex; align-items:center;">
        <core-price-moq-table :part="part" class="mr-5" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { models } from 'feathers-vuex';

const { Material, Finish } = models.api;

export default {
  name: 'CompactPartCard',
  props: {
    part: Object,
    customerPriceRange: Array,
    uploadThumbnail: Function,
    isMeshVisible: Boolean,
    onThumbClick: Function,
  },
  computed: {
    ...mapState('app', ['locale']),
    ...mapGetters('materials', {
      findMaterials: 'find',
    }),

    toleranceTranslation: vm => item => {
      if (!item.Tolerance) {
        return '-';
      }
      return vm.$t(`views.instantQuote.tolerance${item.Tolerance.replace('Precision', '').trim()}`);
    },

    /* TODO revisit this. Why are we not simply using the part
    *   properties? Need to investigate later if we can simply
    * do that */
    partMaterial() {
      const material = _.get(Material.findInStore({
        query: {
          RowPointer: this.part.Material,
          IsHidden: false,
          $sort: { Rank: 1 },
        },
      }), 'data[0]');
      return material || 'N/A';
    },

    getThickness() {
      const thickness = _.get(this.findMaterials({
        query: {
          _id: this.part.MaterialThickness,
          IsHidden: false,
          $sort: { Rank: 1 },
        },
      }), 'data[0]');
      return thickness || 'N/A';
    },

    getOuterDiameter() {
      if (this.part.AnalysisData.major_dia) {
        const diameter = parseFloat(this.part.AnalysisData.major_dia).toFixed(2);
        return `${diameter} ${this.$t('views.rfqs.mm')}`;
      }
      return 'N/A';
    },

    getRoughness() {
      switch (this.part.Roughness) {
        case '6.0':
          return this.$t('views.instantQuote.roughnessMilled');
        case '3.2':
          return this.$t('views.instantQuote.roughnessMilled');
        case '1.6':
          return this.$t('views.instantQuote.roughnessFine');
        case '0.8':
          return this.$t('views.instantQuote.roughnessGrade');
        default:
          return 'N/A';
      }
    },

    getSeries() {
      const series = _.get(Material.findInStore({
        query: {
          RowPointer: this.part.MaterialSeries,
        },
      }), 'data[0]');
      return series || 'N/A';
    },

    getFinish() {
      const finish = _.get(Finish.findInStore({
        query: {
          RowPointer: this.part.MaterialFinish,
          IsHidden: false,
          $sort: { Rank: 1 },
        },
      }), 'data[0]');
      return finish || 'N/A';
    },
  },
};
</script>
