<template>
  <core-home-hero>
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <v-card
        class="logout-card"
        color="transparent"
        flat
      >
        <v-card-title align="center">
          <span class="title">
            {{ $t("views.logout.title") }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-progress-circular
            v-if="working"
            class="mr-2"
            color="primary"
            indeterminate
          />

          {{ working ? $t("views.logout.loggingOut") : $t("views.logout.loggedOut") }}
        </v-card-text>
      </v-card>
    </v-row>
  </core-home-hero>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',

  data() {
    return {
      working: false,
    };
  },

  mounted() {
    setTimeout(async () => {
      this.working = true;
      await this.logout();
      // window.$chatwoot.reset();
      this.working = false;

      localStorage.removeItem('feathers-jwt');
      this.$router.go('/');
    }, 1000);
  },

  methods: {
    ...mapActions('auth', ['logout'])
  }
};
</script>

<style scoped>
.logout-card {
  z-index: 5;
}
</style>
