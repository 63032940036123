import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('roles', {
      rolesLoading: 'isFindPending',
    }),

    ...mapGetters('roles', {
      roles: 'list',
      getRole: 'get',
      findRoles: 'find',
    }),
  },

  methods: {
    ...mapActions('roles', {
      fetchRoles: 'find',
      removeRole: 'remove'
    }),

    getRoleName(id) {
      return _.get(this.getRole(id), 'name', '?');
    },

    getRoleInactive(id) {
      return _.get(this.getRole(id), 'inactive', false);
    },
  }
};
