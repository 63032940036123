<template>
  <v-app id="vue-app">
    <core-snack />

    <core-dialog
      v-model="updateExists"
      width="500"
      persistent
      type="acknowledgement"
      :ok-button-text="$t('common.updateToNewVersion')"
      :cancel-button-text="$t('common.notNow')"
      @click:ok="refreshApp"
      @click:cancel="updateExists = false"
    >
      <template #title>
        {{ $t('common.newVersion') }}
      </template>

      <template #content>
        {{ $t('common.releaseNote') }}
      </template>
    </core-dialog>

    <core-payment-dialog />

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="shouldSkipMainRender"
        class="fill-height"
      >
        <router-view />
      </div>

      <div
        v-else
        class="fill-height"
      >
        <v-navigation-drawer
          v-if="showNavigationBar"
          id="navigation-drawer"
          class="text-center elevation-10"
          width="220px"
          app
          permanent
          clipped-left
        >
          <template
            #prepend
          >
            <img
              src="/img/BETA@1x.svg"
              width="45px"
              height="45px"
              class="logo"
              style="z-index: 9999; position: absolute !important; top:-5px; right: 180px"
              @click="$router.push('/')"
            >

            <img
              src="/img/Komacut-logo.svg"
              width="175px"
              height="130px"
              style="margin-top: -27px;"
              class="logo toHover"
              @click="$router.push('/')"
            >
            <v-btn
              id="instantQuote"
              width="190"
              class="ml-1 justify-center"
              color="primary"
              x-large
              data-cy="instant-quote-link"
              :disabled="!userCanQuote"
              @click="onActionButtonClicked"
            >
              {{ $t('menus.instantQuote') }}
            </v-btn>

            <v-list
              v-if="isFindHardwareCategoriesPending || hardwareCategories.length===0"
              width="250px"
              dense
            >
              <v-list-group
                class="menu-icons"
                :class="{'disable-icons': isFindHardwareCategoriesPending || hardwareCategories.length===0}"
                @click="isHardwareShopMenuExpanded = !isHardwareShopMenuExpanded"
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-left"
                      style="font-size: 16px "
                    >
                      <v-skeleton-loader
                        type="image"
                        width="190"
                        height="10"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-for="n in 4">
                  <v-list-item
                    :key="n"
                    class="text-left"
                    style="height: auto; padding-left: 50px"
                  >
                    <v-list-item-content>
                      <v-skeleton-loader
                        type="image"
                        max-width="130"
                        height="10"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="`divider-${n}`" style="width: 170px" class="ml-10" />
                </template>
              </v-list-group>
              <v-divider v-show="!isHardwareShopMenuExpanded" class="mx-2" />
            </v-list>

            <v-list v-else width="250px" dense>
              <v-list-group
                class="menu-icons"
                :class="{'disable-icons': isFindHardwareCategoriesPending}"
                @click="isHardwareShopMenuExpanded = !isHardwareShopMenuExpanded"
              >
                <template #activator>
                  <!--                  <v-list-item-content-->
                  <!--                    v-if=" hardwareCategories.length===0"-->
                  <!--                  >-->
                  <!--                    <v-list-item-title-->
                  <!--                      class="text-left"-->
                  <!--                      style="font-size: 16px "-->
                  <!--                    >-->
                  <!--                      <v-skeleton-loader-->
                  <!--                        type="image"-->
                  <!--                        width="190"-->
                  <!--                        height="10"-->
                  <!--                      />-->
                  <!--                    </v-list-item-title>-->
                  <!--                  </v-list-item-content>-->

                  <v-list-item-content
                    v-if="hardwareCategories.length !==0"
                    @click="$router.push({
                      path: '/hardware_categories',
                      query: { timestamp: Date.now() } //Avoid NavigationDuplicated: Avoided redundant navigation error
                    })"
                  >
                    <v-list-item-title
                      class="text-uppercase green--text font-weight-bold justify-center d-flex"
                      data-cy="shop-hardware-button"
                      style="font-size: 16px "
                    >
                      {{ $t('menus.shopHardware') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-if="hardwareCategories.length===0">
                  <template v-for="n in 4">
                    <v-list-item
                      :key="n"
                      class="text-left"
                      style="height: auto; padding-left: 50px"
                    >
                      <v-list-item-content>
                        test
                        <v-skeleton-loader
                          type="image"
                          max-width="130"
                          height="10"
                        />
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="`divider-${n}`" style="width: 170px" class="ml-10" />
                  </template>
                </template>
                <template
                  v-for="category in hardwareCategories"
                >
                  <v-list-item
                    :key="category.id"
                    :data-cy="`${category.name.toLowerCase().trim().replaceAll(' ', '-')}-hardware-option`"
                    class="text-left"
                    :to="{ name: 'HardwareCategory', params: { id: category.id } }"
                    style="height: auto; padding-left: 50px"
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ category[locale === 'cn' ? 'nameCHS' : 'name'] }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="`divider-${category.id}`" style="width: 170px" class="ml-10" />
                </template>
              </v-list-group>
              <v-divider v-show="!isHardwareShopMenuExpanded" class="mx-2" />
            </v-list>
          </template>

          <!--┏━━━━━━━━━━━━━━━━━━━━━━»»ICONS SECTION««━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓-->

          <div style="min-height:500px; height: 100%; " class="d-flex align-center justify-center">
            <v-list style="height: 500px; width: 220px;" class="d-flex flex-column">
              <v-list-item
                v-if="currentOrderItemsCount"
                data-cy="go-to-the-order-form-left-nav-bar"
                to="/order/new"
              >
                <v-badge
                  data-cy="current_order-items-count-left-nav-bar"
                  left
                  overlap
                  color="error"
                  :content="currentOrderItemsCount"
                >
                  <v-list-item-title class="toHover flex">
                    <div class="d-flex align-center">
                      <v-icon
                        left
                        x-large
                      >
                        mdi-view-list-outline
                      </v-icon>
                      <span style="font-size: 15px">{{ $t('views.confirmedOrder.title') }}</span>
                    </div>
                  </v-list-item-title>
                </v-badge>
              </v-list-item>
              <v-list-item
                v-for="(link, i) in links"
                :key="i"

                :data-cy="link.label.toLowerCase() + '-link'"
                :disabled="!link.enabled"
                :to="link.to"
              >
                <v-list-item-title class="toHover">
                  <div class="d-flex align-center">
                    <v-img
                      id="selectedItem"
                      class="mb-2 mr-2"
                      :src="link.src"
                      max-width="40"
                      max-height="40"
                    />
                    <span style="font-size: 15px">{{ link.label }}</span>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>

          <template #append>
            <v-hover v-slot="{ hover }">
              <div>
                <div
                  :class="{ 'on-hover': hover }"
                  class="d-flex justify-space-between ml-4"
                  style="width: 150px;"
                  @click="emailDialogIsActive = true"
                >
                  <img class="d-flex" src="/img/iconHelp@1x.svg">
                  <div class="caption">
                    <p class="mb-0">
                      {{ $t('feedBack.helpUsImprove') }}
                    </p>
                    <span class="caption grey--text font-weight-bold">
                      {{ $t('feedBack.sendFeedbackMenu') }}
                    </span>
                  </div>
                </div>
                <p class="caption text-center mt-1 mr-2">
                  Komacut v.{{ version }}
                </p>
              </div>
            </v-hover>
          </template>
        </v-navigation-drawer>

        <v-main class="fill-height" style="padding-bottom: 90px">
          <keep-alive>
            <router-view />
          </keep-alive>
        </v-main>
      </div>
    </transition>
    <v-dialog
      v-model="emailDialogIsActive"
      width="700"
      persistent
      class="px-16"
    >
      <div class="d-flex justify-end elevation-5" style="background-color: white">
        <v-btn
          plain
          class="d-flex mt-3"
          text
          small
          @click="cleanFeedback"
        >
          <v-icon color="#7e7e7e">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-card
        elevation="0"
        style="padding-right: 95px;padding-left: 95px"
        class="pb-8 pt-0 mt-0 rounded-tl-0 rounded-tr-0"
      >
        <v-card-title class="text-h5 justify-center px-0 pt-0">
          <span class="text-center font-weight-bold">{{ $t('feedBack.title') }}</span>
        </v-card-title>

        <v-card-text class="px-0 text-center">
          {{ $t('feedBack.body') }}
        </v-card-text>

        <v-textarea
          v-model="userCommentFeedback"
          :label="$t('views.rfqs.emailDialogPlaceholder')"
          outlined
          :placeholder="$t('views.rfqs.emailDialogPlaceholder')"
          hide-details
        />
        <p v-if="isExeFile" class="caption red--text mt-3 text-center">
          {{ $t('feedBack.noExeFile') }} : {{ errorFileName }}
        </p>

        <p v-else-if="maxFileSize" class="caption red--text mt-3 text-center">
          {{ $t('feedBack.maxFileWarning') }}
        </p>

        <p v-else class="caption text--secondary mt-3 text-center">
          {{ $t('feedBack.maxFile') }}
        </p>
        <v-row style="width: 500px !important;">
          <v-col cols="4" class="mr-0">
            <v-btn
              outlined
              color="primary"
              class="mr-5"
              :disabled="feedbackUploadFile.length === 5 || userCommentFeedback === ''"
              @click="$refs.uploader.click();"
            >
              {{ $t('feedBack.uploadFile') }}
            </v-btn>
            <input
              id="uploader"
              ref="uploader"
              class="d-none"
              type="file"
              multiple="multiple"
              accept="*/*"
              @change="onFileChanged"
            >
            <span v-if="feedbackUploadFile.length !== 0" class="caption text--secondary">
              {{ $t('common.size') }} : {{ bytesToSize(totalSizeInMb, 2) }}
            </span>
          </v-col>

          <v-col cols="3" class="ml-0">
            <span v-if="feedbackUploadFile.length === 0" class="text--secondary caption justify-center">
              {{ $t('feedBack.noFileChosen') }}
            </span>
            <template v-for="(selectedFile,index) in feedbackUploadFile" v-else>
              <div :key="index" class="">
                <p class="d-flex justify-center mb-0 fileUpdateHover" style="width: 300px !important;">
                  <span
                    :title="selectedFile.filename"
                    style="width: 300px !important;"
                    class="text-truncate"
                  >{{ selectedFile.filename }}</span>
                  <v-icon
                    class="d-flex"
                    small
                    right
                    @click="removeFile(selectedFile.fileSize, index);"
                  >
                    mdi-close
                  </v-icon>
                </p>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-btn
          :disabled="isSendEmailPending || userCommentFeedback === ''"
          :loading="isSendEmailPending "
          class="mt-6"
          color="primary"
          block
          @click="sendFeedBackEmail"
        >
          {{ $t('feedBack.sendFeedback') }}
        </v-btn>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { models } from 'feathers-vuex';
import { datadogRum } from '@datadog/browser-rum';
import localeCn from 'dayjs/locale/zh-cn';
import localeEn from 'dayjs/locale/en';
import update from './mixins/swupdate';

export default {
  name: 'App',

  mixins: [
    update,
  ],

  data() {
    return {
      isHardwareShopMenuExpanded: false,
      totalSizeInMb: 0,
      isExeFile: false,
      maxFileSize: false,
      errorFileName: '',
      feedbackUploadFile: [],
      file: {},
      userCommentFeedback: '',
      menuItemState: {},
      isSendEmailPending: false,
      emailDialogIsActive: false,
      createUserFeedBack: null,
    };
  },

  computed: {
    ...mapState('app', [
      'image', 'sidebarBackgroundColor', 'title', 'logo', 'locale', 'version'
    ]),
    ...mapState('hardware-categories', {
      isFindHardwareCategoriesPending: 'isFindPending',
    }),
    ...mapState('hardware-categories', {
      isFindCategoriesPending: 'isFindPending',
    }),
    ...mapState('countries', { countriesStateIds: 'ids' }),
    ...mapState('regions', { regionsStateIds: 'ids' }),
    ...mapGetters('temp-rfqs', { getTempRfqIdInProgress: 'getTempRfqIdInProgress' }),
    ...mapGetters('auth', { authUser: 'user' }),
    ...mapState('auth', ['accessToken']),
    ...mapGetters('app', ['currentOrderItemsCount']),
    Country: () => models.api.Country,
    Regions: () => models.api.Regions,
    HardwareCategory: () => models.api.HardwareCategory,
    HardwareProduct: () => models.api.HardwareProduct,
    ...mapGetters('auth', { loggedInUser: 'user' }),

    hardwareCategories: vm => vm.HardwareCategory.findInStore().data,
    company: () => models.api.Company.getFromStore(0),

    userCanQuote: ({ loggedInUser }) => loggedInUser && (loggedInUser.isAdmin || _.get(loggedInUser, 'rights.instantQuote', false)),

    links({ loggedInUser, company }) {
      const links = [
        {
          to: '/rfqs',
          src: '/img/rfqs.svg',
          label: this.$t('menus.rfqs'),
          enabled: loggedInUser && (loggedInUser.isAdmin || loggedInUser.rights.rfqs),
        },
        {
          to: '/orders',
          src: '/img/orders.svg',
          label: this.$t('menus.orders'),
          enabled: loggedInUser && (loggedInUser.isAdmin || loggedInUser.rights.orders),
        },
        {
          to: '/my_parts',
          src: '/img/myparts.svg',
          label: this.$t('menus.myParts'),
          enabled: loggedInUser && (loggedInUser.isAdmin || loggedInUser.rights.myParts),
        },
        {
          to: '/tools/materials',
          src: '/img/engineeringtools.svg',
          label: this.$t('menus.engineeringTools'),
          enabled: true,
        },
      ];

      // Must check string value
      if (process.env.VUE_APP_DO_SHOW_SANDBOX === 'true') {
        links.push({
          to: '/sandbox',
          src: '/img/settings.svg',
          label: 'Sandbox',
          enabled: process.env.VUE_APP_DO_SHOW_SANDBOX,
        });
      }
      if (!process.env.VUE_APP_API_URL.includes('b2b-api') && company && company.AllowBetaTesting === '1') {
        links.push({
          to: '/internal/3d-packaging-tool',
          src: '/img/settings.svg',
          label: 'Packaging Tool',
          enabled: company && company.AllowBetaTesting === '1',
        });
      }
      return links;
    },

    restrictedFileExtensions: () => [
      'ade',
      'adp',
      'apk',
      'appx',
      'appxbundle',
      'bat',
      'cab',
      'chm',
      'cmd',
      'com',
      'cpl',
      'dll',
      'dmg',
      'ex',
      'ex_',
      'exe',
      'hta',
      'ins',
      'isp',
      'iso',
      'jar',
      'js',
      'jse',
      'lib',
      'lnk',
      'mde',
      'msc',
      'msi',
      'msix',
      'msixbundle',
      'msp',
      'mst',
      'nsh',
      'pif',
      'ps1',
      'scr',
      'sct',
      'shb',
      'sys',
      'vb',
      'vbe',
      'vbs',
      'vxd',
      'wsc',
      'wsf',
      'wsh',
      'zip'
    ],

    isLogin() {
      return this.$route.fullPath === '/login';
    },

    isLogout() {
      return this.$route.fullPath === '/logout';
    },

    shouldSkipMainRender() {
      /**
             * During the initial render, the $route instance is not available, so we have to use the raw
             * History object instance.
             * NOTE: If we don't use a reactive ($route) in this compute, it will not be recomputed
             */
      return this.isLogin || this.isLogout || [
        /^\/404/,
        /^\/login/,
        /^\/logout/,
        /^\/on_boarding/,
        /^\/confirmation/,
        /^\/forgotten_password/,
        /^\/reset_password/,
        /^\/signup/,
        /^\/resetpassword_send_email/,
        /^\/resetpassword_success/,
      ].some(url => _.get(window, 'location.pathname', '').match(url));
    },

    showNavigationBar() {
      return this.$route.name !== 'Support';
    },

  },

  watch: {
    loggedInUser: {
      deep: true,
      handler(newUser) {
        if (newUser) {
          datadogRum.setUser({
            id: newUser._id,
            fullName: newUser.fullName,
          });
        }
      },
    },
  },

  mounted() {
    this.createUserFeedBack = this.$feathers.service('feedback');
    this.$nextTick(() => {
      this.$i18n.locale = this.locale;
    });
    this.autoLoadGeographicData();
    const translationDate = this.locale === 'cn' ? localeCn : localeEn;
    moment.locale(translationDate);
    // const that = this;
    // // eslint-disable-next-line func-names
    // (function (d, t) {
    //   const BASE_URL = 'https://chat.komacut.com';
    //   const g = d.createElement(t);
    //   const s = d.getElementsByTagName(t)[0];
    //   g.src = `${BASE_URL}/packs/js/sdk.js`;
    //   g.defer = true;
    //   g.async = true;
    //   s.parentNode.insertBefore(g, s);
    //   // eslint-disable-next-line func-names
    //   g.onload = function () {
    //     window.chatwootSDK.run({
    //       websiteToken: 'PN2n9TJBxGDsp2wJcVvqWP9q',
    //       baseUrl: BASE_URL
    //     });
    //   };
    //   const timer = setInterval(() => {
    //     const arrayOfElement = document.getElementsByClassName('woot-widget-bubble');
    //     const element = Array.from(arrayOfElement);
    //     if (element[0]) {
    //       const div = element[0];
    //       const showTip = () => {
    //         const tip = document.createElement('span');
    //         tip.id = 'tip';
    //         tip.innerHTML = that.$t('menus.chatWithUs');
    //         div.appendChild(tip);
    //         tip.style.cssText = `
    //           opacity:1;
    //           width:100px;
    //           bottom:30px;
    //           right:70px;
    //           position:absolute;
    //           font-size:14px;
    //           font-family:"Nunito Sans";
    //           background:rgba(97,97,97,.9);
    //           color:white;
    //           padding:5px;
    //           border-radius:4px;`;
    //       };
    //       const hideTip = () => {
    //         const tip = document.getElementById('tip');
    //         tip.remove();
    //       };
    //
    //       div.addEventListener('mouseover', showTip, false);
    //       div.addEventListener('mouseout', hideTip, false);
    //       clearInterval(timer);
    //     }
    //   }, 1000);
    // }(document, 'script'));
  },

  methods: {
    ...mapActions('app', ['pushSnack']),

    bytesToSize(bytes, decimals) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1000;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      // eslint-disable-next-line no-restricted-properties
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    removeFile(fileSize, index) {
      this.maxFileSize = false;
      this.totalSizeInMb -= parseInt(fileSize, 10);
      this.feedbackUploadFile.splice(index, 1);
    },

    async sendFeedBackEmail() {
      this.isSendEmailPending = true;
      try {
        await this.createUserFeedBack.create(
          {
            comment: this.userCommentFeedback,
            fileArray: this.feedbackUploadFile
          }
        );
      } catch (e) {
        console.error(e);
      }
      this.cleanFeedback();
    },

    cleanFeedback() {
      this.feedbackUploadFile = [];
      this.userCommentFeedback = '';
      this.emailDialogIsActive = false;
      this.isSendEmailPending = false;
      this.errorFileName = '';
      this.isExeFile = false;
      this.maxFileSize = false;
      this.totalSizeInMb = 0;
      document.getElementById('uploader').value = '';
    },

    onFileChanged(e) {
      const currentFiles = e.target.files;
      this.isExeFile = false;
      this.maxFileSize = false;
      this.errorFileName = '';

      for (let i = 0; i < 5 - this.feedbackUploadFile.length; i++) {
        const file = currentFiles[i];

        const extensionFile = file ? file.name.split('.').pop() : '';

        if (this.restrictedFileExtensions.includes(extensionFile.toLowerCase())) {
          this.errorFileName = file.name;
          this.isExeFile = true;
          document.getElementById('uploader').value = '';
          return;
        }

        const bytes = file ? file.size : 0;
        const totalFileSize = this.totalSizeInMb + bytes;
        const sizeLimitInBytes = 1024 * 1000 * 5;
        if (totalFileSize > sizeLimitInBytes) {
          this.maxFileSize = true;
          document.getElementById('uploader').value = '';
          return;
        }
        this.totalSizeInMb = totalFileSize;

        const reader = new FileReader();
        reader.onloadend = () => {
          const readFile = reader.result;
          this.feedbackUploadFile.push({ path: readFile, filename: file.name, fileSize: file.size });
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      }
      document.getElementById('uploader').value = '';
    },

    async autoLoadGeographicData() {
      const countryCount = this.Country.countInStore({ query: {} });
      if (countryCount === 0) {
        this.Country.find({ query: {} });
      }

      const regionCount = this.Regions.countInStore({ query: {} });
      if (regionCount === 0) {
        this.Regions.find({ query: {} });
      }
    },

    settingPathRights(user) {
      if (_.get(user, 'isAdmin', false) || _.get(user, 'rights.companyManagement', false)) {
        return '/settings/company';
      }
      return '/settings/forwarders';
    },

    onActionButtonClicked() {
      this.$router.push(`/rfqs/${this.getTempRfqIdInProgress}`);
    },
  },
};
</script>

<style scoped>

>>> .menu-icons .v-list-item__icon:last-of-type:not(:only-child) {
    margin-left: 0 !important;
    justify-content: unset;
}

>>> .disable-icons .v-list-item__icon:last-of-type:not(:only-child) {
    display: none;
}

>>> .menu-icons .theme--light.v-icon {
    color: #2C984E !important;
}

.v-list-item {
    font-size: 12px;
    height: 100px;
}

.on-hover {
    cursor: pointer;
    opacity: 0.6
}

.tiny-font {
    font-size: 10px;
}

.tiny-font-bold {
    font-size: 10px;
    font-weight: bold;
}

.toHover:hover {
    color: #6cb043;
    filter: invert(0.4) sepia(1) saturate(4) hue-rotate(75.6deg) brightness(1);
}

.fileUpdateHover:hover {
    cursor: pointer;
    background-color: rgba(192, 192, 192, 0.3);
}

.v-navigation-drawer__content .v-list-item--active {
    color: #6cb043;
    filter: invert(0.4) sepia(1) saturate(4) hue-rotate(75.6deg) brightness(1);
}

.v-image__image--contain {
    top: 9px;
    height: 60%;
}

.search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
}

div.v-responsive.v-image > div.v-responsive__content {
    overflow-y: auto;
}

.logo:hover {
    cursor: pointer;
}

.menu {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}
</style>

<style>
html {
    overflow-y: auto !important;
    overflow-x: overlay !important;
    height: 100%;
}

.row {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.v-data-table tbody tr.v-data-table__expanded__content {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.v-data-table td, .v-data-table th {
    padding: 0 !important;
}

.v-data-table tr td {
    cursor: default !important;
}

header.v-sheet.v-toolbar.v-toolbar--flat.v-app-bar {
    background-color: #f5f5f5 !important;
}

.theme--light.v-application {
    background: #f5f5f5 !important;
}

.v-navigation-drawer {
    z-index: 11 !important;
}

.v-content {
    padding-top: 64px !important;
}

.v-toolbar__content {
    height: 64px !important;
}

.v-app-bar.v-app-bar--fixed {
    z-index: 7 !important;
}

.v-list-item__action {
    margin: 6px 0 !important;
}

.v-list--dense .v-list-item, .v-list-item--dense {
    min-height: 32px !important;
}

.v-input__slot {
    margin-bottom: 2px !important;
}

.v-expansion-panel-header {
    padding: 8px 16px !important;
}

.flex-vcenter {
    display: flex;
    align-items: center;
}

.v-tabs-slider-wrapper {
    height: 3px !important;

}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    width: 0;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

.menu-offset-class {
    margin: -10px 0 0 0;
}

</style>
