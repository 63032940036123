<template>
  <div style="width: 800px">
    <v-simple-table
      dense
      fixed-header
      height="600px"
      style="width: 1000px"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase" style="width:90px;">
              {{ $t('views.engineeringTools.tabs.guideLine.no') }}
            </th>
            <th class="text-left text-uppercase" style="width:100px; padding-left: 0 !important;">
              {{ $t('views.engineeringTools.tabs.guideLine.type') }}
            </th>
            <th class="text-left text-uppercase" style="width:100px; padding-left: 0 !important;">
              {{ $t('views.engineeringTools.tabs.guideLine.code') }}
            </th>
            <th class="text-left text-uppercase" style="width:500px; padding-left: 0 !important;">
              {{ $t('views.engineeringTools.tabs.guideLine.description') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in guideLineList"
            :key="item.name"
          >
            <td class="pl-5 font-weight-bold">
              {{ index + 1 }}
            </td>
            <td class="text-left font-weight-bold" style="width:150px">
              <v-icon v-if="item.type === $t('common.Warning') " left color="#C77500">
                mdi-alert-outline
              </v-icon>
              <v-icon v-else left color="#B00020">
                mdi-alert-circle-outline
              </v-icon>
              {{ item.type }}
            </td>
            <td class="text-left font-weight-bold">
              {{ item.code }}
            </td>
            <td class="text-left">
              {{ item.description }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'InstantQuoteGuideLine',

  computed: {
    guideLineList: vm => [
      { type: vm.$t('common.Error'), code: '200', description: vm.$t('views.engineeringTools.tabs.guideLine.no3DSolidEntitiesDetected') },
      { type: vm.$t('common.Error'), code: '201', description: vm.$t('views.engineeringTools.tabs.guideLine.multiple3DSolidEntities') },
      { type: vm.$t('common.Error'), code: '202', description: vm.$t('views.engineeringTools.tabs.guideLine.unsupportedSldprtVersionCaption') },
      { type: vm.$t('common.Warning'), code: '301', description: vm.$t('views.engineeringTools.tabs.guideLine.thicknessIsTooBig') },
      { type: vm.$t('common.Warning'), code: '302', description: vm.$t('views.engineeringTools.tabs.guideLine.smallAndBigRadiiDetected') },
      { type: vm.$t('common.Warning'), code: '303', description: vm.$t('views.engineeringTools.tabs.guideLine.smallRadiusDetected') },
      { type: vm.$t('common.Warning'), code: '304', description: vm.$t('views.engineeringTools.tabs.guideLine.bigRadiusDetected') },
      { type: vm.$t('common.Warning'), code: '306', description: vm.$t('views.engineeringTools.tabs.guideLine.smallDistanceDetectedBetweenHoleToHole') },
      { type: vm.$t('common.Warning'), code: '307', description: vm.$t('views.engineeringTools.tabs.guideLine.smallDistanceDetectedBetweenHoleToEdge') },
      { type: vm.$t('common.Warning'), code: '321', description: vm.$t('views.engineeringTools.tabs.guideLine.overallPartLengthLonger') },
      { type: vm.$t('common.Warning'), code: '322', description: vm.$t('views.engineeringTools.tabs.guideLine.smallChamferOnHoleFound') },
      { type: vm.$t('common.Warning'), code: '323', description: vm.$t('views.engineeringTools.tabs.guideLine.smallChamferOnOuterEdgesFound') },
      { type: vm.$t('common.Error'), code: '401', description: vm.$t('views.engineeringTools.tabs.guideLine.thicknessWasNotDetected') },
      { type: vm.$t('common.Error'), code: '402', description: vm.$t('views.engineeringTools.tabs.guideLine.partCouldNotBeRead') },
      { type: vm.$t('common.Error'), code: '410', description: vm.$t('views.engineeringTools.tabs.guideLine.generalError') },
      { type: vm.$t('common.Error'), code: '411', description: vm.$t('views.engineeringTools.tabs.guideLine.partIsNotValidTryToEdit') },
      { type: vm.$t('common.Error'), code: '412', description: vm.$t('views.engineeringTools.tabs.guideLine.notAllGeometryWasCorrectlyConvertedToSheetMetal') },
      { type: vm.$t('common.Error'), code: '413', description: vm.$t('views.engineeringTools.tabs.guideLine.invalidFeaturesRecognizedPleaseRepair') },
      { type: vm.$t('common.Error'), code: '414', description: vm.$t('views.engineeringTools.tabs.guideLine.formFeaturesRecognizedPleaseRemoveThem') },
      { type: vm.$t('common.Error'), code: '415', description: vm.$t('views.engineeringTools.tabs.guideLine.loftedBendFeaturesRecognized') },
      { type: vm.$t('common.Error'), code: '416', description: vm.$t('views.engineeringTools.tabs.guideLine.machiningFeatureFound') },
      { type: vm.$t('common.Error'), code: '417', description: vm.$t('views.engineeringTools.tabs.guideLine.invalidThreadTypeFound') },
      { type: vm.$t('common.Error'), code: '418', description: vm.$t('views.engineeringTools.tabs.guideLine.filletFeatureDetected') },
      { type: vm.$t('common.Error'), code: '450', description: vm.$t('views.engineeringTools.tabs.guideLine.unfoldedModelIsInterferingWithItself') },
      { type: vm.$t('common.Error'), code: '451', description: vm.$t('views.engineeringTools.tabs.guideLine.unableToUnfoldTheModel') },
      { type: vm.$t('common.Error'), code: '500', description: vm.$t('views.engineeringTools.tabs.guideLine.PartDiameterTooSmall') },
      { type: vm.$t('common.Error'), code: '501', description: vm.$t('views.engineeringTools.tabs.guideLine.PartDiameterTooLarge') },
      { type: vm.$t('common.Error'), code: '502', description: vm.$t('views.engineeringTools.tabs.guideLine.PartLengthTooSmall') },
      { type: vm.$t('common.Error'), code: '503', description: vm.$t('views.engineeringTools.tabs.guideLine.PartLengthTooLarge') },
      { type: vm.$t('common.Error'), code: '504', description: vm.$t('views.engineeringTools.tabs.guideLine.MillingAreaTooLarge') },
      { type: vm.$t('common.Error'), code: '505', description: vm.$t('views.engineeringTools.tabs.guideLine.HoleSizeTooSmall') },
      { type: vm.$t('common.Error'), code: '506', description: vm.$t('views.engineeringTools.tabs.guideLine.HoleSizeTooLarge') },
      { type: vm.$t('common.Error'), code: '507', description: vm.$t('views.engineeringTools.tabs.guideLine.HoleTooDeep') },
      { type: vm.$t('common.Error'), code: '508', description: vm.$t('views.engineeringTools.tabs.guideLine.WallThicknessTooThin') },
    ]
  },
};
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
  background-color: #beebc9 !important;
  padding: 0 0 0 15px !important;
  height: 25px !important;
}
::v-deep thead th:first-child {
  border-radius: 5px 0 0 0 ;
}

::v-deep thead th:last-child {
  border-radius: 0 5px 0 0 ;
}
</style>
