<template>
  <v-card
    rounded
    width="95%"
    min-width="1118"
    height="101px"
    color="#F9F9F9"
    outlined
    style="background-color: #616161"
  >
    <v-card-text style="color: #FFFFFF">
      <v-row>
        <img
          class="mt-n8 ml-1"
          src="/img/welcomeScreen/icon-referral-speaker.svg"
        >
        <div class="ml-4 mt-2 text-h5 font-weight-bold">
          {{ $t('views.dashboard.welcomeScreenReferralBanner.titleLabel1') }}
        </div>
        <div class="mx-4 mt-1 text-h4 font-weight-bold">
          {{ $t('views.dashboard.welcomeScreenReferralBanner.titleLabel2') }}
        </div>
        <div class="mt-2 text-h5 font-weight-bold">
          {{ $t('views.dashboard.welcomeScreenReferralBanner.titleLabel3') }}
        </div>
        <v-btn
          class="ml-4 mt-1"
          color="primary"
          large
          @click.stop="dialog=true"
        >
          {{ $t('views.dashboard.welcomeScreenReferralBanner.referNow') }}
        </v-btn>
      </v-row>
      <v-row class="ml-12 mt-n4">
        <div class="ml-12 pl-8 text-body-2">
          {{ $t('views.dashboard.welcomeScreenReferralBanner.referNote') }}
        </div>
      </v-row>
    </v-card-text>
    <core-dialog
      v-model="dialog"
      type="confirmation"
      :ok-button-text="'Refer Now'"
      width="619px"
      persistent
      :is-loading="isCreatePending"
      @click:ok="onActionButtonClicked"
      @click:cancel="cancelReferralDialog"
    >
      <template #title>
        {{ $t('views.dashboard.referralScreenDialog.heading') }}
      </template>

      <template #content>
        <div class="text-body-1" style="text-align: center;">
          {{ $t('views.dashboard.referralScreenDialog.subHeading1') }}<br>
          {{ $t('views.dashboard.referralScreenDialog.subHeading2') }}
        </div>
        <v-form
          ref="referralForm"
          v-model="isReferralValid"
          @input="$emit('validityChange', $event)"
        >
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="referral.firstName"
                    maxlength="60"
                    data-cy="new-address-name-input"
                    :placeholder="$t('common.firstName')"
                    :label="$t('common.firstName')"
                    :rules="[isRequired]"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="referral.lastName"
                    maxlength="60"
                    data-cy="new-address-name-input"
                    :placeholder="$t('common.lastName')"
                    :label="$t('common.lastName')"
                    :rules="[isRequired]"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="referral.email"
                    maxlength="128"
                    data-cy="new-address-email-input"
                    :placeholder="$t('form.address.fields.email')"
                    :label="$t('form.address.fields.email')"
                    :rules="[isRequired, isEmail]"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="referral.company"
                    :rules="[isRequired]"
                    :label="$t('views.settings.tabs.company.name')"
                    :placeholder="$t('views.settings.tabs.company.name')"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="font-weight-bold">
                    {{ $t('views.signUp.termsAndConditions') }}
                  </div>
                  <ul class="caption font-italic">
                    <li>
                      {{ $t('views.dashboard.referralScreenDialog.t&cPoint1') }}
                    </li>
                    <li>
                      {{ $t('views.dashboard.referralScreenDialog.t&cPoint2') }}
                    </li>
                    <li>
                      {{ $t('views.dashboard.referralScreenDialog.t&cPoint3') }}
                    </li>
                    <li>
                      {{ $t('views.dashboard.referralScreenDialog.t&cPoint4') }}
                    </li>
                    <li>
                      {{ $t('views.dashboard.referralScreenDialog.t&cPoint5') }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </core-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { models } from 'feathers-vuex';
import FormRules from '@/mixins/form-rules';

const { Referral } = models.api;

export default {
  name: 'WelcomeScreenReferral',
  mixins: [
    FormRules,
  ],
  data: () => ({
    dialog: false,
    isReferralValid: false,
    referral: new Referral(),
  }),
  computed: {
    ...mapGetters('auth', { loggedInUser: 'user' }),
    ...mapGetters('temp-rfqs', { getTempRfqIdInProgress: 'getTempRfqIdInProgress' }),
    ...mapState('referrals', ['isCreatePending']),
    userCanQuote: ({ loggedInUser }) => loggedInUser && (loggedInUser.isAdmin || _.get(loggedInUser, 'rights.instantQuote', false)),
  },
  methods: {
    async onActionButtonClicked() {
      const valid = this.$refs.referralForm.validate();
      if (valid) {
        await this.referral.save({ notificationMessage: this.$t('views.dashboard.referralScreenDialog.referralSentMessage') });
        this.cancelReferralDialog();
      }
    },
    cancelReferralDialog() {
      this.dialog = false;
      this.referral = new Referral();
    }
  },
};
</script>

<style scoped>
</style>
