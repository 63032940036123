export default {
  computed: {
    cncTurningLinearDimensions: vm => [
      {
        deviations: `0.5 ${vm.$t('views.engineeringTools.tabs.processLimitations.up')} ${vm.$t('views.engineeringTools.tabs.processLimitations.to')} 3`,
        fine: '±0.05',
        medium: '±0.1',
        coarse: '±0.2'
      },
      {
        deviations: `${vm.$t('views.engineeringTools.tabs.processLimitations.over')} 3 ${vm.$t('views.engineeringTools.tabs.processLimitations.up')} ${vm.$t('views.engineeringTools.tabs.processLimitations.to')} 6`,
        fine: '±0.05',
        medium: '±0.1',
        coarse: '±0.3'
      },
      {
        deviations: `${vm.$t('views.engineeringTools.tabs.processLimitations.over')} 6 ${vm.$t('views.engineeringTools.tabs.processLimitations.up')} ${vm.$t('views.engineeringTools.tabs.processLimitations.to')} 30`,
        fine: '±0.10',
        medium: '±0.2',
        coarse: '±0.5'
      },
      {
        deviations: `${vm.$t('views.engineeringTools.tabs.processLimitations.over')} 30 ${vm.$t('views.engineeringTools.tabs.processLimitations.up')} ${vm.$t('views.engineeringTools.tabs.processLimitations.to')} 120`,
        fine: '±0.15',
        medium: '±0.3',
        coarse: '±0.8'
      },
      {
        deviations: `${vm.$t('views.engineeringTools.tabs.processLimitations.over')} 120 ${vm.$t('views.engineeringTools.tabs.processLimitations.up')} ${vm.$t('views.engineeringTools.tabs.processLimitations.to')} 400`,
        fine: '±0.20',
        medium: '±0.5',
        coarse: '±1.2'
      },
      {
        deviations: `${vm.$t('views.engineeringTools.tabs.processLimitations.over')} 400 ${vm.$t('views.engineeringTools.tabs.processLimitations.up')} ${vm.$t('views.engineeringTools.tabs.processLimitations.to')} 1000`,
        fine: '±0.30',
        medium: '±0.8',
        coarse: '±2.0'
      },
    ],
  }
};
