<template>
  <v-container class="pt-0" fluid>
    <v-row v-if="_.isEmpty(orderLines) && loadingParts">
      <v-skeleton-loader
        class="mx-3"
        width="100%"
        loading
        :type="`table-thead,table-row-divider@${nbrOfOrder}`"
      />
    </v-row>

    <v-row v-else>
      <v-col class="pt-0" cols="12">
        <v-data-table
          :headers="orderLinesTableHeaders"
          :items="orderLines"
          :loading="_.isEmpty(orderLines)"
          disable-pagination
          hide-default-footer
          :no-data-text="$t('common.noDataAvailable')"
          :loading-text="$t('common.loadingItems')"
          @click:row="open3DViewer($event)"
        >
          <template #loading>
            <v-skeleton-loader
              class="pa-5 d-flex justify-space-between"
              width="100%"
              loading
              :type="`komacutLine@10`"
              :types="{komacutLine:`table-cell@${nbrOfOrder}`}"
            />
          </template>
          <template #item.CustItemName="{ item }">
            <div style="display: flex; align-items: center;">
              <core-thumbnail-viewer
                :part="item"
                :part-lookup-key="'Item'"
                :width="60"
                :height="60"
                class="mr-5 ml-3"
                data-cy="quote-image"
              />
              <span data-cy="quote-part-value" v-html="highlight(item.CustItemName)" />
            </div>
          </template>
          <!--          <template #item.PDF="{item}">-->
          <!--            <core-my-part-pdf-viewer :part="item" @click.prevent />-->
          <!--          </template>-->
          <template #item.Tolerance="{item: orderline }">
            <span data-cy="quote-tolerance-value">
              {{
                orderline.toleranceTranslation
              }}
            </span>
          </template>
          <template #item.MaterialStr="{ item: orderLine }">
            <span
              data-cy="quote-material-value"
              v-html="highlight(orderLine.materialTranslation)"
            />
          </template>
          <template #item.materialSeriesShortened="{ item: orderLine }">
            <span
              data-cy="quote-series-value"
              v-html="highlight(orderLine.materialSeriesTranslation)"
            />
          </template>
          <template #item.MaterialThicknessStr="{ item: orderLine }">
            <span
              data-cy="quote-thickness-value"
              v-html="highlight(orderLine.materialThicknessTranslation)"
            />
          </template>
          <template #item.MaterialFinishStr="{ item: orderLine }">
            <span
              data-cy="quote-finish-value"
              v-html="highlight(orderLine.materialFinishTranslation )"
            />
          </template>
          <template #item.Revision="{ item: orderLine }">
            <span data-cy="quote-revision-value">{{ orderLine.Revision }}</span>
          </template>
          <template #item.CustItemDesc="{ item: orderLine }">
            <span data-cy="quote-description-value">{{ orderLine.CustItemDesc }}</span>
          </template>
          <template #item.Quantity="{ item: orderLine }">
            <span data-cy="quote-qty-value">{{ orderLine.Quantity }}</span>
          </template>
          <template #item.Price="{ item: orderLine }">
            <span data-cy="quote-unit-value">{{
              currency(orderLine.Price, {symbol: company.currencySymbol}).format()
            }}</span>
          </template>

          <template #item._id="{ item: orderLine }">
            <span data-cy="quote-net-value">{{
              currency(orderLine.Price, {symbol: company.currencySymbol}).multiply(orderLine.Quantity).format()
            }}</span>
          </template>
        </v-data-table>
        <template v-if="(loadingParts || (isLoadingPartsOrderId===orderId)) && !_.isEmpty(orderLines)">
          <v-skeleton-loader
            v-for="index in (nbrOfOrder - orderIdInStore.length)"
            :key="`table-${index}`"
            class="pa-5 d-flex justify-space-between"
            width="100%"
            loading
            :type="`komacutLine@10`"
            :types="{komacutLine:`table-cell@${nbrOfOrder - orderIdInStore.length}`}"
          />
        </template>
        <v-card
          v-if="!_.isEmpty(filteredPartItems) && (nbrOfOrder >1) && (filteredPartItems.length !== nbrOfOrder)"
          flat
          class="grey lighten-2  text-center pa-0 text-uppercase"
          @click="getAllMissingPart"
        >
          <span style=" font-size: 12px">
            {{ `${displayAll ? $t('views.rfqs.hidePart') : $t('views.rfqs.showAllParts')}` }}
          </span>
          <br>
          <div
            class="mdi mdi-chevron-down mt-n2"
            style="font-size: 20px; height:25px"
            :class="{'mdi-rotate-180':displayAll}"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapState } from 'vuex';
import currency from 'currency.js';

const { OrderLine, Company } = models.api;

export default {
  name: 'OrderItemsTable',

  props: {
    isLoadingPartsOrderId: { type: String },
    orderId: { type: String },
    searchValue: { type: String },
    filteredPartItems: {
      type: Array,
      default: () => []
    },
    nbrOfOrder: { type: Number }
  },

  data() {
    return {
      currency,
      displayAll: false,
      loadingParts: false
    };
  },

  computed: {
    ...mapState('order-lines', { loadingOrderLines: 'isFindPending' }),
    ...mapState('app', ['locale']),
    orderLines() {
      const queryFilter = {
        query: {
          CoNum: this.orderId,
        }
      };

      if (!this.displayAll && !_.isEmpty(this.filteredPartItems)) {
        queryFilter.query._id = { $in: this.filteredPartItems };
      }
      return OrderLine.findInStore(queryFilter).data;
    },

    orderIdInStore() {
      return OrderLine.findInStore({
        query: {
          CoNum: this.orderId,
        },
      }).data.map(order => order._id);
    },

    company: () => Company.getFromStore(0),

    toleranceTranslation: vm => item => {
      if (!item.Tolerance) {
        return '-';
      }
      return vm.$t(`views.instantQuote.tolerance${item.Tolerance.replace('Precision', '').trim()}`);
    },

    orderLinesTableHeaders: vm => [
      {
        text: vm.$t('views.orders.partNumber'),
        value: 'CustItemName',
      },
      {
        text: vm.$t('views.orders.revision'),
        value: 'Revision',
      },
      {
        text: vm.$t('common.description'),
        value: 'CustItemDesc',
      },
      {
        text: vm.$t('views.orders.material'),
        value: 'MaterialStr',
      },
      {
        text: vm.$t('views.orders.series'),
        value: 'materialSeriesShortened',
      },
      {
        text: vm.$t('views.orders.thickness'),
        value: 'MaterialThicknessStr',
      },
      {
        text: vm.$t('views.orders.finish'),
        value: 'MaterialFinishStr',
      },
      {
        text: vm.$t('views.instantQuote.tolerances'),
        value: 'Tolerance',
      },

      {
        text: vm.$t('views.orders.unitPrice', { currencySymbol: vm.company.currencySymbol }),
        value: 'Price',
      },
      {
        text: vm.$t('views.orders.quantity'),
        value: 'Quantity',
      },
      {
        text: vm.$t('views.orders.netPrice', { currencySymbol: vm.company.currencySymbol }),
        value: '_id',
      },
    ].map(h => ({
      align: 'center',
      class: ['text-uppercase'],
      ...h,
    })),
  },

  methods: {

    open3DViewer(event) {
      if (event.ProductType === 'FG') {
        this.$emit('open3DViewer', event);
      }
    },

    highlight(value) {
      const escapedSearchValue = _.escapeRegExp(this.searchValue);
      const re = new RegExp(`(${escapedSearchValue})`, 'gi');
      if (!_.isEmpty(this.filteredPartItems)) {
        return value.replace(
          re,
          '<span style="background-color: yellow">$1</span>'
        );
      }
      return value;
    },
    async getAllMissingPart() {
      this.displayAll = !this.displayAll;
      if (!this.displayAll) {
        return;
      }

      if (this.orderIdInStore.length === this.nbrOfPart) {
        return;
      }
      this.loadingParts = true;
      await OrderLine.find({
        query: {
          CoNum: this.orderId,
          _id: { $nin: this.orderIdInStore }
        }
      });
      this.loadingParts = false;
    },
  },

};
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 25px !important;
  margin-top: 10px;
}

>>> .v-data-table__wrapper > table > thead > tr > th {
  color: #9f9f9f !important;;
  background-color: #f4f4f4 !important;
}

::v-deep thead th:first-child {
  border-radius: 0 !important;
}

::v-deep thead th:last-child {
  border-radius: 0 !important;
}
</style>
