<template>
  <v-tooltip bottom color="transparent">
    <template v-slot:activator="{ on }">
      <td
        v-on="on"
      >
        <span class="shimmer">
          {{ customerPriceRangeText }}
        </span>
      </td>
    </template>
    <span>
      <v-card width="max-content">
        <v-card-title class="subtitle-1 d-flex justify-center">
          {{ $t('common.unitPricePerQuantity') }}
        </v-card-title>

        <v-divider />

        <v-list
          color="transparent"
          dense
        >
          <v-list-item
            v-for="(p, i) in customerPriceRangeTable"
            :key="i"
          >
            <v-list-item-content>
              {{ p.split(':')[0] + ':' }}
            </v-list-item-content>

            <v-list-item-content class="align-end pr-6">
              {{ p.split(':')[1] }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </span>
  </v-tooltip>
</template>

<script>
import currency from 'currency.js';
import { models } from 'feathers-vuex';

const { Company } = models.api;

export default {
  name: 'TableUnitPricing',

  props: {
    item: Object,
  },

  computed: {
    company: () => Company.getFromStore(0),

    priceRange() {
      return JSON.parse(this.item.PriceMOQ);
    },

    customerPriceRange() {
      if (!this.priceRange) {
        return [];
      }

      const reqQtyPriceIndex = this.priceRange
        .findIndex(range => range.Qty > this.item.ReqQty) - 1;

      if (reqQtyPriceIndex < 0) {
        return [];
      }

      return this.priceRange
        .slice(reqQtyPriceIndex, Math.min(reqQtyPriceIndex + 3, this.priceRange.length));
    },

    customerPriceRangeTable() {
      return this.customerPriceRange.map(range => `${range.Qty}: ${currency(range.Price, { symbol: this.company.currencySymbol }).format()} / unit`);
    },

    customerPriceRangeText() {
      if (_.isEmpty(this.customerPriceRange)) {
        return '';
      }
      return `${currency(_.last(this.customerPriceRange).Price, { symbol: this.company.currencySymbol }).format()} - ${currency(this.customerPriceRange[0].Price, { symbol: this.company.currencySymbol }).format()}`;
    },
  },
};
</script>

<style>
  .hovered .shimmer {
    display: inline-block;
    color: white;

    background: rgba(0, 0, 0, 0.87) -webkit-gradient(linear, 100% 0, 0 0,
    from(rgba(0, 0, 0, 0.87)), color-stop(0.5, #ffffff),
    to(rgba(0, 0, 0, 0.87)));

    background-position: -4rem top; /*50px*/
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation-name: shimmer;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-background-size: 4rem 100%; /*50px*/
    -webkit-animation-timing-function: linear;
  }

  @-webkit-keyframes shimmer {
    from {
      background-position: -4rem top; /*50px*/
    }
    to {
      background-position: 11rem top; /*200px*/
    }
  }
</style>
