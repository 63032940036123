<template>
  <v-row>
    <v-col>
      <v-list color="transparent">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="title"
            >
              {{ 'Filters' }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider />

      <template v-for="(filter, i) in localValue">
        <v-list
          :key="`list-${i}`"
          color="transparent"
        >
          <v-subheader>
            {{ filter.section }}
          </v-subheader>

          <component
            :is="getFilter(filter.type)"
            v-model="filter.value"
            v-bind="filter"
          />
        </v-list>

        <v-divider
          v-if="(i + 1) !== Object.keys(localValue).length"
          :key="`divider-${i}`"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['show', 'onclose', 'value'],

  computed: {

    filterTypes() {
      return new Map([
        ['checkbox', 'core-filter-checkbox-group'],
        ['switch', 'core-filter-switch-group'],
        ['radio', 'core-filter-radio-group'],
        ['rangeSlider', 'core-filter-range-slider'],
      ]);
    },

    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    getFilter(filterType) {
      if (this.filterTypes.has(filterType)) {
        return this.filterTypes.get(filterType);
      }
      return 'span';
    },
  },
};
</script>

<style scoped>
  .v-list-item {
    min-height: 30px;
}
</style>
