<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <img :src="filePath">
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'SvgViewer',
  props: {
    filePath: String,
  },
  methods: {
  }
};
</script>

<style scoped>

</style>
