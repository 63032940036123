import Vue from 'vue';
import Vuex from 'vuex';
import { FeathersVuex, models } from '@/plugins/feathers-client';

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context('./', false, /.js$/);
const appModules = requireModule.keys().filter(path => path !== './index.js').map(path => requireModule(path).default);

const modules = {
  ...appModules.reduce((acc, mod) => ({ ...acc, ...mod.store }), {}),
};
const plugins = [...appModules.map(mod => mod.plugin)];

const store = new Vuex.Store({
  modules,
  plugins,
});

const resetStore = name => {
  const model = models.api.byServicePath[name];
  if (model.namespace) {
    model.store.commit(`${model.namespace}/clearAll`);
  }
};

const resetStores = () => {
  store.commit('app/clearAll');
  store.commit('auth/clearAll');
  // store.commit('tabs/clearAll');

  for (const name of Object.keys(models.api.byServicePath)) {
    if (!['countries', 'regions'].includes(name)) {
      resetStore(name);
    }
  }
};

export {
  modules,
  plugins,
  resetStore,
  resetStores,
};

export default store;
