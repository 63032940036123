<template>
  <v-card
    hover
    width="510px"
    min-width="510px"
    height="100%"
    :loading="part.isRemovePending"
    class="ma-1 px-2 fill-height flex-column align-content-space-between partCard"
    data-cy="my-part-card-grid"
    :ripple="false"
  >
    <v-container class="pa-0">
      <v-row no-gutters data-cy="my-part-first-horizontal-grid-view">
        <v-col cols="2" class="py-0 pl-1 d-flex flex-row">
          <div
            class=" d-flex flex-column "
            @click="partViewerPart = part; isPartViewerActive = true;"
          >
            <core-thumbnail-viewer
              :part="part"
              :width="90"
              :height="90"
              class="pa-0"
              data-cy="my-part-image-grid"
              :is-display-border="true"
            />
          </div>

          <v-btn
            style="position: relative; top: 110px; right:95px;"
            class="mt-n3"
            text
            x-small
            color="primary"
            data-cy="my-part-view-details-button-grid"
            @click="partViewerPart = part; isPartViewerActive = true;"
          >
            {{ $t('common.viewDetails') }}
            <v-icon x-small right>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10">
          <v-row no-gutters class="pl-7">
            <v-col cols="11" align-self="start" class="pa-0">
              <div class="flex-row text-truncate">
                <span
                  class="text-truncate"
                  style="font-size: 25px;font-weight: 500;"
                  data-cy="my-part-part-grid"
                  :title="part.CustItemName"
                >
                  {{ part.CustItemName }}
                </span>
              </div>
            </v-col>

            <v-col cols="1" align-self="start" class="pa-0">
              <v-btn
                right
                class="align-self-start ml-auto"
                :loading="part.isRemovePending"
                color="primary"
                icon
                data-cy="my-part-remove-button-grid"
                @click="
                  deleteDialog.display = true;
                  deleteDialog.partToDelete = part;"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="part.Machine === 'turning'"
              offset="1"
              cols="4"
              class="pb-0 pl-6"
            >
              <div class="caption text--secondary">
                {{ $t('views.instantQuote.roughness') }}
              </div>
              <span>
                <span data-cy="my-part-roughness-grid">
                  {{ getRoughnessName }}<br>
                  {{ getRoughnessValue }}
                </span>
              </span>
            </v-col>
            <v-col
              :offset="part.Machine === 'turning' ? 1: 6"
              cols="6"
              class="pl-7 d-flex justify-end pr-0"
            >
              <core-rang-price-moq :part="part" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col align-self="start" cols="3" class="pb-0">
          <div style="min-width: 50px !important;" class="text-truncate">
            <div class="caption text--secondary text-truncate">
              {{ $t('views.myParts.material') }}
            </div>
            <span
              class="text-truncate"
              data-cy="my-part-material-grid"
              :title="part.materialTranslation"
            >
              {{ part.materialTranslation }}
            </span>
          </div>
        </v-col>
        <v-col cols="auto" class="pb-0">
          <div class="text-truncate">
            <div
              class="caption text--secondary text-truncate"
              style="min-width: 50px !important;"
            >
              {{ $t('views.myParts.finish') }}
            </div>
            <span
              data-cy="my-part-finish-grid"
              :title="part.MaterialFinishOptionStr || part.MaterialFinishStr"
            >
              {{
                part.materialFinishOptionTranslation || part.materialFinishTranslation
              }}
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="pb-0 pt-2">
          <div class="text-truncate">
            <div class="caption text--secondary text-truncate">
              {{ $t('views.myParts.series') }}
            </div>
            <span
              data-cy="my-part-series-grid"
              style="max-width: 30px !important"
              class="text-truncate"
              :title="part.materialSeriesTranslation"
            >
              {{
                part.materialSeriesTranslation
              }}
            </span>
          </div>
        </v-col>
        <v-col align-self="start" cols="3" class="pb-0 pt-2">
          <div class="caption text--secondary">
            {{ $t('views.instantQuote.tolerances') }}
          </div>
          <span>
            <span data-cy="my-part-tolerances-grid">
              {{
                part.toleranceTranslation
              }}
            </span>
          </span>
        </v-col>
        <v-col align-self="start" cols="3" class="pb-0">
          <v-text-field
            v-model.number="ReqQty"
            :min="part.defaultQuantity"
            :max="9999999"
            class="d-inline-block"
            style="width:135px !important;"
            data-cy="quantity-input-upload"
            :label="$t('views.rfqs.quantity')"
            type="number"
            hide-details
            outlined
            dense
            @blur="ReqQty < Math.min(...part.PriceMOQ.map(o => o.Qty)) ? ReqQty = Math.min(...part.PriceMOQ.map(o => o.Qty)) : ReqQty = ReqQty;"
            @input.native="part.qtyOfPart = updateValue($event)"
            @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
          />
        </v-col>
        <v-col align-self="start" cols="3" class="pb-0 pt-2">
          <v-sheet height="48px" :color="isExpired() ? '#FBE9EC': '#f0fdf3' " class="pa-2 py-0 rounded-lg ">
            <span class="caption text--secondary text-left">
              {{ $t('views.productOption.totalPrice', {currencySymbol: currencySymbol}) }}
            </span>
            <span
              class="mt-2"
              :style="[isExpired() ? {'color': '#be2a45'} : {'color': ''}]"
              data-cy="my-part-total-price-grid"
            >
              {{ calculatePrice(part).multiply(ReqQty) }}
            </span>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- This to make both sheet-metal and turning card height equal as turning
       part have two liner Tolerances -->
      <v-row v-if="part.Machine !== 'turning'" dense>
        <v-col style="height: 100%">
          &nbsp;
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pa-0 pt-2 d-flex align-end" data-cy="my-part-card-actions-grid">
      <v-tooltip right data-cy="my-part-information-tooltip-grid">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="d-flex"
            v-bind="attrs"
            color="primary"
            data-cy="my-part-information-grid"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span class="white--text" data-cy="my-part-user-grid">
          {{ part.PortalUserId }}
        </span>
        <br>
        <span data-cy="my-part-expiration-date-grid">
          {{
            isExpired() ? 'Is Expired Since' : $t('common.validUntil')
          }}:   {{ moment(part.ExpireDate).format('YYYY-MM-DD') }}
        </span>
      </v-tooltip>
      <v-spacer />
      <core-technical-drawing-pdf
        v-if="need2dDrawing"
        :part="part"
      />
      <core-upload-svg-drawing
        v-if="needSvg"
        :part="part"
      />
      <template v-if="_.get(currentUser, 'isAdmin') || _.get(currentUser, ['rights', 'placeOrder'])">
        <template v-if="isExpired(part)">
          <v-btn
            :loading="part.isSavePending"
            color="primary"
            data-cy="refresh-quote-button-grid"
            @click="refreshMyPartPrice(part)"
          >
            <v-icon left>
              mdi-autorenew
            </v-icon>
            {{ t('views.myParts.refreshQuote') }}
          </v-btn>
        </template>
        <template v-else>
          <template v-if="isPartPresentInCurrentOrder(part)">
            <v-btn
              color="primary"
              class="ml-6"
              data-cy="remove-order-this-button-grid"
              @click.stop="removeToOrder(part)"
            >
              {{ $t('views.productOption.removeToOrder') }}
            </v-btn>
          </template>
          <template v-else>
            <v-tooltip v-if="need2dDrawing && !part.TwoDFile || needSvg && !part.SvgFile" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on">
                  <v-btn
                    v-bind="attrs"
                    color="primary"
                    class="ml-6"
                    data-cy="order-this-button-grid"
                    disabled
                    @click.stop="addToOrder"
                  >
                    {{ $t('views.productOption.addToOrder') }}
                  </v-btn>
                </div>
              </template>
              <span v-if="need2dDrawing">
                {{ $t('views.productOption.addToOrderToolTip') }}
              </span>
              <span v-else>
                {{ $t('views.productOption.addToOrderSvgToolTip') }}
              </span>
            </v-tooltip>
            <v-btn
              v-else
              color="primary"
              class="ml-6"
              data-cy="order-this-button-grid"
              @click.stop="addToOrder"
            >
              {{ $t('views.productOption.addToOrder') }}
            </v-btn>
          </template>
        </template>
      </template>
    </v-card-actions>
    <core-part-viewer-drawer
      v-if="isPartViewerActive"
      :is-open="isPartViewerActive"
      :part="part"
      @closeSidePanel="isPartViewerActive = false"
    />
    <core-dialog
      v-if="deleteDialog.display"
      v-model="deleteDialog.display"
      type="confirmation"
      width="500px"
      data-cy="delete-dialogue"
      :ok-button-text="$t('common.delete')"
      @click:ok="
        deleteDialog.display = false; part.remove(); removeToOrder(part)"
      @click:cancel="cancelDelete"
    >
      <template #title>
        {{ $t('views.myParts.deleteDialogPartTitle') }}
      </template>
      <template #content>
        <div class="pa-10 text-center">
          <span class="d-block">
            <span
              class="d-block"
              data-cy="delete-dialogue-2"
              v-html="$t('common.deleteDialogText', { name: deleteDialog.partToDelete.CustItemName })"
            />
          </span>
        </div>
      </template>
    </core-dialog>
  </v-card>
</template>
<script>
import { models } from 'feathers-vuex';

import {
  computed, defineComponent, ref,
} from '@vue/composition-api';
import currency from 'currency.js';

const { Company, MyProduct } = models.api;

export default defineComponent({
  props: {
    /**
         * Manipulate the way field's value displays
         */
    part: {
      type: Object,
      default: () => {
      }
    },
  },

  setup(props, context) {
    const t = context.root.$t;

    const company = computed(() => Company.getFromStore(0));

    const currentUser = computed(() => context.root.$store.getters['auth/user']);

    const partViewerPart = ref({});

    const isPartViewerActive = ref(false);

    const currencySymbol = computed(() => _.get(company.value, 'currencySymbol', '$'));

    const defaultQty = computed(() => Math.min(...props.part.PriceMOQ.map(o => o.Qty)));

    const ReqQty = ref(defaultQty.value);

    const deleteDialog = ref({
      display: false,
      partToDelete: new MyProduct(),
    });

    const currentOrder = computed(() => context.root.$store.state.app.currentOrder);

    const getRoughnessName = computed(() => {
      switch (props.part.Roughness) {
        case '6.0':
          return context.root.$t('views.myParts.roughnessMilledName');
        case '3.2':
          return context.root.$t('views.myParts.roughnessStandardName');
        case '1.6':
          return context.root.$t('views.myParts.roughnessFineName');
        case '0.8':
          return context.root.$t('views.myParts.roughnessGradeName');
        default:
          return 'N/A';
      }
    });

    const getRoughnessValue = computed(() => {
      switch (props.part.Roughness) {
        case '6.0':
          return context.root.$t('views.myParts.roughnessMilledValue');
        case '3.2':
          return context.root.$t('views.myParts.roughnessStandardValue');
        case '1.6':
          return context.root.$t('views.myParts.roughnessFineValue');
        case '0.8':
          return context.root.$t('views.myParts.roughnessGradeValue');
        default:
          return '';
      }
    });

    const sortByQuantity = part => {
      if (part.PriceMOQ) {
        const priceMoq = JSON.parse(part.PriceMOQ);
        return priceMoq.sort((a, b) => b.Qty - a.Qty).map(moq => ({
          ...moq,
          Price: currency(moq.Price, { symbol: currencySymbol.value }).format(),
        }));
      }
      return [];
    };

    const priceRange = part => {
      try {
        const parsedData = part.PriceMOQ;
        const first = parsedData[0];
        const last = parsedData.pop();
        return `${currency(last.Price, { symbol: currencySymbol.value }).format()} - ${currency(first.Price, { symbol: currencySymbol.value }).format()}`;
      } catch {
        return context.root.$t('common.noDataAvailable');
      }
    };

    const isExpired = () => moment().diff(props.part.ExpireDate, 'days') > 0;

    const refreshMyPartPrice = async part => {
      const query = {
        query: {
          shouldUpdatePrice: true,
        },
        notificationMessage: context.root.$t('views.myParts.refreshPriceSuccessMessage'),
      };
      await part.save(query);
    };

    const itemRowBackground = part => (this.isExpired(part) ? 'expired-row' : null);

    const cancelDelete = () => {
      deleteDialog.value.display = false;
      deleteDialog.value.partToDelete = new MyProduct();
    };

    const addToOrder = async () => {
      // eslint-disable-next-line no-underscore-dangle
      const order = await context.root.$store._actions['app/initCurrentOrder'][0]();
      order.addItem('myproduct', props.part._id, ReqQty.value);
    };

    const removeToOrder = part => {
      if (currentOrder.value) {
        currentOrder.value.items = currentOrder.value.items.filter(item => item._id !== part._id);
      }
    };

    const calculatePrice = computed(() => part => {
      if (!part.PriceMOQ) {
        return currency(0, { symbol: currencySymbol.value });
      }

      let priceMoq;
      if (typeof part.PriceMOQ === 'string') {
        priceMoq = JSON.parse(part.PriceMOQ);
      } else {
        priceMoq = [...part.PriceMOQ];
      }

      const priceMOQ = [...priceMoq].sort(({ Qty: a }, { Qty: b }) => b - a);

      const findPrice = priceMOQ.find(({ Qty }) => Qty <= ReqQty.value) || Math.min(...priceMOQ.map(o => o.Qty));

      if (!findPrice) {
        return currency(0, { symbol: currencySymbol.value });
      }

      return currency(findPrice.Price, { symbol: currencySymbol.value });
    });

    const updateValue = () => event => {
      if (parseInt(event.target.value, 10) <= parseInt(event.target.min, 10)) {
        return event.target.min;
      }
      if (parseInt(event.target.max, 10) < parseInt(event.target.value, 10)) {
        return event.target.max;
      }
      return event.target.value;
    };

    const isPartPresentInCurrentOrder = computed(() => part => {
      if (currentOrder.value === null) {
        return false;
      }

      const currentPart = currentOrder.value.items.findIndex(item => item._id === part._id);

      return currentPart !== -1;
    });

    // const need2dDrawing = computed(() => props.part.Machine === 'turning');
    const need2dDrawing = computed(() => props.part.Machine === 'sheet-metal' && props.part.CustFileName.split('.').pop().toUpperCase() === 'DXF');

    const needSvg = computed(() => props.part.Machine === 'sheet-metal' && props.part.LaserEngraving === '1');

    return {
      sortByQuantity,
      removeToOrder,
      addToOrder,
      cancelDelete,
      itemRowBackground,
      refreshMyPartPrice,
      isExpired,
      priceRange,
      deleteDialog,
      calculatePrice,
      currencySymbol,
      currentUser,
      isPartPresentInCurrentOrder,
      t,
      defaultQty,
      ReqQty,
      isPartViewerActive,
      partViewerPart,
      updateValue,
      getRoughnessName,
      getRoughnessValue,
      need2dDrawing,
      needSvg,
    };
  },
});
</script>

<style scoped>

</style>
