import Store from './index';
import i18n from '../plugins/i18n';
import { reset } from '../utils/vuex';
import { makeAuthPlugin } from '../plugins/feathers-client';

export default {
  plugin: makeAuthPlugin({
    userService: 'users',
  }),

  store: {
    auth: {
      namespaced: true,

      mutations: {
        clearAll: reset(),
      },

      actions: {
        async login({ dispatch }, { user }) {
          await dispatch('auth/authenticate', {
            strategy: 'local',
            ...user,
          }, { root: true });

          const locale = (Store.state.auth.user.locale === 'cn') ? 'cn' : 'en-US';
          await Store.dispatch('app/setLocale', { $i18n: i18n, locale });
        },
      },
    },
  },
};
