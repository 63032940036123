import i18n from '../plugins/i18n';

export default {
  methods: {
    isRequired: value => !!value || i18n.t('common.thisFieldIsRequired'),
    isPositiveInteger: value => /^[1-9][0-9]*$/.test(value),
    isPositiveIntegerOrFloat: value => /^(0|[1-9]\d*)(\.\d+)?$/.test(value),
    isZeroOrGreaterInteger: value => /^[0-9][0-9]*$/.test(value),
    isEmail: value => /^[A-Za-z.0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z.0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z.0-9](?:[A-Za-z.0-9-]*[A-Za-z.0-9])?\.)+[A-Za-z.0-9](?:[A-Za-z.0-9-]*[A-Za-z.0-9])?/.test(value),
    maxLength(maxlen) {
      return v => (v || '').length <= maxlen || i18n.t('common.errorStringLength', { maxlength: maxlen });
    },
    containAlphabetsNumbersHyphensUnderscoreSpace: value => /^[a-zA-Z0-9-_\s]*$/.test(value) || i18n.t('common.containAlphabetsNumbersHyphensUnderscoreSpace'),
    isPhoneNumberIsValid: value => /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/.test(value)
  }
};
