<template>
  <v-card>
    <v-card-title
      class="py-1"
      style="background-color: #4fb052; color: white;"
    >
      {{ _.get(partDesignData[0], 'value') }}
    </v-card-title>

    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr
            v-for="(item, i) in partDesignData.slice(1)"
            :key="item.field"
            :class="{'dark': (i % 2 !== 0)}"
          >
            <td style="display: flex; align-items: center;">
              <img
                :src="`/img/${item.icon}.svg`"
                class="mr-1"
                width="21px"
                height="21px"
              >
              {{ item.field }}
            </td>
            <td>
              <span :style="[{ color: getTextColor(item.value) }]">
                {{ item.value }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: 'PartDesignTable',
  props: {
    part: Object,
  },
  computed: {
    partDesignData() {
      let tableData = [];

      const analysisData = _.get(this.partViewerPart, 'CsiAnalysisData', []);

      if (_.isEmpty(analysisData)) {
        tableData = [
          { field: null, value: 'Parts Design Data' },
          { field: 'Net Weight (kg)', value: '-' },
          { field: 'Gross Weight (kg)', value: '-' },
          { field: 'Material usage (%)', value: '-' },
          { field: '# of Fabrication Processes', value: '-' },
          { field: 'Quantity / sheet', value: '-' },
          { field: 'Material', value: '-' },
        ];
      } else {
        tableData = analysisData.filter(datum => datum.Category === 'T2')
          .sort((datum1, datum2) => +datum1.Rank - +datum2.Rank)
          .map(datum => ({ field: datum.Description, value: datum.Value }));
      }

      const icons = [
        'dumbbell',
        'dumbbell',
        'lightning-bolt',
        'gear',
        'scale',
        'material',
      ];
      return tableData.map((row, index) => ({ ...{ icon: icons[index - 1] }, ...row }));
    },
  },
  methods: {
    getTextColor(value) {
      switch (value) {
        case 'Pass':
        case 'Yes':
          return 'green';
        case 'Fail':
        case 'No':
          return 'red';
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-label  {
  font-size: 12px !important
}
::v-deep .v-input__slot {
  padding: 0 !important;
}
::v-deep .v-input__slot label{
  margin-top: 2px !important;
}
</style>
