<template>
  <v-card
    hover
    height="100%"
    width="510px"
    min-width="510px"
    :loading="part.isRemovePending"
    class="ma-1 px-2 fill-height flex-column align-content-space-between partCard"
    data-cy="my-part-card-grid"
    :ripple="false"
  >
    <v-row no-gutters data-cy="my-part-first-horizontal-grid-view">
      <v-col cols="2" class="py-0 pl-1 d-flex flex-row">
        <div
          class=" d-flex flex-column "
          @click="partViewerPart = part; isPartViewerActive = true;"
        >
          <core-thumbnail-viewer
            :part="part"
            :width="90"
            :height="90"
            class="pa-0"
            data-cy="my-part-image-grid"
            :is-display-border="true"
          />
        </div>
      </v-col>
      <v-col cols="10">
        <v-row no-gutters class="pl-7">
          <v-col cols="11" align-self="start" class="pa-0">
            <div class="flex-row text-truncate">
              <span
                class="text-truncate"
                style="font-size: 25px;font-weight: 500;"
                data-cy="my-part-part-grid"
                :title="part.CustItemName"
              >
                {{ part.CustItemName }}
              </span>
            </div>
          </v-col>

          <v-col cols="1" align-self="start" class="pa-0">
            <v-btn
              right
              class="align-self-start ml-auto"
              :loading="part.isRemovePending"
              color="primary"
              icon
              data-cy="my-part-remove-button-grid"
              @click="
                deleteDialog.display = true;
                deleteDialog.partToDelete = part;"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col offset="6" cols="6" class="pl-7 d-flex justify-end pr-0">
            <core-rang-price-moq class="ml-auto align-content-end" :part="part" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-row class="px-3 flex-nowrap" style="width:100%">
        <v-col cols="12" class="pb-0 flex-nowrap">
          <div class="caption text--secondary ">
            {{ $t('common.description') }} &nbsp; &nbsp; &nbsp;
          </div>
          <div data-cy="my-part-finish-grid">
            {{ part.CustItemDesc === '' || part.CustItemDesc === null ? '-' : part.CustItemDesc }}
          </div>
        </v-col>
      </v-row>
      <v-row class="px-3">
        <v-col cols="3" class="text-truncate pt-2 pb-0">
          <div style="width: 45px">
            <div class="caption text--secondary">
              {{ $t('views.hardwareCategory.barcode') }}
            </div>
            <span>
              <span data-cy="my-part-tolerances-grid">
                {{ part.BarCode || '-' }}
              </span>
            </span>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex justify-center pt-1 pb-0">
          <core-bag-items-list :part="part.Items" />
        </v-col>
        <v-spacer />
        <v-col cols="3" class="pb-0">
          <v-text-field
            v-model.number="ReqQty"
            :min="part.defaultQuantity"
            :max="9999999"
            class="d-inline-block"
            data-cy="quantity-input-upload"
            :label="$t('views.rfqs.quantity')"
            type="number"
            hide-details
            outlined
            dense
            @blur="ReqQty < Math.min(...part.PriceMOQ.map(o => o.Qty)) ? ReqQty = Math.min(...part.PriceMOQ.map(o => o.Qty)) : ReqQty = ReqQty;"
            @input.native="part.qtyOfPart = updateValue($event)"
            @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
          />
        </v-col>

        <v-col align-self="start" cols="3" class="pb-0 pt-2">
          <v-sheet height="48px" :color="isExpired() ? '#FBE9EC': '#f0fdf3' " class="pa-2 py-0 rounded-lg">
            <span class="caption text--secondary text-left">
              {{ $t('views.productOption.totalPrice', {currencySymbol: currencySymbol}) }}
            </span>
            <span class="mt-2" :style="[isExpired() ? {'color': '#be2a45'} : {'color': ''}]">
              {{ calculatePrice(part).multiply(ReqQty) }}
            </span>
          </v-sheet>
        </v-col>
      </v-row>
    </v-row>

    <v-row dense>
      <v-col style="height: 100%">
        &nbsp;
      </v-col>
    </v-row>
    <v-card-actions class="pa-0 pt-2 align-end" data-cy="my-part-card-actions-grid">
      <v-tooltip right data-cy="my-part-information-tooltip-grid">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            color="primary"
            data-cy="my-part-information-grid"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span class="white--text" data-cy="my-part-user-grid">
          {{ part.PortalUserId }}
        </span>
        <br>
        <span data-cy="my-part-expiration-date-grid">
          {{
            isExpired() ? 'Is expired since' : $t('common.validUntil')
          }}:   {{ moment(part.ExpireDate).format('YYYY-MM-DD') }}
        </span>
      </v-tooltip>
      <v-spacer />

      <template v-if="_.get(currentUser, 'isAdmin') || _.get(currentUser, ['rights', 'placeOrder'])">
        <template v-if="isExpired(part)">
          <v-btn
            :loading="part.isSavePending"
            color="primary"
            data-cy="refresh-quote-button-grid"
            @click="refreshMyPartPrice(part)"
          >
            <v-icon left>
              mdi-autorenew
            </v-icon>
            {{ t('views.myParts.refreshQuote') }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            v-if="isPartPresentInCurrentOrder(part)"
            color="primary"
            class="ml-6"
            @click.stop="removeToOrder(part)"
          >
            {{ $t('views.productOption.removeToOrder') }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            class="ml-6"
            @click.stop="addToOrder"
          >
            {{ $t('views.productOption.addToOrder') }}
          </v-btn>
        </template>
      </template>
    </v-card-actions>
    <core-dialog
      v-if="deleteDialog.display"
      v-model="deleteDialog.display"
      type="confirmation"
      width="500px"
      data-cy="delete-dialogue"
      :ok-button-text="$t('common.delete')"
      @click:ok="
        deleteDialog.display = false; part.remove() ; removeToOrder(part)"
      @click:cancel="cancelDelete"
    >
      <template #title>
        {{ $t('views.myParts.deleteDialogBagTitle') }}
      </template>
      <template #content>
        <div class="pa-10 text-center">
          <span class="d-block">
            <span
              class="d-block"
              data-cy="delete-dialogue-2"
              v-html="$t('common.deleteDialogText', { name: deleteDialog.partToDelete.CustItemName })"
            />
          </span>
        </div>
      </template>
    </core-dialog>
  </v-card>
</template>
<script>
import { models } from 'feathers-vuex';

import {
  computed, defineComponent, ref,
} from '@vue/composition-api';
import currency from 'currency.js';

const { Part, Company, HardwareProduct } = models.api;

export default defineComponent({
  props: {
    /**
     * Manipulate the way field's value displays
     */
    part: {
      type: Object,
      default: () => {
      }
    },
  },

  setup(props, context) {
    const t = context.root.$t;

    const currentProduct = ref({});

    const company = computed(() => Company.getFromStore(0));

    const currentUser = computed(() => context.root.$store.getters['auth/user']);

    const currencySymbol = computed(() => _.get(company.value, 'currencySymbol', '$'));

    const defaultQty = computed(() => Math.min(...props.part.PriceMOQ.map(o => o.Qty)));

    const ReqQty = ref(defaultQty.value);

    const deleteDialog = ref({
      display: false,
      partToDelete: new Part(),
    });

    const currentOrder = computed(() => context.root.$store.state.app.currentOrder);

    const isExpired = () => moment().diff(props.part.ExpireDate, 'days') > 0;

    const refreshMyPartPrice = async part => {
      const query = {
        query: {
          shouldUpdatePrice: true,
        },
        notificationMessage: context.root.$t('views.myParts.refreshPriceSuccessMessage'),
      };

      await part.save(query);
    };

    const itemRowBackground = part => (this.isExpired(part) ? 'expired-row' : null);

    const cancelDelete = () => {
      deleteDialog.value.display = false;
      deleteDialog.value.partToDelete = new Part();
    };

    const addToOrder = async () => {
      // eslint-disable-next-line no-underscore-dangle
      const order = await context.root.$store._actions['app/initCurrentOrder'][0]();

      order.addItem('myproduct', props.part._id, ReqQty.value);
    };

    const removeToOrder = part => {
      if (currentOrder.value) {
        currentOrder.value.items = currentOrder.value.items.filter(item => item._id !== part._id);
      }
    };

    const calculatePrice = computed(() => part => {
      if (!part.PriceMOQ) {
        return currency(0, { symbol: currencySymbol.value });
      }

      let priceMoq;
      if (typeof part.PriceMOQ === 'string') {
        priceMoq = JSON.parse(part.PriceMOQ);
      } else {
        priceMoq = [...part.PriceMOQ];
      }

      const priceMOQ = [...priceMoq].sort(({ Qty: a }, { Qty: b }) => b - a);

      const findPrice = priceMOQ.find(({ Qty }) => Qty <= ReqQty.value) || Math.min(...priceMOQ.map(o => o.Qty));

      if (!findPrice) {
        return currency(0, { symbol: currencySymbol.value });
      }

      return currency(findPrice.Price, { symbol: currencySymbol.value });
    });

    const isPartPresentInCurrentOrder = computed(() => part => {
      if (currentOrder.value === null) {
        return false;
      }

      const currentPart = currentOrder.value.items.findIndex(item => item._id === part._id);

      return currentPart !== -1;
    });

    const getProductDescription = id => HardwareProduct.getFromStore(id).description;

    const removeBag = async () => {
      currentProduct.value = new Part({ ...props.part });

      currentProduct.value.remove({ shouldShowNotification: true });
    };

    const updateValue = () => event => {
      if (parseInt(event.target.value, 10) <= parseInt(event.target.min, 10)) {
        return event.target.min;
      }
      if (parseInt(event.target.max, 10) < parseInt(event.target.value, 10)) {
        return event.target.max;
      }
      return event.target.value;
    };

    return {
      removeToOrder,
      addToOrder,
      cancelDelete,
      itemRowBackground,
      refreshMyPartPrice,
      isExpired,
      deleteDialog,
      calculatePrice,
      currencySymbol,
      currentUser,
      isPartPresentInCurrentOrder,
      t,
      ReqQty,
      getProductDescription,
      removeBag,
      currentProduct,
      updateValue
    };
  },
});
</script>

<style scoped>

</style>
