<template>
  <v-card>
    <v-toolbar
      color="primary"
      class="mb-4"
      flat
    >
      <v-card-title class="white--text">
        Role
      </v-card-title>
    </v-toolbar>

    <v-card-text>
      <v-col class="py-0">
        <v-container>
          <v-row>
            <v-col
              class="py-0"
              md="5"
              cols="12"
            >
              <v-text-field
                v-model="localValue.name"
                :label="$t('common.name')"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="5"
            >
              <v-select
                v-model="localValue.rights"
                :label="$t('form.role.fields.rights')"
                :items="rightsWithDescriptions"
                item-text="label"
                item-value="value"
                chips
                clearable
                small-chips
                deletable-chips
                multiple
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="2"
            >
              <v-checkbox
                v-model="localValue.inactive"
                :label="$t('common.inactive')"
              />
            </v-col>
          </v-row>
        </v-container>

        <slot />
      </v-col>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        color="primary"
        :disabled="!originalFormDataChanged || loading"
        :loading="loading"
        @click="$emit('save', localValue)"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import FormMixin from '../../mixins/form';

export default {
  mixins: [
    FormMixin,
  ],

  props: {
    loading: Boolean,
  },

  computed: {
    ...mapState('app', ['rights']),

    /**
     * Returns all rights descriptions.
     */
    rightsWithDescriptions() {
      return this.rights.map(right => ({ value: right, label: this.$t(`rights.${right}`) }));
    },
  },
};
</script>
