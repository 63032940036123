<template>
  <v-container class="fill-height px-5" style="min-width: 1190px; max-width: 1400px; max-height: 100%">
    <v-row class="fill-height">
      <v-col
        class="rounded-xl"
        style="z-index:99; background-color: white;height: 100%; min-width: 550px;"
        cols="6"
      >
        <v-row>
          <v-col
            style="width:100%"
            class="d-flex flex-column align-center mt-10"
          >
            <div class="d-flex justify-center">
              <img src="/img/Komacut-logo.svg" width="180px">
            </div>
            <span class="text-h5 font-weight-bold mt-8" data-cy="email-sent-message">
              {{ $t('views.signUp.EmailSent') }}
            </span>
            <span class="mt-4">{{ $t('views.signUp.EmailSendInstruction') }}</span>
            <v-card
              class="transparent d-flex justify-center align-center mt-16"
              flat
            >
              <v-card-text>
                <img
                  src="/img/img_mailsent@3x.png"
                  height="300px"
                >
              </v-card-text>
            </v-card>
            <v-btn
              class="my-16"
              color="primary"
              depressed
              data-cy="go-to-login-button"
              @click="$router.push('/login')"
            >
              {{ $t('views.accountActivation.goToLogin') }}
            </v-btn>
          </v-col>
        </v-row>
        <p class="text--secondary caption text-center mb-0" style="margin-top: 20px !important">
          {{ $t('common.footerRight') }} <a
            href="https://komacut.com/about-us/contact-us/"
            class="text-decoration-none"
          > {{ $t('common.contactUs') }}</a>
        </p>
      </v-col>
      <v-col cols="6" class="pl-0 d-flex justify-center align-center">
        <core-promotional />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

  mounted() {
    window.dataLayer.push({
      event: 'Enhanced_signup',
      enhanced_conversion_data: {
        email: this.$route.params.id
      }
    });
  }
};

</script>
