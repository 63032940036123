<template>
  <v-card-text :class="['ownerships', 'mt-1', 'pa-0', alignLeft ? 'left' : '']">
    <span v-if="value.createdBy" class="created">
      <span>{{ $t('common.createdBy') }}</span>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-avatar :class="{ avatar: true, small: !large }" v-on="on">
            <!--            <v-gravatar :email="value.createdBy" />-->
          </v-avatar>
        </template>
        <span>{{ value.createdBy }}</span>
      </v-tooltip>
      {{ moment().to(moment(value.createdAt)) }}
    </span>

    <span v-if="value.updatedBy" class="updated">
      <span v-if="value.createdBy" class="ownershipsSeparator">-</span>
      <span>{{ $t('common.updatedBy') }}</span>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-avatar :class="{ avatar: true, small: !large }" v-on="on">
            <v-gravatar :email="value.updatedBy" />
          </v-avatar>
        </template>
        <span>{{ value.updatedBy }} {{ value.updatedBy }}</span>
      </v-tooltip>
      {{ moment().to(moment(value.updatedAt)) }}
    </span>
  </v-card-text>
</template>

<script>

import { mapState } from 'vuex';

export default {
  props: {
    value: Object,
    large: Boolean,
    alignLeft: Boolean,
  },
  computed: {
    ...mapState('auth', ['users']),
  }
};
</script>

<style scoped>
.ownerships {
  color: rgba(0, 0, 0, .54);
  width: 100%;
  text-align: end;
}

.ownerships.left {
  margin-left: -6px;
  text-align: start;
}

.created,
.updated {
  font-weight: normal;
  font-size: smaller;
  font-style: oblique;
}

.ownershipsSeparator {
  margin: 0 .5em;
}

.avatar {
  margin: 0em .5em !important;
  width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
}

.avatar.small {
  width: 16px !important;
  min-width: 16px !important;
  height: 16px !important;
}
</style>
