<template>
  <v-container fluid class="partCard pa-1">
    <v-row class="flex-nowrap" no-gutters>
      <v-col
        v-if="productGroup.HideDrawingsLink !== 1"
        cols="auto"
        class="pt-1"
        style="width: 140px"
        align-self="start"
      >
        <v-btn
          depressed
          text
          color="primary"
          data-cy="drawing-table-btn"
          @click="isDrawingsDialogOpen = true"
        >
          <v-icon
            left
          >
            mdi-vector-square
          </v-icon>
          {{ $t('views.productOption.drawings') }}
        </v-btn>
      </v-col>
      <v-col>
        <v-row no-gutters>
          <template v-for="(productOption, index) in productOptions">
            <v-col
              v-if="productOption.attributeTypes[0].fieldType === 'dropdown'"
              :key="`dropdown-${productOption.id}`"
              cols="auto"
              class="px-1 pb-2"
              align-self="end"
            >
              <v-select
                v-model="selectedOption[productOption.id]"
                dense
                attach
                class="fit"
                persistent-placeholder
                :no-data-text="$t('common.noDataAvailableListType')"
                :label="productOption[locale === 'en-US' ? 'label' : 'labelChs' ]"
                :items="filteredItems(index)"
                :item-text="locale === 'cn' ? 'textChs' : 'text'"
                :data-cy="`dropdown-${productOption.id}`"
                :data-cy-2="`${productOption.label.toLowerCase().replaceAll(' ', '-')}-select`"
                item-value="id"
                outlined
                hide-details
                @click.stop
              >
                <template slot="item" slot-scope="data">
                  <span data-cy="diameter-option">{{ locale === 'en-US' ? data.item.text : data.item.textChs }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-else-if="productOption.attributeTypes[0].fieldType === 'checkbox'"
              :key="`checkbox-${productOption.id}`"
              class="pl-0"
              align-self="end"
            >
              <v-checkbox
                v-model="selectedOption[productOption.id]"
                :label="productOption[locale === 'cn' ? 'labelChs' : 'label' ]"
                :true-value="productOption.options[1].id"
                :false-value="productOption.options[0].id"
                :data-cy="`checkbox-${productOption.id}`"
                hide-details
                @click.stop
              />
            </v-col>
            <v-col
              v-if="productOption.attributeTypes[0].fieldType === 'readonly'"
              :key="`plainText-${productOption.id}`"
              :data-cy="`plainText-${productOption.id}`"
            >
              <div
                style="color:rgba(0,0,0,0.6);"
                class="caption"
              >
                {{ productOption[locale === 'cn' ? 'labelChs' : 'label'] }}
              </div>
              <div>
                {{
                  getReadOnlyProduct[locale === 'cn' ? 'textChs' : 'text']
                }}
              </div>
            </v-col>
          </template>
        </v-row>
      </v-col>

      <v-col cols="auto">
        <core-rang-price-moq
          v-if="productGroup.HidePriceMOQ !== 1"
          class="ml-auto mt-auto align-content-end align-self-end"
          :part="selectedProduct"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="flex-nowrap">
      <v-col
        style="width:140px"
        align-self="end"
        cols="auto"
        class="pr-2"
      >
        <v-text-field
          v-model="reqQty"
          class="mb-0"
          dense
          data-cy="quantity-input-upload"
          :label="$t('views.rfqs.quantity')"
          :rules="[isPositiveInteger]"
          min="1"
          max="9999999"
          type="number"
          hide-details
          outlined
          @input.native="reqQty = updateValue($event); "
          @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
          @click.stop
        />
      </v-col>
      <v-col align-self="end" cols="auto" class="pr-2">
        <v-sheet color="#F1FDF3" class="pa-2 py-0 rounded-lg ">
          <div class="caption text--secondary text-left">
            {{ $t('views.productOption.unitPrice', {currencySymbol: currencySymbol}) }}
          </div>
          <span>
            {{ (UnitPrice * 1).toFixed(5) }}
          </span>
        </v-sheet>
      </v-col>
      <v-col align-self="end" cols="auto" class="pr-2">
        <v-sheet color="#F1FDF3" class="pa-2 py-0 rounded-lg ">
          <div class="caption text--secondary text-left">
            {{ $t('views.productOption.totalPrice', {currencySymbol: currencySymbol}) }}
          </div>
          <span>
            {{ (UnitPrice * reqQty).toFixed(2) }}
          </span>
        </v-sheet>
      </v-col>
      <v-col
        v-if="productGroup.ShowUploadFile === 1"
        align-self="end"
        cols="auto"
        class="pr-2"
      >
        <core-drawing-consultancy-pdf
          :pdf-url="drawingConsultancyPdfBlobUrl"
          @pdfUrl="(event) => drawingConsultancyPdfBlobUrl = event"
        />
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        align-self="end"
        class="pr-2 text-right"
      >
        <v-btn
          v-if="productGroup.HideAddToBag !== 1"
          outlined
          color="primary"
          :disabled="!selectedProduct || !reqQty"
          data-cy="add-product-to-custom-bag"
          @click.stop="$emit('add-to-bag', {product:selectedProduct, quantity: reqQty})"
        >
          {{ $t('views.productOption.addToCustomBag') }}
        </v-btn>
      </v-col>
      <v-col align-self="end" class="text-left" cols="auto">
        <v-btn
          color="primary"
          data-cy="add-product-to-order"
          :disabled="!canOrder"
          @click.stop="addToOrder()"
        >
          {{ $t('views.productOption.addToOrder') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="selectedProduct && reqQty < minQty" class="flex-nowrap" no-gutters>
      <v-col class="pa-0 pt-1" cols="12">
        <v-alert
          dense
          color="#f8f2ec"
          class="text-center mb-0 mr-0"
          data-cy="minimum-order-message"
        >
          <v-icon
            color="#C77500"
          >
            mdi-alert-outline
          </v-icon>
          {{ $t('common.toAddToOrderMinimum', {qty: minQty}) }}
        </v-alert>
      </v-col>
    </v-row>

    <v-dialog
      style="height: 1000px !important; overflow-y: hidden !important; "
      :value="isDrawingsDialogOpen"
      width="unset"
      scrollable
      class="overflow-hidden pa-0"
    >
      <v-card class="pa-0">
        <v-card-title class="pb-0">
          <v-container fluid class="fill-height pa-0">
            <v-row>
              <v-col align-self="end" cols="10">
                <div class="text-h5 text-left font-weight-bold">
                  <core-hardware-thumbnail-viewer
                    :image-id="productGroup.imageId"
                    :width="50"
                    :height="50"
                  />
                  {{ productGroup[locale === 'cn' ? 'textChs' : 'text'] }}
                </div>
              </v-col>
              <v-spacer />
              <v-col align-self="start" cols="1">
                <v-btn
                  icon
                  data-cy="close-drawing-table"
                  @click="isDrawingsDialogOpen = false;"
                >
                  <v-icon large>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text class="overflow-hidden px-6 pt-0" style="height: 1000px !important; ">
          <core-hardware-drawing-table
            :product-group-key="productGroupKey"
            :product-group-id="productGroupId"
            :product-options="productOptions"
            :product-query="pQuery"
            :product-selected="selectedProduct"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapActions, mapState, mapGetters } from 'vuex';
import currency from 'currency.js';
import FormRules from '@/mixins/form-rules';

export default {
  name: 'ProductOption',

  mixins: [
    FormRules,
  ],

  props: {
    productGroupId: {
      type: String,
      default: ''
    },
    productGroupKey: {
      type: String,
      default: ''
    },
    productOptions: {
      type: Array,
      default: () => []
    },
    productQuery: {
      type: Object,
      default: () => {
      },
    },
    productGroup: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      priceProduct: {},
      isDrawingsDialogOpen: false,
      selectedOptions: {},
      reqQty: 1,
      customBag: [],
      currency,
      forceUpdateField: Date.now(),
      drawingConsultancyPdfBlobUrl: null,
    };
  },

  computed: {
    ...mapState('app', ['locale']),
    ...mapGetters('app', ['currentOrder']),
    HardwareProduct: () => models.api.HardwareProduct,

    minQty: vm => {
      if (vm.selectedProduct.PriceMOQ.length === 0) {
        return 1;
      }
      return Math.min(...vm.selectedProduct.PriceMOQ.map(o => o.Qty));
    },

    filteredItems: vm => index => {
      const currentOption = vm.productOptions[index];
      if (!currentOption) {
        return [];
      }

      const optionsFilter = vm.productOptions.slice(0, index).map(o => ({ [o.id]: vm.selectedOptions[o.id] || '' }));
      // Please figure what it does, it's cool !
      const query = {
        query: {
          ...vm.pQuery.query,
          ...Object.fromEntries(optionsFilter.map(value => Object.entries(value)[0]))
        }
      };

      const productValue = vm.HardwareProduct.findInStore(query).data;

      if (productValue.length === 0) {
        return [];
      }

      const res = productValue.map(o => (o[currentOption.id]));
      if (res.length === 0) {
        return [];
      }

      return res.map(value => currentOption.options.find(option => option.id === value)) || [];
    },

    pQuery: vm => {
      const query = {
        query: {
          ...vm.productQuery.query,
          [vm.productGroupKey]:
          vm.productGroupId
        }
      };
      return query;
    },

    filteredProduct: vm => vm.HardwareProduct.findInStore(vm.pQuery).data,
    // const query = {
    //   query: {
    //     ...vm.pQuery.query,
    //   }
    // };

    // vm.HardwareProduct.findInStore(vm.pQuery).data,

    selectedProduct: vm => {
      const query = {
        query: {
          ...vm.pQuery.query,
          ...vm.selectedOptions
        }
      };

      const product = vm.HardwareProduct.findInStore(query).data;
      if (product.length === 0 || product.length > 1) {
        return null;
      }

      // if (product[0]) {
      //   this.priceProduct = { ...product[0], ReqQty: vm.reqQty };
      // }
      return product[0];
    },

    UnitPrice: vm => {
      if (!vm.selectedProduct) {
        return currency(0, { symbol: vm.currencySymbol, precision: 2 });
      }
      const priceTable = [...vm.selectedProduct.PriceMOQ];
      const findPrice = priceTable.find(({ Qty }) => vm.reqQty <= Qty) || priceTable[0];
      if (!findPrice) {
        return currency(0, { symbol: vm.currencySymbol, precision: 2 });
      }
      return findPrice.Price;
    },

    updateValue: () => event => {
      if (parseInt(event.target.value, 10) <= parseInt(event.target.min, 10)) {
        return event.target.min;
      }
      if (parseInt(event.target.max, 10) < parseInt(event.target.value, 10)) {
        return event.target.max;
      }
      return event.target.value;
    },

    productReadOnlyOptions: vm => (vm.productOptions ? vm.productOptions
      .filter(option => option.attributeTypes[0].fieldType === 'readonly') : []),

    productCheckBoxOptions: vm => (vm.productOptions ? vm.productOptions
      .filter(option => option.attributeTypes[0].fieldType === 'checkbox') : []),

    getReadOnlyProduct: vm => {
      const product = vm.HardwareProduct.findInStore(vm.pQuery).data[0];
      if (!product) {
        return {};
      }

      return vm.productReadOnlyOptions[0].options.find(value => value.id === product[vm.productReadOnlyOptions[0].id]) || {};
    },

    selectedOption: vm => {
      if (vm.HardwareProduct.store.state['hardware-products'].ids.length > 0) {
        vm.productOptions.forEach((fil, index) => {
          if (!vm.selectedOptions[fil.id]) {
            if (['readonly', 'checkbox'].includes(fil.attributeTypes[0].fieldType)) {
              vm.$set(vm.selectedOptions, fil.id, vm.filteredProduct[0][fil.id]);
            } else if (vm.filteredItems(index).length === 1) {
              vm.$set(vm.selectedOptions, fil.id, vm.filteredItems(index)[0].id);
            } else {
              vm.$set(vm.selectedOptions, fil.id, '');
            }
          }
        });
      }

      return vm.selectedOptions;
    },
    currencySymbol() {
      return _.get(models.api.Company.getFromStore(0), 'currencySymbol', '$');
    },
    canOrder() {
      return !(!this.selectedProduct || this.reqQty < this.minQty || (this.productGroup.ShowUploadFile === 1 && !this.drawingConsultancyPdfBlobUrl));
    }
  },

  watch: {
    currentOrder(value) {
      // Clear drawing consultancy pdf document when currentOrder is Null
      if (!value) {
        this.drawingConsultancyPdfBlobUrl = '';
      }
    },
  },

  methods: {
    ...mapActions('app', ['initCurrentOrder']),

    async addToOrder() {
      const order = await this.initCurrentOrder();
      order.addItem('hardware', this.selectedProduct._id, this.reqQty, this.drawingConsultancyPdfBlobUrl);
    }
  }
};
</script>

<style scoped>
>>> .v-select.fit {
  width: max-content;
  min-width: 120px;
}

>>> .v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}

>>> .v-input__slot {
  margin-bottom: 0px !important;
}

>>> .v-dialog {
  max-height: 95%;
  min-height: 80vh;
}

.totalPrice.v-text-field--outlined >>> fieldset {
  border-color: transparent;
}

notDisplay {
  visibility: hidden;
}

.partCard:hover .displayDetail {
  visibility: visible;
  display: inline;
  position: relative;
}

.partCard:hover .shimmer {

  background: var(--color) -webkit-gradient(linear, 100% 0, 0 0,
  from(var(--color)), color-stop(0.5, #ffffff),
  to(var(--color)));

  background-position: -4rem top; /*50px*/
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation-name: shimmer;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-background-size: 4rem 100%; /*50px*/
  -webkit-animation-timing-function: linear;
}

.colorRed {
  color: #be2a45 !important;
  --color: #be2a45 !important
}

.colorGreen {
  color: #128347 !important;
  --color: #128347 !important
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -4rem top; /*50px*/
  }

  70% {
    background-position: 12.5rem top; /*200px*/
  }

  100% {
    background-position: 12.5rem top; /*200px*/
  }
}

</style>
