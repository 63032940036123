<template>
  <v-container class="pa-0">
    <v-menu
      data-cy="my-part-second-horizontal-grid-view"
      open-on-hover
      bottom
      offset-x
      left
      z-index="9999999"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
          v-bind="attrs"
          tag="p"
          min-width="180px"
          min-height="41px"
          :color="isExpired(part) ? '#fbe9ec' : '#f0fdf3'"
          data-cy="my-part-price-range-box-grid"
          class="d-inline-block font-weight-medium mb-0 pa-1"
          style="border-radius:5px"
          v-on="on"
        >
          <template v-if="isExpired()">
            <div class="mt-n1 text-center">
              <span
                style="font-size: 13px"
                class="flex-nowrap align-center"
              >
                {{ $t('common.unitPriceVariation') }}
              </span>
            </div>

            <div class="mt-n4">
              <span
                v-if="isExpired()"
                style="color:#be2a45; font-size:26px"
                class="mdi  mdi-alert-circle rounded d-inline-flex align-center text-left ml-1"
              />
              <span
                class="shimmer font-weight-bold ma-0 caption text-center"
                style="font-size: 15px"
                data-cy="my-part-price-range-grid"
                :class="{ 'colorRed': isExpired(part), 'colorGreen': !isExpired(part) }"
              >
                <span class="mb-1 pl-0 text-center" style="font-size: 13px;inline-size: 1px;">
                  {{
                    priceRange(internalItems)
                  }}
                </span>
              </span>
            </div>
          </template>

          <template v-else>
            <div class="mt-n1 text-center">
              <span
                style="font-size: 13px"
                class="flex-nowrap align-center"
              >
                {{ $t('common.unitPriceVariation') }}
              </span>
            </div>

            <div class="mt-n1 pl-3 d-flex align-center justify-center">
              <span
                class="shimmer font-weight-bold ma-0 caption text-center"
                style="font-size: 15px"
                data-cy="my-part-price-range-grid"
                :class="{ 'colorRed': isExpired(part), 'colorGreen': !isExpired(part) }"
              >
                <span class="mb-1 pl-2 text-center" style="font-size: 13px;inline-size: 1px;">
                  {{
                    priceRange(internalItems)
                  }}
                </span>
              </span>
              <span
                v-if="!isExpired(part)"
                :class="{ 'colorRed': isExpired(part), 'colorGreen': !isExpired(part) }"
                class="mdi mdi-magnify rounded d-inline-flex align-center justify-center ml-1 white--text "
                :style="[isExpired(part) ? {'background-color': '#be2a45'} : {'background-color': '#5FCE79'}]"
                style="font-size:13px ;background-color: #5FCE79; color: white !important; width: 20px; font-weight: bold !important"
              />
            </div>
          </template>
        </v-sheet>
      </template>

      <div style="background-color: white; z-index:9999999" class="pa-2">
        <span class="body-2 font-weight-bold">{{ $t('common.unitPricePerQuantity') }}</span>
        <div
          style="display: grid;width: min-content;grid-template-columns: max-content max-content;"
        >
          <template v-for="(item,index) in sortByQuantity(internalItems)">
            <span :key="`qty-${index}`" style="color: #8D8D8D">
              {{ item.Qty }}: {{ '\xa0' }}
            </span>
            <span :key="`price-${index}`">
              {{ $t('common.pricePerUnit', {price: item.Price}) }}
            </span>
          </template>
        </div>
      </div>
    </v-menu>
  </v-container>
</template>

<script>

import { models } from 'feathers-vuex';

import {
  computed, defineComponent, ref,
} from '@vue/composition-api';
import currency from 'currency.js';

const { Part, Company } = models.api;

export default defineComponent({
  props: {
    /**
     * Manipulate the way field's value displays
     */
    part: {
      type: Object,
      default: () => {
      }
    },
  },

  setup(props, context) {
    const t = context.root.$t;

    const company = computed(() => Company.getFromStore(0));

    const currentUser = computed(() => context.root.$store.getters['auth/user']);

    const currencySymbol = computed(() => _.get(company.value, 'currencySymbol', '$'));

    const deleteDialog = ref({
      display: false,
      partToDelete: new Part(),
    });

    const internalItems = computed(() => {
      if (props.part) {
        return props.part;
      }
      return { ExpiryDate: Date.now() };
    });

    const currentOrder = computed(() => context.root.$store.state.app.currentOrder);

    const sortByQuantity = part => {
      if (!part) {
        return [];
      }

      if (part.PriceMOQ) {
        let priceMoq;
        if (typeof part.PriceMOQ === 'string') {
          priceMoq = JSON.parse(part.PriceMOQ);
        } else {
          priceMoq = [...part.PriceMOQ];
        }

        return priceMoq.sort((a, b) => b.Qty - a.Qty).map(moq => ({
          ...moq,
          Price: currency(moq.Price, { symbol: currencySymbol.value, precision: 4 }).format(),
        }));
      }

      if (part.priceMOQ) {
        let priceMoq;
        if (typeof part.priceMOQ === 'string') {
          priceMoq = JSON.parse(part.priceMOQ);
        } else {
          priceMoq = [...part.priceMOQ];
        }

        return priceMoq.sort((a, b) => b.Qty - a.Qty).map(moq => ({
          ...moq,
          Price: currency(moq.Price, { symbol: currencySymbol.value, precision: 4 }).format(),
        }));
      }
      return [];
    };

    const priceRange = part => {
      if ((part.PriceMOQ && part.PriceMOQ.length !== 0) || (part.priceMOQ && part.priceMOQ.length !== 0)) {
        let priceMoq;
        if (typeof part.PriceMOQ === 'string') {
          priceMoq = JSON.parse(part.PriceMOQ);
          // eslint-disable-next-line no-prototype-builtins
        } else if (part.hasOwnProperty('priceMOQ')) {
          priceMoq = [...part.priceMOQ];
        } else {
          priceMoq = [...part.PriceMOQ];
        }
        const parsedData = priceMoq;
        const first = parsedData[0];
        const last = parsedData.pop();
        return `${last.Price} ${currencySymbol.value} - ${first.Price}${currencySymbol.value} `;
      }
      return context.root.$t('common.nA');
    };

    const isExpired = () => moment().diff(props.part && props.part.ExpireDate ? props.part.ExpireDate : Date.now(), 'days') > 0;

    const refreshMyPartPrice = async part => {
      const query = {
        query: {
          shouldUpdatePrice: true,
        },
        notificationMessage: context.root.$t('views.myParts.refreshPriceSuccessMessage'),
      };

      await part.save(query);
    };

    const itemRowBackground = part => (this.isExpired(part) ? 'expired-row' : null);

    const cancelDelete = () => {
      deleteDialog.value.display = false;
      deleteDialog.value.partToDelete = new Part();
    };

    const addToOrder = async part => {
      // eslint-disable-next-line no-underscore-dangle
      const order = await context.root.$store._actions['app/initCurrentOrder'][0]();
      order.addItem('part', part._id, part.qtyOfPart);
    };

    const removeToOrder = part => {
      if (currentOrder.value) {
        currentOrder.value.items = currentOrder.value.items.filter(item => item._id !== part._id);
      }
    };

    const calculatePrice = computed(() => part => {
      if (!part.PriceMOQ) {
        return currency(0, { symbol: currencySymbol.value });
      }

      let priceMoq;
      if (typeof part.PriceMOQ === 'string') {
        priceMoq = JSON.parse(part.PriceMOQ);
      } else {
        priceMoq = [...part.PriceMOQ];
      }

      const findPrice = priceMoq.find(({ Qty }) => part.qtyOfPart <= Qty) || priceMoq;

      if (!findPrice) {
        const unitPrice = Math.min(...priceMoq.map(o => o.Qty));
        // eslint-disable-next-line consistent-return
        const price = parseFloat(unitPrice.toFixed(2)) === 0.00 ? 0.01 : findPrice.Price;
        const finalPrice = currency(price, { symbol: currencySymbol.value, precision: 4 });
        return currency(finalPrice, { symbol: '', precision: 2 }).multiply(part.qtyOfPart);
      }

      // eslint-disable-next-line consistent-return
      const price = parseFloat(findPrice.Price.toFixed(2)) === 0.00 ? 0.01 : findPrice.Price;
      const finalPrice = currency(price, { symbol: currencySymbol.value, precision: 4 });

      return currency(finalPrice, { symbol: currencySymbol.value, precision: 2 }).multiply(part.qtyOfPart);
    });

    const isPartPresentInCurrentOrder = computed(() => part => {
      if (currentOrder.value === null) {
        return false;
      }

      const currentPart = currentOrder.value.items.findIndex(item => item._id === part._id);

      return currentPart !== -1;
    });

    return {
      sortByQuantity,
      removeToOrder,
      addToOrder,
      cancelDelete,
      itemRowBackground,
      refreshMyPartPrice,
      isExpired,
      priceRange,
      deleteDialog,
      calculatePrice,
      currencySymbol,
      currentUser,
      isPartPresentInCurrentOrder,
      internalItems,
      t
    };
  },
});

</script>

<style>
.notDisplay {
  visibility: hidden;
}

.partCard:hover .displayDetail {
  visibility: visible;
  display: inline;
  position: relative;
}

.partCard:hover .shimmer {

  background: var(--color) -webkit-gradient(linear, 100% 0, 0 0,
  from(var(--color)), color-stop(0.5, #ffffff),
  to(var(--color)));

  background-position: -4rem top; /*50px*/
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation-name: shimmer;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-background-size: 4rem 100%; /*50px*/
  -webkit-animation-timing-function: linear;
}

.colorRed {
  color: #be2a45 !important;
  --color: #be2a45 !important
}

.colorGreen {
  color: #128347 !important;
  --color: #128347 !important
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -4rem top; /*50px*/
  }

  70% {
    background-position: 12.5rem top; /*200px*/
  }

  100% {
    background-position: 12.5rem top; /*200px*/
  }
}
</style>
