// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'referrals';

const Model = class Referral extends BaseModel {
    static modelName = 'Referral';

    static instanceDefaults() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
      };
    }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
