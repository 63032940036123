<template>
  <v-avatar
    :size="size"
    :style="`border: solid ${displayBorder ? size/20 : 0}px white ;background-color: #E8E8E8`"
  >
    <!--    <v-progress-circular v-if="isImgLoading" indeterminate />-->
    <!--    <template v-else>-->
    <!--      <v-img-->
    <!--        v-if="!hideAvatar && imgLoad"-->
    <!--        eager-->
    <!--        :src="urlPreview || `${host}uploads/avatar_${fileName}?${(new Date()).getTime()}`"-->
    <!--        @error="imgLoad=false"-->
    <!--        @load="imgLoad=true"-->
    <!--      >-->
    <!--        <template v-slot:placeholder class="d-flex align-center justify-center">-->
    <!--          <v-progress-circular-->
    <!--            indeterminate-->
    <!--            size="10"-->
    <!--            width="2"-->
    <!--            color="#C8C8C8"-->
    <!--          />-->
    <!--        </template>-->
    <!--      </v-img>-->
    <!--      <v-progress-circular-->
    <!--        v-else-if="!user"-->
    <!--        indeterminate-->
    <!--        size="10"-->
    <!--        width="2"-->
    <!--        color="red"-->
    <!--      />-->
    <span
      class="text-uppercase font-weight-bold d-flex"
      :style="{'font-size': fontSize}"
      style=" color: rgba(0,0,0,0.86); "
    >
      {{ initial }}
    </span>
    <!--    </template>-->
  </v-avatar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Avatar',
  props: {
    displayBorder: {
      type: Boolean,
      default: false
    },
    user: {
      required: true,
    },
    imgSrc: {
      type: String,
      default: ''
    },
    hideAvatar: {
      type: Boolean,
      default: false
    },
    urlPreview: {
      type: String,
      default: ''
    },
    isImgLoading: {
      type: Boolean,
      default: false
    },
    isContactsFindPending: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  data() {
    return {
      host: '',
      fileName: '',
      timestamp: '',
      imgLoad: true,
    };
  },
  computed: {
    ...mapState('auth', ['accessToken']),

    // eslint-disable-next-line no-mixed-operators
    fontSize: vm => `${vm.size / 40.0 * 17}px`,
    initial: vm => (vm.user.firstName && vm.user.lastName ? (vm.user.firstName[0] + vm.user.lastName[0]) : '...'),
    imgSrcUrl: vm => (vm.urlPreview || `${vm.host}uploads/avatar_${vm.fileName}?${(new Date()).getTime()}`)
  },
  watch: {
    isImgLoading() {
      this.imgLoad = true;
    }
  },
  created() {
    this.host = process.env.VUE_APP_API_URL;
    this.fileName = _.get(this.user, 'ContactEmail', '') || _.get(this.user, '_id', '');
  },
};
</script>
