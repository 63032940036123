import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';
import i18n from '../plugins/i18n';

const servicePath = 'users';

const Model = class User extends BaseModel {
  static modelName = 'User'

  static instanceDefaults() {
    return {
      firstName: '',
      lastName: '',
      JobTitle: '',
      officeMobile: '',
      officePhone: '',
      password: '',
      OkToSendDesignTips: null,
      OkToSendManufacturingTips: null,
      // With this field, you can select specific notifier's action at the creation of the contact
      action: 'resendVerifySignup',
      rights: {
        instantQuote: false,
        rfqs: false,
        myParts: false,
        orders: false,
        placeOrder: false,
        accountStatement: false,
        userManagement: false,
        companyManagement: false,
        forwarderManagement: false,
        passwordChanges: false,
        supportUser: false,
      },
      isAdmin: false,
      locale: i18n.locale,
      eula: {
        accepted: false,
        validationDate: '',
      },
      isVerified: false,
      isAvatarLoading: false,
      isAvatarIsHidden: false,
      previewAvatarUrl: '',
      hasCsiData: false,
      department: '',
      refCode: '',
    };
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath).hooks({
      after: {
        get: [
          context => {
            context.result.hasCsiData = true;
            return context;
          }
        ],
      }
    }),
    Model,
    preferUpdate: true
  }),

  store: makeServiceStore({ servicePath, Model }),
};
