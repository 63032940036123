<template>
  <div
    style="background-color: #E9F4ED;"
    class="rounded d-flex flex-column align-center py-0 my-0"
  >
    <div
      style="
        display: grid;
        width: min-content;
        grid-template-columns: max-content max-content;
      "
    >
      <span class="body-2 text-right">{{ $t('views.instantQuote.quantity') }}:: {{ '\xa0' }}</span>
      <span class="body-2">{{ $t('common.pricePerUnit') }}</span>
      <template v-for="(bracket, i) in customerPriceRange">
        <span
          :key="`qty-${i}`"
          style="color: #8D8D8D"
          class="text-right"
          :data-cy="`unit-price-qty-${i}`"
        >
          {{ i === 0 ? quantity : bracket.Qty }}: {{ '\xa0' }}
        </span>
        <span :key="`price-${i}`" :data-cy="`unit-price-amount-${i}`">
          {{
            currency(bracket.Price, {
              symbol: company.currencySymbol,
              precision: 4
            }).format()

          }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import currency from 'currency.js';
import { models } from 'feathers-vuex';

const { Company } = models.api;

export default {
  name: 'ProductPriceMoqTable',
  props: {
    part: Object,
  },
  data: () => ({
    currency,
  }),
  computed: {
    company: () => Company.getFromStore(0),

    priceRange: vm => _.get(vm, 'part.priceMOQ', null),

    quantity: vm => {
      if (!vm.part.ReqQty || vm.part.ReqQty <= 0) {
        return 1;
      }
      if (!vm.part.ReqQty || vm.part.ReqQty <= 100) {
        return 100;
      }
      if (vm.part.ReqQty >= 1000000) {
        return 1000000;
      }
      return vm.part.ReqQty;
    },
    customerPriceRange() {
      if (!this.priceRange || this.quantity <= 0) {
        return [];
      }

      const reqQtyPriceIndex = this.priceRange
        .findIndex(range => range.Qty > this.quantity) - 1;

      if (reqQtyPriceIndex < 0) {
        return this.priceRange.slice(-1);
      }
      return this.priceRange
        .slice(reqQtyPriceIndex, Math.min(reqQtyPriceIndex + 3, this.priceRange.length));
    },
  },
};
</script>
