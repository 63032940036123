export default {
  props: {
    value: { type: Object, default: () => {} },
  },

  data() {
    return {
      data: {},
    };
  },

  watch: {
    value: {
      deep: true,

      handler(newValue) {
        this.data = _.clone(newValue);
      },
    },

    data: {
      deep: true,

      handler(newValue) {
        if (!_.isEqual(this.value, newValue)) {
          this.$emit('input', newValue);
        }
      },
    },
  },

  mounted() {
    this.data = _.clone(this.value);
  },
};
