// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'packaging-tools';

const Model = class PackagingTool extends BaseModel {
  static modelName = 'PackagingTool';

  static instanceDefaults() {
    return {
      stlFile: '',
      qty: 1000,
      spacing: 1,
      unitWeight: 1,
      width: 800,
      length: 470,
      height: 400,
      maxWeight: 1000,
      maxTime: 300,
      engineResult: null,
    };
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
