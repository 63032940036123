<template>
  <v-container class="fill-height px-5" style="min-width: 1190px; max-width: 1400px; height: 100%">
    <v-row class="fill-height">
      <v-col
        class="rounded-xl pa-0"
        style="z-index:99; background-color: white;height: 100%; min-width: 550px;"
        cols="6"
      >
        <v-row class="d-flex justify-space-between align-center">
          <v-select
            style="max-width: 130px;"
            class="ml-3 mt-2"
            :value="locale"
            :items="langList"
            item-value="value"
            item-text="label"
            background-color="transparent"
            solo
            flat
            hide-details
            @change="(currentLang) => {setLocale({ $i18n: i18n, locale: currentLang });} "
          >
            <template slot="selection" slot-scope="data">
              <v-icon class="mr-2" color="primary">
                mdi-web
              </v-icon>
              <span style="color: #2c984e;">{{ data.item.label }}</span>
            </template>
          </v-select>
          <v-btn class="mr-8 mt-2" color="primary" @click="$router.push('/signup')">
            {{ $t('views.signUp.signUp') }}
          </v-btn>
        </v-row>

        <v-row>
          <v-col
            offset="2"
            cols="8"
            class="d-flex flex-column align-center mb-16"
          >
            <img src="/img/Komacut-logo.svg" width="180px">

            <span class="text-h5 font-weight-bold my-10 mb-16">
              {{ $t('views.login.login') }}
            </span>

            <v-form
              ref="loginForm"
              v-model="isLoginFormValid"
              class="mt-16"
              style="width: 100%;"
              @submit.prevent="loginSubmit"
            >
              <v-text-field
                v-model="user._id"
                :label="$t('common.email')"
                :rules="[isRequired, isEmail]"
                outlined
                dense
                autofocus
                data-cy="email-login"
              />

              <v-text-field
                v-model="user.password"
                :label="$t('views.login.password')"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isPasswordVisible ? 'text' : 'password'"
                :rules="[isRequired]"
                outlined
                dense
                required
                hide-details
                data-cy="password-login"
                @click:append="isPasswordVisible = !isPasswordVisible"
              />

              <div class="d-flex justify-end mb-6">
                <router-link to="/forgotten_password" data-cy="forgotten-password-login">
                  {{ $t('views.login.forgotPassword') }}
                </router-link>
              </div>

              <v-btn
                :loading="isAuthenticatePending"
                :disabled="!isLoginFormValid || isAuthenticatePending"
                color="success"
                type="submits"
                large
                block
                data-cy="submit-login"
              >
                <span v-if="isAuthenticated">
                  {{ $t('views.login.login') }}
                </span>
                <!-- Not sure if this is ever rendered??? -->
                <span v-else>
                  {{ $t('views.login.login') }}
                </span>
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
        <p class="text--secondary caption text-center mt-16 mb-0" style="margin-top: 230px !important">
          {{ $t('common.footerRight') }}  <a href="https://komacut.com/about-us/contact-us/" class="text-decoration-none"> {{ $t('common.contactUs') }}</a>
        </p>
      </v-col>
      <v-col cols="6" class="pl-0 d-flex justify-center align-center">
        <core-promotional />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FormRules from '@/mixins/form-rules';
import i18n from '@/plugins/i18n';
import { resetStores } from '../store';
import { LEVEL } from '../utils/log';

export default {
  name: 'login',

  mixins: [
    FormRules,
  ],

  data() {
    return {
      langList: [
        { label: 'English', value: 'en-US' },
        { label: '中文', value: 'cn' }
      ],
      i18n,
      authenticating: false,
      user: {
        _id: '',
        password: '',
      },
      isPasswordVisible: false,
      isLoginFormValid: false,
    };
  },

  computed: {
    ...mapState('auth', [
      'isAuthenticatePending',
    ]),
    ...mapState('auth', { loggedInUser: 'user' }),
    ...mapState('app', ['locale']),
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),

    ...mapGetters('app', [
      'appVersion'
    ]),

  },

  mounted() {
    resetStores();
    const navLang = navigator.language;
    let currentLang = navLang.split('-')[0];
    // The standard for Chinese localization is not cn but zh cf :
    // http:// www.lingoes.net/en/translator/langcode.htm
    currentLang = (currentLang === 'zh') ? 'cn' : 'en-US';
    this.setLocale({ $i18n: this.i18n, locale: currentLang });
  },

  methods: {
    ...mapActions('auth', { secureLogin: 'login' }),
    ...mapActions('app', ['pushSnack', 'setLocale']),

    async loginSubmit() {
      try {
        this.isLoginFormValid = this.$refs.loginForm.validate();

        localStorage.removeItem('feathers-jwt');

        await this.secureLogin({ user: this.user });
        // window.$chatwoot.reset();

        this.pushSnack({
          snack: { message: this.$t('views.login.loginSuccess') },
          level: LEVEL.SUCCESS,
        });
        if (this.loggedInUser.rights.supportUser) {
          this.$router.push('/support');
        } else {
          this.$router.push('/');
        }
      } catch (e) {
        console.error(e);
        this.pushSnack({
          snack: { message: e.code === 401 ? this.$t('views.login.loginFailure') : e.message },
          level: LEVEL.ERROR,
        });
      }
    },
  },
};
</script>

<style scoped >
.login-card {
  z-index: 5;
}
</style>
