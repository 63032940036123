<template>
  <v-container class="pa-0">
    <v-row class="ml-4">
      <v-col
        cols="2"
        class="mr-2"
      >
        <v-btn
          depressed
          text
          color="primary"
          x-large
          width="128px"
          @click="$emit('openDrawingPanel')"
        >
          <v-icon
            left
          >
            mdi-vector-square
          </v-icon>
          Drawings
        </v-btn>
      </v-col>
      <template v-for="(productOption, index) in productOptions">
        <v-col
          v-if="productOption.attributeTypes[0].fieldType === 'dropdown'"
          :key="`dropdown-${productOption.id}`"
        >
          <v-select
            v-model="selectedOptions[productOption.id]"
            :label="productOption[locale === 'en-US' ? 'label' : 'labelChs' ]"
            :items="filteredItems(index)"
            attach
            :item-text="locale === 'en-US' ? 'text' : 'textChs'"
            item-value="id"
            outlined
            hide-details
            @click.stop
          />
        </v-col>
        <v-col
          v-else-if="productOption.attributeTypes[0].fieldType === 'checkbox'"
          :key="`checkbox-${productOption.id}`"
          class="pl-0"
        >
          <v-checkbox
            v-model="selectedOptions[productOption.id]"
            :label="productOption[locale === 'en-US' ? 'label' : 'labelChs' ]"
            :true-value="productOption.options[1].id"
            :false-value="productOption.options[0].id"
            hide-details
            @click.stop
          />
        </v-col>
        <v-col
          v-if="productOption.attributeTypes[0].fieldType === 'readonly'"
          :key="`plainText-${productOption.id}`"
        >
          <div
            style="color:rgba(0,0,0,0.6);"
            class="caption"
          >
            {{ productOption[locale === 'en-US' ? 'label' : 'labelChs'] }}
          </div>
          <div>
            {{
              getReadOnlyProduct[locale === 'en-US' ? 'text' : 'textChs']
            }}
          </div>
        </v-col>
      </template>
    </v-row>
    <v-row class="ml-4">
      <v-col cols="3" class="pr-0">
        <div class="d-flex justify-center">
          <v-text-field
            v-model.number="reqQty"
            style="max-width: 80px;display: inline-block !important;"
            class="mr-2"
            height="56px"
            data-cy="quantity-input-upload"
            :label="$t('views.instantQuote.quantity')"
            :rules="[isPositiveInteger]"
            type="number"
            hide-details
            outlined
            @input.native="reqQty = Math.max(Math.abs(Math.trunc($event.target.value)), 0);"
            @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
            @click.stop
          />
          <v-sheet
            color="#f0fdf3"
            class="rounded text-center pa-1 text-truncate"
            height="56px"
            width="110px"
          >
            <p class="subtitle-2 mb-0" style="color: #8D8D8D">
              Total Price ($)
            </p>
            <span>{{ calculatePrice }}</span>
          </v-sheet>
        </div>
      </v-col>

      <v-col>
        <v-btn
          outlined
          x-large
          color="primary"
          :disabled="!selectedProduct"
          @click.stop="$emit('add-to-bag', selectedProduct)"
        >
          Add to custom bag
        </v-btn>

        <v-btn
          color="primary"
          x-large
          class="ml-6"
          :disabled="!selectedProduct"
          @click.stop="addToOrder()"
        >
          Add to order
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapActions, mapState } from 'vuex';
import FormRules from '@/mixins/form-rules';

export default {
  name: 'ProductOption',

  mixins: [
    FormRules,
  ],

  props: {
    productGroupId: {
      type: String,
      default: ''
    },
    productGroupKey: {
      type: String,
      default: ''
    },
    productOptions: {
      type: Array,
      default: () => []
    },
    productQuery: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      isDrawingsDialogOpen: false,
      selectedOptions: {},
      reqQty: 1,
      customBag: [],
      forceUpdateField: Date.now()
    };
  },

  computed: {
    ...mapState('app', ['locale']),
    HardwareProduct: () => models.api.HardwareProduct,

    filteredItems: vm => index => {
      const currentOption = vm.productOptions[index];
      if (!currentOption) {
        return [];
      }

      const optionsFilter = vm.productOptions.slice(0, index).map(o => ({ [o.id]: vm.selectedOptions[o.id] || '' }));

      const query = {
        query: {
          $sort: vm.productQuery.query.$sort,
          [vm.productGroupKey]: vm.productGroupId
        }
      };
      Object.assign(query.query, ...optionsFilter);

      const productValue = vm.HardwareProduct.findInStore(query).data;

      if (productValue.length === 0) {
        return [];
      }

      vm.productReadOnlyOptions.forEach(option => {
        vm.selectedOptions[option.id] = productValue[0][option.id];
      });

      vm.productCheckBoxOptions.forEach(option => {
        vm.selectedOptions[option.id] = productValue[0][option.id];
      });

      const res = productValue.map(o => (o[currentOption.id]));
      if (res.length === 0) {
        return [];
      }

      return res.map(value => currentOption.options.find(option => option.id === value)) || [];
    },

    selectedProduct: vm => {
      const query = {
        query: {
          $sort: vm.productQuery.query.$sort,
          [vm.productGroupKey]: vm.productGroupId,
          ...vm.selectedOptions
        }
      };

      const product = vm.HardwareProduct.findInStore(query).data;
      if (product.length === 0 || product.length > 1) {
        return null;
      }

      return product[0];
    },

    calculatePrice: vm => {
      if (!vm.selectedProduct) {
        return '';
      }

      const findPrice = vm.selectedProduct.priceMOQ.reverse().find(({ Qty }) => Qty < vm.reqQty) || vm.selectedProduct.priceMOQ[0];

      if (!findPrice) {
        return '';
      }

      return Math.round((findPrice.Price * vm.reqQty) * 100) / 100;
    },

    productReadOnlyOptions: vm => (vm.productOptions ? vm.productOptions
      .filter(option => option.attributeTypes[0].fieldType === 'readonly') : []),

    productCheckBoxOptions: vm => (vm.productOptions ? vm.productOptions
      .filter(option => option.attributeTypes[0].fieldType === 'checkbox') : []),

    getReadOnlyProduct: vm => {
      const product = vm.HardwareProduct.findInStore({
        query: {
          $sort: vm.productQuery.query.$sort,
          [vm.productGroupKey]: vm.productGroupId
        }
      }).data[0];
      if (!product) {
        return {};
      }

      return vm.productReadOnlyOptions[0].options.find(value => value.id === product[vm.productReadOnlyOptions[0].id]) || {};
    },

    selectedOption: vm => {
      if (vm.HardwareProduct.store.state['hardware-products'].ids.length > 0) {
        vm.productOptions.forEach(fil => {
          if (!vm.selectedOptions[fil.id]) {
            vm.$set(vm.selectedOptions, fil.id, '');
          }
        });
      }
      return vm.selectedOptions;
    },
  },

  watch: {
    selectedProduct() { // watch it
      this.forceUpdateField = Date.now();
    }
  },

  methods: {
    ...mapActions('app', ['initCurrentOrder']),

    async addToOrder() {
      const order = await this.initCurrentOrder();
      order.addItem('hardware', this.selectedProduct._id, this.reqQty);
    }
  }
};
</script>

<style scoped>

>>> .v-skeleton-loader__image {
  height: 600px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody {
  padding-left: 55px !important;
  width: 50px
}

::v-deep .warningStyle {
  background-color: #fbe9ec !important;
}

.warningFontColor {
  color: #B71C1C;
}

::v-deep .v-data-table__expand-icon--active {
  color: green;
  transform: rotate(90deg) !important;
}

::v-deep .v-icon.notranslate.v-data-table__expand-icon {
  color: green;
  margin: 0 10px;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #beebc9;
  padding: 0 0 0 15px !important;
  height: 25px;
  font-size: 12px;
}

::v-deep thead th:first-child {
  border-radius: 5px 0 0 0;
}

::v-deep thead th:last-child {
  border-radius: 0 5px 0 0;
}

.active {
  background-color: green !important;
  color: white !important;
  opacity: 1;
}

.active-filter {
  background-color: #f5f5f5 !important;
  color: green !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr:first-child:hover > td:first-child {
  border-top-left-radius: 0 !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr:first-child:hover > td:last-child {
  border-top-right-radius: 0 !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded > td {
  border-bottom: none !important;
}

::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  background-color: #fafffb !important;
}

</style>
