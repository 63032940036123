<template>
  <div>
    <div class="d-flex justify-center">
      <v-btn
        x-small
        depressed
        fab
        style="position: absolute; top: 15px; left: 50%; transform: translateX(-50%);"
        data-cy="download-pdf-button"
        :disabled="isPDFDownloadPending"
        @click="downloadPdf"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>
    </div>
    <v-container class="fill-height pr-0" fluid>
      <v-virtual-scroll
        ref="containerRef"
        :bench="2"
        :items="loadedPages"
        :height="itemHeight"
        :width="itemHeight"
        :item-height="itemHeight"
        style="overflow-y: scroll;"
      >
        <template #default="{ item }">
          <div class="" :style="`min-width: ${1.4*itemHeight}px`">
            <core-pdf-canvas
              :ref="item.id"
              :key="item.id"
              :page="item"
              :container-ref="$refs.containerRef"
              :item-height="itemHeight"
              @enterViewport="(Math.abs($event - currentPage) === 1) ? currentPage = $event : () => {}"
            />
          </div>
        </template>
      </v-virtual-scroll>

      <v-chip class="my-2" style="position: absolute; bottom: 15px; left: 50%; transform: translateX(-50%);">
        {{ currentPage }} / {{ _.get(pdf, 'numPages', '?') }}
      </v-chip>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      default: 'KomacutPdf'
    },
    filePath: String,
    itemHeight: {
      required: false,
    },
    viewerHeight: {
      required: false,
    }
  },

  data: () => ({
    pdf: null,
    PDFjs: null,
    pdfCdn: 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf.min.js',
    pdfWorkerCdn: 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf.worker.min.js',
    pageCount: 0,
    pageBuffer: 4,
    currentPage: 1,
    isPDFDownloadPending: false
  }),
  computed: {
    from: vm => vm.currentPage,
    to: vm => vm.currentPage + vm.pageBuffer,
    loadedPages: vm => {
      if (!vm.pdf) {
        return [{ id: 'fake' }];
      }

      return new Array(vm.pdf.numPages)
        .fill(undefined)
        .map((v, i) => ({
          pageNumber: i + 1,
          pdf: vm.pdf,
          id: `${vm.pdf.fingerprint}-${i}`,
        }));
    }
  },

  created() {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    this.initPDF();
  },
  destroyed() {
    document.getElementsByTagName('html')[0].style.overflow = 'visible';
  },
  methods: {
    importPDF() {
      return new Promise(resolve => {
        const script = document.createElement('script');
        script.onload = () => {
          resolve(window['pdfjs-dist/build/pdf']);
        };
        script.async = true;
        script.src = this.pdfCdn;
        document.head.appendChild(script);
      });
    },
    async initPDF() {
      this.PDFjs = await this.importPDF();
      this.PDFjs.GlobalWorkerOptions.workerSrc = this.pdfWorkerCdn;
      this.loadPDF();
    },
    loadPDF() {
      // Asynchronous download of PDF
      this.isPDFDownloadPending = true;
      const loadingTask = this.PDFjs.getDocument(this.filePath);
      loadingTask.promise.then(pdf => {
        this.pdf = pdf;
        this.isPDFDownloadPending = false;
      });
    },
    saveByteArray(reportName, byte) {
      const blob = new Blob([byte], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const fileName = reportName;
      link.download = fileName;
      link.click();
    },
    downloadPdf() {
      this.pdf.saveDocument().then(save => {
        this.saveByteArray(this.fileName, save);
      });
    }
  }
};

</script>
