<template>
  <v-container fluid style="min-width: 1800px">
    <div class="d-flex justify-center mt-16">
      <img src="/img/Komacut-logo.svg" width="200px">
    </div>
    <v-row
      style="height: 50vh;"
      class="d-flex flex-column align-center justify-center"
    >
      <v-card
        v-if="success"
        style="background-color: transparent;"
        flat
      >
        <v-card-title class="flex-column justify-center">
          {{ $t('views.accountActivation.title') }}
        </v-card-title>
        <v-card-text align="center" class="pa-5">
          <p class="text-center">
            {{ $t('views.accountActivation.thankYou') }}
          </p>
          {{ $t('views.accountActivation.useTheLinkBelow') }}
        </v-card-text>
        <v-card-actions class="justify-center pa-5">
          <v-btn color="primary" depressed @click="$router.push('/login')">
            {{ $t('views.accountActivation.goToLogin') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-else color="transparent" flat>
        <v-card-title v-if="accountAlreadyExist">
          {{ $t('views.accountActivation.alreadyVerified') }}
        </v-card-title>
        <v-card-title v-else>
          <v-progress-circular
            class="mr-2"
            color="primary"
            indeterminate
          />
          {{ $t('views.accountActivation.verifying') }}
        </v-card-title>
        <v-card-actions v-if="accountAlreadyExist" class="justify-center pa-5">
          <v-btn color="primary" depressed @click="$router.push('/login')">
            {{ $t('views.accountActivation.goToLogin') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      success: false,
      accountAlreadyExist: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.verifyAccount();
    }, 1000);
  },
  methods: {
    async verifyAccount() {
      const { token } = this.$route.query;
      try {
        if (token) {
          await this.$feathers.service('authManagement').create({
            action: 'verifySignupLong',
            value: token
          });
          this.success = true;
        }
      } catch (e) {
        this.accountAlreadyExist = true;
      }
    }
  }
};
</script>
