<template>
  <div class="filter-range-slider">
    <v-subheader>
      {{ section }}
    </v-subheader>

    <v-list-item dense>
      <v-list-item-content class="pt-10 px-4">
        <v-range-slider
          v-model="localValue"
          :min="$attrs.min"
          :max="$attrs.max"
          :thumb-label="true"
        />
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import FilterMixin from '../../../mixins/filter';

export default {
  name: 'RangeSlider',

  mixins: [
    FilterMixin,
  ],
};
</script>

<style scoped>

</style>
