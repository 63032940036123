import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import authClient from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import feathersVuex from 'feathers-vuex';

import { LEVEL } from '@/utils/log';
import ddlogger from '@/utils/logger';

const errorHandler = async context => {
  const { error, params } = context;
  console.error('errorHandler', error);
  console.error('Error feathers-client', error);

  if (_.get(error, 'code') === 401) {
    const availablePathsWithoutJwt = [
      '/login',
      '/signup',
      '/on_boarding',
      '/confirmation',
      '/forgotten_password',
      '/reset_password',
      '/signup_send_email',
      '/resetpassword_send_email',
      '/resetpassword_success'
    ];
    if (!availablePathsWithoutJwt.some(v => window.location.href.includes(v))) {
      window.location.href = '/login';
    }
    return context;
  }
  const errorMessage = _.omit(error, 'hook');
  console.error(errorMessage);
  if (params.shouldShowNotification === false) {
    return context;
  }

  // eslint-disable-next-line no-use-before-define
  BaseModel.store.dispatch('app/pushSnack', {
    snack: errorMessage,
    level: LEVEL.ERROR,
  });

  return context;
};

const notifyViaSnackbar = async context => {
  const { params, method } = context;
  if (params.notificationMessage) {
    params.shouldShowNotification = true;
  }

  if (!params.shouldShowNotification) {
    return context;
  }
  let message = params.notificationMessage;
  if (!message) {
    switch (method) {
      case 'create':
      case 'patch':
        message = 'common.saveSuccess';
        break;
      case 'remove':
        message = 'common.deleteSuccess';
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line no-use-before-define
  BaseModel.store.dispatch('app/pushSnack', {
    snack: { message },
    level: LEVEL.SUCCESS,
  });
  return context;
};

const host = process.env.VUE_APP_API_URL;
const socket = io(host, { transports: ['websocket'] });

const feathersClient = feathers()
  .configure(socketio(socket, { timeout: 1000 * 60 }))
  // .configure(socketio(socket, { timeout: 1000 * 60 * 5 }))
  .configure(authClient({ storage: window.localStorage }))
  .hooks({
    after: {
      create: [
        notifyViaSnackbar,
      ],
      patch: [
        notifyViaSnackbar,
      ],
      remove: [
        notifyViaSnackbar,
      ],
    },
    error: {
      all: [
        context => {
          console.error(context);
        },
        errorHandler,
      ],
    },
  });
// TODO fix the duplicate item (temp and save) when the analysis engin time up change the backend algo
feathersClient.service('temp-parts').timeout = 70 * 1000;

const {
  makeServicePlugin,
  makeAuthPlugin,
  BaseModel: CoreModel,
  models,
  clients,
  FeathersVuex,
} = feathersVuex(feathersClient, {
  serverAlias: 'api',
  idField: '_id',
});

class BaseModel extends CoreModel {
  async create(...data) {
    const start = performance.now();
    try {
      await super.create(...data);
      ddlogger.logger.debug('action', {
        action: 'create',
        type: this.constructor.name,
        author: this.createdById,
        perfMetric: (performance.now() - start),
      });
    } catch (err) {
      console.error(err);
      ddlogger.logger.error('error', {
        action: 'create',
        error: err,
        type: this.constructor.name,
        author: this.createdById,
        perfMetric: (performance.now() - start),
      });
      throw err;
    }
    return this;
  }

  async save(...data) {
    const start = performance.now();
    try {
      await super.save(...data);
      ddlogger.logger.debug('action', {
        action: 'save',
        type: this.constructor.name,
        author: this.createdById,
        perfMetric: (performance.now() - start),
      });
    } catch (err) {
      console.error(err);
      ddlogger.logger.error('error', {
        action: 'save',
        error: err,
        type: this.constructor.name,
        author: this.createdById,
        perfMetric: (performance.now() - start),
      });
      throw err;
    }
    return this;
  }
}

function makeServiceStore(options, rest) {
  return {
    [options.servicePath]: {
      namespaced: true,

      ...(rest || {}),
    },
  };
}

export {
  makeAuthPlugin,
  makeServicePlugin,
  makeServiceStore,
  BaseModel,
  models,
  clients,
  FeathersVuex,
};

export default feathersClient;
