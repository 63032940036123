<template>
  <v-text-field
    v-model="localValue"
    append-icon="mdi-magnify"
    :label="$t('common.search')"
    single-line
    hide-details
    flat
    solo-inverted
    dense
  />
</template>

<script>
import ModelableMixin from '../../mixins/modelable';

export default {
  name: 'SearchBar',

  mixins: [
    ModelableMixin,
  ],
};
</script>

<style scoped>

</style>
