// TODO this is the exact same as the filter mixin
// TODO It's generic enough to be renamed and used everywhere
export default {
  props: {
    value: {},
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    }
  },
};
