<template>
  <div style="width: 1000px">
    <span class="text-h5 mb-4">Finishing Colors</span>
    <v-expansion-panels class="mt-4" multiple style="border-radius: 5px">
      <v-expansion-panel
        v-for="(item,index) in content"
        :key="index"
        :disabled="item.disabled"
      >
        <v-expansion-panel-header>
          <span class="font-weight-bold">{{ item.title }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(finish,i) in item.finish">
            <div
              :key="i"
              class="d-flex flex-row d-inline-flex"
              style="width:90px"
            >
              <div class="text-center">
                <v-avatar
                  :color="finish.color"
                  size="45"
                >
                  <span :style="{ color: getContrast(finish.color) }">{{ finish.code }}</span>
                </v-avatar>
                <p class="caption font-weight-thin">
                  {{ finish.label }}
                </p>
              </div>
            </div>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'FinishColor',
  data() {
    return {
      content: [
        { title: 'Mill Finish', finish: [{ code: 'AL', label: 'Aluminium', color: '#D3D3D3' }, { code: 'ST', label: 'Steel', color: '#616161' }, { code: 'SS', label: 'Stainless Steel', color: '#A2A2A2' }], disabled: false },
        { title: 'Antirust Oiling', finish: [{ code: 'ST', label: 'Steel', color: '#616161' }], disabled: false },
        { title: 'Brushed', disabled: true },
        { title: 'Anodized', disabled: true },
        { title: 'Micro-Polishing', disabled: true },
        { title: 'Passivation', disabled: true },
        { title: 'Zinc Plating', disabled: true },
        { title: 'E-Coating', disabled: true },
        { title: 'Powder Coating', disabled: true },
      ]
    };
  },

  methods: {
    getContrast(hexcolor) {
      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }

      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(hex => hex + hex).join('');
      }

      // Convert to RGB value
      const r = parseInt(hexcolor.substr(0, 2), 16);
      const g = parseInt(hexcolor.substr(2, 2), 16);
      const b = parseInt(hexcolor.substr(4, 2), 16);

      // Get YIQ ratio
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

      // Check contrast
      return (yiq >= 128) ? 'black' : 'white';
    }
  }
};
</script>

<style scoped>
::v-deep .v-expansion-panel::before{border-radius: 10px}
</style>
