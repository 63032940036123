<template>
  <v-col class="py-0">
    <v-row>
      <v-col class="py-0">
        <v-text-field
          v-model="localValue"
          :label="$t('common.email')"
          :placeholder="$t(`form.email.placeholders.email`)"
          :rules="[notEmptyRules, validEmail]"
          :disabled="isDisabled"
          @change="$emit('change')"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import ModelableMixin from '../../mixins/modelable';

export default {
  mixins: [
    ModelableMixin,
  ],

  props: {
    isDisabled: Boolean,
  },

  data() {
    return {
      notEmptyRules: value => (!!value || 'Cannot be empty'),
      validEmail: value => {
        // eslint-disable-next-line
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.';
      },
    };
  },
};
</script>
