<template>
  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="text-subtitle-2">
          {{ $t(_.get(partDesignData[0], 'value')) }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(item) in partDesignData.slice(1)"
                  :key="item.field"
                >
                  <td style="display: flex; align-items: center; color: #747474">
                    <v-icon small class="mr-2" color="green">
                      {{ item.icon }}
                    </v-icon>
                    {{ $t(item.field) }}
                  </td>
                  <td class="text-truncate font-weight-medium" style="text-align: right">
                    <span
                      class="ml-3"
                      :style="[{ color: getTextColor(item.value) }]"
                    >
                      {{ (locale === 'en-US') || (item.valueCHS === '-') ? $t(item.value) : item.valueCHS }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapState } from 'vuex';

const { CsiAnalysis } = models.api;
export default {
  name: 'ManufacturingProcessTable',
  props: {
    part: Object,
  },
  computed: {
    ...mapState('app', ['locale']),
    partDesignData() {
      let tableData = [];
      if (this.part) {
        tableData = _.get(CsiAnalysis.getFromStore(this.part.Item), 'data', []);
      }
      if (_.isEmpty(tableData)) {
        tableData = [
          { field: null, value: '3dSideViewer.manufacturingProcesses.title' },
          { field: '3dSideViewer.manufacturingProcesses.productType', value: '-' },
          { field: '3dSideViewer.manufacturingProcesses.laserCutting', value: '-' },
          { field: '3dSideViewer.manufacturingProcesses.deburring', value: '-' },
          { field: '3dSideViewer.manufacturingProcesses.threadChamfer', value: '-' },
          { field: '3dSideViewer.manufacturingProcesses.cncBending', value: '-' },
          { field: '3dSideViewer.manufacturingProcesses.surfaceFinish', value: '-' },
        ];
      } else {
        tableData = tableData.filter(datum => datum.Category === 'S2')
          .filter(datum => (datum.Description ? datum.Description.startsWith('T2') : ''))
          .sort((datum1, datum2) => +datum1.Rank - +datum2.Rank)
          .map(datum => ({ field: datum.Description.replace('T2.', ''), value: datum.Value || '-', valueCHS: datum.ValueCHS || '-' }));
      }
      let icons = [
        'mdi-aspect-ratio',
        'mdi-content-cut',
        'mdi-inbox-arrow-down',
        'mdi-record-circle',
        'mdi-crop-free',
        'mdi-filter-variant',
        'mdi-flip-v mdi-chart-multiline',
        'mdi-select-all',
      ];
      if (this.part.isTurningPart || this.part.Machine === 'turning') {
        icons = [
          'mdi-aspect-ratio',
          'mdi-vibrate',
          'mdi-inbox-arrow-down',
          'mdi-dots-horizontal',
          'mdi-record-circle',
          'mdi-record-circle',
          'mdi-record-circle',
          'mdi-record-circle',
          'mdi-crop-free',
          'mdi-ticket-confirmation',
          'mdi-ticket-confirmation',
          'mdi-filter-variant',
          'mdi-select-all',
        ];
      }
      return tableData.map((row, index) => ({ ...{ icon: icons[index - 1] }, ...row }));
    },
  },
  methods: {
    getTextColor(value) {
      value = value.split('.').pop();
      switch (value) {
        case 'Pass':
        case 'Yes':
          return 'green';
        case 'Fail':
        case 'No':
          return 'red';
        default:
          return '';
      }
    }
  }
};
</script>
