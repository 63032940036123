<template>
  <v-container fluid class="pa-1" style="height: 1000px !important; width: 800px">
    <v-row class="ml-4">
      <span class="d-flex align-center justify-center text-h6 mr-8"> {{ $t('views.productOption.selectSpecs') }}</span>
      <template v-for="(productOption, index) in productOptions">
        <v-col
          v-if="productOption.attributeTypes[0].fieldType === 'dropdown'"
          :key="`dropdown-${productOption.id}`"
          cols="auto"
        >
          <v-select
            v-model="optionVmodel[productOption.id]"
            :label="productOption[locale === 'cn' ? 'labelChs' : 'label' ]"
            :items="filteredItems(index,productQuery)"
            :item-text="locale === 'cn' ? 'textChs' : 'text'"
            style="max-width: 120px"
            :data-cy="`drawing-dropdown-${productOption.id}`"
            attach
            dense
            item-value="id"
            outlined
            hide-details
            @click.stop
          />
        </v-col>
        <v-col
          v-else-if="productOption.attributeTypes[0].fieldType === 'checkbox'"
          :key="`drawing-checkbox-${productOption.id}`"
          class="pl-0"
        >
          <v-checkbox
            v-model="optionVmodel[productOption.id]"
            :label="productOption[locale === 'cn' ? 'labelChs' : 'label' ]"
            :true-value="productOption.options[1].id"
            :data-cy="`checkbox-${productOption.id}`"
            false-value=""
            hide-details
            @click.stop
          />
        </v-col>
        <v-col
          v-if="productOption.attributeTypes[0].fieldType === 'readonly'"
          :key="`plainText-${productOption.id}`"
          :data-cy="`plainText-${productOption.id}`"
        >
          <div
            style="color:rgba(0,0,0,0.6);"
            class="caption"
          >
            {{ productOption[locale === 'cn' ? 'labelChs' : 'label'] }}
          </div>
          <div>
            {{
              getReadOnlyProduct[locale === 'cn' ? 'textChs' : 'text']
            }}
          </div>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-data-table
        style="width: 100%"
        fixed-header
        height="1000"
        :headers="drawingTableHeaders"
        :items="filteredProduct"
        class="my-4"
        :no-data-text="$t('common.noDataAvailable')"
        :loading-text="$t('common.loadingItems')"
        hide-default-footer
        disable-pagination
      >
        <template v-for="productId in productOptionsId" v-slot:[`item.${productId}`]="{item}">
          <span :key="productId">
            {{ findTranslation(productId, item[productId]) }}
          </span>
        </template>

        <template #item.download="item">
          <v-btn
            text
            :data-cy="`2d-drawing-download-${productGroupKey}`"
            color="primary"
            class="my-1"
            @click="downloadDrawingFiles('itemSpec2d', item.item.specFile2d)"
          >
            <v-icon left>
              mdi-vector-square
            </v-icon>
            {{ $t('views.productOption.2DDrawing') }}
          </v-btn>
          <v-btn
            :data-cy="`3d-drawing-download-${productGroupKey}`"
            text
            color="primary"
            class="my-1"
            @click="downloadDrawingFiles('itemSpec3d', item.item.specFile3d)"
          >
            <v-icon left>
              mdi-cube-outline
            </v-icon>
            {{ $t('views.productOption.3DDrawing') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapActions, mapState } from 'vuex';
import { LEVEL } from '@/utils/log';

export default {
  name: 'HardwareDrawingTable',

  props: {
    productGroupId: {
      type: String,
      default: ''
    },
    productGroupKey: {
      type: String,
      default: ''
    },
    productOptions: {
      type: Array,
      default: () => []
    },
    productQuery: {
      type: Object,
      default: () => {
      },
    },
    productSelected: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      isDrawingsDialogOpen: false,
      selectedOptions: {},
      isDownloadPending: false
    };
  },

  computed: {
    ...mapState('app', ['locale']),
    ...mapState('auth', ['accessToken']),

    HardwareProduct: () => models.api.HardwareProduct,

    drawingTableHeaders: vm => {
      const optionHeader = vm.productOptions.map(productOption => ({
        text: vm.locale === 'cn' ? productOption.labelChs : productOption.label,
        value: productOption.id,
        align: 'center',
      }));
      optionHeader.push({ text: vm.$t('common.download'), value: 'download' });
      return optionHeader;
    },

    productOptionsId: vm => {
      if (vm.productOptions.length === 0) {
        return [];
      }
      return vm.productOptions.map(option => option.id);
    },

    filteredItems: vm => (index, pquery) => {
      const currentColumn = vm.productOptions[index];

      if (!currentColumn) {
        return [];
      }
      // Please figure what it does, it's cool !;
      const query = {
        query: {
          ..._.get(pquery, 'query', {}),
        }
      };
      const productValue = vm.HardwareProduct.findInStore(query).data;

      if (productValue.length === 0) {
        return [];
      }

      const res = productValue.map(o => (o[currentColumn.id]));
      if (res.length === 0) {
        return [];
      }

      return [{
        text: 'All',
        textChs: 'AllChs',
        id: ''
      }, ...res.map(value => currentColumn.options.find(option => option.id === value)) || []];
    },

    filteredProduct: vm => {
      const query = {
        query: {
          ...vm.productQuery.query,
          ...vm.filterQuery
        }
      };
      return vm.HardwareProduct.findInStore(query).data;
    },

    productReadOnlyOptions: vm => (vm.productOptions ? vm.productOptions
      .filter(option => option.attributeTypes[0].fieldType === 'readonly') : []),

    productCheckBoxOptions: vm => (vm.productOptions ? vm.productOptions
      .filter(option => option.attributeTypes[0].fieldType === 'checkbox') : []),

    getReadOnlyProduct: vm => {
      const product = vm.HardwareProduct.findInStore({
        query: {
          $sort: vm.productQuery.query.$sort,
          [vm.productGroupKey]: vm.productGroupId
        }
      }).data[0];
      if (!product) {
        return {};
      }

      return vm.productReadOnlyOptions[0].options.find(value => value.id === product[vm.productReadOnlyOptions[0].id]) || {};
    },

    optionVmodel: vm => {
      // Here we setup the default values
      if (vm.HardwareProduct.store.state['hardware-products'].ids.length > 0) {
        vm.productOptions.forEach(fil => {
          if (!vm.selectedOptions[fil.id]) {
            if (fil.attributeTypes[0].fieldType === 'readonly') {
              vm.$set(vm.selectedOptions, fil.id, vm.filteredProduct[0][fil.id]);
            } else {
              vm.$set(vm.selectedOptions, fil.id, '');
            }
          }
        });
      }
      return vm.selectedOptions;
    },
    // eslint-disable-next-line no-unused-vars
    filterQuery: vm => Object.fromEntries(Object.entries(vm.selectedOptions).filter(([_, v]) => v !== '')),

  },

  watch: {
    productSelected: {
      handler() {
        if (this.productSelected) {
          this.productOptions.forEach(product => {
            this.$set(this.selectedOptions, product.id, this.productSelected[product.id]);
          });
        }
      },
      // force eager callback execution
      immediate: true
    }
  },

  methods: {
    ...mapActions('app', ['pushSnack']),
    async downloadDrawingFiles(fileType, fileName) {
      const downloadUrl = `${process.env.VUE_APP_API_URL}downloadDrawingFiles/${fileName}/${fileType}`;
      this.isDownloadPending = true;

      try {
        const response = await fetch(
          downloadUrl, {
            method: 'GET',
            headers: {
              Authorization: this.accessToken,
            },
          }
        );

        if (!response.ok) {
          throw response.statusText;
        }
        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.isDownloadPending = false;
      } catch (e) {
        this.pushSnack({
          snack: { message: e },
          level: LEVEL.ERROR,
        });
      }
    },

    findTranslation(productId, option) {
      if (!this.productOptions) {
        return '';
      }
      const optionValue = this.productOptions.filter(productOption => productOption.id === productId)[0].options.filter(opt => opt.id === option);

      if (!optionValue.length) {
        return '';
      }
      return optionValue[0][[this.locale === 'cn' ? 'textChs' : 'text']];
    }
  }
};
</script>

<style scoped>

>>> .v-skeleton-loader__image {
  height: 600px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody {
  padding-left: 55px !important;
  width: 50px
}

::v-deep .warningStyle {
  background-color: #fbe9ec !important;
}

.warningFontColor {
  color: #B71C1C;
}

::v-deep .v-data-table__expand-icon--active {
  color: green;
  transform: rotate(90deg) !important;
}

::v-deep .v-icon.notranslate.v-data-table__expand-icon {
  color: green;
  margin: 0 10px;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #beebc9;
  padding: 0 0 0 15px !important;
  height: 25px;
  font-size: 12px;
}

::v-deep thead th:first-child {
  border-radius: 5px 0 0 0;
}

::v-deep thead th:last-child {
  border-radius: 0 5px 0 0;
}

.active {
  background-color: green !important;
  color: white !important;
  opacity: 1;
}

.active-filter {
  background-color: #f5f5f5 !important;
  color: green !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr:first-child:hover > td:first-child {
  border-top-left-radius: 0 !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr:first-child:hover > td:last-child {
  border-top-right-radius: 0 !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded > td {
  border-bottom: none !important;
}

::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  background-color: #fafffb !important;
}

</style>
