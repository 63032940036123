<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header class="pl-3">
        <span class="text-subtitle-2" style="vertical-align: sub">
          <v-icon left style="width:30px" color="red">
            mdi-alert-outline
          </v-icon>
          {{ $t( _.get(analysisData[0], 'value')) }}
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card flat>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(item, i) in analysisData.slice(1)"
                  :key="item.field"
                  :class="{ 'dark': (i % 2 !== 0) }"
                >
                  <td class="d-flex align-center text-truncate" style="color: #747474;">
                    <v-icon
                      small
                      class="mr-2"
                      color="red"
                    >
                      {{ item.icon }}
                    </v-icon>
                    {{ $t(item.field) }}
                  </td>
                  <td class="text-truncate font-weight-medium" style="text-align: right">
                    <span
                      class="ml-3"
                      :style="[{ color: getTextColor(item.value) }]"
                    >
                      {{ $t(item.value) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { models } from 'feathers-vuex';

const { CsiAnalysis } = models.api;

export default {
  name: 'PartAnalysisTable',
  props: {
    part: Object,
  },
  computed: {
    analysisData() {
      let tableData = [];
      if (this.part) {
        tableData = _.get(CsiAnalysis.getFromStore(this.part.Item), 'data', []);
      }
      if (_.isEmpty(tableData)) {
        tableData = [
          { field: null, value: '3dSideViewer.warningErrors.title' },
          { field: '3dSideViewer.warningErrors.unfolding', value: '-' },
          { field: '3dSideViewer.warningErrors.warnings', value: '-' },
          { field: '3dSideViewer.warningErrors.errors', value: '-' },
        ];
      } else {
        tableData = tableData.filter(datum => datum.Category === 'S1')
          .filter(datum => (datum.Description ? datum.Description.startsWith('T3') : ''))
          .sort((datum1, datum2) => +datum1.Rank - +datum2.Rank)
          .map(datum => ({ field: datum.Description.replace('T3.', ''), value: datum.Value || '-' }));
      }
      const icons = [
        'mdi-alert-outline',
        'mdi-alert',
        'mdi-alert-circle',
      ];

      return tableData.map((row, index) => ({ ...{ icon: icons[index - 1] }, ...row }));
    }
  },
  methods: {
    getTextColor(value) {
      value = value.split('.').pop();
      switch (value) {
        case 'pass':
        case 'yes':
          return 'green';
        case 'fail':
        case 'no':
          return 'red';
        default:
          return '';
      }
    }
  }
};
</script>
