<template>
  <v-row>
    <v-col
      v-for="(productOption) in productOptions"
      :key="`${productOption.id}`"
      align-self="end"
      class="pa-0 text-truncate"
    >
      <div
        :data-cy="`product-option-readonly-label-${productOption.id}`"
        class="caption flex-nowrap d-flex justify-center align-center"
      >
        <span
          style="color:rgba(0,0,0,0.6);"
          class="text-truncate"
        >
          {{ productOption[locale === 'cn' ? 'labelChs' : 'label'].split('(')[0] }}
        </span>
      </div>
      <div
        :data-cy="`product-option-readonly-value-${productOption.id}`"
        class="text-truncate d-flex justify-center align-center"
      >
        <span :data-cy="`product-${productOption.label.split(' ')[0].toLowerCase()}-value`">
          {{
            getReadOnlyProduct(productOption)[locale === 'cn' ? 'textChs' : 'text'].slice(0, 10)
          }}
          <template v-if="getReadOnlyProduct(productOption)[locale === 'cn' ? 'textChs' : 'text'].length >10">
            <br>
            <span>
              {{
                getReadOnlyProduct(productOption)[locale === 'cn' ? 'textChs' : 'text'].slice(10)
              }}
            </span>
          </template>
        </span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapState } from 'vuex';

export default {
  name: 'ProductOptionReadOnly',

  props: {
    itemProduct: {
      type: Object,
      required: true
    },

    currentCategoryId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      reqQty: 1,
      customBag: [],
    };
  },

  computed: {
    ...mapState('app', ['locale']),

    products: () => models.api.HardwareProduct,

    HardwareCategory: () => models.api.HardwareCategory,

    currentCategory: vm => vm.HardwareCategory.getFromStore(vm.currentCategoryId),

    productOptions: vm => (vm.currentCategory ? vm.currentCategory
      .attributes
      .filter(attribute => attribute.attributeTypes[0].attributeType === 'productgroupAttribute')
      .sort((option1, option2) => parseInt(option1.rank, 10) - parseInt(option2.rank, 10)) : []),

    productReadOnlyOptions: vm => (vm.productOptions ? vm.productOptions
      .filter(option => option.attributeTypes[0].fieldType === 'readonly') : []),

    getReadOnlyProduct: vm => productOption => {
      const product = vm.products.getFromStore(vm.itemProduct._id);
      if (!product) {
        return {};
      }
      return productOption.options.find(value => value.id === product[productOption.id]) || {};
    },

  },

};
</script>
