<template>
  <div style="display: inline">
    <div v-if="stlReady" style="display: inline">
      <v-btn
        text
        small
        class="pr-1"
        :loading="isStlRemoved"
        color="primary"
      >
        STL file uploaded
      </v-btn>
      <v-icon v-if="!isStlRemoved" small @click="showStlRemoveDialog = true">
        mdi-close
      </v-icon>
    </div>
    <template v-else>
      <v-btn
        text
        small
        :loading="isStlRemoved"
        color="primary"
        @click="display=true;"
      >
        <v-icon left size="25">
          mdi-file-cad
        </v-icon>
        Upload STL File
      </v-btn>
    </template>

    <core-dialog
      v-model="showStlRemoveDialog"
      type="confirmation"
      width="500px"
      :is-loading="isStlRemoved"
      :ok-button-text="$t('common.delete')"
      @click:ok="removeSTL"
      @click:cancel="showStlRemoveDialog =false"
    >
      <template #title>
        Delete STL File?
      </template>
      <template #content>
        <div class="pa-10 text-center">
          <span
            class="d-block"
            v-html="$t('common.deleteDialogText', { name: 'STL File' })"
          />
        </div>
      </template>
    </core-dialog>
    <core-dialog
      v-if="display"
      v-model="display"
      type="custom"
      width="1000px"
      :eager="true"
      :is-loading="isStlUploadPending"
      :is-ok-disabled="!isFileAdded"
      @click:ok="display = false;"
      @click:cancel="display = false;removeSTL()"
    >
      <template #title>
        Upload STL File
      </template>
      <template #content>
        <div ref="myDialog" class=" text-center">
          <v-card
            class="mt-5"
            height="150"
            flat
            style="border: dashed #D7D7D7 2px;
              background-color: #F1FDF3;
              min-width: 905px; max-width: 1000px;"
          >
            <v-card-text class="d-flex flex-column justify-space-between align-center" style="height: 100%">
              <div class="d-flex align-center">
                <v-icon color="green" size="60">
                  mdi-cloud-upload-outline
                </v-icon>
                <span class="headline mx-4">
                  {{ $t('views.instantQuote.dragFilesToUpload') }}
                </span>
                <v-btn
                  id="dropzone-click-target-stl"
                  depressed
                  text
                  outlined
                  color="success"
                  style="background-color: #FFF"
                >
                  {{ $t('views.instantQuote.browse') }}
                </v-btn>
              </div>
              <div class="d-flex body-2" style="color:#4B4B4B">
                File Supported: STL
              </div>
            </v-card-text>
          </v-card>
          {{ $t('myPartPdf.SingleFileOnly') }}
        </div>
      </template>
    </core-dialog>

    <v-overlay :value="isSTLDownload" z-index="90" />
  </div>
</template>

<script>
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'StlFileUpload',
  props: {
    stlUrl: {
      type: String,
    },
  },
  data() {
    return {
      stlReady: false,
      previewSTLUrl: '',
      isStlUploadPending: false,
      isFileAdded: false,
      showStlRemoveDialog: false,
      myDropZone: null,
      display: false,
      isStlRemoved: false,
      isSTLDownload: false
    };
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    dropzoneOptions: vm => ({
      url: `${process.env.VUE_APP_API_URL}upload`,
      paramName: 'file',
      headers: {
        Authorization: vm.accessToken,
      },
      thumbnailWidth: 150,
      maxFilesize: 20,
      timeout: 9999999,
      maxFiles: 1,
      parallelUploads: 1,
      acceptedFiles: '.stl,.STL',
      dictDefaultMessage: 'dropZone.dictDefaultMessage',
      dictFallbackMessage: 'dropZone.dictFallbackMessage',
      dictFallbackText: 'dropZone.dictFallbackText',
      dictFileTooBig: 'dropZone.dictFileTooBig',
      dictInvalidFileType: 'dropZone.dictInvalidFileType',
      dictResponseError: 'dropZone.dictResponseError',
      dictCancelUpload: 'dropZone.dictCancelUpload',
      dictCancelUploadConfirmation: 'dropZone.dictCancelUploadConfirmation',
      dictRemoveFile: 'dropZone.dictRemoveFile',
      dictMaxFilesExceeded: 'dropZone.dictMaxFilesExceeded',
      clickable: '#dropzone-click-target-stl',
      previewTemplate: vm.template(),
      renameFile: file => `${vm.getNewUuid()}.${_.last(file.name.split('.'))}`,
      init() {
        this.on('error', async file => {
          this.removeFile(file);
        });
        this.on('success', async file => {
          if (file.upload) {
            vm.previewSTLUrl = `${process.env.VUE_APP_API_URL}uploads/${file.upload.filename}`;
            vm.$emit('stlUrl', vm.previewSTLUrl);
          }
          vm.isStlUploadPending = false;
          vm.display = false;
        });

        this.on('sending', () => {
          vm.isStlUploadPending = true;
        });

        this.on('addedfile', () => {
          vm.stlReady = true;
          vm.isFileAdded = true;
        });
      }

    }),
  },
  watch: {
    display(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.myDropZone = new Dropzone(this.$refs.myDialog, this.dropzoneOptions);
        }, 800);
      } else {
        this.myDropZone = null;
      }
    },
    stlUrl(val) {
      if (val) {
        this.stlReady = true;
        this.previewSTLUrl = this.stlUrl;
      } else {
        this.stlReady = false;
      }
    }
  },

  methods: {
    getNewUuid() {
      return uuidv4();
    },
    async onFileChanged(e) {
      const [pdfFile] = e.target.files;
      this.previewSTLUrl = URL.createObjectURL(pdfFile);
      this.$emit('stlUrl', this.previewSTLUrl);
      this.stlReady = true;
    },
    async removeSTL() {
      URL.revokeObjectURL(this.previewSTLUrl);
      this.stlReady = false;
      this.showStlRemoveDialog = false;
      this.previewSTLUrl = '';
      this.$emit('stlUrl', this.previewSTLUrl);
      this.isFileAdded = false;
    },
    template() {
      return `
             <div class="d-flex align-start flex-column mt-16">
                <p style="vertical-align: bottom">Document selected</p>
                <p id="test" class="pa-2" style="background-color: #dee0e4; display: inline">
                  <span class="mdi mdi-open-in-new font-weight-medium" style="vertical-align: middle; color:#2c984e; font-size:1.2rem"></span>
                  <span class="font-weight-bold" style="vertical-align: bottom; color:#2c984e; font-size:1rem" data-dz-name></span>
                </p>
             </div>`;
    }
  },
};
</script>
