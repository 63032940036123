<template>
  <div class="filter-checkbox">
    <v-radio-group v-model="localValue">
      <v-subheader>
        {{ section }}
      </v-subheader>

      <v-list-item
        v-for="(option, i) in $attrs.options"
        :key="i"
        dense
      >
        <v-list-item-action>
          <v-radio :value="option" />
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>
            {{ option }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-radio-group>
  </div>
</template>

<script>
import FilterMixin from '../../../mixins/filter';

export default {
  name: 'RadioGroup',

  mixins: [
    FilterMixin,
  ],
};
</script>

<style scoped>

</style>
