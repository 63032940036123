<template>
  <v-expand-x-transition>
    <div style="position: fixed; bottom: 22px; left: 230px; z-index: 5;">
      <v-btn
        v-for="(button, index) in buttons"
        :key="index"
        depressed
        class="mr-7"
        v-bind="button.props"
        @click="button.action"
      >
        {{ button.text }}
      </v-btn>
    </div>
  </v-expand-x-transition>
</template>

<script>
import {
  defineComponent
} from '@vue/composition-api';

export default defineComponent({
  name: 'FooterButtonActions',
  props: {
    buttons: Array,
  },
});
</script>

<style scoped>
  .v-btn {

  }
</style>
