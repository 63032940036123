<template>
  <v-container class="fill-height" data-cy="no-content">
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <div class="d-flex flex-column align-center">
          <v-img
            :src="'/img/empty.png'"
            width="573"
            height="328"
            class="mb-8"
          />

          <span class="title font-weight-bold">
            {{ title }}
          </span>

          <span class="subtitle-1">
            {{ description }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NoContent',

  props: {
    title: {
      type: String,
      default: 'No content',
    },

    description: {
      type: String,
      default: 'This page is empty',
    },
  },
};
</script>

<style scoped>

</style>
