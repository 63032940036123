import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import 'vuetify/dist/vuetify.min.css';

import en from 'vuetify/src/locale/en.ts';
import fr from 'vuetify/src/locale/fr.ts';

import i18n from './i18n';

Vue.use(Vuetify);

export default new Vuetify({

  icons: {
    iconfont: 'mdi',
  },

  lang: {
    locales: { en, fr },
    current: i18n.locale.slice(0, i18n.locale.indexOf('-')),
  },

  theme: {
    themes: {
      light: {
        // primary: '#4caf50',
        // TODO decide on color in code review. Invision design is inconsistent
        primary: '#2C984E',
        // secondary: '#4caf50',
        tertiary: '#495057',
        accent: '#82B1FF',
        error: '#B00020',
        info: '#00d3ee',
        success: '#60ce79',
        warning: '#ffa21a'
      },
      dark: {
        primary: '#f5f5f5',
        secondary: '#f5f5f5',
        tertiary: '#f5f5f5',
        accent: '#f5f5f5',
        error: '#B00020',
        info: '#00d3ee',
        success: '#60ce79',
        warning: '#ffa21a'
      },
    },
  },
});
