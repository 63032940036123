export default {
  props: {
    value: {},
    section: String,
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    }
  },
};
