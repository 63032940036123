// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'company';

const Model = class Company extends BaseModel {
  static modelName = 'Company';

  static instanceDefaults() {
    return {
    };
  }

  static setupInstance(data) {
    if (data.region) {
      data.region = data.region.split('-').pop();
    }
  }

  get currencySymbol() {
    return this.CurrCode === 'CNY' ? '¥' : '$';
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
