import localeCn from 'dayjs/locale/zh-cn';
import localeEn from 'dayjs/locale/en';
import { models } from 'feathers-vuex';
import { reset, setStorage } from '../utils/vuex';
import rights from '../rights';
import vuetify from '../plugins/vuetify';

const getDefaultLanguage = () => {
  const localLanguage = (
    localStorage.locale
    || window.navigator.userLanguage
    || window.navigator.language
    || 'en-US'
  );
  return (localLanguage === 'zh' || localLanguage === 'cn') ? 'cn' : 'en-US';
};

const state = {
  color: 'success',
  title: 'Komacute',
  sidebarBackgroundColor: 'rgba(27, 27, 27, 0.74)',
  rights,
  locale: getDefaultLanguage(),
  version: process.env.PACKAGE_VERSION || '0.0.0',
  messages: [],
  currentOrder: null
};

const setLocale = async (context, { $i18n, locale, $FeathersVuex }) => {
  context.commit('SET_LOCALE', locale);
  $i18n.locale = locale;
  const translationDate = locale === 'cn' ? localeCn : localeEn;
  moment.locale(translationDate);
  vuetify.framework.lang.current = locale;

  if (!$FeathersVuex) {
    return;
  }

  const userId = context.rootState.auth.user._id;
  const user = await $FeathersVuex.api.User.getFromStore(userId);

  if (user.locale === locale) {
    return;
  }

  user.locale = locale;
  user.save();
};

export default {
  plugin: () => {
  },

  store: {
    app: {
      namespaced: true,

      state,

      getters: {
        appVersion: s => s.version,
        currentOrder: s => s.currentOrder,
        currentOrderItemsCount: s => {
          if (!s.currentOrder || s.currentOrder.items.length === 0) {
            return null;
          }
          return s.currentOrder.items.length;
        }
      },

      mutations: {
        clearAll: reset(state),

        SET_IMAGE: setStorage('image'),
        SET_COLOR: setStorage('color'),

        SET_LOCALE: setStorage('locale'),

        PUSH_SNACK: (stateIn, payload) => {
          stateIn.messages.push(payload);
        },

        POP_SNACK: stateIn => {
          stateIn.messages.pop();
        },

        ACKNOWLEDGE_SNACK_BY_ID: (stateIn, id) => {
          stateIn.messages.splice(stateIn.messages.map(m => m.id).indexOf(id), 1);
        },

        SET_CURRENT_ORDER: (stateIn, order) => {
          stateIn.currentOrder = order;
        }
      },

      actions: {
        setColor(context, color) {
          context.commit('SET_COLOR', color);
        },

        setImage(context, img) {
          context.commit('SET_IMAGE', img);
        },

        setLocale(context, { $i18n, locale, $FeathersVuex }) {
          setLocale(context, { $i18n, locale: locale || 'en-US', $FeathersVuex });
        },

        toggleLocale(context, { $i18n, $FeathersVuex }) {
          const locale = context.state.locale === 'fr' ? 'en-US' : 'fr';
          setLocale(context, { $i18n, locale: locale || 'en-US', $FeathersVuex });
        },

        pushSnack(context, snack) {
          context.commit('PUSH_SNACK', { ...snack, id: _.uniqueId() });
        },

        popSnack(context) {
          context.commit('POP_SNACK');
        },

        /**
         * Removes the snack with the given id from the snack queue
         * @param context
         * @param id
         */
        acknowledgeSnackById(context, id) {
          context.commit('ACKNOWLEDGE_SNACK_BY_ID', id);
        },

        initCurrentOrder: context => {
          if (!context.getters.currentOrder) {
            context.commit('SET_CURRENT_ORDER', new models.api.Order());
          }

          return context.getters.currentOrder;
        },

        clearCurrentOrder: context => {
          context.commit('SET_CURRENT_ORDER', null);
        }
      },
    },
  },
};
