<template>
  <v-container style="margin-top: 25px; width:900px">
    <v-row justify="center">
      <div class="text-center">
        <p class="display-1 mb-4">
          {{ $t('views.signUp.welcomeToKomacut') }}
        </p>

        <p class="mb-4">
          {{ $t('views.signUp.enterInformation') }}
        </p>
      </div>
    </v-row>

    <v-form ref="form" v-model="validation">
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
            v-model="userData._id"
            outlined
            dense
            data-cy="email-input"
            :label="$t('common.email')"
            :disabled="invitationMail"
            :placeholder="$t('views.signUp.sameEmailAsInvited')"
            :rules=" [isRequired]"
            required
            hide-details
          />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          offset="1"
          cols="10"
        >
          <v-text-field
            v-model="userData.password"
            :label="$t('views.signUp.password')"
            :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            :type="isPasswordVisible ? 'text' : 'password'"
            :rules="[isRequired]"
            outlined
            dense
            required
            hide-details
            data-cy="password-input"
            @click:append="isPasswordVisible = !isPasswordVisible"
          />
        </v-col>
        <v-col cols="1" class="pa-0 align-center">
          <div class="text-center mt-7" style="width: 100px">
            <p class="text-center caption " style="margin:0">
              {{ $t('views.signUp.strength') }}
            </p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  color="primary"
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <p>{{ $t('views.resetPassword.mustContain') }}</p>
              <ul>
                <li>{{ $t('views.resetPassword.minCharacters') }}</li>
                <li>{{ $t('views.resetPassword.oneLower') }}</li>
                <li>{{ $t('views.resetPassword.oneUpper') }}</li>
                <li>{{ $t('views.resetPassword.oneDigit') }}</li>
                <li>{{ $t('views.resetPassword.oneSpecial') }}</li>
              </ul>
            </v-tooltip>
            <span
              v-for="value in force"
              :key="value"
              class="dot"
              :style="[ value < passwordStrength(userData.password) ? { 'background-color':getColor(passwordStrength(userData.password))} : { 'background-color':'#D4D8DC'}]"
            />
          </div>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          cols="10"
        >
          <v-text-field
            v-model="confirmPassword"
            data-cy="confirm-password-input"
            :label="$t('views.signUp.confirmPassword')"
            :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            :type="isPasswordVisible ? 'text' : 'password'"
            :rules="[(userData.password === confirmPassword) || $t('views.signUp.passwordMustMatch')]"
            outlined
            dense
            required
            hide-details
            @click:append="isPasswordVisible = !isPasswordVisible"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row justify="center">
      <v-col cols="10">
        <v-btn
          :loading="statePending"
          :disabled="!isFormValid"
          color="success"
          type="submits"
          large
          block
          data-cy="create-account-button"
          @click="submit"
        >
          {{ $t('views.signUp.createAccount') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { models } from 'feathers-vuex';
import { mapActions, mapState } from 'vuex';
import FormRules from '@/mixins/form-rules';
import i18n from '@/plugins/i18n';
import { LEVEL } from '../utils/log';

const { User } = models.api;

export default {
  name: 'OnBoarding',

  mixins: [
    FormRules,
  ],

  data() {
    return {
      force: [20, 40, 60, 80, 100],
      token: '',
      confirmPassword: '',
      isPasswordVisible: false,
      statePending: false,
      email: '',
      onBoardingUser: {},
      invitationMail: false,
      loading: false,
      confirmationPassword: '',
      validation: false,
      firstName: '',
      lastName: '',
      userData: {
        _id: '',
        FirstName: '',
        LastName: '',
        officePhone: '',
        password: '',
        ContactEmail: '',
      },
    };
  },

  computed: {
    ...mapState('app', ['locale']),
    isFormValid() {
      return this.validation && this.isPasswordValid;
    },
    isPasswordValid() {
      return this.passwordStrength(this.userData.password) >= 100;
    }
  },
  created() {
    const navLang = navigator.language;
    let currentLang = navLang.split('-')[0];
    // The standard for Chinese localization is not cn but zh cf :
    // http:// www.lingoes.net/en/translator/langcode.htm
    currentLang = (currentLang === 'zh') ? 'cn' : 'en-US';
    i18n.locale = currentLang;
    const { token, id, fn, ln } = this.$route.query;
    this.token = token;
    this.userData.lastName = fn;
    this.userData.firstName = ln;
    this.invitationMail = !!id;
    this.userData._id = id || '';
  },

  methods: {
    ...mapActions('app', ['pushSnack', 'setLocale']),
    getColor(force) {
      if (force >= 60 && force < 80) {
        return '#C77500';
      }
      if (force >= 80) {
        return '#036F35';
      }
      return '#B00020';
    },
    passwordStrength(pass) {
      let score = 0;
      if (!pass) {
        return score;
      }

      // award every unique letter until 5 repetitions
      const letters = {};
      for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
      }

      // bonus points for mixing it up
      const variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
      };

      let variationCount = 0;
      // eslint-disable-next-line guard-for-in
      for (const check in variations) {
        variationCount += variations[check] === true ? 1 : 0;
      }
      score += (variationCount - 1) * 20;

      return parseInt(score, 10);
    },
    async invitationAccount(token) {
      this.statePending = true;
      try {
        await this.$feathers.service('authManagement').create({
          action: 'verifySignupSetPasswordLong',
          value: { token, password: this.userData.password }
        });

        // TODO logout & go to the login page or keep login & go to the dashboard
        await this.$store.dispatch('auth/logout');
        this.pushSnack({
          snack: { message: this.$t('views.signUp.signUpSuccessMessage') },
          level: LEVEL.SUCCESS,
        });

        this.statePending = false;
        this.$router.push('/login');
      } catch (error) {
        this.statePending = false;
        console.error('Error authenticating!', error);
      }
    },

    createUser() {
      this.statePending = true;
      this.userData.ContactEmail = this.userData._id;
      this.onBoardingUser = new User(this.userData);
      this.onBoardingUser.create().then(() => {
        this.statePending = false;
      });
    },

    onBoardingAccount() {
      if (this.token) {
        this.invitationAccount(this.token);
      } else {
        this.createUser();
      }
    },
    submit() {
      if (this.validation) {
        this.onBoardingAccount();
      } else {
        this.$refs.form.validate();
      }
    }
  },
};
</script>
<style>
.dot {
    height: 10px;
    width: 10px;
    background-color: #D4D8DC;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}
</style>
