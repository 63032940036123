<template>
  <div>
    <v-card
      :ripple="false"
      style="min-width: 730px;"
      @click="isExpanded = !isExpanded"
    >
      <v-container style="display: grid; grid-template-columns: min-content auto min-content;">
        <v-img
          src="https://www.svgrepo.com/show/203564/screw.svg"
          max-height="60px"
          max-width="78px"
          style="align-self: start; margin-top: 8px;"
        />
        <div>
          <div class="text-h4 font-weight-bold">
            {{ productGroup[locale === 'en-US' ? 'description' : 'descriptionChs'] }}
          </div>
          <div class="subtitle-1">
            {{ productGroup[locale === 'en-US' ? 'description' : 'descriptionChs'] }}
          </div>
        </div>
        <v-icon v-if="isExpanded" x-large style="align-self: start;">
          mdi-chevron-down
        </v-icon>
        <v-icon v-else x-large style="align-self: start;">
          mdi-chevron-right
        </v-icon>
      </v-container>
      <v-expand-transition>
        <v-card
          v-if="isExpanded"
          flat
        >
          <v-container style="display: flex; gap: 16px; align-items: center; flex-wrap: wrap;">
            <v-btn
              depressed
              text
              color="primary"
              x-large
              @click.stop="isDrawingsDialogOpen = true"
            >
              <v-icon
                left
              >
                mdi-vector-square
              </v-icon>
              Drawings
            </v-btn>

            <template v-for="productOption in productOptions">
              <v-select
                v-if="productOption.attributeTypes[0].fieldType === 'dropdown'"
                :key="`dropdown-${productOption.id}`"
                v-model="selectedOptions[productOption.id]"
                style="max-width: 150px;"
                :label="productOption[locale === 'en-US' ? 'label' : 'labelChs' ]"
                :items="productOption.options"
                :item-text="locale === 'en-US' ? 'text' : 'textChs'"
                outlined
                hide-details
                @click.stop
              />
              <v-checkbox
                v-else-if="productOption.attributeTypes[0].fieldType === 'checkbox'"
                :key="`checkbox-${productOption.id}`"
                v-model="selectedOptions[productOption.id]"
                style="max-width: 150px; padding-top: 0; margin-top: 0;"
                :label="productOption[locale === 'en-US' ? 'label' : 'labelChs' ]"
                hide-details
                @click.stop
              />
              <div
                v-if="productOption.attributeTypes[0].fieldType === 'readonly'"
                :key="`plainText-${productOption.id}`"
                style="display: flex; flex-direction: column; max-width: 150px; align-self: stretch;"
              >
                <div style="color: rgba(0, 0, 0, 0.6);" class="caption">
                  {{ productOption[locale === 'en-US' ? 'label' : 'labelChs'] }}
                </div>
                <div>
                  {{
                    productOption.options[0][locale === 'en-US' ? 'text' : 'textChs']
                  }}
                </div>
              </div>
            </template>
          </v-container>
          <v-container style="display: flex; gap: 16px; align-items: center;" class="pt-0">
            <v-text-field
              v-model.number="reqQty"
              style="max-width: 110px;"
              class="d-flex"
              data-cy="quantity-input-upload"
              :label="$t('views.instantQuote.quantity')"
              :rules="[isPositiveInteger]"
              type="number"
              hide-details
              outlined
              @input.native="reqQty = Math.max(Math.abs(Math.trunc($event.target.value)), 0);"
              @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
              @click.stop
            />

            <div
              style="display: flex; flex-direction: column; justify-content: center; align-items: start; background-color: #f0fdf3; border-radius: 8px; align-self: stretch; padding: 8px;"
            >
              <div class="subtitle-2" style="color: #8D8D8D">
                Total Price ($)
              </div>
              <div>144.00</div>
            </div>

            <v-btn
              outlined
              x-large
              color="primary"
              :disabled="!customBagId || !selectedProduct"
              data-cy="add-to-custom-bag-button"
              @click.stop="$emit('add-to-bag', selectedProduct)"
            >
              Add to custom bag
            </v-btn>

            <v-btn
              color="primary"
              x-large
              @click.stop="$emit('add-to-order')"
            >
              Add to order
            </v-btn>
          </v-container>
        </v-card>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FormRules from '@/mixins/form-rules';

export default {
  name: 'HardwareProductGroup',

  mixins: [
    FormRules,
  ],

  // These can be computed properties that are pulled from the store based on ID
  props: ['productGroup', 'productOptions', 'productGroupOptionValues', 'customBagId'],

  data() {
    return {
      selectedOptions: {},
      isExpanded: false,
      reqQty: 1,
      isDrawingsDialogOpen: false,
    };
  },

  computed: {
    ...mapState('app', ['locale']),

    // Definitely a more efficient way to do this
    drawingsDialogTableHeaders: vm => vm.productOptions
      .map(attribute => ({
        text: attribute[vm.locale === 'en-US' ? 'label' : 'labelChs'],
        id: attribute.id,
        // value: attribute.value,
      })),

    selectedProduct: () => true,

    optionsProduct: vm => vm.productOptions.map(options => options.options)

    // selectedProduct: vm => vm.productGroup.products.find(
    //   product => Object.entries(vm.selectedOptions).every(
    //     ([key, value]) => product.attributes.find(
    //       attribute => attribute.id === key && attribute.value === value
    //     )
    //   ),
    // ),
  },

  mounted() {
    const sensibleDefaults = {
      dropdown: '',
      checkbox: false,
      plainText: '',
    };

    this.productOptions.forEach(productOption => {
      this.$set(
        this.selectedOptions,
        productOption.id,
        productOption.attributeTypes[0].fieldType === 'plainText'
          ? this.productGroupOptionValues[this.productGroup.id][productOption.id][0].value
          : sensibleDefaults[productOption.attributeTypes[0].fieldType]
      );
    });
  },

  methods: {
    getFormElementFromFieldType: fieldType => {
      switch (fieldType) {
        case 'dropdown':
          return 'v-combobox';
        case 'checkbox':
          return 'v-checkbox';
        case 'plainText':
          return 'p';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #beebc9;
  padding: 0 0 0 15px !important;
  height: 25px !important;
}
</style>
