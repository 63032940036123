<template>
  <div>
    <core-dialog
      :value="isPaymentDialogOpen"
      :is-loading="activePayment && activePayment.isSavePending"
      :width="company.CurrCode === 'CNY' ? 750 : 600"
      type="confirmation"
      persistent
      :ok-button-text="$t('views.editOrder.acceptAndProcess')"
      @click:cancel="cancelActivePayment"
    >
      <template #title>
        <span>{{ $t('views.editOrder.termsAcceptance') }}</span>
      </template>

      <template #content>
        <div class="text-center">
          <div v-html="$t('views.editOrder.termsAcceptance1')" />
          <br>
          <div v-html="$t('views.editOrder.termsAcceptance2')" />
          <br>
          <div v-html="$t('views.editOrder.termsAcceptance3')" />
          <br>
          <div v-html="$t('views.editOrder.termsAcceptance4')" />
        </div>
      </template>

      <template #actions>
        <template v-if="company.CurrCode === 'CNY' && company.PaymentTerms === 'Ful'">
          <v-btn
            color="primary"
            :disabled="activePayment.isSavePending || isIntBankTransferPending"
            data-cy="bank-card-payment-button"
            @click="generatePaymentUrl('0')"
          >
            <v-icon left>
              mdi-credit-card-outline
            </v-icon>
            {{ $t('views.editOrder.bankCard') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="activePayment.isSavePending || isIntBankTransferPending"
            data-cy="ebank-payment-button"
            @click="generatePaymentUrl('2')"
          >
            <v-icon left>
              mdi-bank
            </v-icon>
            {{ $t('views.editOrder.eBank') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="activePayment.isSavePending || isIntBankTransferPending"
            data-cy="wechat-payment-button"
            @click="generatePaymentUrl('I')"
          >
            <img
              src="/img/wechat_logo.svg"
              height="18"
              width="18"
              style="margin-right: 8px;"
            >
            {{ $t('views.editOrder.weChat') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isIntBankTransferPending || activePayment.isSavePending"
            :loading="isIntBankTransferPending"
            data-cy="usd-payment-button"
            @click="goToComfirmationPage"
          >
            <v-icon left>
              mdi-bank
            </v-icon>
            {{ $t('views.editOrder.bankTransfer') }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            color="primary"
            :disabled="activePayment.isSavePending || isIntBankTransferPending"
            data-cy="usd-payment-button"
            @click="generatePaymentUrl"
          >
            <v-icon left>
              mdi-credit-card-outline
            </v-icon>
            {{ $t('views.editOrder.creditCard') }}
          </v-btn>

          <v-btn
            color="primary"
            :disabled="isIntBankTransferPending || activePayment.isSavePending"
            :loading="isIntBankTransferPending"
            data-cy="usd-payment-button"
            @click="goToComfirmationPage"
          >
            <v-icon left>
              mdi-bank
            </v-icon>
            {{ $t('views.editOrder.intBankTransfer') }}
          </v-btn>
        </template>

        <v-btn
          class="px-8"
          color="primary"
          text
          depressed
          :disabled="activePayment.isSavePending || isIntBankTransferPending"
          @click="cancelActivePayment"
        >
          {{ $t('common.cancel') }}
        </v-btn>
      </template>
    </core-dialog>

    <core-dialog
      :value="isQrCodeDialogOpen"
      type="custom"
      width="380px"
      height="500px"
      persistent
      eager
      @click:cancel="cancelActivePayment"
    >
      <template #title>
        <span>&nbsp;</span>
      </template>

      <template #content class="justify-center">
        <div
          class="d-flex justify-space-between text-uppercase px-6 py-3"
          style="background-color:#F1FDF3; font-size: 18px"
        >
          <span class="d-flex font-weight-medium">{{
            $t('views.orders.total', {currency: $t(`currency.${company.CurrCode}`)})
          }}</span>
          <span class="d-flex font-weight-bold black--text">
            {{ currency(activePayment.openBalance, {symbol: company.currencySymbol}).format() }}
          </span>
        </div>
        <div class="d-flex justify-center mt-6">
          <span class="d-flex caption text--secondary">
            {{ $t('views.editOrder.pleaseScanTheWeChatQRCode') }}
          </span>
        </div>
        <div id="qrCodeContainer" class="pa-10 text-center">
          <canvas id="qrCodeCanvas" />
        </div>
        <div class="d-flex justify-center">
          <img
            src="/img/Komacut-logo.svg"
            width="175px"
            height="80px"
          >
        </div>
      </template>
    </core-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { models } from 'feathers-vuex';
import currency from 'currency.js';
import QRCode from 'qrcode';

const { Company, Order } = models.api;

export default {
  name: 'PaymentDialog',

  data() {
    return {
      currency,
      isIntBankTransferPending: false,
    };
  },

  computed: {
    ...mapGetters('payments', {
      isPaymentDialogOpen: 'isPaymentDialogOpen',
      isQrCodeDialogOpen: 'isQrCodeDialogOpen',
      activePayment: 'activePayment',
    }),
    ...mapState('auth', [
      'user',
    ]),
    company: () => Company.getFromStore(0),
  },

  methods: {
    ...mapActions('payments', {
      cancelActivePayment: 'cancelActivePayment',
      setIsPaymentDialogOpen: 'setIsPaymentDialogOpen',
      setIsQrCodeDialogOpen: 'setIsQrCodeDialogOpen',
    }),
    ...mapActions('app', [
      'initCurrentOrder',
      'clearCurrentOrder'
    ]),

    // TODO: Handle logic for non upfront payments
    async generatePaymentUrl(paymentMethod) {
      const validPaymentTypes = ['0', '2', 'I'];
      if (validPaymentTypes.includes(paymentMethod)) {
        this.activePayment.provider.options.type = paymentMethod;

        if (paymentMethod !== 'I') {
          const { redirectUrl } = this.activePayment.provider.options;
          let updatedRedirectUrl = '';
          if (redirectUrl.includes('statements')) {
            updatedRedirectUrl = `${process.env.VUE_APP_API_URL}payment-redirect/statements/`;
          } else if (redirectUrl.includes('orders')) {
            updatedRedirectUrl = `${process.env.VUE_APP_API_URL}payment-redirect/orders/`;
          } else if (redirectUrl.includes('confirmed')) {
            updatedRedirectUrl = `${process.env.VUE_APP_API_URL}payment-redirect/confirmed/`;
          }
          this.activePayment.provider.options.redirectUrl = updatedRedirectUrl;
        }
      }

      await this.activePayment.save();
      if (paymentMethod === 'I') {
        this.setIsPaymentDialogOpen(false);
        this.setIsQrCodeDialogOpen(true);
        requestAnimationFrame(() => QRCode.toCanvas(
          document.getElementById('qrCodeCanvas'),
          this.activePayment.paymentUrl,
          { errorCorrectionLevel: 'H' },
          (err, canvas) => {
            if (err) {
              throw err;
            }
            const container = document.getElementById('qrCodeContainer');
            container.appendChild(canvas);
          }
        ));
      } else {
        window.open(this.activePayment.paymentUrl, '_self').focus();
      }
    },

    async goToComfirmationPage() {
      this.isIntBankTransferPending = true;
      await this.$feathers.service('email').create({
        EmailToValue: this.user._id,
        CommentValue: '',
        ReportTypeValue: 'DepositInvoice',
        EstimateValue: this.activePayment.CoNum,
      });
      await Order.find();
      this.isIntBankTransferPending = false;
      this.setIsPaymentDialogOpen(false);
      await this.$router.push({ path: `/order/confirmed?skipPaymentOrderId=${this.activePayment.CoNum}` });
      await this.clearCurrentOrder();
      this.initCurrentOrder();
    }
  },
};
</script>

<style scoped>

</style>
