<template>
  <v-container
    style="color: white; min-height: 650px; height: 96%; min-width: 650px;"
    class="primary px-15 rounded-r-xl"
  >
    <v-sheet
      tag="div"
      class="white--text primary"
      tile
    >
      <v-row class="font-weight-bold text-h5 mb-8 mt-10">
        {{ $t('promotional.title') }}
      </v-row>

      <v-row class="body-1 mb-8">
        {{ $t('promotional.caption') }}
      </v-row>

      <v-row class="font-weight-bold text-h6 mb-4">
        {{ $t('promotional.subTitle') }}
      </v-row>

      <v-row class="d-flex justify-center align-center mb-5">
        <v-col cols="6">
          <v-row class="d-flex align-center">
            <v-col class="pt-0" cols="12">
              <img
                style="vertical-align:middle"
                width="40px"
                src="/img/icon-lasercutting@1x.svg"
              >
              <span
                class="caption-2 pl-4"
                v-html="$t('promotional.expertise.laserCutting')"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center">
            <v-col class="pt-0" cols="12">
              <img
                style="vertical-align:middle"
                width="40px"
                src="/img/icon-threading@1x.svg"
              >
              <span
                class="caption-2 pl-4"
                v-html="$t('promotional.expertise.threadingChamfering')"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center">
            <v-col class="pt-0" cols="12">
              <img
                style="vertical-align:middle"
                width="40px"
                src="/img/icon-bending@1x.svg"
              >
              <span
                class="caption-2 pl-4"
                v-html="$t('promotional.expertise.sheetMetal')"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center">
            <v-col class="pt-0" cols="12">
              <img
                style="vertical-align:middle"
                width="40px"
                src="/img/icon-bulk@1x.svg"
              >
              <span
                class="caption-2 pl-4"
                v-html="$t('promotional.expertise.bulkFasteners')"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row class="d-flex align-center">
            <v-col class="pt-0" cols="12">
              <img
                style="vertical-align:middle"
                width="40px"
                src="/img/Surface Finishing icon@1x.svg"
              >
              <span
                class="caption-2 pl-3"
                v-html="$t('promotional.expertise.surfaceFinish')"
              />
            </v-col>
          </v-row>

          <v-row class="d-flex align-center">
            <v-col class="pt-0" cols="12">
              <img
                style="vertical-align:middle"
                width="38px"
                src="/img/icon-custom@1x.svg"
              >

              <span
                class="caption-2 pl-4"
                v-html="$t('promotional.expertise.customFastenersBag')"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center">
            <v-col class="pt-0" cols="12">
              <img
                style="vertical-align:middle"
                width="38px"
                src="/img/laserEngravingIcon.svg"
              >

              <span
                class="caption-2 pl-4"
                v-html="$t('promotional.expertise.laserEngraving')"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center">
            <v-col class="pt-0" cols="12">
              <img
                style="vertical-align:middle"
                width="40px"
                src="/img/icon-cnc-turning@1x.svg"
              >

              <span
                class="caption-2 pl-4"
                v-html="$t('promotional.expertise.cncTurning')"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-carousel
        :show-arrows="false"
        height="320px"
        class="primary rounded-r-xl"
        hide-delimiter-background
        hide-delimiters
      >
        <v-carousel-item>
          <div class="font-weight-bold text-center white--text">
            <v-avatar color="blue" size="80">
              <img src="/img/client.png">
            </v-avatar>
            <p class="mb-0 mt-4">
              {{ $t('promotional.clientComment') }}
            </p>
          </div>

          <div class="text-center white--text mt-4">
            <p class="mb-0 text-h6">
              Jonathan D.
            </p>
            <p class="mb-0 font-weight-medium body-2">
              {{ $t('promotional.clientPosition') }},
            </p>
            <p class="mb-0 font-weight-medium body-2">
              HivelQ / Form Studio
            </p>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'promotional',
};
</script>
