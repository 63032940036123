<template>
  <div style="display: inline">
    <div v-if="svgReady" style="display: inline">
      <v-btn
        text
        depressed
        class="pr-1"
        :loading="isSvgRemoved"
        color="primary"
        v-bind="buttonAttrs"
        @click="isSVGDownload=true"
      >
        <v-icon left size="20" v-bind="buttonIconAttrs">
          mdi-open-in-new
        </v-icon>
        {{ $t('svgDrawing.viewSvg') }}
      </v-btn>
    </div>
    <template v-else>
      <v-btn
        depressed
        text
        :disabled="!(part.DrawingRowPointer && part.Item)"
        :loading="isSvgRemoved"
        color="primary"
        v-bind="buttonAttrs"
        @click="display=true;"
      >
        <v-icon left size="25" v-bind="buttonIconAttrs">
          mdi-vector-square
        </v-icon>
        {{ $t('svgDrawing.uploadSvg') }}
      </v-btn>
    </template>
    <core-dialog
      v-if="display"
      v-model="display"
      type="confirmation"
      width="1000px"
      :is-ok-disabled="!isFileAdded"
      :eager="true"
      :ok-button-text="$t('common.upload')"
      :is-loading="isSvgUploadPending || isSvgUploadInProgress"
      @click:ok="uploadSvg(part)"
      @click:cancel="display = false;removeSVG()"
    >
      <template #title>
        {{ $t('svgDrawing.uploadSvg') }}
      </template>
      <template #content>
        <div ref="myDialog" class=" text-center">
          <v-card
            class="mt-5"
            height="150"
            flat
            style="border: dashed #D7D7D7 2px;
              background-color: #F1FDF3;
              min-width: 905px; max-width: 1000px;"
          >
            <v-card-text class="d-flex flex-column justify-space-between align-center" style="height: 100%">
              <div class="d-flex align-center">
                <v-icon color="green" size="60">
                  mdi-cloud-upload-outline
                </v-icon>
                <span class="headline mx-4">
                  {{ $t('views.instantQuote.dragFilesToUpload') }}
                </span>
                <v-btn
                  id="dropzone-click-target-svg"
                  depressed
                  text
                  outlined
                  color="success"
                  style="background-color: #FFF"
                >
                  {{ $t('views.instantQuote.browse') }}
                </v-btn>
              </div>
              <div class="d-flex body-2" style="color:#4B4B4B">
                {{ $t('svgDrawing.FileSupported') }}
              </div>
            </v-card-text>
          </v-card>
          {{ $t('myPartPdf.SingleFileOnly') }}
        </div>
      </template>
    </core-dialog>

    <v-overlay :value="isSVGDownload" z-index="90" />
    <v-navigation-drawer
      v-model="isSVGDownload"
      width="60%"
      fixed
      stateless
      hide-overlay
      right
      temporary
      style="background-color: #ada6a6;z-index: 99 !important;"
    >
      <div
        ref="rfqViewerContainer"
        style="height: calc(100% - 52px);"
      >
        <div class="d-flex justify-end">
          <v-btn
            icon
            x-large
            @click="isSVGDownload=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <core-svg-viewer
          v-if="isSVGDownload"
          :file-path="previewSVGUrl"
          :item-height="800"
        />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import { mapState } from 'vuex';

export default {
  name: 'UploadSvgDrawing',
  props: {
    part: {
      type: Object
    },
    buttonAttrs: {
      type: Object,
      default: () => {},
    },
    buttonIconAttrs: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      svgReady: false,
      previewSVGUrl: '',
      isSvgUploadPending: false,
      isFileAdded: false,
      showSvgRemoveDialog: false,
      myDropZone: null,
      svgPageHeight: 0,
      svgPageWidth: 0,
      display: false,
      isSvgRemoved: false,
      isSVGDownload: false,
      isSvgUploadInProgress: false,
    };
  },
  computed: {
    ...mapState('auth', ['accessToken']),
    dropzoneOptions: vm => ({
      url: '/',
      paramName: 'file',
      headers: {
        Authorization: vm.accessToken,
      },
      thumbnailWidth: 150,
      maxFilesize: 20,
      timeout: 9999999,
      maxFiles: 1,
      parallelUploads: 1,
      acceptedFiles: '.svg',
      autoProcessQueue: false,
      dictDefaultMessage: 'dropZone.dictDefaultMessage',
      dictFallbackMessage: 'dropZone.dictFallbackMessage',
      dictFallbackText: 'dropZone.dictFallbackText',
      dictFileTooBig: 'dropZone.dictFileTooBig',
      dictInvalidFileType: 'dropZone.dictInvalidFileType',
      dictResponseError: 'dropZone.dictResponseError',
      dictCancelUpload: 'dropZone.dictCancelUpload',
      dictCancelUploadConfirmation: 'dropZone.dictCancelUploadConfirmation',
      dictRemoveFile: 'dropZone.dictRemoveFile',
      dictMaxFilesExceeded: 'dropZone.dictMaxFilesExceeded',
      clickable: '#dropzone-click-target-svg',
      previewTemplate: vm.template(),
      init() {
        this.on('maxfilesexceeded', file => {
          this.removeAllFiles();
          this.addFile(file);
        });
        this.on('error', async file => {
          this.removeFile(file);
        });
        this.on('success', async () => {
          vm.isSvgUploadPending = false;
          vm.display = false;
          await vm.checkIfSvgExist();
        });

        this.on('sending', () => {
          vm.isSvgUploadPending = true;
        });

        this.on('addedfile', async file => {
          const removeButton = Dropzone.createElement('<span class="mdi mdi-close" style="vertical-align: middle; font-size: 1.5rem; cursor: pointer" ></span>');
          vm.previewSVGUrl = URL.createObjectURL(file);
          vm.$emit('svgUrl', vm.previewSVGUrl);
          vm.svgReady = true;
          vm.isFileAdded = true;
          removeButton.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.removeFile(file);
            vm.removeSVG();
          });

          file.previewElement.children[1].appendChild(removeButton);
        });
      }

    }),
  },
  watch: {
    display(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.myDropZone = new Dropzone(this.$refs.myDialog, this.dropzoneOptions);
        }, 800);
      } else {
        this.myDropZone = null;
      }
    },
    svgUrl(val) {
      if (val) {
        this.svgReady = true;
        this.previewSVGUrl = this.svgUrl;
      } else {
        this.svgReady = false;
      }
    }
  },

  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      /* 70 breathing room */
      this.svgPageWidth = this.$refs.rfqViewerContainer.getBoundingClientRect().width - 70;
      const ratio = 8.5 / 11;
      this.svgPageHeight = this.svgPageWidth / ratio;
    });
    resizeObserver.observe(this.$refs.rfqViewerContainer);
    // If file is already uploaded.
    if (this.part.SvgFile) {
      this.svgReady = true;
      const host = process.env.VUE_APP_API_URL;
      const fileName = this.part.SvgFile;
      this.svgUrl = `${host}2dDrawing/${fileName}/${this.part.DrawingRowPointer.toLowerCase()}/${this.accessToken}`;
      this.previewSVGUrl = this.svgUrl;
    }
  },
  methods: {
    async uploadSvg(part) {
      this.isSvgUploadInProgress = true;
      const file = await fetch(this.previewSVGUrl);
      const blob = await file.blob();
      const fd = new FormData();
      fd.append('file', blob, `${part.Item}-2D.svg`);
      const uploadUrl = `${process.env.VUE_APP_API_URL}upload?rowPointer=${part.DrawingRowPointer}&name=${part.Item}-2D&desc=SVG&ext=SVG&uploadToCsi=true`;

      // Need to refactor the upload service.
      // Don't forget this service is also used by Dropzone
      // in order to upload the drawing file to the CSI
      await fetch(uploadUrl, {
        method: 'POST',
        headers: {
          Authorization: this.accessToken,
        },
        body: fd,
      });
      this.isSvgUploadInProgress = false;
      this.display = false;
      // maintaining state
      this.part.SvgFile = fd.get('file').name;
      this.part.commit();
      console.log(uploadUrl);
    },

    async onFileChanged(e) {
      const [svgFile] = e.target.files;
      this.previewSVGUrl = URL.createObjectURL(svgFile);
      this.$emit('svgUrl', this.previewSVGUrl);
      this.svgReady = true;
    },
    async removeSVG() {
      URL.revokeObjectURL(this.previewSVGUrl);
      this.svgReady = false;
      this.showSvgRemoveDialog = false;
      this.previewSVGUrl = '';
      this.$emit('svgUrl', this.previewSVGUrl);
      this.isFileAdded = false;
    },
    template() {
      return `
             <div class="d-flex align-start flex-column mt-16">
                <p style="vertical-align: bottom">Document selected</p>
                <p id="test" class="pa-2" style="background-color: #dee0e4; display: inline">
                  <span class="mdi mdi-open-in-new font-weight-medium" style="vertical-align: middle; color:#2c984e; font-size:1.2rem"></span>
                  <span class="font-weight-bold" style="vertical-align: bottom; color:#2c984e; font-size:1rem" data-dz-name></span>
                </p>
             </div>`;
    }
  },
};
</script>
