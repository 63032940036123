<template>
  <v-row no-gutters align="center">
    <div v-if="product.myItem.ProductCode === 'HW-Bag'" class="body-2">
      <span class="font-weight-bold ml-2" data-cy="purchase-order-option">
        {{
          product.myItem.CustItemName || 'Bag Name Missing'
        }} - {{ product.myItem.CustItemDesc || 'Bag Description Missing' }}
      </span>
    </div>

    <div v-if="product.myItem.ProductCode === 'FG'" class="body-2">
      <span class="font-weight-bold ml-2" data-cy="purchase-order-option">
        {{ product.myItem.CustItemName }} -  {{ $t('common.revision', {revision: product.myItem.CustItemRevision}) }}
      </span>

      {{ product.materialTranslation ? `- ${product.materialTranslation}` : '' }}
      {{
        product.materialFinishTranslation || product.materialFinishOptionTranslation ? `- ${product.materialFinishTranslation || product.materialFinishOptionTranslation}` : ''
      }}
      {{ product.materialSeriesTranslation ? `- ${product.materialSeriesTranslation}` : '' }}
      {{ product.myItem.MaterialThicknessStr ? `- ${product.myItem.MaterialThicknessStr}` : '' }}
      {{ product.toleranceTranslation ? `- ${product.toleranceTranslation}` : '' }}
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'EditOrderDropDownInformation.vue',
  props: {
    product: {
      type: Object,
      default: () => {
      }
    }
  }
};
</script>

<style scoped>

</style>
