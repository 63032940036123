<template>
  <!-- 100px offset to account for the sidebar menu, which is 200px (so half) -->
  <v-snackbar
    :value="isOpen"
    v-bind="$attrs"
    :color="type"
    :multi-line="multi"
    :timeout="-1"
    app
    style="z-index: 99999;"
    :class="[{ 'offset-app-bar': !['SignUp', 'Login', 'Confirmation', 'OnBoarding', 'ForgottenPassword', 'ResetPassword'].includes($route.name) }]"
    top
  >
    <div class="d-flex align-center" data-cy="snack-message">
      <v-icon left>
        {{ icon }}
      </v-icon>
      {{ $t(message, {Param1: param1, Param2: param2}) }}
    </div>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { LEVEL } from '@/utils/log';

export default {
  data() {
    return {
      param1: '',
      param2: '',
      isOpen: false,
      type: LEVEL.INFO,
      message: '',
      multi: false,
      icon: 'mdi-information'
    };
  },

  computed: {
    ...mapState('app', [
      'messages',
    ]),
  },

  watch: {
    messages(messages) {
      if (this.isOpen || messages.length === 0) {
        return;
      }
      const { snack, level, id } = _.last(messages);

      this.type = level;
      switch (level) {
        case LEVEL.SUCCESS:
          this.icon = 'mdi-checkbox-marked-circle';
          break;
        case LEVEL.WARNING:
          this.icon = 'mdi-alert';
          break;
        case LEVEL.INFO:
        case LEVEL.ERROR:
          this.icon = 'mdi-information';
          break;
        default:
          // ESLint forces me to write a default case so... easter egg?
          this.icon = 'mdi-heart';
          break;
      }

      this.multi = snack.message.indexOf('\n') !== -1;
      this.message = snack.message.replace('\n', '<br>');
      this.param1 = snack.data ? snack.data.param1 : '';
      this.param2 = snack.data ? snack.data.param2 : '';
      this.isOpen = true;
      const duration = level === LEVEL.ERROR ? 5000 : 4000;
      setTimeout(() => {
        this.isOpen = false;
        // Allow for delay between messages
        setTimeout(() => {
          this.acknowledgeSnackById(id);
        }, 225);
      }, duration);
    },
  },

  methods: {
    ...mapActions('app', {
      acknowledgeSnackById: 'acknowledgeSnackById',
    }),
  },
};
</script>

<style scoped>
.offset-app-bar {
  top: -110px;
}
</style>
