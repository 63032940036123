<template>
  <v-list two-line>
    <v-list-item>
      <v-list-item-avatar>
        <v-skeleton-loader type="avatar" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          {{ part.partNo }}
          <v-btn
            icon
            text
            small
            class="mb-1"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-list-item-title>

        <v-list-item-subtitle>{{ part.filename }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn
          style="position:absolute; bottom: 60%;"
          icon
          @click="action"
        >
          <v-icon>{{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: ['name', 'part', 'expanded', 'action']
};
</script>
