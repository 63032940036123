// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'hardware-categories';

const Model = class HardwareCategory extends BaseModel {
    static modelName = 'HardwareCategory';

    static instanceDefaults() {
      return {};
    }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
