<template>
  <v-card>
    <v-toolbar
      color="primary"
      class="mb-4"
      flat
    >
      <v-card-title class="white--text">
        User
      </v-card-title>
    </v-toolbar>

    <v-card-text>
      <v-col class="py-0">
        <v-container>
          <v-row>
            <v-col
              class="py-0"
              md="6"
              cols="12"
            >
              <form-email
                v-model="localValue.email"
                :is-disabled="!!localValue._id"
                types-locale-key="form.email.types"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="localValue.PhoneOffice"
                v-mask="'(###) ###-####'"
                :label="$t('common.number')"
                :placeholder="$t(`form.phone.placeholders.number`)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="py-0"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="localValue.firstName"
                :label="$t('common.firstName')"
                :placeholder="$t('common.firstName')"
              />
            </v-col>

            <v-col
              class="py-0"
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="localValue.lastName"
                :label="$t('common.lastName')"
                :placeholder="$t('common.lastName')"
              />
            </v-col>

            <v-col
              class="py-0"
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="localValue.JobTitle"
                :label="$t('common.position')"
                :placeholder="$t(`form.contact.placeholders.position`)"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
            >
              <v-select
                v-if="isAdmin"
                v-model="localValue.roleIds"
                :label="$t('form.user.fields.roles')"
                :items="roles"
                :item-text="'name'"
                item-value="_id"
                item-disabled="inactive"
                chips
                clearable
                small-chips
                deletable-chips
                multiple
              />
            </v-col>
          </v-row>
        </v-container>

        <slot />
      </v-col>

      <!-- Will eventually be used -->
      <!--      <v-select-->
      <!--        v-if="isAdmin"-->
      <!--        v-model="localValue.locale"-->
      <!--        :label="$t('form.userPreferences.fields.lang')"-->
      <!--        :items="locales"-->
      <!--        :item-text="'text'"-->
      <!--        item-value="value"-->
      <!--        chips-->
      <!--        clearable-->
      <!--        small-chips-->
      <!--        deletable-chips-->
      <!--      />-->
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        color="primary"
        :disabled="!originalFormDataChanged || loading"
        :loading="loading"
        @click="save()"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import RolesMixin from '../../mixins/roles';
import FormMixin from '../../mixins/form';
import EditFormMixin from '../../mixins/editForm';
import { LEVEL } from '../../utils/log';

export default {
  mixins: [
    RolesMixin,
    FormMixin,
    EditFormMixin
  ],

  props: {
    loading: Boolean,
  },

  data() {
    return {
      isColorPickerActive: false,
    };
  },

  computed: {
    ...mapGetters('contacts', {
      findContacts: 'find'
    }),
    ...mapState('auth', [
      'user',
    ]),
  },

  methods: {
    ...mapActions('app', [
      'pushSnack'
    ]),
    refresh() {
      this.fetchRoles();
    },

    save() {
      const contact = this.findContacts({ query: { ContactId: this.localValue.ContactId } }).data;
      if (contact.length > 0 && contact[0].ContactId) {
        this.localValue.ContactId = contact[0].ContactId;
        this.$emit('save', this.localValue);
      } else if (this.localValue._id) {
        this.pushSnack({
          snack: { message: 'This user is not linked to any contacts.' },
          level: LEVEL.ERROR,
        });
      } else {
        this.$emit('save', this.localValue);
      }
    }
  },
};
</script>
