<template>
  <div style="min-width: 905px; max-width: 1000px;">
    <v-card
      v-if="!(status.isMeshUploaded || status.isSvgUploaded) || currentErrorMessage"
      class="pa-4"
    >
      <v-container fluid class="py-0">
        <v-row>
          <v-col>
            <v-alert
              v-for="(we, index) in _.get(part, 'AnalysisData.errors_and_warnings', []) "
              :key="index"
              close="ma-0"
              :color="ErrorAndWarningCodeMapping[we.code] === 'Error' ? '#F3D9DE' : '#f8f2ec'"
            >
              <div class="body-2 font-weight-bold text-uppercase">
                <v-tooltip top z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      :color="ErrorAndWarningCodeMapping[we.code] === 'Error' ? '#B00020' :'#C77500'"
                      style="vertical-align: sub"
                      v-on="on"
                    >
                      {{
                        ErrorAndWarningCodeMapping[we.code] === 'Error' ? 'mdi-alert-circle-outline' : 'mdi-alert-outline'
                      }}
                    </v-icon>
                  </template>
                  <span
                    v-if="ErrorAndWarningCodeMapping[we.code] === 'Error'"
                    v-html="$t('sidePanelErrorAndWarning.errorTooltip')"
                  />
                  <span v-else v-html="$t('sidePanelErrorAndWarning.errorTooltip')" />
                </v-tooltip>
                {{ $t(`common.${ErrorAndWarningCodeMapping[we.code]}`) }} - {{ $t(`errorAndWarning.${we.code}`) }}
              </div>
              <span v-if="ErrorAndWarningCodeMapping[we.code] === 'Error' && we.code === 202" class="text--secondary caption">
                {{ $t('errorAndWarning.unsupportedSldprtVersionCaption') }}
              </span>
              <span
                v-else-if="ErrorAndWarningCodeMapping[we.code] === 'Error'"
                class="text--secondary caption"
              >
                {{ $t('errorAndWarning.errorThicknessCaption') }}
              </span>
              <span
                v-else-if="ErrorAndWarningCodeMapping[we.code] === 'Warning' && (we.code !== '301')"
                class="text--secondary caption"
              >
                {{ $t('errorAndWarning.warningCaption') }}
              </span>
              <span v-else class="text--secondary caption">
                {{ $t('errorAndWarning.warningThicknessCaption') }}
              </span>
              <a
                class="caption font-weight-bold mr-0"
                @click="isGuideLineVisible = true"
              >{{ $t('errorAndWarning.reviewGuidelines') }}<span
                style="font-size: 20px; vertical-align: bottom"
                class=" mdi mdi-chevron-right"
              /> </a>
            </v-alert>

            <v-alert
              v-if="currentErrorMessage"
              color="#F3D9DE"
            >
              <div class="body-2 font-weight-bold">
                <v-tooltip top z-index="9999">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      class="mr-1"
                      color="#B00020"
                      style="vertical-align: sub"
                      v-on="on"
                    >
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <span v-html="$t('sidePanelErrorAndWarning.errorTooltip')" />
                </v-tooltip>
                <div class="d-inline">
                  <span class="text-uppercase">
                    <span v-if="$te(currentErrorMessage)">
                      {{ $t('common.Error') }} - {{ $t(currentErrorMessage) }}
                    </span>
                    <span v-else>
                      {{ $t('common.Error') }} - {{ $t('common.serverResponseError') }}
                      <p class="text--secondary caption mb-0">
                        {{ $t('errorAndWarning.modelIsBrokenCaption') }}
                      </p>
                    </span>
                  </span>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left d-flex">
            <v-icon
              :color="currentErrorMessage ? 'error' : 'primary'"
              x-large
            >
              {{ currentErrorMessage ? 'mdi-alert-circle-outline' : 'mdi-file-image-outline' }}
            </v-icon>

            <div class="ml-5 d-flex justify-center flex-column">
              <span :class="[{ 'error--text': currentErrorMessage }, 'font-weight-bold d-flex']">
                {{ part.CustFileName }}
              </span>

              <span v-if="!currentErrorMessage" class="font-weight-light" data-cy="status-upload">
                {{ currentUploadStatus }}
              </span>
            </div>
          </v-col>

          <v-col
            v-if="currentErrorMessage"
            cols="1"
            class="d-flex justify-center align-center"
          >
            <v-btn color="primary" text @click="retryUpload(part)">
              <v-icon small left>
                mdi-reload
              </v-icon>
              {{ $t('views.instantQuote.retryUpload') }}
            </v-btn>
          </v-col>

          <v-col class="d-flex justify-end align-center">
            <span v-if="!currentErrorMessage" class="mr-10">
              <!-- TODO localization -->
              <!-- Need to explicitely check for string value 'NaNundefined' -->
              {{ Math.round(part.uploadProgress) }}% {{
                $t('common.of')
              }} {{ part.fileSize === 'NaNundefined' ? '0kB' : part.fileSize }} {{ $t('common.uploaded') }}
            </span>

            <v-btn
              icon
              :color="!currentErrorMessage ? 'primary' : 'error'"
              @click.stop="$emit('remove', part)"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-progress-linear
              :value="currentErrorMessage ? 100 : uploadProgress"
              height="10"
              rounded
              :indeterminate="!currentErrorMessage && uploadProgress === 100"
              :color="currentErrorMessage ? 'error' : 'primary'"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card v-else-if="part.Status === 'Working'">
      <core-compact-part-card
        :part="part"
        :customer-price-range="customerPriceRange"
        :upload-thumbnail="uploadThumbnail"
        :is-mesh-visible="isMeshVisible"
        :on-thumb-click="() => $emit('thumbnail:clicked', part)"
      />
    </v-card>

    <v-card v-else class="pa-5" max-width="1000px">
      <!-- Info labels -->
      <v-alert
        v-if="!_.isEmpty(infoLabels)"
        color="#E7F1F8"
      >
        <div class="body-2">
          <v-tooltip top z-index="9999">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="#1479BD"
                style="vertical-align: sub"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span v-html="$t('sidePanelErrorAndWarning.infoTooltip')" />
          </v-tooltip>
          {{ $t('common.Info') }} -
          <span
            v-for="(value, key, index) in infoLabels"
            :key="key"
          >
            <span v-if="key === 'bendsInfoLabel' && value === 0">
              <span v-if="Object.keys(infoLabels).length - 1 !== index">
                {{ $t('infoLabels.noBendDetectedLabel') }},
              </span>
              <span v-else>
                {{ $t('infoLabels.noBendDetectedLabel') }}
              </span>
            </span>
            <span v-else-if="Object.keys(infoLabels).length - 1 !== index">
              {{ $t(`infoLabels.${key}`, { 'Param': value }) }},
            </span>
            <span v-else>
              {{ $t(`infoLabels.${key}`, { 'Param': value }) }}
            </span>
          </span>
          {{ $t('common.detected') }}
        </div>
      </v-alert>

      <!-- Warnings -->
      <v-alert
        v-if="_.get(part, 'AnalysisData.errors_and_warnings', []).filter(e => ErrorAndWarningCodeMapping[e.code] === 'Warning').length"
        color="#f8f2ec"
      >
        <span
          v-for="(we, index) in _.get(part, 'AnalysisData.errors_and_warnings', []).filter(e => ErrorAndWarningCodeMapping[e.code] === 'Warning')"
          :key="index"
        >
          <div class="body-2">
            <v-tooltip top z-index="9999">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  :color="ErrorAndWarningCodeMapping[we.code] === 'Error' ? '#B00020' :'#C77500'"
                  style="vertical-align: sub"
                  v-on="on"
                >
                  {{ ErrorAndWarningCodeMapping[we.code] === 'Error' ? 'mdi-alert-circle-outline' : 'mdi-alert-outline' }}
                </v-icon>
              </template>
              <span
                v-if="ErrorAndWarningCodeMapping[we.code] === 'Error'"
                v-html="$t('sidePanelErrorAndWarning.errorTooltip')"
              />
              <span v-else v-html="$t('sidePanelErrorAndWarning.waringTooltip')" />
            </v-tooltip>
            {{ $t(`common.${ErrorAndWarningCodeMapping[we.code]}`) }} - {{ $t(`errorAndWarning.${we.code}`) }}
          </div>
        </span>
      </v-alert>
      <!-- Errors -->
      <v-alert
        v-for="(we, index) in _.get(part, 'AnalysisData.errors_and_warnings', []).filter(e => ErrorAndWarningCodeMapping[e.code] === 'Error')"
        :key="index"
        :color="ErrorAndWarningCodeMapping[we.code] === 'Error' ? '#F3D9DE' :'#f8f2ec' "
      >
        <div class="body-2 font-weight-bold text-uppercase">
          <v-tooltip top z-index="9999">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                :color="ErrorAndWarningCodeMapping[we.code] === 'Error' ? '#B00020' :'#C77500'"
                style="vertical-align: sub"
                v-on="on"
              >
                {{ ErrorAndWarningCodeMapping[we.code] === 'Error' ? 'mdi-alert-circle-outline' : 'mdi-alert-outline' }}
              </v-icon>
            </template>
            <span
              v-if="ErrorAndWarningCodeMapping[we.code] === 'Error'"
              v-html="$t('sidePanelErrorAndWarning.errorTooltip')"
            />
            <span v-else v-html="$t('sidePanelErrorAndWarning.waringTooltip')" />
          </v-tooltip>
          {{ $t(`common.${ErrorAndWarningCodeMapping[we.code]}`) }} - {{ $t(`errorAndWarning.${we.code}`) }}
        </div>
        <span v-if="ErrorAndWarningCodeMapping[we.code] === 'Error'" class="text--secondary caption">
          {{ $t('errorAndWarning.errorCaption') }}
        </span>
        <span
          v-if="ErrorAndWarningCodeMapping[we.code] === 'Warning' && (we.code !== 301)"
          class="text--secondary caption"
        >
          {{ $t('errorAndWarning.warningCaption') }}
        </span>
        <span
          v-if="ErrorAndWarningCodeMapping[we.code] === 'Warning' && (we.code === 301)"
          class="text--secondary caption"
        >
          {{ $t('errorAndWarning.warningThicknessCaption') }}
        </span>
        <a
          class="caption font-weight-bold mr-0"
          @click="isGuideLineVisible = true"
        >{{ $t('errorAndWarning.reviewGuidelines') }}<span
          style="font-size: 20px; vertical-align: bottom"
          class=" mdi mdi-chevron-right"
        /> </a>
      </v-alert>
      <v-alert
        v-if="part.EstimateLineStatus.isMainAnalysisCompletedMsg"
        color="#F3D9DE"
      >
        <v-tooltip top z-index="9999">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              class="mr-1"
              color="#B00020"
              style="vertical-align: sub"
              v-on="on"
            >
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span v-html="$t('sidePanelErrorAndWarning.errorTooltip')" />
        </v-tooltip>
        <template v-if="checkErrorMessage(status.isMainAnalysisCompletedMsg)">
          <span class="body-2 font-weight-bold text-uppercase">
            {{ $t('common.Error') }}
          </span>
          <br>
          <span v-html="errorFormat(status.isMainAnalysisCompletedMsg)" />
        </template>
        <template v-else>
          <template v-if="checkTranslation($t(status.isMainAnalysisCompletedMsg))">
            <span class="body-2 font-weight-bold text-uppercase">{{ $t('common.Error') }}</span> -
            {{ removeLinklabel($t(status.isMainAnalysisCompletedMsg)) }}
            <a class="mr-0" @click="$router.push('/tools/process') ">{{
              $t('CSIError.ProcessLimitations.LinkLabel')
            }}</a>
          </template>
          <template v-else>
            <span class="body-2 font-weight-bold text-uppercase">{{ $t('common.Error') }}</span> -
            {{ $t(status.isMainAnalysisCompletedMsg, {Param1: status.CsiErrorParam}) }}
          </template>
        </template>
      </v-alert>
      <div style="display: grid; grid-template-columns: min-content auto;">
        <div style="width: 174px; display:flex; flex-direction: column; align-items: center;">
          <core-model-viewer
            :part="part"
            :width="150"
            :height="150"
            :is-mesh-uploaded="status.isMeshUploaded"
            :is-svg-uploaded="status.isSvgUploaded"
            :class="{ 'blip-3d': isMeshVisible }"
            @click.stop="() => {
              $emit('thumbnail:clicked', part);
            }"
            @load:mesh="uploadThumbnail"
          />

          <template v-if="isSheetMetalMachine && !part.is2dModel">
            <div
              v-if="!status.isManufacturableMsg"
              class="caption font-weight-light grey--text"
            >
              {{ $t('views.instantQuote.detectedThickness') }}
            </div>
            <div class="font-weight-bold">
              <v-icon v-if="checkThickness" color="#C77500" style="vertical-align: unset">
                mdi-alert-outline
              </v-icon>
              <span :style="checkThickness ? {'color':'#C77500'} : {}">
                {{
                  detectedThickness === 0 ? $t('views.instantQuote.detectingThickness') : detectedThickness ? ` ${detectedThickness}mm` : '-'
                }}
              </span>
            </div>
          </template>
          <template v-else-if="!isSheetMetalMachine">
            <div
              v-if="!status.isManufacturableMsg"
              class="caption font-weight-light grey--text"
            >
              {{ $t('views.instantQuote.detectedOuterDia') }}
            </div>
            <div class="font-weight-bold">
              <v-icon v-if="checkOuterDia" color="#C77500" style="vertical-align: unset">
                mdi-alert-outline
              </v-icon>
              <span :style="checkOuterDia ? {'color':'#C77500'} : {}">
                {{
                  detectedOuterDia === 0 ? $t('views.instantQuote.detectingOuterDia') : detectedOuterDia ? ` ${detectedOuterDia}mm` : '-'
                }}
              </span>
            </div>
          </template>

          <v-btn
            text
            color="primary"
            data-cy="view-details-upload"
            @click.stop="() => {
              $emit('thumbnail:clicked', part);
            }"
          >
            {{ $t('common.viewDetails') }}
            <v-icon small right>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </div>

        <div>
          <div
            style="display: flex;"
            class="pb-2"
          >
            <span class="text-h6 font-weight-bold">
              {{ part.CustFileName }}
            </span>

            <v-spacer />

            <div
              :class="{
                'grey--text': !status.isMainAnalysisCompleted,
                calculateCostOk : status.isMainAnalysisCompleted
              }"
              class="font-weight-light text-uppercase"
            >
              {{
                $t(status.isMainAnalysisCompleted ? 'views.instantQuote.calculated' : 'views.instantQuote.notCalculated')
              }}
              <v-icon
                right
                size="30"
                :class="{ calculateCostOk : status.isMainAnalysisCompleted }"
              >
                mdi-check
              </v-icon>
            </div>
          </div>
          <v-form ref="partOptions" v-model="arePartOptionsValid">
            <div
              style="display: grid;
              grid-template-columns: 3fr 1fr 4fr;
              grid-column-gap: 10px;"
              class="pb-2"
            >
              <v-text-field
                v-model="part.CustItemName"
                maxlength="21"
                class="inputHeight"
                data-cy="part-input-upload"
                :disabled="isCalculatingCost || isErrorDetected"
                :placeholder="$t('views.instantQuote.partNumberPlaceholder')"
                :label="$t('views.instantQuote.partNumber')"
                :rules="[isRequired,maxLength(20),containAlphabetsNumbersHyphensUnderscoreSpace]"
                outlined
                dense
                @change="status.isMainAnalysisCompleted = false;"
              />
              <v-text-field
                v-model="part.RevisionNumber"
                min="0"
                max="99"
                data-cy="revision-input-upload"
                :disabled="isCalculatingCost || isErrorDetected"
                :rules="[isZeroOrGreaterInteger]"
                type="number"
                class="inputHeight"
                :label="$t('views.instantQuote.revision')"
                outlined
                dense
                hide-details
                @input.native="
                  part.RevisionNumber = Math.abs(Math.trunc($event.target.value));
                  part.RevisionNumber = part.RevisionNumber > 99 ? 99 : part.RevisionNumber
                  status.isMainAnalysisCompleted = false;"
              />
              <v-text-field
                v-model="part.CustItemDesc"
                maxlength="40"
                class="inputHeight"
                data-cy="description-input-upload"
                :disabled="isCalculatingCost || isErrorDetected"
                :placeholder="$t('views.instantQuote.descriptionPlaceholder')"
                :rules="[isRequired]"
                :label="$t('common.description')"
                outlined
                dense
                hide-details
                @change="status.isMainAnalysisCompleted = false;"
              />
            </div>

            <div
              style="display: grid;
              grid-template-columns: 200px auto 190px;
              grid-gap: 10px;"
            >
              <div>
                <v-select
                  ref="materialSelector"
                  v-model="part.Material"
                  class="pb-2"
                  data-cy="material-input-upload"
                  :items="materials"
                  :label="$t('views.instantQuote.material')"
                  :disabled="isCalculatingCost || isErrorDetected"
                  :rules="[isRequired]"
                  :item-text="locale === 'en-US' ? 'Description' : 'DescriptionCHS'"
                  item-value="RowPointer"
                  persistent-hint
                  dense
                  hide-details
                  outlined
                  @change="
                    part.MaterialStr = materials.find(m => m.RowPointer === $event).Description;
                    part.MaterialStrCHS = materials.find(m => m.RowPointer === $event).DescriptionCHS;
                    part.MaterialSeries = '';
                    part.MaterialSeriesStr = '';
                    part.MaterialThickness ='';
                    part.MaterialThicknessStr = '';
                    part.MaterialFinish = '';
                    part.MaterialFinishStr = '';
                    part.MaterialFinishOption = '';
                    part.MaterialFinishOptionStr = '';
                    status.isMainAnalysisCompleted = false;
                    part.MaterialDimensions = {};
                    part.PriceMOQ ? part.PriceMOQ = null : '';
                  "
                />

                <v-select
                  ref="finishSelector"
                  v-model="part.MaterialFinish"
                  class="pb-2"
                  data-cy="finish-input-upload"
                  :items="finishes"
                  :rules="[isRequired]"
                  :disabled="isCalculatingCost || isErrorDetected || !part.MaterialThickness || isFinishesFindPending"
                  :item-disabled="finish => !legalFinishes.some(lf => lf.RowPointer === finish.RowPointer)"
                  :loading="isFinishesFindPending"
                  :label="$t('views.instantQuote.finish')"
                  :item-text="locale === 'en-US' ? 'Description' : 'DescriptionCHS'"
                  item-value="_id"
                  dense
                  hide-details
                  outlined
                  @change="
                    $refs.partOptions.validate();
                    part.MaterialFinishStr = finishes.find(m => m.RowPointer === $event).Description
                    part.MaterialFinishStrCHS = finishes.find(m => m.RowPointer === $event).DescriptionCHS
                    part.MaterialFinishOption = '';
                    part.MaterialFinishOptionStr = '';
                    status.isMainAnalysisCompleted = false;
                    part.PriceMOQ ? part.PriceMOQ = null : '';
                  "
                />
                <v-select
                  v-model="part.Tolerance"
                  class="pb-2"
                  data-cy="tolerances-input-upload"
                  :full-width="false"
                  :disabled="isCalculatingCost || isErrorDetected"
                  :items="toleranceValues"
                  item-text="label"
                  item-value="value"
                  :label="`${$t('views.instantQuote.tolerances')} \xa0 \xa0 \xa0 \xa0`"
                  :rules="[isRequired]"
                  dense
                  hide-details
                  outlined
                  @change="
                    status.isMainAnalysisCompleted = false;
                    part.PriceMOQ ? part.PriceMOQ = null : '';
                  "
                />
                <v-tooltip top z-index="5">
                  <span v-if="isSheetMetalMachine" v-html="$t('views.instantQuote.toleranceTooltip')" />
                  <span v-else>
                    <v-simple-table dense style="background-color: transparent; color: inherit;">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th colspan="4" class="text-uppercase text-center" style="color: inherit;">
                              {{ $t('views.engineeringTools.tabs.processLimitations.linearDimensions') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody class="text-center">
                          <tr>
                            <td rowspan="2" class="header" style="width: 180px;">
                              {{ $t('views.engineeringTools.tabs.processLimitations.deviations') }}
                            </td>
                            <td colspan="3" class="header" style="width: 250px;">
                              {{ $t('views.engineeringTools.tabs.processLimitations.toleranceClass') }}
                            </td>
                          </tr>
                          <tr>
                            <td class="header">
                              {{ $t('views.engineeringTools.tabs.processLimitations.fineIso') }}<br>
                              ({{ $t('views.engineeringTools.tabs.processLimitations.fine') }})
                            </td>
                            <td class="header">
                              {{ $t('views.engineeringTools.tabs.processLimitations.mediumIso') }}<br>
                              ({{ $t('views.engineeringTools.tabs.processLimitations.medium') }})
                            </td>
                            <td class="header">
                              {{ $t('views.engineeringTools.tabs.processLimitations.coarseIso') }}<br>
                              ({{ $t('views.engineeringTools.tabs.processLimitations.coarse') }})
                            </td>
                          </tr>
                          <tr v-for="(item, index) in cncTurningLinearDimensions" :key="index">
                            <td class="font-weight-bold text-left">
                              {{ item.deviations }}
                            </td>
                            <td>
                              {{ item.fine }}
                            </td>
                            <td>
                              {{ item.medium }}
                            </td>
                            <td>
                              {{ item.coarse }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <i>More details, please see Engineering tools/Process Detail</i>
                  </span>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      style="position: relative; top:-62px; right: -72px; cursor: pointer;"
                      class="mb-2"
                      v-bind="attrs"
                      color="primary"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-text-field
                  v-if="showCustomFields"
                  v-model.number="part.Threads"
                  class="d-flex pb-2 mt-n8"
                  data-cy="thread-input-upload"
                  min="0"
                  :label="$t('views.instantQuote.threads')"
                  :disabled="isCalculatingCost || isErrorDetected"
                  type="number"
                  hide-details
                  outlined
                  dense
                  @input.native="part.Threads = Math.max(Math.abs(Math.trunc($event.target.value)), 0);status.isMainAnalysisCompleted = false; addThreads(); "
                  @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
                />
              </div>

              <div>
                <v-select
                  v-model="part.MaterialSeries"
                  data-cy="series-input-upload"
                  :items="series"
                  :label="$t('views.instantQuote.series')"
                  :rules="[isRequired]"
                  :disabled="isCalculatingCost || isErrorDetected || !part.Material"
                  :item-text="locale === 'en-US' ? getTranslationSeries('Description') : getTranslationSeries('DescriptionCHS')"
                  class="pb-2"
                  item-value="RowPointer"
                  persistent-hint
                  hide-details
                  outlined
                  dense
                  @change="
                    part.MaterialSeriesStr = series.find(m => m.RowPointer === $event).Description;
                    part.MaterialSeriesStrCHS = series.find(m => m.RowPointer === $event).DescriptionCHS;
                    part.MaterialFinish = '';
                    part.MaterialFinishOption = '';
                    status.isMainAnalysisCompleted = false;
                    part.MaterialDimensions = {};
                    part.PriceMOQ ? part.PriceMOQ = null : '';
                  "
                />
                <v-select
                  v-model="part.MaterialFinishOption"
                  data-cy="finish-options-input-upload"
                  class="pb-2"
                  :items="options"
                  :rules="[v => options.length === 0 || isRequired(v)]"
                  :disabled="isCalculatingCost || isErrorDetected || !part.MaterialFinish || options.length === 0"
                  :label="$t('views.instantQuote.finishOption')"
                  :item-text="locale === 'en-US' ? getTranslationFinish('Description') : getTranslationFinish('DescriptionCHS')"
                  item-value="RowPointer"
                  dense
                  hide-details
                  outlined
                  @change="
                    part.MaterialFinishOptionStr = options.find(m => m.RowPointer === $event).Description;
                    status.isMainAnalysisCompleted = false;
                    part.PriceMOQ ? part.PriceMOQ = null : '';
                  "
                />

                <div class="d-flex justify-space-around">
                  <v-text-field
                    v-model.number="part.ReqQty"
                    class="d-flex"
                    data-cy="quantity-input-upload"
                    style="width: 180px; margin-right:10px;"
                    :min="minReqQty"
                    :label="$t('views.instantQuote.quantity')"
                    :rules="[isPositiveInteger]"
                    :disabled="isCalculatingCost || isErrorDetected"
                    type="number"
                    hide-details
                    outlined
                    dense
                    @input.native="part.ReqQty = Math.max(Math.abs(Math.trunc($event.target.value)), 0);status.isMainAnalysisCompleted = false;"
                    @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
                  />
                  <core-rang-price-moq
                    style="width: 180px;"
                    :part="part"
                  />
                </div>
                <div class="d-flex justify-lg-space-around">
                  <v-text-field
                    v-if="showCustomFields"
                    v-model.number="part.Countersinks"
                    class="d-flex pb-2 mt-2 mr-1"
                    data-cy="countersinks-input-upload"
                    min="0"
                    :label="$t('views.instantQuote.countersinks')"
                    :disabled="isCalculatingCost || isErrorDetected"
                    type="number"
                    hide-details
                    outlined
                    dense
                    @input.native="part.Countersinks = Math.max(Math.abs(Math.trunc($event.target.value)), 0);status.isMainAnalysisCompleted = false; addCountersinks();"
                    @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
                  />
                  <v-text-field
                    v-if="showCustomFields"
                    v-model.number="part.Counterbores"
                    class="d-flex pb-2 mt-2 ml-1"
                    data-cy="counterbores-input-upload"
                    min="0"
                    :label="$t('views.instantQuote.counterbores')"
                    :disabled="isCalculatingCost || isErrorDetected"
                    type="number"
                    hide-details
                    outlined
                    dense
                    @input.native="part.Counterbores = Math.max(Math.abs(Math.trunc($event.target.value)), 0);status.isMainAnalysisCompleted = false; addCounterbores();"
                    @keydown="['Period', 'Minus'].includes($event.code) && $event.preventDefault()"
                  />
                </div>
              </div>

              <div>
                <div class="pb-3">
                  <v-select
                    v-if="isSheetMetalMachine || isDebugModeEnabled"
                    :value="part.MaterialThickness"
                    :items="thicknesses"
                    :rules="[isRequired]"
                    :disabled="isCalculatingCost || !part.MaterialSeries || isErrorDetected"
                    :label="$t('views.instantQuote.thickness')"
                    :item-text="locale === 'en-US' ? 'Description' : 'DescriptionCHS'"
                    item-value="_id"
                    data-cy="thickness-input-upload"
                    dense
                    return-object
                    hide-details
                    outlined
                    @change="
                      part.MaterialThickness = $event._id;
                      part.MaterialThicknessStr = selectedThickness.Description;
                      part.MaterialThicknessStrCHS = selectedThickness.DescriptionCHS;
                      part.MaterialDimensions = {
                        SheetLength: $event.SheetLength,
                        SheetWidth: $event.SheetWidth,
                      };
                      part.MaterialFinish = '';
                      part.MaterialFinishOption = '';
                      status.isMainAnalysisCompleted = false;
                      part.PriceMOQ ? part.PriceMOQ = null : '';
                    "
                  />
                  <v-select
                    v-if="isTurningMachine"
                    v-model="part.Roughness"
                    data-cy="roughness-input-upload"
                    :disabled="isCalculatingCost || isErrorDetected"
                    :items="roughnessValues"
                    item-text="label"
                    item-value="value"
                    :label="`${$t('views.instantQuote.roughness')}`"
                    :rules="[isRequired]"
                    dense
                    hide-details
                    outlined
                    @change="
                      status.isMainAnalysisCompleted = false;
                      part.PriceMOQ ? part.PriceMOQ = null : '';
                    "
                  />
                  <v-btn
                    color="primary"
                    height="40px"
                    style="margin-top:10px; margin-bottom: -5px"
                    data-cy="calculate-upload"
                    :disabled="!canCalculateCost"
                    :loading="isCalculatingCost"
                    outlined
                    block
                    @click.stop="errorArray;part.EstimateLineStatus.isMainAnalysisCompletedMsg = ''; $emit('calculatecost', part)"
                  >
                    {{ $t(part.EstimateLineStatus.isMainAnalysisEverCalled ? 'views.instantQuote.recalculateThis' : 'views.instantQuote.calculateThis') }}
                  </v-btn>
                </div>
                <core-price-moq-table :part="part" />
              </div>
            </div>
          </v-form>
        </div>
      </div>

      <div style="display: grid; grid-template-columns: 350px auto;">
        <div>
          <v-btn
            color="primary"
            text
            data-cy="delete-upload"
            @click.stop="deleteDialog = true"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            {{ $t('common.delete') }}
          </v-btn>
          <core-technical-drawing-pdf
            v-if="needPdf"
            :part="part"
          />
          <core-upload-svg-drawing
            v-if="needSvg"
            :part="part"
          />
        </div>
      </div>

      <div v-if="isDebugModeEnabled">
        <span class="text-h6 font-weight-bold">Add Custom Data to Engine Response</span>
        <v-form ref="customDataToAddForm" class="ma-2" @submit.prevent="addCustomDataToEngineResponse">
          <v-row>
            <v-text-field
              v-model="customThreadCountToAdd"
              min="0"
              type="number"
              :rules="[isPositiveInteger]"
              class="mt-4"
              outlined
              dense
              label="Total Threads"
              placeholder="Enter total number of threaded holes"
              hide-details
            />
          </v-row>
          <v-row>
            <v-spacer />
            <v-btn
              color="primary"
              class="my-4"
              type="submit"
              :disabled="isPatchPending"
              :loading="isPatchPending"
              outlined
            >
              Add Custom Data
            </v-btn>
          </v-row>
        </v-form>
        <v-divider />
        <pre>
          {{ _.get(part, 'AnalysisData', 'No results') }}
        </pre>
        <v-divider />
        <pre>
          {{ JSON.parse(_.get(part, 'PricingData', '{}')) }}
        </pre>
        <v-divider />
        <pre>
          {{ _.get(part, 'SecondaryAnalysisData', 'No results') }}
        </pre>
      </div>
    </v-card>

    <core-dialog
      v-if="deleteDialog"
      v-model="deleteDialog"
      type="confirmation"
      width="500px"
      data-cy="delete-dialog-upload"
      :ok-button-text="$t('common.delete')"
      @click:ok="part.remove();
                 deleteDialog = false;"
      @click:cancel="deleteDialog = false"
    >
      <template #title>
        {{ $t('views.instantQuote.deleteDialogTitle') }}
      </template>
      <template #content>
        <div class="pa-10 text-center">
          <!-- There's gotta be a better way to handle linebreaks other than 'line1 <br> line2' -->
          <span
            class="d-block"
            v-html="$t('common.deleteDialogText', { name: part.CustFileName })"
          />
        </div>
      </template>
    </core-dialog>

    <v-navigation-drawer
      v-model="isGuideLineVisible"
      class="px-4"
      width="860"
      fixed
      right
      temporary
      style="background-color:#f5f5f5; overflow-x: visible !important; z-index: 199 !important;"
    >
      <div class="mt-3" style="min-width:800px; overflow-x: visible !important;">
        <div class="d-flex justify-space-between">
          <span style="font-size: 16px" class="d-flex mr-9 font-weight-medium">{{
            $t("sidePanelErrorAndWarning.title")
          }}</span>
          <div class="caption mb-3" style="width: 450px; line-height: 0 !important;">
            <v-icon left color="#B00020" style="vertical-align: baseline">
              mdi-alert-circle-outline
            </v-icon>
            <div class="d-inline mb-0">
              <span style="vertical-align: super" v-html="$t('sidePanelErrorAndWarning.errors')" />
            </div>

            <p class="mb-1 mt-0">
              <v-icon style="vertical-align: sub" left color="#C77500">
                mdi-alert-outline
              </v-icon>
              <span style="vertical-align: sub" v-html="$t('sidePanelErrorAndWarning.warnings')" />
            </p>
          </div>
          <v-btn
            class="justify-end ml-5 mt-0"
            icon
            plain
            text
            x-large
            @click="isGuideLineVisible = false"
          >
            <v-icon left class="mb-7">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div>
          <instantQuoteGuideLine />
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { models } from 'feathers-vuex';
import ddlogger from '@/utils/logger';
import FormRules from '@/mixins/form-rules';
import instantQuoteGuideLine from '@/components/core/InstantQuoteGuideLine.vue';
import cncTurningLinearDimensionsTableValues from '@/mixins/cncTurningLinearDimensionsTableValues';

const { Material, Finish } = models.api;

export default {
  name: 'UploadPartCard',
  mixins: [
    FormRules, cncTurningLinearDimensionsTableValues
  ],
  components: { instantQuoteGuideLine },

  props: {
    isDebugModeEnabled: Boolean,
    part: Object,
    retryUpload: Function,
    minReqQty: { type: Number, default: 1 },
  },

  data() {
    return {
      errorArray: [],

      isGuideLineVisible: false,
      isMeshVisible: false,
      fullScreen: false,
      deleteDialog: false,
      arePartOptionsValid: false,
      ErrorAndWarningCodeMapping: {
        200: 'Error',
        201: 'Error',
        202: 'Error',
        401: 'Error',
        301: 'Warning',
        302: 'Warning',
        303: 'Warning',
        304: 'Warning',
        410: 'Error',
        413: 'Error',
        414: 'Error',
        415: 'Error',
        402: 'Error',
        411: 'Error',
        412: 'Error',
        322: 'Warning',
        323: 'Warning',
        321: 'Warning',
        451: 'Warning',
        450: 'Error',
        416: 'Error',
        417: 'Error',
        418: 'Error',
        306: 'Warning',
        307: 'Warning',
        500: 'Error',
        501: 'Error',
        502: 'Error',
        503: 'Error',
        504: 'Error',
        505: 'Error',
        506: 'Error',
        507: 'Error',
        508: 'Error',
      },
      customThreadCountToAdd: null,
    };
  },

  computed: {
    ...mapState('app', ['locale']),
    ...mapState('auth', ['accessToken']),
    ...mapState('temp-parts', ['isPatchPending']),
    ...mapGetters('finishes', { findFinishes: 'find' }),
    ...mapState('finishes', { isFinishesFindPending: 'isFindPending' }),

    status: vm => vm.part.EstimateLineStatus,

    toleranceValues: vm => {
      if (vm.isSheetMetalMachine) {
        return [
          {
            label: vm.$t('views.instantQuote.tolerancestandard'),
            value: 'Standard'
          },
          {
            label: vm.$t('views.instantQuote.tolerancehigh'),
            value: 'High Precision'
          }
        ];
      }
      return [
        {
          label: vm.$t('views.instantQuote.toleranceISO2768F'),
          value: 'ISO2768F'
        },
        {
          label: vm.$t('views.instantQuote.toleranceISO2768M'),
          value: 'ISO2768M'
        },
        {
          label: vm.$t('views.instantQuote.toleranceISO2768C'),
          value: 'ISO2768C'
        },
      ];
    },

    roughnessValues: vm => [
      {
        label: vm.$t('views.instantQuote.roughnessMilled'),
        value: '6.0'
      },
      {
        label: vm.$t('views.instantQuote.roughnessStandard'),
        value: '3.2'
      },
      {
        label: vm.$t('views.instantQuote.roughnessFine'),
        value: '1.6'
      },
      {
        label: vm.$t('views.instantQuote.roughnessGrade'),
        value: '0.8'
      },
    ],

    detectedThickness: vm => {
      if ((!vm.status.isManufacturable && !vm.status.isThicknessCalculated)) {
        return 0;
      }
      return vm.part.AnalysisData.thickness;
    },
    checkThickness() {
      return this.detectedThickness > 20;
    },
    detectedOuterDia: vm => {
      if ((!vm.status.isManufacturable && !vm.status.isOuterDiaCalculated)) {
        return 0;
      }
      return vm.part.AnalysisData.major_dia;
    },
    checkOuterDia() {
      return !(this.detectedOuterDia > 5 && this.detectedOuterDia < 300);
    },

    isErrorDetected() {
      const errorAndWarning = _.get(this.part, 'AnalysisData.errors_and_warnings', []);
      return errorAndWarning.some(EandW => this.ErrorAndWarningCodeMapping[EandW.code] === 'Error');
    },

    canCalculateCost: vm => !vm.isErrorDetected
      && vm.arePartOptionsValid
      && !vm.isCalculatingCost
      && vm.status.isManufacturable
      && !vm.status.isMainAnalysisCompleted,

    // isCalculatingCost: ({ part, status }) => (part.isSavePending && !status.isMainAnalysisCompleted) || part.isMainAnalysisInProgress,
    isCalculatingCost: ({ status }) => status.isMainAnalysisInProgress,

    selectedThickness: vm => vm.thicknesses.find(t => t._id === vm.part.MaterialThickness),

    uploadProgress: vm => _.get(vm.part, 'uploadProgress', 100),

    isSheetMetalMachine: vm => vm.part.AnalysisData.machine === 'sheet-metal',

    isTurningMachine: vm => vm.part.AnalysisData.machine === 'turning',

    cncItems: vm => Material.findInStore({
      query: {
        IsHidden: false,
        Process: 'CNCTurning',
        OutDia: { $gte: vm.part.AnalysisData.major_dia + 1 },
      },
    }).data,

    cncSeries: vm => {
      const uniqueParentRowPointers = new Set();
      // Iterate through the data and add unique ParentRowPointers to the Set
      vm.cncItems.forEach(item => {
        uniqueParentRowPointers.add(item.ParentRowPointer);
      });
      return Material.findInStore({
        query: {
          RowPointer: {
            $in: Array.from(uniqueParentRowPointers)
          }
        }
      }).data;
    },

    cncMaterials: vm => {
      const uniqueMaterialsRowPointers = new Set();
      vm.cncSeries.forEach(item => {
        uniqueMaterialsRowPointers.add(item.ParentRowPointer);
      });
      return Material.findInStore({
        query: {
          RowPointer: {
            $in: Array.from(uniqueMaterialsRowPointers)
          }
        }
      }).data;
    },

    materials: vm => {
      if (vm.isSheetMetalMachine) {
        return Material.findInStore({
          query: {
            ParentRowPointer: null,
            IsHidden: false,
            Process: 'LaserCutting'
          },
        }).data;
      }
      return vm.cncMaterials;
    },

    series: vm => {
      if (!vm.part.Material) {
        return [];
      }
      return Material.findInStore({
        query: {
          ParentRowPointer: vm.part.Material,
          IsHidden: false,
          $sort: { Rank: 1 },
        },
      }).data;
    },

    thicknesses() {
      let additionalQuery = {};
      if (!this.isSheetMetalMachine) {
        additionalQuery = {
          OutDia: { $gte: this.part.AnalysisData.major_dia + 1 },
          InDia: null,
          $sort: { OutDia: 1, Rank: 1 }
        };
      }
      return this.part.MaterialSeries
        ? Material.findInStore({
          query: {
            ParentRowPointer: this.part.MaterialSeries,
            IsHidden: false,
            $sort: { Rank: 1 },
            ...additionalQuery
          },
        }).data : [];
    },

    finishes() {
      return Finish.findInStore({
        query: {
          ParentRowPointer: null,
        },
      }).data;
    },

    legalFinishes() {
      const legalFinishes = Finish.findInStore({
        query: {
          ParentRowPointer: this.part.MaterialThickness,
        },
      }).data;

      return this.finishes.filter(f => legalFinishes.find(lf => lf._id.endsWith(f._id)));
    },

    options() {
      return this.part.MaterialThickness && this.part.MaterialFinish
        ? Finish.findInStore({
          query: {
            ParentRowPointer: this.part.MaterialFinish,
            IsHidden: false,
          },
        }).data
        : [];
    },

    currentUploadStatus(vm) {
      if (!(this.status.isMeshUploaded || this.status.isSvgUploaded) && this.part.uploadProgress && this.part.uploadProgress === 100) {
        return `${vm.$t('common.analyzing')}...`;
      }
      if ((this.status.isMeshUploaded || this.status.isSvgUploaded) && !this.status.isMeshLoaded) {
        return `${vm.$t('common.loading')}...`;
      }
      return `${vm.$t('common.uploading')}...`;
    },

    currentErrorMessage: vm => {
      const { isDrawingUploadedMsg, isMeshUploadedMsg, isSvgUploadedMsg, isManufacturableMsg } = vm.status;
      return isDrawingUploadedMsg || isMeshUploadedMsg || isManufacturableMsg || isSvgUploadedMsg;
    },

    priceRange() {
      let returnData;
      try {
        returnData = JSON.parse(this.part.PriceMOQ);
      } catch {
        returnData = false;
      }
      return returnData;
    },

    customerPriceRange() {
      if (!this.priceRange) {
        return [];
      }

      const reqQtyPriceIndex = this.priceRange
        .findIndex(range => range.Qty > this.part.ReqQty) - 1;

      if (reqQtyPriceIndex < 0) {
        return this.priceRange.slice(-1);
      }
      return this.priceRange
        .slice(reqQtyPriceIndex, Math.min(reqQtyPriceIndex + 3, this.priceRange.length));
    },

    sheetMetalInfoLabels(vm) {
      const labels = {};
      if (this.part) {
        const bends = _.get(this.part, 'AnalysisData.no_of_bends', 0);
        const holes = _.get(this.part, 'AnalysisData.no_of_holes', 0);
        const threads = _.get(this.part, 'AnalysisData.threads', []);
        const countersink = _.get(this.part, 'AnalysisData.countersink_sizes', []);
        const counterbore = _.get(this.part, 'AnalysisData.counterbore_sizes', []);
        const laserEngraved = _.get(this.part, 'AnalysisData.laser_engraved_holes', []);
        const laserEngravedDepth = _.get(this.part, 'AnalysisData.laser_engraved_hole_depth', undefined);

        if (bends) {
          labels.bendsInfoLabel = bends;
        }
        if (this.part.is2dModel && bends === 0) {
          labels.bendsInfoLabel = bends;
        }

        if (holes) {
          labels.holesInfoLabel = holes;
        }
        if (threads.length) {
          if (this.part.Threads) {
            // For custom threads, no need to show Thread type, only show thread count
            const [, threadCount] = threads[0];
            labels.threadsInfoLabel = threadCount;
          } else {
            const supportedThreads = threads.filter(th => !(th[0] === 0 || th[0] === 1 || th[0] === -1));
            const unsupportedThreads = threads.filter(th => (th[0] === 0 || th[0] === 1 || th[0] === -1));
            const threadLabel = supportedThreads.map(([item, count]) => `${count} ${vm.$t('common.pieces')} ${item.split(' ')[0]}`);
            if (unsupportedThreads.length) {
              threadLabel.push(`${unsupportedThreads.length} ${vm.$t('common.pieces')} ${vm.$t('common.unsupported')}`);
            }
            labels.threadsInfoLabel = threadLabel.join(', ').replace(/, ([^,]*)$/, ` ${vm.$t('common.andSymbol')} $1`);
          }
        }
        if (countersink.length) {
          labels.countersinkInfoLabel = countersink.reduce((accumulator, item) => accumulator + item[2], 0);
        }
        if (counterbore.length) {
          labels.counterboreInfoLabel = counterbore.reduce((accumulator, item) => accumulator + item[2], 0);
        }
        if (laserEngraved.length) {
          labels.laserEngravedInfoLabel = laserEngravedDepth ? `${parseFloat(laserEngravedDepth).toFixed(2)}mm` : '';
        }
      }
      return labels;
    },

    turningInfoLabels() {
      const labels = {};
      if (this.part) {
        const holes = _.get(this.part, 'AnalysisData.holes', 0);
        if (holes) {
          labels.holesInfoLabel = holes;
        }
        const chamfer = _.get(this.part, 'AnalysisData.chamfer', 0);
        if (chamfer) {
          labels.chamfersInfoLabel = chamfer;
        }

        const internalFillets = _.get(this.part, 'AnalysisData.internal_fillets', 0);
        const externalFillets = _.get(this.part, 'AnalysisData.external_fillets', 0);
        if (internalFillets || externalFillets) {
          labels.filletsInfoLabel = parseInt(internalFillets, 10) + parseInt(externalFillets, 10);
        }

        const millings = _.get(this.part, 'AnalysisData.machine_millings', 0);
        if (millings) {
          labels.millingsInfoLabel = millings;
        }

        const threadsOnXAxis = _.get(this.part, 'AnalysisData.threads_on_x_axis', 0);
        const threadsOnYAxis = _.get(this.part, 'AnalysisData.threads_on_y_axis', 0);
        if (threadsOnXAxis || threadsOnYAxis) {
          labels.threadsInfoLabel = parseInt(threadsOnXAxis, 10) + parseInt(threadsOnYAxis, 10);
        }
      }

      const counterbore = _.get(this.part, 'AnalysisData.counterbore', 0);
      if (counterbore) {
        labels.counterboreInfoLabel = counterbore;
      }

      const countersink = _.get(this.part, 'AnalysisData.countersink', 0);
      if (countersink) {
        labels.countersinkInfoLabel = countersink;
      }

      return labels;
    },

    infoLabels(vm) {
      if (vm.isSheetMetalMachine) {
        return vm.sheetMetalInfoLabels;
      }
      if (vm.isTurningMachine) {
        return vm.turningInfoLabels;
      }
      return {};
    },

    needSvg(vm) {
      const laserEngraving = _.get(this.part, 'AnalysisData.laser_engraved_holes', []);
      return vm.isSheetMetalMachine && laserEngraving.length > 0;
    },

    needPdf() {
      return this.part.isSheetMetalPart && this.part.is2dModel;
    },

    showCustomFields() {
      return this.part.isSheetMetalPart && this.part.is2dModel;
    }
  },

  watch: {
    selectedThickness: {
      immediate: true,
      handler(thickness) {
        if (thickness) {
          this.part.MaterialThicknessStr = thickness.Description;
          this.part.MaterialThicknessStrCHS = thickness.DescriptionCHS;
          this.part.RawMaterial = thickness ? thickness.Item : '';
          this.fetchFinishes();
        }
      },
    },

    'part.MaterialSeries': {
      handler() {
        if (this.part.is2dModel) {
          return;
        }
        this.selectClosestAvailableThickness();
      },
    },

    currentErrorMessage: {
      immediate: true,
      handler(newMsg, oldMsg) {
        if (!(newMsg && !oldMsg)) {
          return;
        }
        ddlogger.logger.error('error', {
          action: 'uploadpart',
          error: newMsg,
          type: 'TempPart',
          author: this.createdById,
        });
      }
    }
  },

  methods: {
    ...mapActions('parts', { updatePart: 'update' }),

    checkTranslation(translation) {
      const words = ['Process', '请查看工艺细节指南'];
      return words.some(word => translation.includes(word));
    },
    removeLinklabel(string) {
      return string.replace(this.$t('CSIError.ProcessLimitations.LinkLabel'), '');
    },
    checkErrorMessage(errorValue) {
      const error = errorValue.replace(' - ', ' ').trim();
      const errorArray = error.split(' ');
      return errorArray.length > 1;
    },
    errorFormat(errorValue) {
      const errorArray = errorValue.replace(' - ', ' ').trim().split(' ');
      const errorTitle = this.$t(errorArray.shift());
      let bulletError = '';
      errorArray.forEach(errorElt => {
        bulletError += `<li>${this.$t(errorElt.trim())}</li>`;
      });
      return `${errorTitle}<ul>${bulletError}</ul>`;
    },
    getTranslationSeries(value) {
      return v => v[value].replace(this.$refs.materialSelector.selectedItems[0].Description, '');
    },
    getTranslationFinish(value) {
      return v => v[value].replace(`${this.$refs.finishSelector.selectedItems[0][value]}} - `, '');
    },
    fetchFinishes() {
      Finish.find({
        query: {
          Item: this.isSheetMetalMachine ? this.selectedThickness.Item : this.selectedThickness.Category,
          thicknessId: this.selectedThickness._id,
        },
      });
    },
    uploadThumbnail() {
      if (this.status.isThumbnailUploaded) {
        return;
      }

      try {
        this.$nextTick(async () => {
          this.isMeshVisible = true;
          const canvas = this.$el.getElementsByTagName('canvas')[0];
          const image = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

          const fd = new FormData();
          fd.append('file', image, `${this.part.DrawingRowPointer}.PNG`);
          const uploadUrl = `${process.env.VUE_APP_API_URL}upload`;

          await fetch(uploadUrl, {
            method: 'POST',
            headers: {
              Authorization: this.accessToken,
            },
            body: fd,
          });

          await this.part.save({
            data: {
              'EstimateLineStatus.isThumbnailUploaded': true,
            },
          });
        });
      } catch (e) {
        console.error(e);
      }
    },
    selectClosestAvailableThickness() {
      if (this.detectedThickness === 0 || _.isEmpty(this.thicknesses)) {
        return;
      }
      if (this.isSheetMetalMachine) {
        const [closestToDetected] = this.thicknesses
          .map(t => {
            /* T=2.25mm becomes 2.25, all thicknesses are formatted this way */
            const thickness = parseFloat(t.Description.slice(0, -2));
            const difference = Math.abs(thickness - this.detectedThickness);

            return { difference, ...t };
          })
          .sort((t1, t2) => t1.difference - t2.difference);

        if (closestToDetected.difference === 0) {
          this.part.MaterialThickness = closestToDetected._id;
          this.part.MaterialThicknessStr = closestToDetected.Description;
          this.part.MaterialDimensions = {
            SheetWidth: closestToDetected.SheetWidth,
            SheetLength: closestToDetected.SheetLength
          };
        }
      } else {
        const [closestToDetected] = this.thicknesses
          .map(t => {
            /* T=2.25mm becomes 2.25, all thicknesses are formatted this way */
            const thickness = parseFloat(t.Description.slice(0, -2));
            return { thickness, ...t };
          })
          .sort(th => th.thickness);
        this.part.MaterialThickness = closestToDetected._id;
        this.part.MaterialThicknessStr = closestToDetected.Description;
      }
    },
    checkIfErrorIsExiste(errorCode) {
      return _.get(this.part, 'AnalysisData.errors_and_warnings', []).some(i => i.code === errorCode);
    },

    addCustomDataToEngineResponse() {
      const valid = this.$refs.customDataToAddForm.validate();
      if (!valid) {
        return;
      }
      const threadToAdd = 'M3x0.5';
      const threads = [[threadToAdd, parseInt(this.customThreadCountToAdd, 10)]];
      this.part.patch({ data: { 'AnalysisData.threads': threads } });
    },

    addThreads() {
      const threadToAdd = 'M3x0.5';
      let threads = [];
      if (this.part.Threads > 0) {
        threads = [[threadToAdd, this.part.Threads]];
      }
      this.part.AnalysisData = { ...this.part.AnalysisData, threads };
    },

    addCountersinks() {
      let countersinks = [];
      if (this.part.Countersinks > 0) {
        countersinks = [[0, 0, this.part.Countersinks]];
      }
      this.part.AnalysisData = { ...this.part.AnalysisData, countersink_sizes: countersinks };
    },

    addCounterbores() {
      let counterbores = [];
      if (this.part.Counterbores) {
        counterbores = [[[0, 0], [0, 0], this.part.Counterbores]];
      }
      this.part.AnalysisData = { ...this.part.AnalysisData, counterbores_sizes: counterbores };
    },

  },
};
</script>

<style scoped>
.calculateCostOk {
  color: #2c984e;
}

.center-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

pre {
  white-space: pre-wrap;
}

th {
  height: auto;
}

.inputHeight::v-deep .v-input__control {
  min-height: 20px !important;
}

::v-deep .v-text-field__details {
  margin-bottom: 0 !important;
}

::v-deep .v-input__prepend-outer {
  margin: 4px 4px 4px 0 !important;
}

::v-deep .v-list-item {
  min-height: 30px;
}

.text-field ::v-deep .v-input__slot input {
  padding: 0 !important;
}

.text-field ::v-deep .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-input__control {
  min-height: 10px !important;
}

.hidden {
  visibility: hidden;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.part-thumbnail:hover {
  cursor: pointer;
}

::v-deep .v-skeleton-loader__avatar {
  width: 150px !important;
  height: 150px !important;
}

::v-deep .v-input--radio-group__input {
  display: flex;
  justify-content: center;
}

.blip {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-green 2s infinite;
}

.blip-3d {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  height: 150px;
  width: 150px;
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
</style>
