<template>
  <v-menu
    ref="menu"
    v-model="menuPicker"
    :close-on-content-click="false"
    :return-value.sync="data"
    transition="scale-transition"
    min-width="290px"
    nudge-top="-6"
    offset-y
    offset-overflow
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="data"
        v-mask="'##:##'"
        v-bind="$attrs"
        append-icon="mdi-clock-outline"
        hint="hh:mm"
        :error="invalidTime"
        :error-messages="invalidTimeMessage"
        v-on="{ ...$listeners, ...on }"
        @blur="onBlur"
      />
    </template>

    <v-time-picker
      v-if="menuPicker"
      v-model="data"
      format="24hr"
      v-bind="$attrs"
      :locale="$i18n.locale"
      v-on="$listeners"
      @click:minute="$refs.menu.save(data)"
    />
  </v-menu>
</template>

<script>
import ModelMixin from '../../mixins/model';

export default {
  mixins: [
    ModelMixin,
  ],

  props: {
    value: { type: String, default: '' },
  },

  data() {
    return {
      time: '',
      menuPicker: false,
      invalidTime: false,
      invalidTimeMessage: undefined,
    };
  },

  watch: {
    data(newValue) {
      if (_.isEmpty(newValue)) {
        return;
      }

      const t = moment(newValue, 'HH:mm', true);
      if (t.isValid()) {
        this.pickerTime = t.format('HH:mm');
      }
    },

    pickerTime(newValue) {
      this.data = newValue;
    },
  },

  mounted() {
    this.pickerTime = this.data;
  },

  methods: {
    validate(s) {
      if (_.isEmpty(s)) {
        return;
      }

      const t = moment(s, 'HH:mm', true);
      this.invalidTime = !t.isValid();
      this.invalidTimeMessage = this.invalidTime ? this.$t('common.error.time') : undefined;
    },

    onBlur(event) {
      let t = event.relatedTarget;
      while (t) {
        if (t.classList && t.classList.contains('v-picker--time')) {
          break;
        }
        t = t.parentNode;
      }
      if (!t) {
        this.menuPicker = false;
      }
      this.$refs.menu.save(this.data);
      this.validate(this.data);
    },
  }
};
</script>
