// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';
import i18n from '@/plugins/i18n';

const servicePath = 'order-lines';

const Model = class OrderLine extends BaseModel {
    static modelName = 'OrderLine';

    static instanceDefaults() {
      return {
        CoNum: '',
        CoLine: '',
        Item: '',
        CustItemName: '',
        CustItemDesc: '',
        Quantity: 0,
        Price: 0,
        MaterialStr: '',
        MaterialSeriesStr: '',
        MaterialThicknessStr: '',
        MaterialFinishStr: '',
        MaterialFinishOptionStr: '',
        Status: '',
        DrawingRowPointer: '',
        ThumbnailItemId: '',
        CreatedByUserName: '',
        CreateDate: null,
        DrawingNumber: '',
        DueDate: null,
      };
    }

    static setupInstance(data) {
      if (data.MaterialStr) {
        this.materialSeriesShortened = data.MaterialSeriesStr.replace(data.MaterialStr, '').trim();
      }
    }

    get materialTranslation() {
      return i18n.locale === 'en-US' ? this.MaterialStr : this.MaterialStrChs;
    }

    get materialThicknessTranslation() {
      return i18n.locale === 'en-US' ? this.MaterialThicknessStr : this.MaterialThicknessStrChs;
    }

    get materialSeriesTranslation() {
      return i18n.locale === 'en-US' ? this.materialSeriesShortened : this.MaterialSeriesStrChs;
    }

    get materialFinishTranslation() {
      return i18n.locale === 'en-US' ? this.MaterialFinishStr.replace(/-/g, '') : this.MaterialFinishStrChs.replace(/-/g, '');
    }

    get materialFinishOptionTranslation() {
      return i18n.locale === 'en-US' ? this.MaterialFinishOptionStr.replace(/-/g, '') : this.MaterialFinishOptionStrChs.replace(/-/g, '');
    }

    get toleranceTranslation() {
      if (!this.Tolerance) {
        return '-';
      }
      return i18n.t(`views.instantQuote.tolerance${this.Tolerance.replace('Precision', '').trim()}`);
    }

    get is2dModel() {
      if (this.CustFileName) {
        return this.CustFileName.split('.').pop().replaceAll(' ', '').toUpperCase() === 'DXF';
      }
      return false;
    }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
  }),

  store: makeServiceStore({ servicePath, Model }),
};
