export default {
  props: {
    /** Columns to display from items/entities in the table
     *    text: string
     *    value: string
     *    align?: 'start' | 'center' | 'end'
     *    sortable?: boolean
     *    filterable?: boolean
     *    divider?: boolean
     *    class?: string | string[]
     *    width?: string | number
     *    filter?: (value: any, search: string, item: any) => boolean
     *    sort?: (a: any, b: any) => number
     */
    headers: { type: Array, default: () => [] },

    // Radial menu items to display when table row is clicked
    menuItems: { type: Array, default: () => [] },

    // Name of the entity key for the table
    itemKey: { type: String, default: '_id' },

    // Name of the default sort entity key for the table
    sortKey: { type: [String, Array], default: 'name' },

    // Wheter the sorting is descending or not in the table
    sortDesc: { type: [Boolean, Array], default: false },

    // Items/entities to display in the table
    items: { type: Array, default: () => [] },

    // Are items/entities still loading
    loading: { type: Boolean, default: false },

    // Text to display while loading
    loadingText: String,

    // Selected items/entities in the table
    selected: { type: Array, default: () => [] },

    // Hovered row index in the table
    hovered: { type: Number, default: () => -1 },

    // Table page being displayed
    page: { type: Number, default: 1 },

    // Table number of records being displayed
    limit: { type: Number, default: -1 },

    // Total number of items on the server
    total: { type: Number, default: 0 },

    // Should we hide the search text field
    hideSearch: Boolean,

    // Default search text
    search: String,

    // Current entity selected when opening the radial menu
    entity: Object,

    // Default filter value
    filter: String,

    // Default remote + local filter value
    query: Object,

    // Default local filter value
    localQuery: Object,

    // Default remote filter value
    remoteQuery: Object,

    // Delay before search is triggered when changing currentSearch
    searchDelay: { type: Number, default: 500 },
  },

  data() {
    return {
      currentPage: 1,
      currentLimit: 10,
      currentTotal: 0,
      currentEntity: undefined,
      currentSortKey: '',
      currentSortDesc: false,
      currentSelected: [],
      currentHovered: -1,
      currentSearch: undefined,
      currentFilter: undefined,
      currentQuery: undefined,
      currentRemoteQuery: undefined,
      currentLocalQuery: undefined,
      delayedSearch: undefined,
      delayedSearchTimeout: 0,
    };
  },

  watch: {
    entity(newValue) {
      this.currentEntity = newValue;
    },

    page(newValue) {
      this.currentPage = newValue;
    },

    limit(newValue) {
      this.currentLimit = newValue;
    },

    total(newValue) {
      this.currentTotal = newValue;
    },

    sortKey(newValue) {
      this.currentSortKey = newValue;
    },

    sortDesc(newValue) {
      this.currentSortDesc = newValue;
    },

    selected(newValue) {
      this.currentSelected = newValue;
    },

    hovered(newValue) {
      this.currentHovered = newValue;
    },

    filter(newValue) {
      this.currentFilter = newValue;
    },

    query(newValue) {
      this.currentQuery = newValue;
    },

    remoteQuery(newValue) {
      this.currentRemoteQuery = newValue;
    },

    localQuery(newValue) {
      this.currentLocalQuery = newValue;
    },

    search(newValue) {
      this.currentSearch = newValue;
    },

    currentEntity(newValue) {
      this.$emit('update:entity', newValue);
    },

    currentPage(newValue) {
      this.$emit('update:page', newValue);
      this.refresh();
    },

    currentLimit(newValue) {
      this.$emit('update:limit', newValue);
      this.refresh();
    },

    currentSortKey(newValue) {
      this.$emit('update:sort-key', newValue);
      this.refresh();
    },

    currentSortDesc(newValue) {
      this.$emit('update:sort-desc', newValue);
      this.refresh();
    },

    currentSelected(newValue) {
      this.$emit('update:selected', newValue);
    },

    currentHovered(newValue) {
      this.$emit('update:hovered', newValue);
    },

    currentFilter(newValue) {
      this.$emit('update:filter', newValue);
      this.refresh();
    },

    currentQuery(newValue) {
      this.$emit('update:query', newValue);
      this.refresh();
    },

    currentRemoteQuery(newValue) {
      this.$emit('update:remote-query', newValue);
      this.refresh();
    },

    currentLocalQuery(newValue) {
      this.$emit('update:local-query', newValue);
      this.refresh();
    },

    currentSearch(newValue) {
      this.$emit('update:search', newValue);

      clearTimeout(this.delayedSearchTimeout);
      this.delayedSearchTimeout = setTimeout(() => {
        this.delayedSearch = this.currentSearch;
        this.refresh();
      }, this.searchDelay);
    },
  },

  mounted() {
    this.currentSortKey = this.sortKey;
    this.currentSortDesc = this.sortDesc;
    this.currentEntity = this.entity;
    this.currentPage = this.page;
    this.currentLimit = this.limit;
    this.currentSelected = this.selected;
    this.currentHovered = this.hovered;
    this.currentSearch = this.search;
    this.currentFilter = this.filter;
    this.currentQuery = this.query;
    this.currentRemoteQuery = this.remoteQuery;
    this.currentLocalQuery = this.localQuery;
  },

  methods: {
    refresh() {
      // override if need be
    },
  },
};
