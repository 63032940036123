<template>
  <v-sheet class="pa-0">
    <v-menu
      data-cy="my-part-second-horizontal-grid-view"
      open-on-hover
      bottom
      offset-x
      left
      z-index="999999"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
          v-bind="attrs"
          tag="span"
          data-cy="my-part-price-range-box-grid"
          class="d-inline-block pa-0 text-center px-auto font-weight-medium"
          style="border-radius:5px"
          v-on="on"
        >
          <span class="caption text--secondary">
            # {{ $t('common.items') }}
          </span>

          <div class="pa-0">
            <span
              class="font-weight-bold ma-0 pa-0 text-center"
              data-cy="my-part-price-range-grid"
            >
              <span style="color:#128347"> {{ part.length }}</span>
            </span>
            <span
              class="mdi mdi-magnify  rounded d-inline-flex align-center justify-center ml-1 white--text"
              style="background-color: #5FCE79; color: white !important; width: 25px; font-weight: bold !important"
            />
          </div>
        </v-sheet>
      </template>

      <div style="background-color: white" class="pa-2">
        <span class="body-2 font-weight-bold">{{ $t('views.rfqs.quantity') }} / {{ $t('common.item') }}</span>
        <div
          style="display: grid;width: min-content;grid-template-columns: max-content max-content;"
        >
          <template v-for="(item,index) in part">
            <span :key="`qty-${index}`" style="color: #8D8D8D">
              {{ item.Qty }} {{ '\xa0' }}
            </span>
            <span :key="`price-${index}`">
              {{ getProductDescription(item.Item) }}
            </span>
          </template>
        </div>
      </div>
    </v-menu>
  </v-sheet>
</template>

<script>

import { models } from 'feathers-vuex';

import {
  defineComponent,
} from '@vue/composition-api';

const { HardwareProduct } = models.api;

export default defineComponent({
  props: {
    /**
     * Manipulate the way field's value displays
     */
    part: {
      type: Array,
      default: () => []
    },
  },

  setup() {
    const getProductDescription = id => (HardwareProduct.getFromStore(id) ? HardwareProduct.getFromStore(id).description : '');

    return {
      getProductDescription
    };
  },
});

</script>
