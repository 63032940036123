// eslint-disable-next-line max-len
import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';

const servicePath = 'temp-rfqs';

const Model = class TempRfq extends BaseModel {
  static modelName = 'TempRfq';

  static instanceDefaults() {
    return {
      isCsi: false,
      csiRfqId: '',
    };
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
    extend() {
      return {
        state: {
          tempRfqIdInProgress: 'new',
        },
        getters: {
          getTempRfqIdInProgress: state => state.tempRfqIdInProgress,
        },
        mutations: {
          setTempRfqIdInProgress: (state, id) => {
            state.tempRfqIdInProgress = id || 'new';
          },
        },
        actions: {}
      };
    },
  }),

  store: makeServiceStore({ servicePath, Model }),
};
