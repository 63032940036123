import Vue from 'vue';
import VueI18n from 'vue-i18n';
import appMessages from '../locales';
import _ from './simulateLodash';

/** Add/replace client's i18n translations */
// import komacutMessages from '../_komacut/locales';

Vue.use(VueI18n);

export default new VueI18n({
  fallbackLocale: 'en-US',
  messages: _.defaultsDeep(
    {},
    appMessages,
  ),
});
