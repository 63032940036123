<template>
  <v-col class="py-0" cols="auto">
    <v-row>
      <v-col class="py-0 align-self-center" cols="auto">
        <span>{{ label }}</span>
      </v-col>

      <v-col class="pa-0 align-self-center" cols="auto">
        <v-select
          v-model="data"
          class="version-select"
          :style="{ 'max-width': width }"
          :items="items"
          :item-text="itemText"
          :item-value="itemValue"
          background-color="success lighten-5"
          v-bind="$attrs"
          hide-details
          dense
          solo
        />
      </v-col>

      <v-col class="pa-0 pl-2 align-self-center" cols="auto">
        <v-btn
          color="success"
          icon
          @click="$emit('new-version')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import ModelMixin from '../../mixins/model';

export default {
  mixins: [
    ModelMixin,
  ],

  props: {
    value: Number,
    label: String,
    items: { type: Array, default: () => [] },
    itemText: [String, Function],
    itemValue: [String, Function],
    itemDisabled: [String, Function],
    width: String,
  }
};
</script>

<style scoped>
.version-select {
  max-width: 5em;
}
</style>
