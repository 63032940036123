<template>
  <!-- TODO handle scrolling - style="overflow-x: scroll;" -->
  <div class="fill-height">
    <v-app-bar
      class="elevation-0"
      app
      dark
    >
      <v-toolbar-title class="font-weight-light">
        <div style="display: flex;">
          <slot name="toolbar-title" />
          <v-btn
            v-if="user.rights.supportUser"
            class="secondary ml-2"
            :disabled="isPatchPending"
            :loading="isPatchPending"
            @click.stop="logoutFromCompany"
          >
            {{ $t('common.logoutFromCompany') }}
          </v-btn>
        </div>
      </v-toolbar-title>

      <v-spacer />
      <v-toolbar-items class="justify-center align-center">
        <slot name="toolbar-icons" />
        <v-select
          :value="locale"
          class="d-flex "
          style="min-width:130px!important; max-width: 130px; padding-left: 0 !important;"
          :items="langList"
          item-value="value"
          item-text="label"
          solo
          flat
          background-color="#272727"
          hide-details
          single-line
          @change="(currentLang) => {setLocale({ $i18n: i18n, locale: currentLang });} "
        >
          <template #prepend>
            <v-icon class="ma-0" color="primary">
              mdi-web
            </v-icon>
          </template>
          <template slot="selection" slot-scope="data">
            <span style="color: #2c984e">{{ data.item.label }}</span>
          </template>
        </v-select>
        <v-menu
          open-on-hover
          offset-y
          class="justify-center align-center"
        >
          <template v-slot:activator="{ on }">
            <v-list-item style="min-width: 300px !important;max-width: 300px" v-on="on">
              <core-avatar
                class="mr-3"
                data-cy="main-menu"
                :hide-avatar="currentUser.isAvatarIsHidden"
                :url-preview="currentUser.previewAvatarUrl"
                :user="currentUser"
                :size="60"
                :display-border="true"
                :is-img-loading="currentUser.isAvatarLoading"
              />
              <v-list-item-content>
                <v-list-item-title
                  data-cy="user-name-main-menu"
                  :class="[{loading: !currentUser.firstName && !currentUser.lastName}]"
                >
                  {{ currentUser.firstName + ' ' + currentUser.lastName }}
                </v-list-item-title>
                <v-list-item-subtitle
                  data-cy="company-name-main-menu"
                  :class="[{loading: !_.get(company, 'CompanyName')}]"
                >
                  {{ _.get(company, 'CompanyName') }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>
                  mdi-menu-down
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-card>
            <v-list data-cy="company-name-main-menu-options">
              <v-list-item
                v-for="(option, i) in preferenceMenuOptions"
                :key="i"
                :data-cy="`main-menu-${option.text.replace(' ', '-').toLowerCase()}-option`"
                :disabled="!option.enabled"
                @click="$router.push({ path: option.to })"
              >
                <v-icon left>
                  {{ option.icon }}
                </v-icon>
                <v-list-item-content>
                  {{ option.text }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-badge
          data-cy="current-order-items-count"
          :value="currentOrderItemsCount"
          color="error"
          overlap
          left
          :content="currentOrderItemsCount"
        >
          <v-btn
            data-cy="go-to-the-order-form"
            color="primary"
            text
            large
            to="/order/new"
          >
            <v-icon left large>
              mdi-view-list-outline
            </v-icon>
            {{ $t('views.confirmedOrder.title') }}
          </v-btn>
        </v-badge>
      </v-toolbar-items>

      <template #extension>
        <slot name="extension" />
      </template>
    </v-app-bar>

    <slot />

    <v-footer
      class="pr-3 pa-0 grey--text caption"
      height="80px"
      fixed
    >
      <v-row class="align-center justify-center fill-height text-center" style="min-width:1000px; margin-left: 220px;">
        <v-col style="overflow: hidden; text-overflow: ellipsis; width:1px">
          <span style="white-space: nowrap;">
            Komacut&reg; www.komacut.com
            &copy;
            {{ new Date().getFullYear() }}
            <a class="text-decoration-none" href="https://www.komacut.com/" target="_blank">
              {{ $t('common.allRights') }}
            </a>
            <br>
            {{ $t('common.footerRight') }}
            <a
              class="text-decoration-none"
              href="https://komacut.com/legal/privacy-policy/"
              target="_blank"
            >{{ $t('common.privacy') }}</a>
            {{ $t('common.and') }}
            <a
              class="text-decoration-none"
              href="https://komacut.com/legal/platform-terms/"
              target="_blank"
            >{{ $t('common.terms') }}.</a>
          </span>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { models } from 'feathers-vuex';
import i18n from '@/plugins/i18n';
import UpdateEntityMixin from '../../mixins/update-entity';
import ServiceMixin from '../../mixins/service';
import TableMixin from '../../mixins/table';

const { Company, User } = models.api;

export default {
  mixins: [
    UpdateEntityMixin,
    ServiceMixin,
    TableMixin,
  ],
  data() {
    return {
      langList: [
        { label: 'English', value: 'en-US' },
        { label: '中文', value: 'cn' }
      ],
      i18n,
    };
  },

  computed: {
    ...mapGetters('app', [
      'appVersion',
      'currentOrder',
      'currentOrderItemsCount',
    ]),
    ...mapState('app', ['locale']),
    ...mapState('auth', [
      'user',
    ]),
    ...mapState('users', ['isPatchPending']),
    HardwareCategory: () => models.api.HardwareCategory,

    HardwareProduct: () => models.api.HardwareProduct,

    currentUser: vm => User.getFromStore(vm.user._id),

    company: () => Company.getFromStore(0),

    preferenceMenuOptions: vm => {
      const { rights = {} } = vm.currentUser;
      return [
        {
          icon: 'mdi-cog',
          text: vm.$t('menus.settings'),
          to: '/settings/profile',
          enabled: (vm.currentUser.isAdmin || rights.userManagement)
        },

        { icon: 'mdi-logout', text: vm.$t('menus.logout'), to: '/logout', enabled: true },
      ];
    },
  },
  created() {
    // No need to fetch Hardware items as for support user session token not set to CSI.
    // and also we disable left side navigation bar.
    if (this.$route.name === 'Support') {
      return;
    }
    const categoriesInStore = this.HardwareCategory.store.state['hardware-categories'].ids.length;

    if (categoriesInStore === 0) {
      this.HardwareCategory.find();
    }

    const productInStore = this.HardwareProduct.store.state['hardware-products'].ids.length;

    if (productInStore === 0) {
      this.HardwareProduct.find();
    }
  },
  methods: {
    ...mapActions('app', ['setLocale']),
    async logoutFromCompany() {
      await this.user.patch({ data: { shouldLogoutFromCustomerSession: true } });
      await this.$router.push('/logout');
    }
  },
};
</script>
<style scoped>
::v-deep .v-input__append-inner > .v-input__icon--append > .v-icon {
  color: green !important;
}

::v-deep .v-input__prepend-outer {
  margin-right: 0;
}
</style>
