import feathersClient, { makeServicePlugin, BaseModel, makeServiceStore } from '../plugins/feathers-client';
import { PAYMENT_STATUS } from '@/shared/types/paymentStatuses';
import router from '@/router';

const servicePath = 'payments';

const Model = class Payment extends BaseModel {
  static modelName = 'Payment';

  static instanceDefaults() {
    return {
      paymentUrl: '',
    };
  }
};

export default {
  plugin: makeServicePlugin({
    servicePath,
    service: feathersClient.service(servicePath),
    Model,
    // eslint-disable-next-line no-unused-vars
    extend({ store, module }) {
      return {
        state: {
          activePayment: null,
          isPaymentDialogOpen: false,
          isQrCodeDialogOpen: false,
        },
        getters: {
          activePayment: state => state.activePayment,
          isPaymentDialogOpen: state => state.isPaymentDialogOpen,
          isQrCodeDialogOpen: state => state.isQrCodeDialogOpen,
        },
        mutations: {
          setActivePayment: (state, payment) => {
            state.activePayment = payment;
          },
          setIsPaymentDialogOpen: (state, isPaymentDialogOpen) => {
            state.isPaymentDialogOpen = isPaymentDialogOpen;
          },
          setIsQrCodeDialogOpen: (state, isQrCodeDialogOpen) => {
            state.isQrCodeDialogOpen = isQrCodeDialogOpen;
          },
        },
        actions: {
          initPayment: (context, { payment, paymentData }) => {
            const clone = _.merge(payment, paymentData);
            context.commit('setIsPaymentDialogOpen', true);
            context.commit('setActivePayment', clone);
          },
          setIsPaymentDialogOpen: (context, isPaymentDialogOpen) => {
            context.commit('setIsPaymentDialogOpen', isPaymentDialogOpen);
          },
          setIsQrCodeDialogOpen: (context, isQrCodeDialogOpen) => {
            context.commit('setIsQrCodeDialogOpen', isQrCodeDialogOpen);
          },
          cancelActivePayment: context => {
            context.commit('setIsPaymentDialogOpen', false);
            context.commit('setIsQrCodeDialogOpen', false);
            context.commit('setActivePayment', null);
          },
        },
      };
    },
    handleEvents: {
      patched: (payment, utils) => {
        const activePayment = utils.model.store.getters['payments/activePayment'] || {};

        const { provider, status } = payment;
        if (
          provider.name === 'lian-lian'
          && provider.options.type === 'I'
          && status === PAYMENT_STATUS.SUCCEEDED
          && activePayment._id === payment._id
        ) {
          utils.model.store.dispatch(`${servicePath}/cancelActivePayment`);
          router.push({
            path: new URL(provider.options.redirectUrl).pathname,
            query: {
              orderNo: payment._id,
            },
          });
        }
        return true;
      }
    }
  }),

  store: makeServiceStore({ servicePath, Model }),
};
