<template>
  <v-container class="ma-0 pa-0">
    <v-card class="pa-0" elevation="0">
      <v-radio-group
        class="pl-2"
        :value="value"
        @change="$emit('input', $event);"
      >
        <v-radio
          v-for="(address, i) in shippingAddresses"
          :key="i"
          :value="address.ShipToId"
          class="py-2 mr-3"
        >
          <template #label>
            <div class="d-flex justify-space-between align-center address-radio-options py-1" style="width: 100%">
              <div>
                <div class="subtitle-2 ">
                  {{ address.name }}
                </div>
                <div>{{ formatAddressText(address) }}</div>
              </div>
              <v-btn
                class="justify-end"
                icon
                small
                @click.stop="openAddressFormDialog(address)"
              >
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <v-card-actions style="position: relative;">
        <v-btn
          text
          class="mt-4 ml-4"
          color="primary"
          style="position: absolute; top: 10px; left: -20px"
          @click="openAddressFormDialog();"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          New Address
        </v-btn>
      </v-card-actions>
    </v-card>

    <core-dialog
      :value="addressModal.isActive"
      :is-loading="isShippingAddressCreatePending || isShippingAddressPatchPending"
      width="60%"
      type="confirmation"
      persistent
      @click:ok="saveAddress"
      @click:cancel="addressModal.isActive = false; addressModal.address = null;"
    >
      <template #title>
        New Address
      </template>

      <template #content>
        <form-address v-model="addressModal.address" address-type="business" />
      </template>
    </core-dialog>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex';
import { mapState } from 'vuex';
import AddressFormatterMixin from '@/mixins/address-formatter';

const {
  ShippingAddress,
} = models.api;
export default { name: 'OrderShippingAddress',
  mixins: [AddressFormatterMixin],
  props: ['shippingAddresses', 'value', 'moveButton'],
  data() {
    return {
      content: {},
      addressModal: {
        isActive: false,
        address: null,
      },
      tempOrder: {
        items: [],
        expediteOption: null,
        shippingAddress: null,
        forwarder: null,
      },
    };
  },
  computed: { ...mapState('shipping-addresses', {
    isShippingAddressPatchPending: 'isPatchPending',
    isShippingAddressCreatePending: 'isCreatePending',
  }),

  ...mapState('orders', {
    isOrderCreatePending: 'isCreatePending',
  }) },

  methods: {
    openAddressFormDialog(address) {
      this.addressModal.address = address || new ShippingAddress({});
      this.addressModal.isActive = true;
    },
    closeAddressFormDialog() {
      this.addressModal.address = null;
      this.addressModal.isActive = false;
    },
    async saveAddress() {
      await this.addressModal.address.save();
      this.addressModal.isActive = false;
    },
  } };
</script>
<style scoped>
.address-radio-options:hover {
  background-color: rgba(0,0,0,.03);
}

.button-moved {
  position: absolute;
  top: 10px;
  left: -20px
}
</style>
